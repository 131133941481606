.create-account-modal {
  width: 501px;
  max-width: 501px;
  height: 331px;
  border-radius: 30px;
  background: var(--white);
  box-shadow: 0px 5px 99px rgba(var(--gray-rgb), 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--content {
    padding: 34px 36px 0 43px;
  }

  &--title {
    font-weight: 600;
    font-size: var(--fs-25);

    line-height: 32px;
    text-align: left;
    color: var(--primary);
    margin-bottom: 3px;
  }

  &--description {
    font-weight: normal;
    font-size: var(--fs-14);
    line-height: 20px;
    text-align: left;
    color: var(--gray-400);
  }

  &--buttons {
    margin-top: 22px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    [ayn-button] {
      flex: 1;

      &:last-child {
        margin-left: 10px;
        min-width: 210px;
      }
    }

    .btn[disabled] {
      background: var(--gray-25);
    }
  }

  &--footer-text {
    width: 501px;
    height: 97px;
    border-radius: 0px 0px 30px 30px;
    background: #f8f9fc;
    padding: 29px 68px 28px 43px;
    font-weight: normal;
    font-size: var(--fs-14);
    line-height: 20px;
    text-align: left;
    color: var(--gray-400);
    margin-top: auto;

    u {
      text-decoration: underline;
      color: var(--primary);
    }
  }
}
