.ayn-alert {
  border-radius: 25px;
  background: var(--gray-500);

  [data-theme='dark'] & {
    background: var(--white);
  }

  border: 1px solid var(--gray-300);
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 25px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -11px;
    width: 412.51px;
    height: 135.92px;
    background: linear-gradient(to top, rgba(var(--white-rgb), 0) 0%, rgba(var(--white-rgb), 0.1) 100%);
    transform: skew(-37deg, 0deg);
  }

  &-icon {
    margin-left: auto;
    margin-right: 15px;
    color: var(--white);
    @include darkModeColorDefault;
  }

  &--content {
    padding-left: 7px;

    &__title {
      font-weight: 600;
      font-size: var(--fs-20);
      line-height: 32px;
      color: var(--white);
      @include darkModeColorDefault;
    }

    &__description {
      font-size: var(--fs-14);
      line-height: 20px;
      color: var(--white);
      @include darkModeColorDefault;
    }
  }

  &--button {
    z-index: 1;
    right: 32px;

    ayn-icon {
      @include darkModeColorDefault;
    }
  }

  &--close {
    z-index: 1;
    position: absolute;
    right: 32px;
    cursor: pointer;
    color: var(--white);
    @include darkModeColorDefault;

    &:hover {
      transition-duration: 0.1s;
    }

    &:after {
      content: '';
      opacity: 0;
      transition: all 0.5s;
    }

    &:active:after {
      opacity: 1;
      transition: 0s;
    }
  }

  &.primary {
    background: var(--primary);
  }

  &.orange {
    background: var(--orange);
  }
}
