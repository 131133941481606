.aayn-page-header {
  text-align: center;

  &--title {
    color: var(--primary);

    @include darkModeColorDefault;

    line-height: 2rem;

    @include h(h1);
  }

  &--description {
    @include fs(1);
    color: var(--gray-500);
    line-height: 1;
    margin-top: 15px;
    font-weight: semi-bold;
  }
}
