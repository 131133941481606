.aayn-certificate-download {
  &--certificate-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    gap: 5px;
  }
  &--user-name,
  &--certificate-name {
    font-size: var(--fs-20);
  }
  &--actions {
    display: block;
    text-align: center;
  }
}
