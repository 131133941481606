@mixin title {
  font-weight: 600;
  font-size: var(--fs-18);
  line-height: 32px;
  text-align: left;
  color: var(--gray-500);
}

@mixin description {
  font-size: var(--fs-14);
  line-height: 20px;
  text-align: left;
  color: var(--gray-500);
}

.aayn-settings {
  display: flex;
  border-radius: 30px;
  padding: 40px 42px 40px;

  @include media-breakpoint-down(md) {
    padding: 10px;
  }

  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;

  border: 1px solid var(--white);
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));
  flex-direction: column;
  height: 100%;

  &-branding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;

    @include media-breakpoint-down(md) {
      padding: 10px;
    }

    &--custom {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 30px;
      border-radius: 25px;
      background: rgba(var(--white-rgb), 0.4);
      @include darkModeBgDefault;
      border: 1px solid var(--white);
      position: relative;
      align-items: flex-start;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: stretch;
      }

      &-header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;

        &__title {
          @include title();
        }

        &__description {
          @include description();
        }
      }

      &__file {
        display: inline-flex;
        @include content-center();
        width: 140px;
        height: 140px;
        border-radius: 20px;
        border: 1px solid var(--white);
        background: var(--white);

        &.error {
          box-shadow: 0 10px 30px 0 rgba(var(--gray-rgb), 0.08);
          border: solid 1px rgba(var(--orange-rgb), 0.6);
          background-color: rgba(var(--orange-rgb), 0.05);
        }
      }

      &__fileupload {
        display: inline-flex;
        @include content-center();
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23B3C1D4FF' stroke-width='2' stroke-dasharray='3%2c5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        flex-direction: column;
        width: 125px;
        height: 125px;
        border-radius: 15px;
        transition: 0.2s all;

        &:hover {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23333' stroke-width='2' stroke-dasharray='3%2c5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        }

        &__file_select {
          display: inline-flex;
          @include content-center();
          flex-direction: column;
          color: var(--gray-500);

          cursor: pointer;
        }

        input {
          display: none;
        }

        &__img {
          width: 110px;
          height: 110px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            border-radius: 15px;
            border: 1px solid var(--gray-200);
            object-fit: contain;
          }

          .btn {
            position: absolute;
            top: 27px;
            left: 27px;
            width: 56px;
            height: 56px;
            display: none;

            ayn-icon {
              margin-left: -12px;
            }
          }

          &:hover {
            img {
              opacity: 0.1;
              cursor: pointer;
            }

            .btn {
              display: flex;
              justify-content: center;
            }
          }
        }

        span {
          &:not(:last-child) {
            font-weight: 600;
            font-size: var(--fs-16);
            line-height: 20px;
            margin-top: 4px;
            color: var(--gray-500);
          }

          font-size: var(--fs-14);
          line-height: 20px;
          text-align: left;
          color: var(--gray-400);
        }
      }

      &-content {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        flex: 1;
        width: 100%;
        margin-top: 30px;
      }

      &-form {
        display: flex;
        padding: 30px;
        border-radius: 25px;
        background: rgba(var(--white-rgb), 0.4);
        @include darkModeBgDefault;
        border: 1px solid var(--white);
        position: relative;
        flex: 1;
        align-items: center;

        &-inputs {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1;
          gap: 20px;
          margin-left: 20px;

          &--item {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 10px;
            margin-top: 10px;
            gap: 20px;

            &-buttons {
              display: flex;
              gap: 10px;
            }
          }
        }
      }

      &-colors {
        display: flex;
        padding: 30px;
        flex: 1;
        border-radius: 25px;
        background: rgba(var(--white-rgb), 0.4);
        @include darkModeBgDefault;
        border: 1px solid var(--white);
        position: relative;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;

        ayn-color-picker {
          min-width: 250px;
        }
      }
    }
  }
}
