.aayn-ad-preview-instagram-single {
  .aayn-ad-preview-placeholder {
    margin-top: 15px;
  }

  &_image.full-width {
    margin-top: 16px;
    max-height: 408px;
    max-width: 408px;
    object-fit: contain;
    border-radius: 0;
  }

  &_content {
    &__labels {
      display: flex;
      flex-direction: column;
      justify-content: start;
    }

    &__body {
      font-size: var(--fs-16);
      margin: 17px 27px 0 27px;
      color: var(--gray-500);

      &-business_name {
        font-weight: bold;
        margin-right: 6px;
      }
    }

    &__cta {
      padding: 16px 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--body-bg);

      span {
        font-size: var(--fs-16);
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }
}
