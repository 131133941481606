.ayn-file-upload-process {
  width: 26.5rem;
  height: 6.125rem;
  border-radius: 1.25rem;
  background: var(--white);
  box-shadow: 0rem 0.3125rem 2.5rem rgba(var(--gray-rgb), 0.08);
  display: flex;
  align-items: center;
  padding-left: 1.1875rem;
  justify-content: center;

  .body-movin {
    width: 100%;
    height: 100%;
    max-width: 70px;

    ayn-lottie {
      height: 100%;
    }
  }

  .progress {
    width: 14.5rem;
    height: 2.6012rem;
    padding-left: 0.6875rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .progress-title {
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: var(--fs-18);

        line-height: 2rem;
        text-align: left;
        color: var(--gray-500);

        max-width: 11.875rem;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
      }

      .progress-status {
        font-weight: normal;
        font-size: var(--fs-12);

        line-height: 2rem;
        text-align: right;
        color: var(--gray-400);
      }
    }

    ayn-progressBar {
      display: inline-block;

      .ayn-progressbar {
        &.medium {
          min-width: 14.375rem !important;
          width: 14.375rem !important;
        }
      }
    }
  }

  .file-button {
    margin: auto 1.3125rem;
    display: flex;

    button {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 1.25rem;
      justify-content: center;
      align-items: center;
      padding: 0;

      .btn--icon {
        margin-left: 0;

        svg {
          margin-top: 3px;
        }
      }
    }

    &.disable {
      button {
        background: var(--gray-100);
      }
    }

    &.success {
      button {
        background: var(--success);
      }
    }

    &.delete {
      button {
        background: var(--orange);
      }
    }
  }
}
