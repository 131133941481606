.ayn-video-player {
  position: relative;
  max-width: 100%;
  height: 525px;
  border-radius: 20px;
  border: 1px solid var(--gray-100);
  background: var(--gray-500);
  iframe,
  object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 525px;
  }
}
