.all-campaigns--table-google-remove-btn {
  @extend .all-campaigns--table-edit-btn;

  &[disabled] {
    background-color: var(--gray-100);

    svg {
      path {
        fill: var(--gray-300);
      }
    }
  }
}

.ayn-expandable-table--row.active-primary.active {
  .all-campaigns--table-google-remove-btn {
    background-color: rgba(var(--orange-rgb), 0.3);

    svg {
      path {
        fill: var(--white);
      }
    }
  }
}

.aayn-all-campaigns--show-pm-campaigns {
  margin-left: 16px;
  display: inline-flex;
  align-items: center;

  ayn-checkbox {
    margin-right: 12px;
  }
}
