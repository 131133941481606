.aayn-notifications--body {
  top: 70px !important;
  left: -190px !important;
  background-color: var(--white);
  border-radius: 20px;
  width: 439px;
  box-shadow: 0 5px 30px rgba($color: #3b2a82, $alpha: 0.08);
  padding: 28px 9px 31px 30px;

  &-header {
    color: var(--primary);
    font-size: var(--fs-18);

    margin-bottom: 16px;

    @include flex-align-center;
    @include fw('semibold');

    ayn-icon[name='bell'] {
      color: var(--primary);
      margin-right: 9px;
      width: 20px;
    }

    &-close {
      margin: 0 19px 0 auto;

      svg {
        width: 18px;
        height: 18px;

        g,
        path {
          fill: var(--gray-400);
        }
      }
    }
  }

  &-main {
    max-height: 480px;
    padding-right: 15px;
    overflow: auto;

    @include scrollbar(5px);

    &-title {
      font-size: var(--fs-16);

      color: var(--gray-500);
      margin: 6px 0;

      @include fw('medium');
    }

    &-list {
      aayn-notification-item {
        &:not(:last-child) .aayn-notifications--body-main-list-item {
          margin-bottom: 13px;
        }
      }

      &-item {
        border-radius: 15px;
        background: var(--white);
        border: 1px solid var(--gray-200);
        padding: 13px 13px 13px 15.8px;

        @include flex;

        &__new {
          border: 2px solid var(--primary);

          .aayn-notifications--body-main-list-item__body {
            &_content {
              color: var(--primary);

              @include fw('medium');
            }

            &_footer {
              ayn-icon {
                color: var(--primary);
              }
            }
          }
        }

        &__img {
          margin-right: 12.8px;
          width: 26px;
          height: 26px;
          display: inline-block;
        }

        &__body {
          font-size: var(--fs-16);

          line-height: 20px;
          color: var(--gray-500);

          &_content {
            font-size: var(--fs-16);

            line-height: 20px;
            color: var(--gray-500);
          }

          &_footer {
            margin-top: 10px;

            @include flex-justify-between;

            &_date {
              font-size: var(--fs-14);
              color: var(--gray-400);
            }

            ayn-icon {
              display: flex;
              color: var(--gray-400);
            }
          }
        }
      }
    }
  }
}
