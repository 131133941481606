$headings: (
  'h1': var(--fs-40),
  'h2': var(--fs-35),
  'h3': var(--fs-30),
  'h4': var(--fs-25),
  'h5': var(--fs-18),
  'h6': var(--fs-16)
);

$texts: (
  1: var(--fs-20),
  2: var(--fs-18),
  3: var(--fs-16),
  4: var(--fs-14)
);

$font-weights: (
  'thin': 300,
  'normal': 400,
  'medium': 500,
  'semibold': 600
);

$alignments: ('left', 'center', 'right', 'justify');

@each $key, $value in $headings {
  #{$key},
  .#{$key} {
    font-size: $value;

    // NOTE: Applies to all headers except "h6".
    @if $key != 'h6' {
      @extend .fw-semibold;
    }
  }
}

@each $size, $value in $texts {
  .fs-#{$size} {
    font-size: $value;
  }
}

@each $key, $value in $font-weights {
  .fw-#{$key} {
    font-weight: $value;
  }
}

@each $value in $alignments {
  .text-#{$value} {
    text-align: #{$value};
  }
}

// MIXINS:

@mixin h($heading: h1) {
  font-size: map-get($map: $headings, $key: $heading);
}

@mixin fs($fs: 1) {
  font-size: map-get($map: $texts, $key: $fs);
}

@mixin fw($fw: 'normal') {
  font-weight: map-get($map: $font-weights, $key: $fw);
}
