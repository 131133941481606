.aayn-input-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &--inner {
    display: flex;
    align-items: center;
    margin-right: 9px;
    flex: 1;
    position: relative;

    [ayn-button] {
      padding: 0;
      height: auto;
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);
      background: transparent;
      color: var(--gray-400);

      &:not(.active):hover {
        color: var(--gray-500);
      }

      &.active {
        color: var(--accent);
      }
    }

    &-character-number {
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);
      font-size: var(--fs-14);
      line-height: 1;
      color: var(--dark-blue--100);
    }

    $parent: &;

    &.has-preview {
      #{$parent}-character-number {
        right: 50px;
        margin-right: 8.7px;
        color: var(--gray-500);
      }
    }
  }

  [ayn-input] {
    padding: 0 80px 0 30px;
    height: 56px;
    border-radius: 20px;
    background: var(--white);
    box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
    transition: 0.15s;

    &:not(:focus):not(:hover):not(.invalid) {
      border: 1px solid var(--white);
    }

    &:read-only {
      background: var(--white);
      color: var(--gray-500);
    }

    &.invalid {
      background: none;
      background-color: var(--orange-5);
      border: 1px solid var(--orange-60);

      &::placeholder {
        color: var(--orange);
      }
    }
  }

  textarea[ayn-input] {
    padding-top: 29px;
    padding-bottom: 10px;
    min-height: 80px;
  }

  &--error-info {
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
  }

  &--ai-button {
    width: 56px;
    height: 56px;
    border-radius: 20px;
    background: transparent;
    color: var(--gray-500);

    &.btn__icon\:loading {
      padding-right: 0;
    }

    &:hover {
      background-color: var(--white);
    }

    &.active {
      background-color: var(--accent);
      color: var(--white);
    }

    &__loading {
      svg {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.aayn-tooltip-create-ad {
  height: 30px;
  padding: 0 15px;
  display: flex;
  align-items: center;
}
