.aayn-friend-invite--body {
  width: 866px;
  height: 439px;
  border-radius: 30px;
  box-shadow: 0 5px 99px rgba(var(--gray-rgb), 0.08);
  padding: 47px 47px 48px 40px;
  background: var(--white);
  left: 297px !important;

  &-close {
    position: absolute;
    top: 29px;
    right: 32px;

    ayn-icon[name='exit'] {
      color: var(--gray-400);
    }
  }

  @mixin title($color, $fs) {
    font-size: $fs;

    line-height: 42px;
    text-align: center;
    color: $color;

    @include fw('semibold');
  }

  &-title2 {
    @include title(var(--accent), 26px);
  }

  &-title {
    @include title(var(--primary), 40px);
  }

  &-description {
    @include title(var(--gray-400), 16px);

    line-height: 25px;
    font-weight: 500;
    margin-top: 15px;
  }

  &-inputs {
    display: flex;
    margin-top: 23px;

    ayn-input-with-button {
      flex: 1;

      &:not(:first-child) {
        margin-left: 22px;
      }
    }
  }

  &-share-text {
    font-weight: normal;
    font-size: var(--fs-20);

    line-height: 20px;
    text-align: center;
    color: var(--gray-500);
    margin: 25px auto 20px;
  }

  &-share-items {
    display: flex;
  }

  &-share-item {
    display: flex;
    align-items: center;
    height: 56px;
    border-radius: 15px;
    background: var(--white);
    border: 1px solid var(--gray-200);
    padding: 0 4px 0 15px;
    font-weight: 500;
    font-size: var(--fs-16);

    line-height: 32px;
    text-align: left;
    color: var(--gray-500);
    flex: 1;

    &:not(:last-child) {
      margin-right: 20px;
    }

    img {
      margin-right: 6px;
    }

    [ayn-button] {
      margin-left: auto;
      height: 48px;
      border-radius: 14px;
    }
  }
}
