@mixin defaultColors {
  //Primary - #6437fc
  --primary-rgb: 100, 55, 252;
  --primary: rgba(var(--primary-rgb));
  --primary-100-rgb: rgba(var(--primary-rgb), 0.8);
  --primary-100: rgba(var(--primary-rgb), 0.8);
  --primary-10: rgba(var(--primary-rgb), 0.1);

  //accent - #007fff
  --accent-rgb: 0, 127, 255;
  --accent: rgba(var(--accent-rgb));


  //success - #522dd0
  --success-rgb: rgba(var(--accent-rgb));
  --success: rgba(var(--accent-rgb));

  //error - #f46b06
  --orange-rgb: 244, 107, 6;
  --orange: rgba(var(--orange-rgb), 1);
  --orange-60: rgba(var(--orange-rgb), 0.6);
  --orange-5: rgba(var(--orange-rgb), 0.1);

  --body-bg-img: url(/assets/images/pngs/app-background.png) no-repeat 0 0 / cover;

  --lavender: rgba(var(--body-bg-rgb), 0.5);
  --body-bg: rgb(var(--body-bg-rgb));

  //gray - #828ead
  --gray-rgb: 130, 142, 173;
  --gray: rgba(var(--gray-rgb));

  --gray-50: rgba(var(--gray-rgb), 0.12);
  --gray-75: rgba(var(--gray-rgb), 0.12);

  --gray-75-solid-rgb: 241, 243, 249;
  --gray-75-solid: rgba(var(--gray-75-solid-rgb));

  --gray-100: rgba(var(--gray-rgb), 0.12);
  --gray-150: rgba(var(--gray-rgb), 0.15);
  --gray-200: rgba(var(--gray-rgb), 0.25);
  --gray-300: rgba(var(--gray-rgb), 0.3);
  --gray-400: rgb(var(--gray-rgb));
  --gray-500: rgb(var(--gray-500-rgb));
  --gray-600: rgba(var(--gray-500), 0.8);
  --gray-615: rgba(var(--gray-rgb), 0.15);

  --dark-blue--100: rgba(var(--gray-500), 0.7);
  --black-rgb: rgba(var(--gray-500));

  --black: rgba(var(--gray-500-rgb));

  --white: rgba(var(--white-rgb));
}

@mixin darkModeColor($color) {
  [data-theme='dark'] & {
    color: $color !important;
  }
}

@mixin darkModeColorDefault {
  @include darkModeColor(var(--gray-500));
}

@mixin darkModeBg($color) {
  [data-theme='dark'] & {
    background: rgba($color, 0.1);
  }
}

@mixin darkModeBgDefault {
  @include darkModeBg(var(--white-rgb));
}

:root {

  [data-theme='light'] {

    @include defaultColors;
    --body-bg-rgb: 216, 223, 255;
    --gray-500-rgb: 33, 41, 54;
    --gray-25: rgba(var(--gray-75-solid-rgb));
    --white-rgb: 255, 255, 255;
    --bg-gray: rgba(var(--white-rgb), 0.3);
    --topbar-before-bg: linear-gradient(180deg, rgba(var(--body-bg-rgb)) 20%, rgba(var(--body-bg-rgb) / 0%) 100%);
    --gradient: radial-gradient(
                    rgba(var(--body-bg-rgb)),
                    rgba(var(--body-bg-rgb) / 50%),
                    rgba(var(--body-bg-rgb) / 50%)
    );
  }

  [data-theme='dark'] {
    @include defaultColors;
    --body-bg-rgb: 16, 18, 31;
    --gray-500-rgb: 255, 255, 255;
    --gray-25: rgb(32 35 48);
    --white-rgb: 50, 50, 90;
    --bg-gray: rgba(40, 48, 69, .1);
    --topbar-before-bg: linear-gradient(180deg, rgba(216, 223, 255) 20%, rgba(rgba(23, 25, 38) / 0%) 100%);
    --gradient: radial-gradient(rgba(216, 223, 255), rgba(216, 223, 255 / 50%), rgba(216, 223, 255 / 50%));
    --body-bg-img: none;

    * {
      box-shadow: none;
      filter: none;

      ng-lottie * {
        filter: revert-layer;
      }
    }
  }

  --font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
  'Open Sans', 'Helvetica Neue', sans-serif;

  --fs-12: clamp(0.625rem, 3vw, 0.75rem);
  --fs-13: clamp(0.625rem, 3vw, 0.8125rem);
  --fs-14: clamp(0.75rem, 3vw, 0.875rem);
  --fs-16: clamp(0.875rem, 3vw, 1rem);
  --fs-18: clamp(1rem, 3vw, 1.125rem);
  --fs-20: clamp(1.125rem, 3vw, 1.25rem);
  --fs-22: clamp(1.25rem, 3vw, 1.375rem);
  --fs-24: clamp(1.375rem, 3vw, 1.5rem);
  --fs-25: clamp(1.375rem, 3vw, 1.5625rem);
  --fs-26: clamp(1.5rem, 3vw, 1.625rem);
  --fs-30: clamp(1.5625rem, 3vw, 1.875rem);
  --fs-34: clamp(1.875rem, 3vw, 2.125rem);
  --fs-35: clamp(1.875rem, 3vw, 2.1875rem);
  --fs-40: clamp(1.875rem, 3vw, 2.5rem);
  --fs-46: clamp(2.25rem, 3vw, 2.875rem);
}


$colors: (
        primary: var(--primary),
        accent: var(--accent),
        success: var(--success),
        orange: var(--orange),
        white: var(--white),
        darkBlue: var(--dark-blue--100),
        gray: var(--gray-500),
        lavender: var(--lavender)
);

$fontSizes: (
        12: var(--fs-12),
        13: var(--fs-13),
        14: var(--fs-14),
        16: var(--fs-16),
        18: var(--fs-18),
        20: var(--fs-20),
        24: var(--fs-24),
        25: var(--fs-25),
        26: var(--fs-26),
        30: var(--fs-30),
        34: var(--fs-34),
        35: var(--fs-35),
        40: var(--fs-40),
        46: var(--fs-46)
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);

// TODO:DUE_DATE: 9.03.2023 - Şu anda bütün proje REM desteklemediği için yoruma alındı. Oluşan stil hataları düzeltilince açılacak!!!

// @media (max-width: 96rem) {
//   html,
//   body {
//     font-size: .875rem !important;
//   }
// }

// @media (max-width: 75rem) {
//   html,
//   body {
//     font-size: .75rem !important;
//   }
// }


