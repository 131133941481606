/**
---- DEFAULT CREATIVE TYPE MIXINS ----
**/

@mixin post {
  aspect-ratio: 1;
  width: 100%;
}

@mixin landscape {
  aspect-ratio: var(--preview-ratio-landscape);
  width: 391px;
  height: 206px;
  border-radius: 20px;

  &::before {
    content: '';
    display: block;
    height: 175.64px;
    width: 4px;
    border-radius: 0 4px 4px 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: var(--preview-firstColor);
  }

  .head {
    width: min-content;
    left: auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;

    &::before {
      display: none;
    }

    .logo {
      height: 58px;
      width: 76px;
      padding: 8px 14px 4px;
      border-radius: 15px 0 0 15px;

      img {
        border-radius: 0;
      }

      &::before {
        left: auto;
        right: 0;
        width: 3px;
        height: 85px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 4px 0 0 4px;
      }
    }
  }

  .content {
    height: 165.72px;
    width: 349.4px;
    top: 50%;
    transform: translateY(-50%);
    left: 21px;
    align-items: flex-start;
    padding: 15px;

    h4 {
      font-size: 12px;
      max-width: 137px;
      line-height: 15px;
      margin-bottom: 0;
      text-align: left;
      word-break: break-word;
    }

    h3 {
      font-size: 22px;
      line-height: 26px;
      max-width: 137px;
      text-align: left;
      word-break: break-word;
    }

    .texts {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .bgImage {
    width: 195.92px;
    height: 148.05px;
    border-radius: 15px 0 0 15px;
    right: 0;
    left: auto;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);

    .nonImage {
      max-width: 100px;
      margin-left: 10px;
    }
  }

  .callToAction {
    height: 28px;
    left: 36px;
    bottom: 34px;
    background: hsl(var(--white));
    color: hsl(var(--baseColor2));
    font-size: 9px;
    transform: none;
    border-radius: 8px;
    padding: 0 10px;

    i {
      color: hsl(var(--baseColor2));
    }
  }

  &::after {
    height: 181.75px;
    width: 113.21px;
    left: auto;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
    border-radius: 20px 0 0 20px;
  }
}

@mixin story {
  aspect-ratio: var(--preview-ratio-story);
  height: 100%;

  .head {
    .logo {
      width: 75px;
      height: 45px;

      &::before {
        width: 66px;
      }
    }
  }

  .content {
    width: 184.54px;
    height: 342.87px;
    border-radius: 15px;
    padding-top: 20px;

    h4 {
      font-size: 14px;
    }

    h3 {
      font-size: 25px;
      line-height: 30px;
    }

    .texts {
      height: 118px;
    }
  }

  .bgImage {
    width: 165.25px;
    height: 221.55px;
  }

  .callToAction {
    font-size: 10px;
    width: 142px;
  }
}

@mixin vertical {
  aspect-ratio: var(--preview-ratio-vertical);
  max-width: calc(410px * var(--preview-ratio-vertical));
  width: 100%;

  .content {
    max-width: 251px;

    h4 {
      font-size: 17px;
    }

    h3 {
      font-size: 30px;
    }

    .texts  {
      height: 145px;
    }
  }

  .bgImage {
    max-width: 217px;
    height: 208px;
  }

  &::after {
    max-width: 283px;
  }

  .callToAction {
    width: 171px;
    height: 36px;
    font-size: 12px;
  }
}

@mixin pin {
  aspect-ratio: var(--preview-ratio-pin);
  width: 261px;
  height: 392px;

  .head {
    .logo {
      width: 75px;
      height: 45px;

      &::before {
        width: 66px;
      }
    }
  }

  .content {
    width: 198.34px;
    height: 339.37px;
    padding-top: 19px;

    h4 {
      font-size: 17px;
    }

    h3 {
      font-size: 30px;
    }

    .texts {
      height: 120px;
    }
  }

  .bgImage {
    width: 164.03px;
    height: 227.05px;
  }

  &::after {
    width: 230.11px;
    height: 154.85px;
  }

  .callToAction {
    width: 142.9px;
    height: 36px;
    font-size: 10px;
  }
}

@mixin mediumBanner {
  aspect-ratio: var(--preview-ratio-mediumBanner);
  width: 300px;
  height: 250px;

  .head {
    .logo {
      width: 89px;
      height: 48px;

      &::before {
        width: 72px;
      }
    }
  }

  .content {
    width: 252.26px;
    height: 216.41px;
    border-radius: 15px;
    padding-top: 21px;

    h4 {
      font-size: 14px;
      margin-bottom: 0;
    }

    h3 {
      font-size: 22px;
      line-height: 26px;
    }

    .texts {
      height: 73px;
    }
  }

  .bgImage {
    width: 225.9px;
    height: 129.02px;
  }

  &::after {
    width: 276.66px;
    height: 98.74px;
  }

  .callToAction {
    width: 171.9px;
    height: 36px;
    font-size: 12px;
  }
}

@mixin leaderBoard {
  aspect-ratio: var(--preview-ratio-leaderBoard);
  width: 392.41px;
  height: 68px;
  border-radius: 20px;

  .head {
    width: min-content;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;

    &::before {
      display: none;
    }

    .logo {
      width: 45.32px;
      height: 45.2px;
      padding: 10px;
      border-radius: 0 15px 15px 0;

      &::before {
        left: 0;
        width: 3px;
        height: 33px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .content {
    height: 54.7px;
    width: 364.42px;
    border-radius: 12px;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
    align-items: flex-start;
    padding: 0 0 0 50px;

    h4 {
      font-size: 12px;
      max-width: 205px;
      line-height: 15px;
      margin-bottom: 0;
      text-align: left;
    }

    h3 {
      font-size: 18px;
      line-height: 22px;
      max-width: 205px;
      text-align: left;
      margin-bottom: 0;
    }

    .texts {
      height: 55px;
      align-items: flex-start;
    }
  }

  .bgImage {
    width: 123.92px;
    height: 48.87px;
    border-radius: 15px 0 0 15px;
    right: 0;
    left: auto;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);

    .nonImage {
      max-width: 100px;
      margin-left: 10px;
      height: 80%;
    }
  }

  .callToAction {
    display: none;
  }

  &::after {
    height: 60px;
    width: 93.93px;
    left: auto;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
    border-radius: 15px 0 0 15px;
  }
}

@mixin wideSkyscraper {
  aspect-ratio: var(--preview-ratio-wideSkyscraper);
  height: 100%;

  .head {
    &::before {
      display: none;
    }

    .logo {
      width: 67px;
      height: 45px;

      &::before {
        width: 58px;
      }
    }
  }

  .content {
    width: 92.54px;
    height: 380.87px;
    border-radius: 12px;
    padding: 43px 5px 0;
    top: 5px;

    h4 {
      font-size: 12px;
    }

    h3 {
      font-size: 18px;
      line-height: 20px;
    }

    .texts {
      height: 119px;
    }
  }

  .bgImage {
    width: 85.25px;
    height: 217.55px;
    border-radius: 15px 15px 4px 4px;
  }

  &::after {
    width: 97.77px;
    height: 153px;
  }

  .callToAction {
    width: 80.9px;
    height: 22px;
    font-size: 7px;
  }
}

@mixin halfPage {
  aspect-ratio: var(--preview-ratio-halfPage);
  height: 100%;

  .head {
    .logo {
      width: 75px;
      height: 45px;

      &::before {
        width: 66px;
      }
    }
  }

  .content {
    width: 159.54px;
    height: 344.87px;
    border-radius: 15px;
    padding: 19px 5px 0;

    h4 {
      font-size: 14px;
      margin-bottom: 0;
    }

    h3 {
      font-size: 21px;
      line-height: 26px;
    }

    .texts {
      height: 102px;
    }
  }

  .bgImage {
    width: 140.25px;
    height: 238.55px;
  }

  &::after {
    width: 177.39px;
    height: 154.85px;
  }

  .callToAction {
    width: 123.9px;
    height: 32px;
    font-size: 9px;
  }
}

@mixin largeLeaderboard {
  aspect-ratio: var(--preview-ratio-largeLeaderboard);
  width: 392px;
  height: 48px;
  border-radius: 15px;

  .head {
    width: min-content;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;

    &::before {
      display: none;
    }

    .logo {
      width: 45.32px;
      height: 30px;
      padding: 5px;
      border-radius: 0 8px 8px 0;

      &::before {
        left: 0;
        width: 2px;
        height: 23px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 0 2px 2px 0;
      }
    }
  }

  .content {
    height: 37.48px;
    width: 365.86px;
    border-radius: 12px;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
    align-items: flex-start;
    padding: 5px;
    padding-left: 50px;

    h4 {
      font-size: 10px;
      line-height: 12px;
      max-width: 205px;
      margin-bottom: 0;
      text-align: left;
    }

    h3 {
      font-size: 13px;
      line-height: 16px;
      max-width: 205px;
      text-align: left;
    }

    .texts {
      align-items: flex-start;
    }
  }

  .bgImage {
    width: 121.51px;
    height: 33.49px;
    border-radius: 8px 0 0 8px;
    right: 0;
    left: auto;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);

    .nonImage {
      max-width: 100px;
      margin-left: 10px;
      height: 80%;
    }
  }

  .callToAction {
    display: flex;
    height: 18.25px;
    left: auto;
    right: 84px;
    top: 50%;
    transform: translateY(-50%);
    background: hsl(var(--white));
    color: hsl(var(--baseColor2));
    font-size: 6px;
    border-radius: 6px;
    padding: 0 10px;

    i {
      color: hsl(var(--baseColor2));
    }
  }

  &::after {
    height: 41.11px;
    width: 75.16px;
    left: auto;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
    border-radius: 15px 0 0 15px;
  }
}

/**
---- DEFAULT CREATIVE PREVIEW STYLES ----
**/

.containerPreviewView {
  width: 100%;
  height: 100%;
  min-width: 428px;
  min-height: 428px;
  box-shadow: 0 20px 80px rgba(var(--gray-rgb), 0.06);
  display: grid;
  place-items: center;
  border: 1px solid var(--white);
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));
  backdrop-filter: blur(30px);
  --webkit-backdrop-filter: blur(30px);
  padding: 18px;
  border-radius: 25px;
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
}

.previewView {
  --preview-firstColor: var(--baseColor1);
  --preview-secondColor: var(--baseColor2);

  border-radius: 20px;
  background: var(--white);

  [data-theme='dark'] & {
    background: var(--gray-500);
  }

  overflow: hidden;
  position: relative;
  margin: auto;

  *:not([class*='fa-']) {
    font-family: var(--font), var(--f-medium);
  }

  &.Post {
    @include post();
  }

  &:global(.Landscape) {
    @include landscape();
  }

  &:global(.Story) {
    @include story();
    max-height: 410px;
  }

  &:global(.Portrait) {
    @include vertical();
    max-height: 410px;
  }

  &:global(.Pin) {
    @include pin();
    max-height: 410px;
  }

  &:global(.MediumBanner) {
    @include mediumBanner();
    max-height: 345px;
  }

  &:global(.LeaderBoard),
  &:global(.LargeLeaderboard) {
    @include leaderBoard();
    flex-direction: row;
    align-items: center;
    border-radius: 14px;
  }

  &:global(.WideSkyScraper) {
    @include wideSkyscraper();
    max-height: 410px;
    max-width: 110px;
  }

  &:global(.HalfPage) {
    @include halfPage();
    max-height: 410px;
  }

  &:global(.LargeLeaderboard) {
    @include largeLeaderboard();
  }

  .head {
    width: calc(100% - 44px);
    left: 22px;
    position: absolute;
    z-index: 3;

    &::before {
      content: '';
      width: 100%;
      height: 6px;
      position: absolute;
      top: 0;
      left: 0;
      background: var(--preview-firstColor);
      border-radius: 0 0 6px 6px;
      z-index: 1;
    }

    .logo {
      width: 115px;
      height: 66px;
      border-radius: 0 0 15px 15px;
      background: var(--white);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      padding: 6px 16px;
      object-fit: contain;

      img {
        object-fit: none;
      }

      &::before {
        content: '';
        width: 85px;
        height: 3px;
        border-radius: 0 0 3px 3px;
        background: var(--preview-firstColor);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .content {
    width: 328.34px;
    height: 339.37px;
    border-radius: 20px;
    background: var(--preview-secondColor);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 26px;
    color: var(--white);
    padding: 30px 20px 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      font-weight: 300;
      font-size: 20px;
      letter-spacing: -0.03em;
      text-align: center;
      color: var(--white);
      @include darkModeColorDefault;
      display: inline;
    }

    h3 {
      font-weight: 600;
      font-size: 28px;
      line-height: 42px;
      text-align: center;
      color: var(--white);
      @include darkModeColorDefault;
    }

    .texts {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 137px;
    }
  }

  .bgImage {
    width: 294.03px;
    height: 196.05px;
    border-radius: 20px 20px 0 0;
    border: 2px solid var(--white);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    z-index: 3;
    background: var(--white);
    display: flex;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .nonImage {
      width: 160px;
      height: 87.81px;
      margin: auto;
      align-self: center;
    }
  }

  &::after {
    content: '';
    width: 360.11px;
    height: 154.85px;
    border-radius: 20px 20px 0 0;
    background: var(--preview-firstColor);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  .callToAction {
    height: 36px;
    border-radius: 10px 10px 0 0;
    background: var(--preview-secondColor);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    @include darkModeColorDefault;
    font-size: 12px;
    font-weight: 600;
    z-index: 4;
    padding: 0 20px;
    white-space: nowrap;

    i {
      margin-left: 5px;
      color: var(--white);
      font-size: 12px;
    }
  }
}
