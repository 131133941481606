.aayn-smart-campaign-edit {
  &--header {
    @extend .modal-v3--header;
    background: none;
    border: none;
    max-width: 1050px;
    padding: 40px 40px 27px 45px;

    .modal-v3--header-content {
      width: 100%;
    }

    h5 {
      color: var(--primary);
      font-size: var(--fs-25);
      width: 100%;
      @include text-ellipsis;
    }

    p {
      color: var(--gray-500);
    }

    & .modal-close {
      top: 22px;
      right: 38px;
      color: var(--dark-blue--100);
    }
  }

  &--content {
    @extend .modal-v3--content;
    padding-top: 0;

    &_card {
      border: 1px solid var(--white);
      border-radius: 30px;
      filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));

      &--header {
        filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));
        background-color: rgba(var(--white-rgb), 0.4);
        display: flex;
        align-items: center;
        padding: 19px 17px;
        border-radius: 30px 30px 0 0;
        border-bottom: 1px solid var(--white);
        gap: 12px;
        color: var(--primary);

        &__title {
          font-size: var(--fs-20);
          font-weight: 600;
          line-height: 1.6;

          &-reccommended {
            font-size: var(--fs-12);
            line-height: 1.43;
            color: var(--primary);
            background-color: var(--primary-10);
            border-radius: 16px;
            padding: 4px 8.8px 4px 10.2px;
            margin-left: 10px;
          }
        }

        &-right {
          display: flex;
          margin-left: auto;
          align-items: center;
          gap: 10px;
        }
      }

      .aayn-smart-campaign--budget-switch {
        .ayn-checkbox {
          width: 67px;
          --height: 32px;

          .ayn-checkbox-box {
            border-color: var(--gray-400);
            background: var(--gray-400);

            &::before {
              height: calc(var(--height) - 8px);
              width: calc(var(--height) - 8px);
              left: 3px;
              bottom: 3px;
              background-color: var(--white);
            }

            &.ayn-highlight {
              background: var(--primary);

              &::before {
                transform: translateX(37px);
                background-color: var(--primary);
              }

              &::before {
                left: 0;
                background: url(/assets/images/icons/brain.svg) no-repeat center center;
                background-size: cover;
                filter: brightness(0) invert(1);
              }
            }
          }
        }
      }

      &--content {
        padding: 40px;
        color: var(--primary);
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: rgba(var(--white-rgb), 0.4);

        &-row {
          display: flex;
          gap: 24px;

          & > * {
            flex: 1;
          }
        }

        &-platforms {
          display: flex;
          flex-direction: column;
          color: var(--gray-500);

          & > * {
            flex: 1;
          }
        }

        &-budget-type {
          display: flex;
          gap: 20px;

          ayn-radio {
            flex: 1;
          }
        }

        &-budget-type {
          display: flex;
          gap: 20px;

          ayn-radio {
            flex: 1;
          }
        }
      }
    }
  }
}

.aayn-smart-campaign-status-edit--content_card-content {
  display: flex;
  justify-content: space-between;
  color: var(--gray-500);
  position: relative;
  padding: 20px 0;

  &::after {
    content: '';
    display: block;
    width: calc(100% + 60px);
    height: 1px;
    background: var(--white);
    position: absolute;
    top: 0;
    left: -30px;
  }
}

ayn-form-field .form-field__has-icon .aayn-smart-currency [ayn-input] {
  padding-right: 20px;
}

.aayn-smart-currency {
  input {
    text-align: end;
  }
}

.aayn-smart-currency--select {
  .ayn-option--inner {
    margin-right: 0;
  }
}
