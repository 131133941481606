.has-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-500);

  .divider {
    width: 100%;
    height: 2px;
    background: var(--gray-100);
    z-index: 0;
    flex: 1;
  }
}
