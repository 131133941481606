.aayn-platform-budget {
  position: relative;
  margin-bottom: 30px;

  &--row {
    display: flex;
    flex: 1;
    gap: 20px;
    margin-bottom: 20px;

    &__input {
      display: block;
      width: 100%;

      &-currency {
        margin-left: 3px;
        font-weight: 500;
        font-size: var(--fs-12);
        line-height: 24px;
        color: var(--dark-blue--100);
      }

      input {
        text-align: right;
        padding-right: 26px;
      }
    }

    &__budget {
      display: flex;
      width: 100%;
      justify-content: space-between;

      ayn-radio {
        width: 100%;
        margin-right: 20px;

        &:not(:first-child) {
          margin-right: 0px;
        }
      }
    }
  }

  &--platform {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 60px;
    color: var(--gray-500);

    &::after {
      content: '';
      display: block;
      width: calc(100% + 60px);
      height: 1px;
      background: var(--white);
      position: absolute;
      top: -30px;
      left: -30px;
    }

    &-icon {
      margin-right: 5px;
    }

    &-title {
      font-size: var(--fs-16);
      font-weight: 600;
      line-height: 1;
      color: var(--gray-500);
    }
  }
}

@media only screen and (min-width: 768px) {
  $class: '.aayn-platform-budget';

  .aayn-platform-budget--horizontal {
    display: flex;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 0;
    gap: 70px;

    &::after {
      content: '';
      display: block;
      width: calc(100% + 60px);
      height: 1px;
      background: var(--white);
      position: absolute;
      top: 0px;
      left: -30px;
    }

    #{$class}--platform {
      margin: 0;
      min-width: 180px;

      &::after {
        content: none;
      }
    }

    #{$class}--row {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .aayn-platform-budget {
    &--row {
      flex-direction: column;
    }
  }
}
