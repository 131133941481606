.aayn-social-accounts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--container {
    max-width: 1180px;

    .row {
      [class*='col'] {
        margin-bottom: 30px;
      }
    }
  }

  .aayn-platform-item {
    --border-color: var(--white);
  }

  &--coming-soon {
    font-size: var(--fs-14);
    text-align: center;
    color: var(--gray-400);
    font-weight: normal;
    line-height: 28px;
  }
}
