aayn-alert-bar {
  width: 100%;
}

.aayn-alert-bar {
  width: 100%;
  max-width: 744px;
  height: 56px;
  border-radius: 20px;
  background: var(--orange);
  border: 1px solid var(--white);
  box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
  display: flex;
  align-items: center;
  color: var(--white);

  @include darkModeColorDefault;

  padding: 0 7px 0 26px;

  > ayn-icon {
    width: 10px;
    height: 10px;
    margin-right: 20px;
    cursor: pointer;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &--texts {
    display: flex;
    flex-direction: column;

    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      color: var(--white);

      @include darkModeColorDefault;

      padding-left: 15px;
      border-left: 1px solid rgba($color: var(--white), $alpha: 0.3);
    }

    &-message {
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      color: var(--white);

      @include darkModeColorDefault;

      padding-left: 15px;
      border-left: 1px solid rgba($color: var(--white), $alpha: 0.3);
    }
  }

  button.btn.has-icon {
    height: 44px;
    border-radius: 14px;
    margin-left: auto;
    max-width: 180px;
    padding: 0 23px;

    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
  }

  &__with-title {
    .aayn-alert-bar--texts-title {
      line-height: 1;
      margin-top: 2px;
    }

    .aayn-alert-bar--texts-message {
      font-size: 13px;
      font-weight: 400;
    }

    .aayn-alert-bar--left-icon {
      width: 23px;
      height: 23px;
      margin-right: 10px;
    }

    &:has(.aayn-alert-bar--left-icon) {
      padding-left: 20px;
    }
  }
}
