ayn-form-field:has(.ayn-datepicker) {
  .form-field {
    ayn-icon {
      position: initial;
      top: unset;
      left: unset;
      right: unset;
      transform: unset;
    }
  }
}

@mixin invalid-datepicker() {
  .ayn-datepicker {
    border-color: var(--orange);

    &--label {
      color: var(--orange);
    }

    &--input {
      color: var(--orange);

      &::placeholder {
        color: var(--orange);
      }
    }

    .datepicker-button {
      ayn-icon svg path {
        color: var(--orange) !important;
      }
    }
  }
}

form.ng-submitted {
  ayn-datepicker {
    &.ng-invalid {
      @include invalid-datepicker;
    }
  }
}

ayn-datepicker {
  &.ng-touched.ng-invalid {
    @include invalid-datepicker;
  }

  ngb-timepicker {
    flex: 1;
  }
}

.ayn-datepicker {
  display: flex;
  position: relative;
  height: 56px;
  border: none;
  border-radius: 20px;
  background-color: var(--white);

  &.disabled {
    opacity: 0.6;

    .ayn-datepicker--label {
      color: var(--gray-400);
    }
  }

  &.active {
    color: var(--accent);
  }

  &:not(.disabled):hover,
  &:not(.disabled):has(.dropdown-menu.show) {
    .ayn-datepicker--input,
    .datepicker-button {
      color: var(--accent) !important;
    }
  }

  &--label {
    min-width: max-content;
    padding: 0 10px 0 30px;
    font-size: var(--fs-16);
    font-weight: 500;
    line-height: 24px;
    color: var(--gray-500);
    background: var(--white);
    display: flex;
    align-items: center;
    border-radius: var(--input-border-radius) 0 0 var(--input-border-radius);

    span {
      @include fw('normal');
    }

    @include fw('semibold');
  }

  &--input {
    border: 0 !important;
    height: 100% !important;
    margin-right: 34px;
    color: var(--accent) !important;
    text-align: right;

    &:active {
      color: var(--primary) !important;
    }

    &::placeholder {
      color: var(--gray-400);
    }
  }

  .datepicker-button {
    position: absolute;
    right: 0;
    width: 20px;
    height: 100%;
    border-radius: 0 0.9rem 0.9rem 0;
    background: var(--white);
    color: var(--accent);
    cursor: pointer;
    margin-right: 20px;

    &.has-icon {
      justify-content: center;
      align-items: center;
      padding: 0;

      svg,
      g,
      path {
        width: 1.6563rem;
        height: 1.6581rem;
      }
    }

    .btn--icon {
      margin-left: 0;
    }

    &:disabled {
      color: var(--gray-400);
    }
  }

  &--footer {
    width: 100%;

    header {
      height: 30px;
      background: rgba(100, 113, 147, 0.1);
      display: flex;
      flex-direction: column;

      span {
        font-weight: normal;
        font-size: var(--fs-14);
        line-height: 30px;
        text-align: center;
        color: var(--gray-500);
        position: relative;
      }
    }

    main {
      display: flex;
      flex: 1;

      ngb-timepicker {
        .ngb-tp {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 17px;
          flex: 1;

          &-input-container {
            &.ngb-tp-hour {
              width: 100%;
              min-width: 285px;
              height: 56px;
              border-radius: 15px;
              background: var(--white);
              border: 1px solid rgba(179, 193, 212, 0.3);
              position: relative;

              &::before {
                content: 'Hour';
                position: absolute;
                width: 80px;
                left: 14px;
                font-family: 'SF Pro Display';
                font-weight: 600;
                font-size: var(--fs-16);

                line-height: 32px;
                text-align: left;
                color: var(--gray-500);
              }
            }

            &.ngb-tp-minute {
              width: 100%;
              min-width: 285px;
              height: 56px;
              margin-top: 12px;
              border-radius: 15px;
              background: var(--white);
              border: 1px solid var(--gray-200);
              position: relative;

              &::before {
                content: 'Minutes';
                position: absolute;
                width: 80px;
                left: 14px;
                font-weight: 600;
                font-size: var(--fs-16);

                line-height: 32px;
                text-align: left;
                color: var(--gray-500);
              }
            }

            .sr-only {
              display: none;
            }
          }

          &-chevron,
          .visually-hidden {
            display: none;
          }

          &-input {
            width: 46px;
            height: 46px;
            background-color: var(--white);
            border: none;

            font-weight: 500;
            font-size: var(--fs-16);
            line-height: 32px;
            color: var(--gray-500);
          }

          &-hour,
          &-minute,
          &-second,
          &-meridian {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;

            .btn {
              border: 0;
              padding: 0;
            }

            .btn-link {
              width: 30px;
              height: 30px;
              border-radius: 10px;
              background: var(--white);
              color: var(--gray-500);
              background-repeat: no-repeat;
              background-position: center;

              &:nth-child(1) {
                margin-right: 5px;

                &::before {
                  content: '';
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  background: url(/assets/images/icons/arrow-right.svg) no-repeat center center;
                  filter: invert(44%) sepia(22%) saturate(571%) hue-rotate(185deg) brightness(95%) contrast(90%);
                }
              }

              &:hover {
                width: 30px;
                height: 30px;
                border-radius: 10px;
                background: rgba(var(--primary-rgb), 0.1);
                border: 1px solid var(--white);
                box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.04);
              }

              &:nth-last-child(1) {
                &::before {
                  content: '';
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  background: url(/assets/images/icons/chevron-left.svg) no-repeat center center;
                  filter: invert(44%) sepia(22%) saturate(571%) hue-rotate(185deg) brightness(95%) contrast(90%);
                }
              }
            }
          }

          &-spacer {
            display: none;
          }
        }
      }
    }
  }
}

ngb-datepicker.ayn-datepicker--calendar {
  max-width: 422px;
  background: var(--white);
  border: none;
  border-radius: 15px;
  margin-top: 9px;
  z-index: 99;
  box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.04);

  .ngb-dp {
    &-header {
      border-radius: 15px 15px 0 0;
      height: 55px;
      padding-top: 8px;
      background: var(--white);

      .ngb-dp {
        &-arrow {
          .btn {
            width: 30px;
            height: 30px;
            border-radius: 10px;
            background-color: var(--primary);

            &.btn[disabled] {
              background-color: var(--gray-200);
            }

            justify-content: flex-end;
            outline: none;

            &:hover {
              background-color: rgba(var(--primary-rgb), 0.1);
              border: 1px solid var(--white);
              box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.04);

              &:disabled {
                .ngb-dp-navigation-chevron {
                  color: rgba(179, 193, 212, 0.2);
                }
              }
            }
          }

          .ngb-dp-navigation-chevron {
            border-style: solid;
            border-width: 2px 2px 0 0;
            display: inline-block;
            width: 8px !important;
            height: 8px !important;
            margin-right: 4px !important;
            color: var(--dark-blue--100);
          }

          &.right {
            .btn {
              &:hover {
                background-color: rgba(var(--primary-rgb), 0.1);
                border: 1px solid var(--white);
                box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.04);
              }
            }

            justify-content: flex-end;

            .ngb-dp-navigation-chevron {
              border-style: solid;
              border-width: 2px 2px 0 0;
              display: inline-block;
              width: 8px !important;
              height: 8px !important;
              margin-right: 7px !important;
              color: var(--dark-blue--100);
            }
          }
        }

        &-arrow-btn {
          background-color: transparent;
          z-index: 1;
        }

        &-navigation-select {
          display: flex;
          flex: 1;
          pointer-events: none;

          select {
            box-shadow: none;
            border: none;
            appearance: none;

            color: var(--gray-500);
            background-color: var(--white);
            font-size: var(--fs-16);
            line-height: 34px;

            &:nth-last-child(1) {
              font-weight: bold;
            }
          }
        }
      }
    }

    &-months {
      display: block;

      .ngb-dp {
        &-weekday,
        &-week-number {
          font-weight: normal;
          font-size: var(--fs-14);
          line-height: 28px;
          color: var(--darkblue);
          text-align: center;
          border-radius: 0;
        }

        &-weekday {
          margin-left: 15px;
          justify-content: space-between;
        }

        &-week {
          display: flex;
        }

        &-weekdays {
          width: 100%;
          height: 40px;
          background-color: var(--white);
          border-bottom: none !important;

          .ngb-dp-weekday {
            color: var(--dark-blue--100) !important;
            @include darkModeColorDefault;
          }
        }

        &-day,
        &-weekday,
        &-week-number {
          width: 36px;
          height: 30px;
          font-style: normal;
          font-weight: normal;
          font-size: var(--fs-14);
          line-height: 24px;
          margin: 5px;
        }

        &-day {
          cursor: pointer;

          .btn-light {
            width: 36px;
            height: 30px;
            font-weight: normal;
            font-size: var(--fs-14);
            line-height: 28px;
            border-radius: 5px;

            color: var(--gray-500);

            &.bg-primary {
              background-color: var(--accent);
              color: var(--white);
            }
          }

          :hover {
            background: var(--accent);
            color: var(--white);
            border: none;
            outline: none;
          }

          &.disabled,
          &.hidden {
            cursor: default;
            pointer-events: none;
          }

          .outside {
            cursor: default;
            opacity: 1;
            background: rgba(179, 193, 212, 0.2);
            color: rgba(179, 193, 212, 0.5) !important;
          }
        }
      }
    }

    &:last-child .ngb-dp-week {
      padding-right: 0;
    }

    &:first-child .ngb-dp-week {
      padding-left: 0;
    }

    .ngb-dp-week:last-child {
      padding-bottom: 0;
    }
  }
}

ngb-datepicker.ayn-datepicker--calendar--in-modal {
  @extend .ayn-datepicker--calendar;
  z-index: 10051;
}
