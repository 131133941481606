:root {
  --input-border-radius: 15px;
}

ayn-form-field {
  width: 100%;

  --icon-color: var(--gray-400);

  &.streched-for-ad-preview {
    .form-field {
      &.ayn-input_invalid {
        [ayn-input] {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      ayn-hint {
        position: absolute;
        margin-left: 100%;
        top: 0;
        height: 100%;
        background-color: var(--orange);
        border-radius: 0px var(--input-border-radius) var(--input-border-radius) 0px;
        box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.15);
        display: flex;
        align-items: center;
        width: 170px;
        backface-visibility: visible !important;
        animation-name: flipInY;
        animation-duration: 0.5s;

        .ayn-form-hint {
          color: var(--white);
          margin: 0 10px 0 16px;
          font-size: var(--fs-14);
          line-height: 16px;
        }
      }
    }
  }

  .form-field {
    width: 100%;
    position: relative;
    border-radius: var(--select-border-radius);
    box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.04);
    height: 56px;

    &:has(textarea) {
      height: auto;
    }

    &.ayn-input__disabled {
      input {
        cursor: default;
        background-color: rgba(239, 239, 239, 0.3);
        border-color: rgba(118, 118, 118, 0.3);
      }
    }

    &--inner {
      position: relative;
      line-height: 0;

      .ayn-prefix {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--gray-500);
      }

      .ayn-prefix + [ayn-input] {
        padding-left: 75px;
      }

      .ayn-recommended {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 22px;
        top: -11px;
        width: max-content;
        height: 22px;
        border-radius: 11px;
        background: var(--gray-25);

        &-text {
          font-weight: 500;
          font-size: var(--fs-12);
          line-height: 32px;
          color: var(--primary);
          padding: 6px 10px;
        }
      }

      &__character-number {
        font-size: var(--fs-16);
        color: var(--gray-400);
        position: absolute;
        right: 19px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    label:not(.ayn-datepicker--label),
    [ayn-label]:not(.ayn-datepicker--label) {
      font-weight: 500;
      font-size: var(--fs-16);

      line-height: 32px;
      color: var(--gray-500);
      margin-bottom: 8px;
      display: block;
      width: 100%;

      &[centered] {
        text-align: center;
      }
    }

    [ayn-input] {
      transition: all 250ms;
      padding-right: 20px;
    }

    ayn-icon {
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);

      svg,
      path,
      g {
        color: var(--icon-color);
      }
    }

    &__has-icon {
      .form-field--inner__character-number {
        right: 45px;
      }

      [ayn-input] {
        padding-right: 3.8125rem;
      }
    }

    [ayn-button] {
      position: absolute;
      right: 6px;
      top: 6px;
      height: calc(100% - 12px);
      border-radius: 20px;
    }
  }
}

.ayn-form-hint {
  font-size: var(--fs-14);
  color: var(--orange);
  margin: 0.25rem 0 0 1.25rem;
}

[ayn-input] {
  background: var(--white);
  border: 0.0625rem solid transparent;
  border-radius: var(--input-border-radius);
  height: 3.5rem;
  outline: none;
  padding: 1.25rem 1.875rem 1.25rem 1.875rem;
  color: var(--gray-500);
  font-size: var(--fs-16);
  width: 100%;
  font-weight: 500;
  caret-color: var(--primary);

  &:hover {
    border-color: rgba(var(--gray-rgb), 0.6);
  }

  &:focus {
    border-color: rgba(var(--primary-rgb), 0.6);
  }

  &::placeholder {
    color: var(--gray-400);
    font-weight: 500;
  }

  &:read-only {
    border-color: var(--gray-200);
    background-color: var(--white);
    cursor: default;
    color: var(--gray-300);
  }

  &:disabled {
    border-color: var(--gray-200);
    background-color: var(--gray-25);
    cursor: default;
    opacity: 75%;
  }
}

[ayn-label] {
  font-size: var(--fs-16);
  font-weight: 500;
  color: var(--gray-500);
  display: inline-block;
  margin-bottom: 0.5rem;
}

.ayn-input_invalid,
.ayn-input_invalid input,
.ayn-input_invalid [ayn-input] {
  border-color: var(--orange);
  color: var(--orange);
  caret-color: var(--orange);

  ayn-icon {
    svg,
    path,
    g {
      fill: var(--orange) !important;
    }
  }

  &::placeholder {
    color: var(--orange);
  }
}

form.ng-submitted {
  .ayn-input-with-button {
    &:has(input.ng-invalid) {
      border-color: var(--orange);
    }
  }
}

.ayn-input-with-button {
  position: relative;
  border: 1px solid transparent;
  border-radius: var(--input-border-radius);
  height: 56px;
  overflow: hidden;
  display: flex;
  align-items: center;

  &:has(input:focus) {
    border-color: var(--primary);
  }

  &:has(input.ng-touched.ng-invalid) {
    border-color: var(--orange);
  }

  input {
    border: none;
    outline: none;
    padding: 0 15px 0 25px;
    height: 100%;
    color: var(--gray-500);
    font-size: var(--fs-16);
    font-weight: 500;
    width: 100%;

    &::placeholder {
      color: var(--gray-400);
    }
  }

  [ayn-button] {
    border-radius: var(--input-border-radius);
    margin: 4px;
    height: 48px;
  }

  label {
    min-width: max-content;
    font-size: var(--fs-16);
    padding-left: 18px;
    text-align: left;
    color: var(--gray-500);
  }

  &.stretch {
    input  {
      padding-left: 10px !important;
    }

    [ayn-button] {
      margin: 0;
      border-radius: 0 14px 14px 0;
      height: 100%;
      box-shadow: none !important;
    }
  }
}

form.ng-submitted {
  .ayn-input-with-select {
    &:has(input.ng-invalid) {
      border-color: var(--orange);

      ayn-select {
        margin-right: 20px;
      }
    }
  }
}

.ayn-input-with-select {
  position: relative;
  border-radius: var(--input-border-radius);
  height: 56px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: var(--white);

  &:has(input:focus) {
    border-color: var(--primary);
  }

  &:has(input.ng-touched.ng-invalid) {
    border-color: var(--orange);

    ayn-select {
      margin-right: 20px;
    }
  }

  &:has(.ayn-select.show) {
    border-bottom-right-radius: 0;
  }

  input {
    border: none;
    outline: none;
    padding: 0 15px 0 25px;
    height: 100%;
    color: var(--gray-500);
    font-size: var(--fs-16);
    font-weight: 500;
    width: 100%;

    &::placeholder {
      color: var(--gray-400);
    }
  }

  ayn-select {
    border-radius: var(--input-border-radius);
  }

  label {
    min-width: max-content;
    padding-left: 18px;
    text-align: left;
  }

  &.stretch {
    input  {
      padding-left: 10px;
    }

    ayn-select {
      margin: 0;
      height: 100%;

      .ayn-select {
        border: none;

        &.show {
          &:has(:not(button:focus)) {
            border-radius: 0;
          }

          .ayn-select--inner {
            .ayn-select--btn {
              border-radius: 0;
              border-right: 1px solid var(--white);
              border-left-color: rgba(var(--gray-rgb), 0.6);
            }
          }
        }

        &--btn {
          border-left: 1px solid var(--gray-200);
          border-radius: 0px;
        }
      }
    }
  }
}

ayn-form-field .form-field .ayn-input-with-select label {
  margin-bottom: 0;
}

.ayn-input-with-overlay {
  position: relative;

  .form-field {
    position: relative;

    &__has-icon {
      .form-field--inner__character-number {
        right: 59px;
      }

      [ayn-input] {
        padding-right: 83px;
      }
    }

    [ayn-input],
    [ayn-button] {
      position: relative;
      z-index: 3;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      width: calc(100% - 30px);
      height: 100%;
      top: 6px;
      left: 15px;
      border-radius: var(--input-border-radius);
      background: #eaeff3;
      position: absolute;
      z-index: 1;
    }

    &::after {
      height: 100%;
      border-radius: var(--input-border-radius);
      background: #f5f7f9;
      top: 12px;
      left: 28px;
      width: calc(100% - 56px);
      z-index: 0;
    }

    [ayn-input] {
      padding-right: 83px;
    }
  }

  .form-field--inner__character-number {
    right: 59px;
    z-index: 3;
  }

  > [ayn-button] {
    width: 46px;
    height: 46px;
    border-radius: 13px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 3;

    ayn-icon {
      transition: 0.2s all;
    }
  }

  &__active {
    ayn-form-field + [ayn-button] {
      ayn-icon {
        position: relative;
        rotate: -90deg;
      }
    }

    .ayn-input-overlay {
      .ayn-overlay {
        width: calc(100% + 1px);
        position: absolute;
        top: 56px !important;
        left: 0 !important;
        margin-top: 0;
        background: linear-gradient(180deg, white 85%, transparent);
        padding: 0 0 80px;

        .ayn-input-overlay--title {
          font-size: var(--fs-18);

          text-align: center;
          color: var(--gray-500);
          margin-bottom: 15px;

          @include fw('semibold');
        }

        ayn-form-field {
          display: block;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }

          & + button {
            margin-top: 10px;
          }

          .form-field {
            &::before,
            &::after {
              display: none;
            }
          }
        }

        .form-field--inner__character-number {
          right: 19px;
        }
      }
    }
  }
}

.ayn-input-with-label {
  display: flex;
  height: 56px;
  border: 1px solid transparent;
  border-radius: var(--input-border-radius);
  overflow: hidden;

  &:has(input:focus) {
    border-color: var(--primary);
  }

  label {
    min-width: 144px;
    padding: 0 10px 0 25px;
    font-size: var(--fs-18);

    line-height: 32px;
    text-align: left;
    color: var(--gray-500);
    background: var(--gray-25);
    border-right: 1px solid var(--gray-200);
    display: flex;
    align-items: center;

    span {
      @include fw('normal');
    }

    @include fw('semibold');
  }

  [ayn-input] {
    border: 0;
  }
}

input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: var(--white);
  transition: background-color 5000s ease-in-out 0s;
}
