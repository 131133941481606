.aayn-audience--detailed-targeting--overlay-inner
  .aayn-audience--detailed-targeting--options
  .aayn-audience--detailed-targeting--overlay-tiktok
  li {
  .aayn-audience--detailed-targeting--li-actions {
    justify-content: end;

    [ayn-button] {
      width: 100px;
    }
  }
}
