.aayn-modal-for-payments {
  max-width: 539px;

  --color: var(--primary);

  .modal-content {
    border-radius: 25px;
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    border: 1px solid var(--white);
    box-shadow: 0 10px 30px rgba(63, 78, 136, 0.08);
  }

  header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 26px;
      border-radius: 25px 25px 0 0;
      background: var(--color);
      position: relative;
      top: 0;
      left: 0;
    }

    &::after {
      content: '';
      background: linear-gradient(to top, rgba(var(--white-rgb), 0) 0%, rgba(var(--white-rgb), 0.1) 100%);
      display: block;
      height: 26px;
      width: 218px;
      transform: skew(291deg, 0deg);
      position: absolute;
      top: 0;
      right: -49px;
    }

    .modal-close {
      width: 40px;
      height: 40px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--gray-500);
      transition: 0.15s;
      cursor: pointer;
      position: absolute;
      right: 12px;
      top: 37px;

      svg {
        width: 12px;
        height: 12px;
      }

      &:hover {
        background: var(--white);
        color: var(--gray-500);
      }
    }

    > ayn-icon {
      margin-top: 28px;
      color: var(--color);
    }

    h5 {
      font-weight: 600;
      font-size: 25px;
      line-height: 32px;
      text-align: center;
      color: var(--color);
      margin-top: 18px;
      margin-bottom: 5px;
      padding: 0 32px;
    }

    p {
      font-size: var(--fs-18);
      line-height: 24px;
      text-align: center;
      color: var(--gray-500);
      padding: 0 32px;
      margin-bottom: 20px;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;

    [ayn-button] {
      margin: auto;
    }
  }

  aayn-payment-form {
    .aayn-auth--form-card {
      background: transparent;
      margin: 22px auto 0;
    }

    .aayn-auth--submit-button {
      background: var(--primary);
      color: var(--white);
    }
  }
}
