.funnel-chart {
  max-width: 100%;
  border-radius: 20px;
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 0.0625rem solid var(--white);
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));

  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(lg) {
    overflow: auto;
  }

  ayn-form-field .form-field {
    height: auto;
  }

  .svg-funnel-js {
    &__container {
      width: 100%;
      height: 100%;
    }

    &__labels {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    &__label {
      flex: 1 1 0;
      position: relative;
      display: grid;
      grid-template-areas: 'title title' 'value percentage';
      align-content: flex-start;

      .label__value {
        font-weight: 600;
        font-size: 25px;
        line-height: 32px;

        text-align: left;
        color: var(--primary);
        padding: 1.875rem 2.3125rem 1.625rem 1.625rem;
        grid-area: value;

        display: inline-block;
        width: auto;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;

        .label__percentage {
          font-size: var(--fs-20);
          line-height: 32px;
          color: var(--gray-500);
          margin-left: 9px;
          font-weight: 500;
          text-align: left;
        }
      }

      .label__title {
        min-width: 100%;
        width: max-content;
        padding: 0 30px 0 25px;
        grid-area: title;

        .ayn-funnel-chart--select {
          margin-top: 25px;

          .ayn-select--inner {
            height: 48px;
          }

          .ayn-overlay {
            background: var(--white);
            padding-top: 0;
          }

          .ayn-option {
            &:hover {
              color: var(--primary);
            }

            &.selected {
              border-color: var(--primary);
              color: var(--primary);
            }
          }
        }
      }

      .label__percentage {
        grid-area: percentage;
      }

      .label__segment-percentages {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        left: 0;
        padding: 0.5rem 1.5rem;
        box-sizing: border-box;
        margin-top: 1.5rem;
        opacity: 0;
        transition: opacity 0.1s ease;
        cursor: default;

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;

          li {
            font-size: var(--fs-13);
            line-height: 1rem;
            color: var(--white);
            margin: 1.125rem 0;
          }
        }
      }
    }

    &:not(.svg-funnel-js--vertical) {
      padding-top: 10rem;
      padding-bottom: 1rem;

      .svg-funnel-js__label {
        &:not(:first-child) {
          border-left: 0.0625rem solid var(--white);
        }
      }
    }

    &.svg-funnel-js--vertical {
      padding-left: 7.5rem;
      padding-right: 1rem;

      &.svg-funnel-js__label {
        padding-top: 1.5rem;
      }
    }

    .svg-funnel-js__subLabels {
      display: none !important; // flex;
      justify-content: right;
      margin-top: -2.25rem;
      position: absolute;
      width: 100%;

      .svg-funnel-js__subLabel {
        display: flex;
        flex-direction: row;
        margin-left: 1.25rem;

        :last-child {
          margin-right: 1.875rem;
        }

        .svg-funnel-js__subLabel--color {
          width: 1.75rem;
          height: 1.75rem;
          border-radius: 50%;
        }

        .svg-funnel-js__subLabel--title {
          margin-left: 0.375rem;
          font-family: SF Pro Display;
          font-weight: 600;
          font-size: var(--fs-18);

          line-height: 2rem;
          text-align: left;
          color: var(--gray-500);
        }
      }
    }
  }
}
