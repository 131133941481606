aayn-select-audience {
  display: block;
  max-width: calc(100% - 106px);
  width: 100%;
}

.aayn-audience {
  width: 100%;

  &--header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &-right {
      margin-left: auto;
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: 600;
      font-size: var(--fs-16);
      color: var(--gray-500);
      line-height: 16px;

      strong {
        font-size: var(--fs-20);
      }

      ayn-icon {
        margin-left: 5px;
        max-width: 20px;

        svg,
        path,
        g {
          fill: currentColor;
        }
      }
    }

    &-warning-message {
      color: var(--orange);
    }
  }

  &--group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 20px;
  }

  &--height\:300 {
    .aayn-audience--item {
      height: 300px;
    }
  }

  aayn-audience-item {
    flex: 1;
    min-width: 410px;
  }

  &--item {
    border-radius: 25px;
    background: rgba(var(--white-rgb), 0.3);
    box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
    border: 1px solid var(--white);
    padding: 30px;
    display: flex;
    flex-direction: column;
    position: relative;

    &--header {
      display: flex;
      align-items: center;
      margin-bottom: 29px;
    }

    &-icon {
      width: 56px;
      height: 56px;
      border-radius: 15px;
      background: rgba(100, 54, 252, 0.1);
      color: var(--primary);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
    }

    &-title {
      font-weight: 600;
      font-size: var(--fs-20);
      color: var(--gray-500);
      margin-bottom: 7px;
    }

    &-description {
      font-size: var(--fs-14);
      color: var(--gray-500);
      line-height: 20px;
    }

    &-description + * {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &__flexed {
      flex-direction: row;
      align-items: center;

      .aayn-audience--item--header {
        margin-bottom: 0;
      }

      .aayn-audience--item-title,
      .aayn-audience--item-description {
        margin-bottom: 0;
        white-space: nowrap;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .aayn-audience aayn-audience-item {
    min-width: 100%;
  }

  aayn-select-audience {
    max-width: 100%;
    padding: 10px;
  }

  .aayn-audience--item {
    padding: 20px;
    height: max-content !important;
  }

  ayn-form-field {
    max-width: 100% !important;
    margin-top: 10px;
  }

  .aayn-audience--item__flexed {
    flex-direction: column;
  }
}
