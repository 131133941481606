.aayn-ad-type-container {
  padding: 0 50px;

  @include media-breakpoint-down(md) {
    padding: 0 10px;
  }

  &--alert {
    margin-top: 20px;
    margin-bottom: 30px;
    display: block;
  }

  &--content {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    > * {
      flex: 1;
    }

    &--preview {
      position: sticky;
      top: 130px;
      align-self: flex-start;
      width: 100%;
    }
  }
}

.aayn-ad-preview {
  background: url('/assets/images/pngs/phone-placeholder.png') no-repeat;
  background-size: 100% auto;
  max-width: 450px;
  min-height: min-content;
  margin: 35px auto 0;
  padding: 16px 20px 0 20px;

  &-loading {
    min-height: 690px;
  }

  &-content {
    background-color: var(--white);
    border-radius: 32px 32px 0 0;
    padding-top: 23px;
    min-height: 690px;
    position: relative;

    ayn-carousel {
      position: static;

      .ayn-carousel {
        position: static;
      }
    }

    .ayn-carousel--btn_container_full-image--buttons {
      bottom: 139px;
    }
  }

  &-main {
    height: 100%;
  }

  &-header {
    display: flex;
    align-items: center;
    padding-left: 23px;

    &--img {
      width: 65px;
      height: 65px;
      background: var(--primary);
      border-radius: 100px;
      margin-right: 13px;
    }

    &--title {
      font-weight: 600;
      font-size: var(--fs-18);

      line-height: 22px;
      color: var(--gray-500);
    }

    &--description {
      font-weight: normal;
      font-size: var(--fs-14);
      line-height: 1;
      color: var(--gray-400);
      display: flex;
      align-items: center;

      ayn-icon {
        margin-left: 6px;
      }
    }
  }

  &-placeholder {
    width: 100%;
    height: 408px;
    background: rgba(234, 239, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .aayn-ad-preview {
    min-height: max-content;
    padding: 20px 20px 0 20px;
  }
}
