.ayn-overlay {
  position: absolute;
  // margin-top: 0.625rem;

  .ayn-overlay-flipped {
    margin-top: 0;
    margin-bottom: 0.625rem;

    &:after,
    &:before {
      border-bottom-color: transparent;
    }
  }

  .ayn-overlay-close {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  .ayn-overlay-shifted {
    &::after,
    &::before {
      left: auto;
      right: 1.25em;
      margin-left: auto;
    }
  }
}

.popup {
  padding: 8px;
  border: 1px solid var(--gray-200);
  border-radius: 15px;
  margin-top: 20px;
  box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.15);
  background: var(--white);

  &--inner {
    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -18px;
      left: 50%;
      transform: translateX(-50%);

      width: 13px;
      height: 10px;

      background: url(/assets/images/icons/popup-arrow-up.svg) no-repeat 0 center;
    }

    [ayn-button] {
      max-height: 40px;
      border-radius: 14px;
      color: var(--gray-500);
      padding-left: 13px;
      background-color: var(--gray-75);

      &[ayn-button='primary']:hover {
        background: var(--primary);
      }

      &[ayn-button='orange']:hover {
        background: var(--orange);
      }

      &:hover {
        color: var(--white);
        @include darkModeColorDefault;

        svg {
          g,
          path {
            fill: var(--white);

            [data-theme='dark'] & {
              fill: var(--gray-500);
            }
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }

    &::before {
      content: '';
      display: block;
    }
  }
}
