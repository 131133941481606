.aayn-callback {
  width: 100%;
  height: 100%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;

  ayn-lottie,
  ayn-lottie svg {
    max-width: 210px;
    margin: 0;
  }

  h2 {
    font-weight: 600;
    font-size: var(--fs-40);

    line-height: 42px;
    text-align: center;
    color: var(--primary);
    margin: 83px auto 5px;
  }

  p {
    font-size: var(--fs-20);
    letter-spacing: -0.02em;
    line-height: 20px;
    text-align: center;
    color: var(--gray-500);
    margin-bottom: 87px;
  }

  img {
    max-width: 52px;
  }
}
