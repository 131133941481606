.aayn-library-image {
  width: 159px;
  height: 159px;
  border-radius: 20px;
  background: rgba(var(--white-rgb), 0.6);
  border: 1px solid rgba(var(--gray-rgb), 0.3);
  padding: 10px;
  overflow: hidden;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    border-radius: 12px;

    object-fit: contain;
  }

  &:not(&__selected):hover {
    cursor: pointer;
  }

  &__selected {
    background: var(--white);

    &--icon {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 46px;
      height: 46px;
      border-radius: 15px;
      background: rgba(var(--white-rgb));
      border: 1px solid var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;

      &:hover {
        background: var(--primary);
        color: var(--white);
      }
    }
  }

  &\:hover {
    width: 139px;
    height: 139px;
    border: 1px solid var(--gray-100);
    background-color: rgba(var(--black-rgb), 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    border-radius: 12px;
    top: 10px;
    left: 10px;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s all;

    ayn-icon {
      width: 40px;
      height: 40px;
      border-radius: 15px;
      background: rgba(var(--white-rgb), 0.2);
      border: 1px solid var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);

      &:hover {
        background: var(--primary);
      }
    }

    span {
      font-weight: 500;
      font-size: var(--fs-14);

      line-height: 32px;
      text-align: center;
      color: var(--white);
    }
  }

  &:not(&__selected):hover {
    .aayn-library-image\:hover {
      opacity: 1;
      visibility: visible;
    }
  }
}
