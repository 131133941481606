.aayn-create-ad {
  aayn-select-platform {
    width: 100%;
  }

  &--header {
    &--platform {
      display: flex;
      height: 56px;
      border: 1px solid var(--white);
      border-radius: 20px;
      background: rgba(var(--white-rgb), 0.7);
      box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.04);

      &__item {
        @include flex-align-center;
        margin-left: 20px;
        margin-right: 20px;
        color: var(--gray-500);

        ayn-icon {
          svg-icon {
            svg {
              width: 26.34px;
              height: 18.03px;
            }
          }

          margin-right: 5.6px;
        }

        &-seperator {
          display: block;
          width: 1px;
          height: 100%;
          background: var(--gray-200);
          margin: 0 13px;
        }

        &:nth-child(2) {
          border-left: 1px solid var(--gray-200);
          margin-left: 13.5px;
          padding-left: 11px;
          height: 56px;
        }
      }

      &__smart {
        @include flex-align-center;
      }
    }

    &--objective {
      display: flex;
      height: 56px;
      @include flex-align-center;

      &__title {
        font-weight: 500;
        font-size: var(--fs-16);

        line-height: 26px;
        text-align: right;
        color: var(--gray-500);
      }

      &-seperator {
        display: block;
        width: 1px;
        height: 100%;
        background: var(--gray-200);
        margin: 0 13px;
      }

      &__name {
        @include flex-align-center;
        border-radius: 20px;
        color: var(--gray-500);
        padding-left: 21px;
        padding-right: 21px;
        height: 56px;
        background: rgba(var(--white-rgb), 0.7);
        border: 1px solid var(--white);
        box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
      }
    }

    &--audience {
      display: flex;
      height: 56px;
      @include flex-align-center;

      &__description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 302px;
        height: 73px;
        background: transparent;
        max-width: 350px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;

        &-item {
          display: flex;
          justify-content: space-between;

          &-left {
            font-weight: normal;
            font-size: var(--fs-16);

            line-height: 25px;
            text-align: left;
            color: var(--gray-400);
          }

          &-right {
            font-weight: 500;
            font-size: var(--fs-16);

            line-height: 25px;
            text-align: right;
            color: var(--gray-500);
          }
        }
      }

      &-seperator {
        display: block;
        width: 1px;
        height: 100%;
        background: var(--gray-200);
        margin: 0 13px;
      }

      &__name {
        @include flex-align-center;
        border-radius: 20px;
        color: var(--gray-500);
        padding-left: 20px;
        padding-right: 20px;
        height: 56px;
        background: rgba(var(--white-rgb), 0.7);
        border: 1px solid var(--white);
        box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
      }
    }

    &--adtype {
      display: flex;
      height: 56px;
      @include flex-align-center;
      background: rgba(var(--white-rgb), 0.7);
      box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
      border-radius: 20px;

      &-score {
        font-weight: 500;
        font-size: var(--fs-16);

        line-height: 26px;
        text-align: right;
        color: var(--gray-500);
      }

      &__description {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-width: 229px;
        height: 73px;

        &-item {
          display: flex;
          justify-content: flex-end;
          font-weight: 500;
          font-size: var(--fs-16);

          line-height: 25px;
          text-align: right;
          color: var(--gray-500);
        }
      }

      &-seperator {
        display: block;
        width: 1px;
        height: 100%;
        background: var(--gray-200);
        margin: 0 13px;
      }

      &__name {
        @include flex-align-center;
        color: var(--gray-500);
        padding-left: 24px;
        padding-right: 24px;
        height: 56px;
      }
    }
  }

  ayn-collapse {
    display: block;
    padding: 0 20px 20px;

    &-item:last-child {
      .ayn-collapse {
        margin-bottom: 0;
      }
    }
  }
}

.aayn-create-ad--item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &--container {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 10px;

    &-item {
      width: 100%;
      flex: 1;
    }
  }

  &--platform-header {
    display: flex;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 27px;

    &__title {
      font-size: var(--fs-20);
      font-weight: 600;
      line-height: 1.6;
      text-align: left;
      color: var(--primary);

      @include darkModeColorDefault;
    }

    &__description {
      margin-left: 16px;
      font-size: var(--fs-14);
      font-weight: normal;
      line-height: 1.43;
      color: var(--gray-400);
    }
  }

  &--row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    aayn-select-ad-item {
      flex: 1;
      width: 100%;
    }

    @media (max-width: 1960px) {
      .__empty {
        &:nth-child(2) {
          display: none;
        }
      }
    }

    @media (max-width: 1496px) {
      .__empty {
        display: none;
      }
    }
  }

  &--btn {
    margin: 20px auto 20px auto;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 28px;
    position: relative;

    .aayn-create-ad--item--loading {
      font-size: var(--fs-16);
      line-height: 32px;
      display: flex;
      align-items: center;
      color: var(--gray-400);
      margin-left: 21px;
      white-space: nowrap;
      position: absolute;
      bottom: 30px;
      right: 30px;

      &_has-loaded {
        color: var(--primary);

        .ayn-icon {
          color: var(--primary);
        }

        .aayn-create-ad--item--loading-img {
          animation: none !important;
          color: var(--primary);
        }
      }

      &-img {
        animation: rotation 0.75s infinite linear;
        margin-left: 5px;
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.aayn-import-from-bing {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  h4 {
    font-weight: 600;
    font-size: var(--fs-18);

    line-height: 32px;
    text-align: center;
    color: var(--gray-500);
  }

  > p {
    font-weight: normal;
    font-size: var(--fs-14);
    line-height: 20px;
    text-align: center;
    color: var(--gray-400);
    margin-bottom: 12px;
  }

  form {
    .ayn-form-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;

      ayn-radio {
        width: calc(calc(100% / 3) - 10px);
        margin-bottom: 15px;

        .ayn-radio.select {
          height: 56px !important;
        }
      }

      ayn-form-field {
        width: calc(calc(100% / 3) - 10px);
      }
    }

    padding: 16px;
  }

  &--sub-title {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    font-size: var(--fs-16);

    line-height: 32px;
    text-align: left;
    color: var(--gray-500);

    strong {
      margin-left: 3px;
      font-weight: bold;
      text-decoration: underline;
      color: var(--primary);
      cursor: pointer;
    }
  }
}

aayn-select-ad-type {
  width: 100%;
}

.aayn-create-ad--inner {
  &-title {
    font-weight: 600;
    font-size: var(--fs-18);

    text-align: center;
    color: var(--gray-500);
    margin-top: 47px;
  }

  &-description {
    font-weight: normal;
    font-size: var(--fs-14);
    line-height: 20px;
    text-align: center;
    color: var(--gray-400);
    margin-top: 5px;
  }
}

.aayn-ad-type {
  [ayn-input] {
    letter-spacing: 0;
  }

  &--row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    &--equal {
      & > * {
        flex: 1;
      }
    }
  }

  &--utm {
    display: flex;
    width: 100%;
    margin-top: 15px;

    ayn-input-with-button {
      width: 100%;
    }
  }

  &__mt-spacing {
    margin-top: 22px;
  }

  &-about {
    margin-top: 23px;
  }

  &--control {
    margin: 15px 0 0;
    display: block;
  }

  &--select-call-to-action {
    min-width: 165px;

    .ayn-select--btn {
      white-space: nowrap;
      padding: 0 12px 0 30px;
    }
  }

  &--collapse {
    display: flex;
    margin-top: 30px;
    width: 100%;

    .aayn-collapse-card {
      border-radius: 15px;
      border: 1px solid var(--white);
      background: rgba(var(--white-rgb), 0.4);
      @include darkModeBgDefault;
      width: 100%;

      &.active {
        .aayn-collapse-card--header__collapse {
          &-icon {
            left: 3px;
          }
        }
      }

      &--header {
        margin: 10px 0 10px 0;

        &__collapse {
          margin-right: 10px;

          &-icon {
            left: 3px;
          }
        }

        &__title {
          h4 {
            font-weight: 600;
            font-size: var(--fs-18);

            line-height: 32px;
            text-align: left;
            color: var(--gray-500) !important;
          }

          & + p {
            font-size: var(--fs-16);
            letter-spacing: -0.02em;
            line-height: 24px;
            color: var(--gray-400);
          }
        }

        .ayn-collapse--header__left-collapse {
          .icon {
            left: 3px !important;
          }
        }
      }

      &--main {
        margin-top: 0;
        margin-left: 10px;
        margin-right: 30px;
      }
    }

    &-extentions {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 13px;

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        min-height: 56px;
        border-radius: 15px;
        background: rgba(var(--white-rgb), 0.4);
        @include darkModeBgDefault;
        border: 1px solid var(--white);
        cursor: pointer;
        padding: 19px 0 19px 25px;

        h3 {
          font-weight: 600;
          font-size: var(--fs-16);

          line-height: 24px;
          text-align: left;
          color: var(--gray-500);
        }

        p {
          font-size: var(--fs-14);
          letter-spacing: -0.02em;
          line-height: 28px;
          text-align: left;
          color: var(--success);
          //margin-top: -10px;
        }

        button {
          margin-right: 10px;
          width: 44px;
          height: 44px;
          border-radius: 10px;
        }
      }

      ayn-radio {
        margin-top: 10px;
      }
    }
  }

  .ayn-option:hover img.language-flag {
    filter: none;
  }
}

@media only screen and (max-width: 600px) {
  .aayn-create-ad ayn-collapse {
    padding: 0;
  }
  .ayn-collapse--header.step-current .step-info {
    display: none;
  }
}
