.aayn-mobile-topbar {
  position: relative;
  top: 0;
  border: 1px solid var(--white);

  box-shadow: 0 0.3125rem 1.5625rem rgba(var(--gray-rgb), 0.08);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  -webkit-backdrop-filter: blur(4.5293598175px);
  backdrop-filter: blur(4.5293598175px);
  --webkit-backdrop-filter: blur(4.529359817504883px);
  margin-left: 10px;
  margin-right: 10px;
  height: 90px;
  border-radius: 0 0 25px 25px;

  &--logo {
    max-height: 80px;

    &-icon {
      display: block;
      max-width: 300px;
      width: 100%;
    }
  }

  &--close {
    position: absolute;
    right: 30px;
    cursor: pointer;
  }

  &--header {
    border: 0.125rem solid var(--white);
    background: var(--bg-gray);
    border-radius: 1.875rem;
    box-shadow: 0 0.3125rem 1.5625rem rgba(var(--gray-rgb), 0.08);
    padding: 10px;
    display: flex;
    -webkit-backdrop-filter: blur(4.5293598175px);
    backdrop-filter: blur(4.5293598175px);
    --webkit-backdrop-filter: blur(4.529359817504883px);
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
    position: relative;
    z-index: 1;

    @mixin base-button {
      border: 1px solid var(--white);
      background: var(--white);
      color: var(--gray-400);
      box-shadow: 0 5px 30px rgba($color: #3b2a82, $alpha: 0.08);

      &:not(.active):hover {
        border-color: rgba($color: var(--accent), $alpha: 0.3);
      }
    }

    &-upgrade {
      padding: 0 1.3rem 0 1.25rem;
      margin-left: 15px;

      svg-icon {
        margin-left: 0;
      }
    }

    aayn-business-selection {
      width: 100%;

      .aayn-business-selection {
        background: var(--white);
        width: 100%;
        height: 56px;
        border-radius: 20px;
        box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.08);
        position: relative;

        &.active {
          border-radius: 20px 20px 0 0;
        }

        &--inner {
          background: transparent;
          width: 100%;
          height: 56px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          border: none;
          outline: none;
          justify-content: space-between;
          padding: 0 14px 0 27px;
          cursor: pointer;
        }

        &--name {
          font-weight: 600;
          font-size: 1rem;

          color: var(--gray-500);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &_right {
          display: flex;
          align-items: center;
          color: var(--gray-500);

          ayn-icon {
            margin-left: 7px;
            max-width: 22px;
          }
        }
      }

      .aayn-business-selection--body {
        background: var(--white);
        width: 100%;
        margin-top: 0;
        border-radius: 0 0 20px 20px;
        box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.08);
        border-top: 1px solid var(--gray-200);
        padding: 11px 12px 12px;
        top: 56px !important;
        left: 0 !important;

        &__list {
          max-height: 261px;
          overflow: auto;

          @include scrollbar(5px);

          &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 46px;
            border-radius: 15px;
            background: var(--white);
            border: 1px solid var(--gray-500);
            padding: 0 22px 0 20px;
            transition: 0.2s;

            &:not(:last-child) {
              margin-bottom: 7px;
            }

            &:not(.active) {
              cursor: pointer;
            }

            &:hover {
              background: darken($color: #eff3f6, $amount: 2);
            }

            &.active {
              background: var(--primary);

              p,
              span {
                color: var(--white);
              }
            }

            &--name {
              font-size: var(--fs-16);
              color: var(--gray-500);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 20px;
            }

            &--type {
              font-size: var(--fs-14);
              color: var(--gray-400);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 20px;
            }
          }
        }
      }

      .aayn-create-business--btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 18px 0 27px;
        min-width: 300px;
        width: 100%;
        height: 56px;
        border-radius: 20px;
        background: var(--white);
        border: 0;
        font-weight: 600;
        font-size: var(--fs-16);

        line-height: 32px;
        color: var(--primary);
        box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.08);
        cursor: pointer;
        transition: 0.15s all;

        &:hover {
          box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.2);
        }

        &:focus {
          box-shadow: 0 0 0 0.1875rem rgba(var(--gray-rgb), 0.3);
        }
      }
    }

    .aayn-language-selection {
      position: relative;
      margin-left: 20px;

      &--selected-btn {
        width: 56px;
        height: 56px;
        border-radius: 20px;
        outline: 0;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @include base-button();
      }

      &.active {
        .aayn-language-selection--selected-btn {
          border-radius: 20px 20px 0 0;
        }
      }
    }

    .aayn-user-menu {
      margin-left: 20px;
      position: relative;
      max-width: 201px;

      &--btn {
        display: flex;
        align-items: center;
        border-radius: 20px;
        min-height: 56px;
        padding: 0 15px 0 9px;
        min-width: 201px;
        max-width: 201px;
        cursor: pointer;

        @include base-button();

        &_img-placeholder {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: var(--gray-100);
          min-width: 40px;
          min-height: 40px;
          border-radius: 20px;
          font-size: var(--fs-16);
          font-weight: 600;
          color: var(--gray-500);
          margin-right: 9px;
        }

        &_info {
          text-align: left;
          max-width: 115px;

          &__name {
            font-size: var(--fs-16);
            font-weight: 600;
            color: var(--gray-500);
            line-height: 1;
            margin-bottom: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &__role {
            font-size: var(--fs-12);
            color: var(--gray-500);
            line-height: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        &.active {
          border-radius: 20px 20px 0 0;
        }
      }

      ayn-icon[name='tick'] {
        display: inline-block;
        margin-left: auto;
        color: var(--gray-500);
      }
    }
  }

  &--main {
    margin-left: 30px;
    margin-right: 30px;
  }

  &--package {
    aayn-package {
      display: block;
      margin-top: auto;

      .ayn-layout--package {
        min-width: 230px;
        width: 100%;

        border-radius: 20px;
        padding: 22px;
        background-color: var(--primary);
        position: relative;
        overflow: hidden;

        &::before {
          content: '';
          display: block;
          width: 167.38px;
          height: 140px;
          background: linear-gradient(rgba(var(--white-rgb), 0) 0%, rgba(var(--white-rgb), 0.1) 100%);
          position: absolute;
          top: 9px;
          right: -90px;
          rotate: 215deg;
        }

        &-title {
          font-weight: 600;
          font-size: var(--fs-16);
          line-height: 1;
          margin-bottom: 15px;
          color: var(--white);
        }

        ul {
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: var(--fs-12);
            line-height: 30px;
            color: var(--white);

            &:last-child {
              border-top: 1px solid rgba(var(--body-bg), 0.2);
            }

            span {
              white-space: nowrap;
            }

            b {
              font-weight: 600;
              font-size: var(--fs-14);
              line-height: 28px;
            }

            .ayn-layout--package-limit-text {
              color: rgba($color: #fff, $alpha: 0.5);
            }
          }
        }

        .ayn-progressbar {
          min-width: 100%;
          width: 100%;
          background: rgba(var(--body-bg), 0.2);
          box-shadow: 0 10px 50px rgba(var(--gray-rgb), 0.06);
          height: 5px;
          margin-top: 5px;
          border-radius: 2.5px;

          &-value {
            background: var(--white);
            height: 100%;
            border-radius: 2.5px;
            animation: unset;
          }
        }
      }

      .ayn-layout--invite-friend {
        .ayn-badge {
          margin-top: 10px;
          color: var(--white);
          height: 30px;
          display: inline-flex;
          align-items: center;
          padding: 0 11px;
          font-size: var(--fs-14);

          white-space: nowrap;
          cursor: pointer;
          transition: 0.2s;
          user-select: none;

          ayn-icon[name='add-user'] {
            padding: 0;
            margin-right: 5px;
          }
        }
      }
    }
  }

  &--menu {
    &__list {
      margin-top: 49px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;

      li {
        height: 50px;
        border-radius: 25px;
        background: rgba(var(--white-rgb), 0.5);
        border: 1px solid var(--white);
        margin-bottom: 15px;
        transition: 0.15s all;
        padding: 0 10px 0 30px;

        &:hover {
          background: var(--white);
        }

        &.active {
          background: var(--accent);

          a {
            color: var(--white);

            ayn-icon {
              background: rgba(var(--white-rgb), 0.1);
            }
          }
        }

        &.disabled {
          opacity: 0.5;
        }

        .aside__menu-text + img {
          margin-left: auto;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          font-size: var(--fs-16);
          color: var(--gray-500);
          text-decoration: none;
          cursor: pointer;

          ayn-icon {
            min-width: 42px;
            height: 40px;
            background: rgba(var(--white-rgb), 0.7);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg-icon {
              display: initial;
            }

            svg {
              max-width: 20px;
            }
          }
        }
      }
    }
  }

  &--buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 100px;

    .btn {
      display: flex;
      justify-content: space-between;

      ayn-icon {
        margin-left: 10px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .aayn-mobile-topbar--header-upgrade {
    display: none;
  }
}

@include media-breakpoint-up(sm) {
  .aayn-mobile-topbar--header-upgrade {
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .aayn-mobile-topbar--menu__list {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
  }

  .aayn-mobile-topbar--header .aayn-language-selection {
    min-height: initial !important;
  }

  .aayn-mobile-topbar--main {
    margin-left: 15px;
    margin-right: 15px;
  }
}
