.aayn-text-generator-ai {
  border-radius: 25px;
  background: rgba(var(--white-rgb), 0.3);
  border: 1px solid var(--white);
  box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
  display: flex;
  flex-direction: column;

  &--header {
    display: flex;
    padding: 30px;
    position: relative;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
    align-items: center;

    &__left {
      flex: 1;
      display: flex;
      gap: 15px;
      align-items: center;
      color: var(--primary);
    }

    &__button {
      flex: 1;
      max-width: 120px;
    }

    &__content {
      h5 {
        font-weight: 600;
        font-size: var(--fs-20);
        line-height: 32px;
        color: var(--gray-500);
      }

      p {
        font-size: var(--fs-14);
        line-height: 20px;
        color: var(--gray-500);
      }
    }

    &__close {
      font-weight: 500;
      font-size: var(--fs-16);
      line-height: 24px;
      color: var(--gray-500);
      cursor: pointer;
      //position: absolute;
      right: 40px;
    }
  }

  &--row {
    display: flex;
    flex: 1;
    margin-top: 20px;
    gap: 15px;

    ayn-input-with-label {
      width: 100%;
    }

    ayn-select {
      width: 100%;
      margin-top: 10px;
    }
  }

  &--form {
    padding: 15px 20px 39px 28px;

    &__scan {
      border: 1px solid var(--gray-300);

      &:hover {
        background-color: var(--accent);
        color: var(--white);
      }

      &[disabled] {
        background-color: rgba(var(--gray-rgb), 0.5);
        color: var(--white);
      }
    }

    &__text-area {
      .form-field {
        ayn-icon {
          top: 80%;
        }
      }

      [ayn-input].text-area {
        height: 110px;
      }
    }

    &__button {
      width: 200px;
      margin: 30px auto 0px;

      button {
        &:disabled {
          color: var(--primary);
          background-color: var(--primary-10);
        }
      }
    }
  }
}

ayn-form-field {
  position: relative;

  .form-field [ayn-button].aayn-text-generator-ai--cancel-btn {
    position: absolute;
    right: 10px !important;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background: var(--orange);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    --icon-color: var(--white);

    ayn-icon {
      position: relative;
      top: unset;
      right: unset;
      transform: none;
    }
  }
}

@include media-breakpoint-down(xl) {
  .aayn-text-generator-ai {
    &--header {
      align-items: center;
      justify-content: center;

      &__content {
        margin-top: 15px;
        margin-left: 0;
        max-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .aayn-text-generator-ai {
    &--row {
      flex-wrap: wrap;
    }
  }
}
