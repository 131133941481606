.aayn-precision-audience-creation-ai {
  .aayn-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &--input-container {
    position: relative;
    flex: 1;

    .ayn-recommended {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 30px;
      top: -11px;
      width: max-content;
      height: 22px;
      border-radius: 11px;
      background: var(--gray-25);
      z-index: 5;

      &-text {
        font-weight: 500;
        font-size: var(--fs-12);
        line-height: 32px;
        color: var(--primary);

        @include darkModeColorDefault;

        padding: 6px 10px;
      }
    }
  }

  &--scan-button {
    margin-left: 20px;
  }

  &-form {
    &__text-area {
      [ayn-input] {
        height: 86px;
      }
    }
  }

  ayn-tab {
    .ayn-tab-item {
      > * {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }
    }
  }

  &--add-more {
    display: inline-block;
    position: relative;
    margin-top: 20px;

    ayn-badge ayn-icon {
      rotate: 0;
      transition: 0.2s all ease-in-out;
    }

    ayn-badge[ng-reflect-color='gray-500'] {
      ayn-icon {
        rotate: 45deg;
      }
    }

    .ayn-overlay {
      position: absolute;
      top: 70px !important;
      left: 0 !important;
      width: 540px;
      border-radius: 25px;
      background: rgba(var(--white-rgb), 0.6);
      border: 1px solid var(--white);
      box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
      padding: 20px;
    }

    &--overlay-inner {
      background: var(--white);
      border-radius: 25px;

      .form-field {
        box-shadow: none;
      }

      ul {
        padding: 0 13px;
        overflow: auto;
        max-height: 222px;
        margin-top: 10px;

        li {
          height: 56px;
          border-radius: 20px;
          background: rgba(var(--white-rgb), 0.7);
          border: 1px solid var(--body-bg);
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          font-size: var(--fs-14);
          line-height: 26px;
          color: var(--gray-500);
          padding: 0 25px 0 27px;
          transform: 0.2s all;

          button {
            font-size: var(--fs-16);
            line-height: 28px;
            text-align: right;
            background: transparent;
            color: var(--gray-500);
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            padding: 0;

            ayn-icon {
              margin-left: 8px;
              color: rgba(128, 141, 172, 0.3);
            }
          }

          &:not(:last-child) {
            margin-bottom: 5px;
          }

          &.__selected {
            cursor: auto;

            button {
              color: var(--primary);

              ayn-icon {
                color: var(--primary);
              }
            }
          }

          &:hover {
            border-color: var(--gray-300);
            cursor: pointer;
          }
        }
      }
    }
  }
}
