.aayn-create-ad-input {
  &--button {
    position: relative;
  }

  &--popup {
    top: 0 !important;
    left: calc(100% + 32px) !important;
    margin-top: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
    box-shadow: none;

    .btn.has-icon svg {
      max-height: unset;
    }

    &--inner {
      display: flex;
      max-height: 309px;

      &_main-box {
        z-index: 2;
        background-color: var(--white);
        padding: 16px 12px 12px 12px;
        min-width: 237px;
        box-shadow: 0px 5px 30px rgba(var(--gray-rgb), 0.08);
        border-radius: 20px;

        &.toggled {
          border-radius: 20px 0 0 20px;
        }
      }

      &-title {
        font-size: var(--fs-20);
        margin-bottom: 14px;
        padding-left: 18px;
        font-weight: 500;
        color: var(--gray-500);
      }

      ul {
        height: 100%;

        li {
          position: relative;
        }

        li:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      .btn {
        width: 100%;
        height: 56px;
        border-radius: 20px;
        justify-content: start;
        padding: 18px 20px;
        gap: 12px;
        min-width: 221px;
        font-weight: 500;

        .btn--icon {
          margin-left: 0;
        }

        &:hover,
        &.active {
          background-color: var(--accent);
          color: var(--white);

          @include darkModeColorDefault;
        }
      }
    }

    &--nested {
      border-left: 1px solid var(--white);
      min-width: 237px;
      padding: 16px 12px 12px 12px;
      background-color: var(--white);
      z-index: 1;
      box-shadow: 0px 5px 30px rgba(var(--gray-rgb), 0.08);
      border-radius: 0 20px 20px 0;

      &.animating {
        box-shadow: none;
      }

      ul {
        height: calc(100% - 32px);
        overflow: auto;
      }
    }
  }

  &--search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

    input {
      width: 100%;
      height: 100%;
      border: 0;
      outline: 0;
      color: var(--gray-500);
      font-size: var(--fs-16);
      font-weight: 500;
      background-color: transparent;
    }

    ayn-icon {
      color: var(--gray-500);
    }
  }
}
