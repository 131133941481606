.aayn-lecture-list-item {
  min-height: 340px;
  width: 100%;
  padding: 19px 18px 15.5px 19px;
  border-bottom: 1px solid var(--gray-200);
  cursor: pointer;
  &--placeholder {
    position: relative;
    .playing-tag {
      position: absolute;
      left: 22px;
      bottom: 23px;
      display: flex;
    }
    img {
      height: 215px;
      width: 100%;
      border-radius: 15px;
      border: 1px solid var(--primary);
    }
    label,
    .pause-video-icon {
      font-size: var(--fs-16);
      color: var(--accent);
    }
    .play-video-icon {
      color: var(--primary);
    }
    label {
      line-height: 20px;
      margin-left: 10px;
    }
  }

  &--information {
    padding-left: 22px;
    padding-top: 24px;
    padding-bottom: 15px;
  }
  &--name {
    &.watching-now {
      color: var(--primary);
    }
    font-size: var(--fs-18);
    line-height: 32px;
  }
  &--description {
    font-size: var(--fs-14);
    line-height: 20px;
    color: var(--gray-400);
  }
}
