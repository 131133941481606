.aayn-video-modal {
  &--body {
    width: 736px;
    padding: 50px;
  }
  &--video {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .ayn-video-player {
      border-radius: 26px;
      background: var(--gray-100);
      height: 370px;
      width: 640px;

      iframe {
        height: 349px;
        width: 620px;
        left: 10px;
        right: 10px;
        top: 10px;
        bottom: 10px;
        border-radius: 20px;
      }
    }
  }

  &--title {
    color: var(--primary);
    font-weight: 600;
    font-size: var(--fs-25);
  }

  &--description {
    margin-top: 16px;
    font-size: var(--fs-16);
    color: var(--gray-400);
    line-height: 24px;
  }

  &--hit-line {
    margin-top: 25px;
  }

  &--actions {
    margin-top: 20px;
    display: flex;
    gap: 32px;
    align-content: center;

    [ayn-button] {
      padding: 0 1.5rem;
    }
  }

  &--skip {
    font-weight: 600;
    font-size: var(--fs-16);

    line-height: 56px;
    color: var(--gray-500);
    cursor: pointer;
  }

  &--emphasis {
    font-weight: 600;
  }
}
