@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

.ayn-collapse {
  display: flex;
  flex-direction: column;
  min-height: 115px;
  border-radius: 30px;
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 1px solid var(--white);
  box-shadow: 0px 10px 50px rgba(var(--gray-rgb), 0.06);
  margin-bottom: 30px;
  overflow: hidden;

  &.active {
    overflow: visible;
  }

  &--header {
    width: 100%;
    min-height: 115px;
    display: flex;
    align-items: center;
    transition: 0.2s;
    position: relative;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;

    .aayn-need-help {
      position: static;
    }

    &__left-collapse {
      width: 25px;
      height: 50px;
      background: var(--white);
      border-radius: 0 15px 15px 0;
      margin-right: 26px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 8px;
        height: 8px;
        background-image: url(/assets/images/icons/collapse-arrow.svg);
        background-repeat: no-repeat;
      }
    }

    &__nav {
      display: flex;
      align-items: flex-end;

      .step-title {
        display: flex;
        align-items: center;
        justify-items: center;

        h2 {
          display: inline-block;
          font-weight: 600;
          font-size: var(--fs-25);

          line-height: 32px;
          text-align: left;
        }
      }

      p {
        font-size: var(--fs-16);
        line-height: 25px;
        color: var(--gray-500);
        margin-left: 10px;
      }
    }

    .step-info {
      display: none;
      height: 25px;
      border-radius: 12.5px;
      background: rgba(var(--primary-rgb), 0.1);
      padding: 0 14px;
      place-items: center;
      font-weight: 500;
      font-size: var(--fs-14);
      color: var(--primary);

      @include darkModeColorDefault;

      margin-right: 35px;
      white-space: nowrap;
    }

    &--ng-content {
      margin-left: auto;
      padding-right: 25px;
      display: flex;
      gap: 20px;
    }

    &:not(.step-current):not(.step-disabled) {
      cursor: pointer;
      color: var(--success);

      &:hover {
        border-radius: 30px;
        background: rgba($color: var(--white), $alpha: 0.5);
      }
    }

    &.step-current {
      .ayn-collapse--header__left-collapse {
        .icon {
          width: 8px;
          height: 8px;
          background-image: url(/assets/images/icons/collapse-arrow.svg);
          background-repeat: no-repeat;
          transform: rotate(90deg);
          animation: rotation 0.5s linear;
        }
      }

      nav {
        h2 {
          color: var(--gray-500);
        }
      }

      .step-info {
        display: grid;
      }
    }

    &.step-complete {
      h2 {
        color: var(--primary);

        @include darkModeColorDefault;
      }
    }

    &.step-incomplete {
      nav {
        h2,
        p {
          color: var(--gray-400);
        }
      }

      .step-info {
        display: grid;
        color: var(--gray-400);
        background-color: var(--white);
      }
    }

    &.step-disabled {
      cursor: default;
    }

    &.step-clickable {
      cursor: pointer;

      h2 {
        color: var(--gray-500);
      }
    }
  }

  $parent: &;

  &--main {
    display: none;
    transform-origin: top left;

    #{$parent}.active & {
      display: flex;
      justify-content: center;
      animation: slide-down 0.28s linear;
    }

    .main {
      min-height: 100px;
      width: 100%;
      background: var(--white);
      border-radius: 0 0 15px 15px;
      filter: drop-shadow(0px 10px 50px rgba(var(--primary-rgb), 0.06));
      margin-top: -5px;
    }
  }
}

@keyframes slide-down {
  0% {
    transform: scaleY(0);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@include media-breakpoint-down(md) {
  .ayn-collapse--header__nav {
    align-items: flex-start;
    flex-direction: column;

    p {
      margin-left: 0;
    }
  }

  .ayn-collapse--header--ng-content {
    padding-right: 25px;
    margin-left: 45px;
    margin-top: 10px;
  }
}
