$headerHeight: 90px;

@mixin createCardGridTemplateColumns {
  $querySizes: (
    2000: 4,
    1669: 2,
    907: 1
  );
  @each $querySize, $repeat in $querySizes {
    @media (max-width: #{$querySize}px) {
      grid-template-columns: repeat(#{$repeat}, minmax(260px, 1fr));
    }
  }
}

.aayn-clickfraud-protection {
  .aayn-collapse-card {
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    border: 1px solid var(--white);
    filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));
  }

  &.full-height {
    .aayn-clickfraud-protection--inner {
      height: calc(100% - $headerHeight);

      aayn-collapse-card {
        .aayn-collapse-card {
          justify-content: center;
          min-height: 100%;
          transition: none;
        }

        .aayn-collapse-card--main {
          transition: none;
        }
      }
    }
  }

  padding: 0 !important;

  .aayn-collapse-card--header {
    margin-top: 28px;
  }

  .aayn-collapse-card--main {
    margin: 0 56px 25px;
  }

  &--badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    border-radius: 14px;
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    border: 1px solid var(--primary);
    margin-left: 12px;
    padding-right: 12px;

    &__text {
      align-items: center;
      color: var(--primary);

      @include darkModeColorDefault;

      font-weight: bold;
      font-size: var(--fs-14);
      letter-spacing: -0.04em;
    }

    ayn-icon {
      svg {
        height: 11px;

        g,
        path {
          fill: var(--primary);

          [data-theme='dark'] & {
            fill: var(--gray-500);
          }
        }
      }
    }
  }

  &--left-header {
    margin-left: 56px;

    @include media-breakpoint-down(md) {
      margin-left: unset;
    }

    &_icon {
      margin-left: 56px;
      color: var(--primary);
    }
  }

  &--inner {
    padding: 30px 40px 37px;
    @include media-breakpoint-down(md) {
      padding: 10px;
    }

    position: relative;

    --status-bar: var(--orange);
    --status-title: var(--primary);

    aayn-collapse-card {
      &:last-child {
        .aayn-collapse-card {
          margin-bottom: 0;
        }
      }

      &:not(:first-child) {
        .aayn-collapse-card {
          &::before {
            display: none;
          }
        }
      }

      .aayn-collapse-card {
        position: relative;
        min-height: 130px;

        @include media-breakpoint-down(md) {
          min-height: 250px;
        }

        &::before {
          content: '';
          height: 5px;
          width: calc(100% - (112px));
          background: var(--status-bar);
          border-radius: 0px 0px 5px 5px;
          position: absolute;
          top: 0;
          margin-left: 56px;
        }

        &:not(.active) {
          .aayn-collapse-card--header {
            display: flex;
            margin: 0 auto;
            min-height: inherit;

            [leftheader] {
              height: 90px;

              & + .aayn-collapse-card--header__title {
                margin-left: 21px;
              }
            }

            @include media-breakpoint-down(md) {
              flex-direction: column;
              padding-top: 10px;

              [leftheader] {
                height: 80px;

                & + .aayn-collapse-card--header__title {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  h4 {
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
        }
      }

      .aayn-clickfraud-protection--header-success {
        max-width: 70px;
        margin-left: 50px;
      }

      .aayn-collapse-card--header__title {
        margin-left: 56px;

        h4 {
          color: var(--status-title);

          @include darkModeColorDefault;
        }
      }

      .aayn-collapse-card--header__right {
        margin-right: 25px;
        margin-left: auto;

        @include media-breakpoint-down(md) {
          position: relative;
          margin-top: 10px;
          right: 0;
          margin-right: 0;
        }
      }
    }
  }

  &-header {
    height: $headerHeight;
    border-radius: 30px 30px 0px 0px;
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    box-shadow: 0px 10px 50px rgba(var(--gray-rgb), 0.06);
    padding: 0 20px 0 18px;

    filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));

    @include flex-align-center;

    ayn-form-field {
      max-width: 296px;
    }

    &--platform {
      max-width: 298px;
      border-radius: 20px;
      border-color: var(--white);
      background-color: var(--white);
      box-shadow: 0px 5px 30px rgba(var(--gray-rgb), 0.08);

      &.show {
        border-radius: 20px 20px 0 0;
      }

      .ayn-select--btn {
        display: flex;

        .ayn-option--inner {
          img {
            margin-right: 8px;

            &:nth-child(2) {
              padding-left: 14px;
              margin-left: 14px;
              position: relative;

              &::before {
                content: '';
                height: 56px;
                width: 1px;
                background: var(--gray-200);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0px;
              }
            }
          }
        }
      }

      ayn-overlay {
        .ayn-overlay {
          box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.08);
          border-top-color: var(--gray-200);
          border-color: var(--white);
          top: 1px !important;
        }
      }

      .ayn-option {
        &.selected,
        &:hover {
          img[name='platforms:facebook'] {
            svg {
              path {
                &:first-child {
                  fill: var(--white);
                }

                &:last-child {
                  fill: transparent;
                }
              }
            }
          }

          img {
            svg {
              path,
              g,
              rect,
              circle {
                fill: var(--white);
              }
            }
          }
        }

        img {
          margin-right: 8px;

          &:nth-child(2) {
            margin-left: 14px;
          }

          &[name='check'] {
            display: none;
          }
        }
      }
    }
  }

  &-protected {
    &--header {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-items: center;
      flex-direction: column;

      ayn-lottie {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 95px;
        height: 70px;
      }

      h3 {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 23px;
        font-weight: 600;
        font-size: var(--fs-35);
        line-height: 32px;
        text-align: left;
        color: var(--orange);
        margin-bottom: 10px;
      }

      p {
        font-weight: normal;
        font-size: var(--fs-18);

        line-height: 26px;
        text-align: center;
        color: var(--gray-500);
        max-width: 871px;
        margin: 0 auto 20px;
        text-align: center;
      }
    }

    &--main {
      display: grid;
      width: 100%;
      gap: 20px;
      margin-top: 25px;
      justify-content: center;
      grid-template-columns: repeat(4, minmax(340px, 1fr));

      @include createCardGridTemplateColumns;

      &__item {
        width: 100%;
        min-height: 222px;
        border-radius: 25px;
        background: rgba(var(--white-rgb), 0.6);

        [data-theme='dark'] & {
          background: rgba(var(--white), 0.3);
        }

        ayn-icon {
          @include darkModeColorDefault;
        }

        border: 1px solid var(--white);
        filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));

        padding: 28px;

        h3 {
          font-weight: 600;
          font-size: var(--fs-20);
          line-height: 1;
          color: var(--gray-500);
          margin-top: 16px;
        }

        p {
          font-size: var(--fs-14);
          line-height: 20px;
          color: var(--gray-500);
          margin-top: 9px;
        }
      }
    }

    &--footer {
      display: block;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 40px;

      p {
        font-weight: 600;
        text-align: center;
        color: var(--primary);

        @include darkModeColorDefault;

        margin-bottom: 20px;
        font-size: var(--fs-20);
        line-height: 1;
      }

      button {
        max-width: 158px;
        margin: auto;
      }
    }
  }

  &-analystic-report {
    &--main {
      display: grid;
      width: 100%;
      gap: 20px;
      justify-content: center;
      grid-template-columns: repeat(4, minmax(340px, 1fr));
      padding: 0;
      @include createCardGridTemplateColumns;
      margin-bottom: 40px;

      &__item {
        width: 100%;
        min-height: 222px;
        border-radius: 25px;
        background: rgba(var(--white-rgb), 0.6);
        border: 1px solid var(--white);
        box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.04);
        padding: 28px;
        display: flex;
        flex-direction: column;

        ayn-icon {
          color: var(--primary);
          @include darkModeColorDefault;
        }

        h3 {
          font-weight: 600;
          font-size: var(--fs-20);
          line-height: 32px;
          color: var(--gray-500);
          margin-top: 5px;
        }

        p {
          font-weight: normal;
          font-size: var(--fs-14);
          line-height: 20px;
          color: var(--gray-400);
          margin: 0 0 15px;
        }

        span {
          display: block;
          margin-top: auto;
          font-weight: 600;
          font-size: var(--fs-35);
          line-height: 1;
          color: var(--primary);
          @include darkModeColorDefault;
        }

        &.success {
          background: var(--primary);
          border: 1px solid var(--white);
          filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));

          ayn-icon,
          h3,
          p,
          span {
            color: var(--white);

            @include darkModeColorDefault;
          }
        }
      }
    }

    &--footer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      justify-items: center;
      margin-top: 30px;
      margin-bottom: 10px;
      margin-left: 0;

      p {
        display: flex;
        justify-content: flex-start;
        font-weight: normal;
        font-size: var(--fs-16);

        line-height: 32px;
        text-align: left;
        color: var(--gray-500);

        ayn-icon {
          display: inline-flex;
          margin-right: 10px;
        }
      }
    }
  }

  &-empty-content {
    display: flex;
    justify-content: center;
    height: 475px;

    &--bg-image {
      position: absolute;
      width: 90%;
      height: 475px;
      background-image: url('/assets/images/pngs/bg-blocked-ips.png');
      filter: blur(8px);
      -webkit-filter: blur(8px);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    aayn-empty-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 600px) {
  .aayn-clickfraud-protection--inner {
    aayn-collapse-card {
      .aayn-collapse-card:not(.active) .aayn-collapse-card--header [leftheader] + .aayn-collapse-card--header__title {
        margin-left: 15px !important;
        align-items: flex-start !important;
      }

      .aayn-collapse-card--header__title {
        margin-left: 15px;
      }

      .aayn-collapse-card {
        min-height: 155px;
      }

      .aayn-collapse-card:not(.active) .aayn-collapse-card--header {
        margin-top: -5px !important;
        align-items: flex-start;
        margin-left: 15px;
      }

      .aayn-collapse-card--header__right {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }

  .aayn-clickfraud-protection {
    .aayn-collapse-card--main {
      margin: 0 15px 15px;
    }

    &--left-header {
      display: none;
    }

    &--header-success {
      display: none;
    }
  }

  .aayn-collapse-card--header {
    margin-top: 10px !important;
  }
}
