@mixin title {
  font-weight: 600;
  font-size: var(--fs-18);
  line-height: 32px;
  text-align: left;
  color: var(--gray-500);
}

@mixin description {
  font-size: var(--fs-14);
  line-height: 20px;
  text-align: left;
  color: var(--gray-500);
}

.aayn-settings {
  display: flex;
  border-radius: 30px;
  padding: 40px 42px 40px;

  @include media-breakpoint-down(md) {
    padding: 10px;
  }

  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 1px solid var(--white);
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));
  flex-direction: column;
  height: 100%;

  .ayn-tab {
    border: 1px solid var(--white);
  }

  &-team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 30px;

    @include media-breakpoint-down(md) {
      padding: 10px;
    }

    &--dream-work {
      width: 100%;

      flex-direction: row;
      padding: 28px;
      min-height: 231px;
      border-radius: 25px;
      background: rgba(var(--white-rgb), 0.4);
      @include darkModeBgDefault;
      border: 1px solid var(--white);
      position: relative;
      align-items: center;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: stretch;
      }

      &__icon {
        width: 56px;
        height: 56px;
        margin-right: 17px;
        border-radius: 15px;
        background: rgba(var(--primary-rgb), 0.1);
        min-width: 56px;
        position: absolute;
        top: 28px;
        left: 28px;
        display: flex;
        align-items: center;
        justify-content: center;

        ayn-icon {
          color: var(--primary);

          svg,
          path,
          g {
            fill: var(--primary) !important;
          }
        }
      }

      &__content-wrapper {
        display: flex;
        align-items: start;

        @include media-breakpoint-down(md) {
          flex-direction: column;
          align-items: stretch;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 73px;

        &-title {
          @include title();
        }

        &-description {
          @include description();

          strong {
            font-weight: bold;
          }
        }
      }

      &__image {
        cursor: pointer;
        margin-left: auto;
        max-width: 350px;

        @include media-breakpoint-down(md) {
          margin-top: 30px;
          max-width: 100%;
        }
      }

      &__button {
        border-radius: 28px;
        width: 146px;
        margin-top: 15px;
      }
    }

    &--alert {
      width: 100%;

      button {
        ayn-icon {
          color: var(--primary);
        }
      }
    }

    &--invite-a-user {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 28px;
      border-radius: 25px;
      background: rgba(var(--white-rgb), 0.4);
      @include darkModeBgDefault;
      border: 1px solid var(--white);
      position: relative;
      align-items: flex-start;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: stretch;
      }

      &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &__title {
          @include title();
        }

        &__count {
          @include title();
        }
      }

      &__description {
        @include description();
      }

      &-form {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        /* align-content: center; */
        align-items: center;
        width: 100%;
        gap: 25px;
      }
    }

    &--user-table {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 28px;
      border-radius: 25px;
      background: rgba(var(--white-rgb), 0.4);
      @include darkModeBgDefault;
      border: 1px solid var(--white);
      position: relative;
      align-items: flex-start;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: stretch;
      }

      &__title {
        @include title();
      }

      &__description {
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: var(--gray-500);
      }

      ayn-table {
        width: 100%;

        td {
          vertical-align: middle;
        }
      }

      &-actions {
        display: flex;
        gap: 10px;
        width: 50px;

        &-button {
          width: 40px;
          height: 40px;
          border-radius: 10px;
          background: var(--gray-75);
          color: var(--gray-500);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
