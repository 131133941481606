.disonnect-confirmation-businesses {
  max-height: 406px;
  overflow: auto;

  @include scrollbar(10px);

  > li {
    width: 100%;
    height: 80px;
    border-radius: 25px;
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    border: 1px solid var(--white);
    box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px 0 32px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .\:business-name {
      font-weight: 600;
      font-size: var(--fs-18);
      color: var(--gray-500);
      margin-bottom: 5px;
    }

    .\:business-category {
      font-size: var(--fs-14);
      line-height: 20px;
      color: var(--gray-500);
    }

    .\:icons {
      display: flex;
      align-items: center;

      ayn-icon {
        margin-left: 10px;

        svg {
          max-width: 20px;
        }
      }
    }
  }
}
