ayn-accordion-input-wrapper {
  .accordion-input-wrapper--container {
    border: 0.0625rem solid var(--gray-200);
    border-radius: 0.9375rem;
    &__active {
      .accordion-input-wrapper--content {
        display: block;
      }
    }
  }

  .accordion-input-wrapper--header {
    display: grid;
    grid-template-columns: 0fr 2fr 1fr;
    align-items: center;
    position: relative;
    padding: 12.5px 0;

    .accordion-input-wrapper--header__collapse {
      position: relative;
      height: 30px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--gray-200);
      border-radius: 0 0.5rem 0.5rem 0;
      margin-right: 10px;
      ayn-icon {
        flex: 0;
        font-weight: normal;
        svg {
          width: 8px;
          height: 8px;
        }
      }
    }

    .accordion-input-wrapper--header--title {
      font-size: 1.125rem;
      font-weight: 500;
      color: var(--gray-500);
      flex: 1;
    }
    .accordion-input-wrapper--header--badge {
      font-size: 1.125rem;
      font-weight: 600;
      background-color: var(--gray-800);
      margin-right: 10px;
      flex: 1;
      justify-self: flex-end;
      position: relative;
      .ayn-badge {
        height: 100%;
      }
    }
  }

  .accordion-input-wrapper--content {
    display: none;
    padding: 0 10px 10px 10px;
    [ayn-input].text-area {
      height: 110px;
    }
    .form-field {
      width: 100%;
    }
  }
}
