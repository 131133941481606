.aayn-account-select-modal {
  min-width: 1087px;
  will-change: filter;
  padding-bottom: 20px;

  @include media-breakpoint-down(lg) {
    min-width: auto;
  }

  .modal-close {
    @include modal-close(var(--white));
    @include darkModeColorDefault;
    right: 30px;
    top: 40px;
  }

  &--header {
    min-height: 120px;
    border-radius: 29px 29px 0px 0px;
    background: var(--primary);
    border: 1px solid var(--gray-300);
    padding: 27px 25px;
    color: var(--white);
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -47px;
      width: 398.51px;
      height: 135.92px;
      background: linear-gradient(to top, rgba(var(--white-rgb), 0) 0%, rgba(var(--white-rgb), 0.1) 100%);
      transform: skew(-37deg, 0deg);
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      background: rgb(var(--white-rgb), 0.1);
      border-radius: 50%;

      ayn-icon {
        color: var(--white);

        @include darkModeColorDefault;

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
    }

    &__title {
      display: flex;

      justify-content: flex-start;
      font-weight: 600;
      font-size: var(--fs-25);

      line-height: 32px;
      text-align: left;
      color: var(--white);

      @include darkModeColorDefault;
    }

    &__description {
      display: flex;
      justify-content: flex-start;
      font-weight: normal;
      font-size: var(--fs-16);
      line-height: 20px;
      text-align: left;
      color: var(--white);

      @include darkModeColorDefault;
    }

    ayn-form-field {
      width: 279px;
      position: absolute;
      right: 87px;
    }
  }

  .cdk-virtual-scrollable {
    @include scrollbar(3px);
  }

  &--main {
    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: 408px;
    padding: 24px;

    table {
      td,
      th {
        vertical-align: middle;
        padding: 0 0 0 10px !important;
      }
    }
  }

  &--stepper {
    display: flex;
    flex-direction: column;

    ayn-stepper {
      padding-left: 36px;
      padding-top: 26px;
    }

    &__body {
      width: 100%;
      max-height: 575px;
      overflow: hidden;
      margin-top: 20px;

      &--table {
        display: block;
        padding: 0 30px 30px;
        border-radius: 30px;
        border: 1px solid var(--white);
        filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));

        .img {
          width: 59px !important;

          img {
            width: 38px;
            height: 38px;
            border-radius: 10px;
          }
        }

        &--header {
          &--name {
            width: 51%;
          }

          &--id {
            width: 23%;
          }

          &--status {
            width: 15%;
          }

          &--action {
            width: 11%;
          }
        }

        tr td {
          padding: 5px 10px !important;
        }

        .name {
          width: 51%;
          max-width: 1px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .account-id {
          width: 23%;
        }

        .status {
          width: 15%;
        }

        .select {
          width: 11%;
        }

        .title {
          width: 294px !important;
        }

        .account {
          width: 100%;

          ayn-select {
            border-radius: 10px !important;
          }

          &__select {
            width: 100%;
            background: var(--white);

            .ayn-select--inner {
              height: 38px;
            }

            .ayn-option {
              height: 38px;
            }
          }
        }

        .status {
          width: 180px !important;
          min-width: 180px !important;
          line-height: 1.2;
          text-align: center;

          &:before {
            content: '';
            width: 8px;
            height: 8px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 5px;
            margin-bottom: 2px;
          }

          &.active {
            &:before {
              background: var(--success);
            }

            color: var(--success) !important;
          }

          &.passive {
            &:before {
              background: var(--gray-500);
            }

            color: var(--gray-500) !important;
          }
        }

        .select {
          width: 160px !important;

          &-btn {
            width: 121px;
            height: 44px;
            justify-content: center;
            border-radius: 22px;
          }
        }

        .table-account {
          ayn-form-field .form-field {
            height: 38px !important;
          }

          width: 250px;

          .account__select {
            width: 100%;
          }
        }

        &.table-selected {
          .ayn-table.primary table tbody tr:nth-child(odd) td {
            background: rgba(234, 238, 255, 0.4) !important;

            [data-theme='dark'] & {
              background: rgba(16, 18, 31, 0.4) !important;
            }
          }

          margin-bottom: 15px;
        }
      }
    }
  }

  &--footer {
    display: flex;
    align-items: center;
    position: relative;

    a {
      ayn-icon {
        margin-right: 5px;
      }

      display: flex;
      align-items: center;
      cursor: pointer;
      position: absolute;
      margin-left: 20px;
    }

    .aayn-account-select-modal--btn {
      width: max-content;
      margin: 5px auto;
      margin-top: 20px;
    }
  }

  ayn-loader-lottie {
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.aayn-ad-account-creation-bing {
  display: flex;
  flex-direction: column;
  width: 1067px;
  min-height: 723px;
  border-radius: 30px;
  box-shadow: 0px 5px 99px rgba(var(--gray-rgb), 0.08);

  .modal-close {
    @include modal-close(var(--white));
    right: 30px;
    top: 40px;
  }

  &--header {
    justify-content: flex-start;
    min-height: 120px;
    border-radius: 25px 25px 0px 0px;
    background: var(--primary);
    border: 1px solid var(--gray-300);
    padding: 27px 25px;
    color: var(--white);
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -47px;
      width: 398.51px;
      height: 135.92px;
      background: linear-gradient(to top, rgba(var(--white-rgb), 0) 0%, rgba(var(--white-rgb), 0.1) 100%);
      transform: skew(-37deg, 0deg);
    }

    &__content {
      margin-left: 15px;
      max-width: 858px;
    }

    &__title {
      font-weight: 600;
      font-size: 25px !important;
      line-height: 32px;
      color: var(--white) !important;
      margin-top: 10px;
    }

    &__description {
      font-size: var(--fs-16);
      line-height: 27px;
      color: var(--white);

      strong {
        font-weight: bold;
      }
    }
  }

  &--main {
    display: flex;
    flex-direction: column;

    border-radius: 30px;
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    border: 1px solid var(--white);
    box-shadow: 0px 10px 30px rgba(63, 78, 136, 0.08);
    padding: 30px;
    margin: 25px;

    .ayn-select {
      background: var(--white) !important;
    }

    &__form {
      &--select-with-label {
        width: 100%;
        height: 56px;
        border-radius: 15px;
        border: 1px solid var(--gray-200);
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
          display: inline-flex;
          margin: auto 20px;

          font-weight: normal;
          font-size: var(--fs-16);
          line-height: 32px;
          text-align: left;
          color: var(--gray-500);
        }

        ayn-select {
          margin-right: 7px;
          display: inline-block;
          width: 100%;

          .ayn-select {
            &--inner {
              height: 46px;

              .ayn-select--btn {
                padding-right: 14px;
                background: var(--white) !important;
              }
            }

            span {
              min-width: 36px;
              height: 24px;
              margin-right: 11px;
            }

            ::ng-deep .ayn-option--inner {
              justify-content: flex-start;

              span {
                margin-right: 11px;
              }
            }
          }
        }
      }
    }
  }

  &--footer {
    display: flex;
    justify-content: center;

    button {
      margin-bottom: 35px;
      max-width: max-content;
    }
  }
}
