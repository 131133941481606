.ayn-file-upload {
  width: 100%;
  position: relative;
  padding: 27px 24px;
  border-radius: 25px;
  border: solid 1px var(--white) !important;
  background: rgba(var(--white-rgb), 0.6);
  $fileClass: &;

  &__filled {
    padding: 10px;
  }

  &__less-padding {
    padding: 13px 11px 16px;
  }

  $parent: &;

  &__required {
    background-color: var(--orange-5);
    border-color: var(--orange-60);
    color: var(--orange);

    #{$parent}--inner {
      &-content {
        color: var(--orange);
      }

      &--description {
        background-color: var(--orange-5);
        border-color: #{rgba($orange, 0.2)};
        color: var(--orange);
      }

      .ayn-file-upload--description {
        color: var(--orange);

        b {
          color: var(--orange);
        }
      }

      .ayn-file-upload--placeholder {
        color: var(--orange);
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  &--inner {
    height: 100%;
    position: relative;
    box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.04);
    border-radius: 25px;

    &--description {
      padding: 30px;

      @include media-breakpoint-down(md) {
        padding: 20px;
      }

      border-radius: 20px;

      &__required {
        position: absolute;
        top: 20px;
        right: 20px;
        color: black;

        &--single {
          top: 8px;
          right: 8px;
        }
      }
    }

    &-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: var(--gray-500);

      ayn-lottie {
        max-width: 120px;
        margin: 0;
      }
    }
  }

  &--footer {
    &-text {
      font-weight: 600;
      font-size: var(--fs-18);

      line-height: 32px;
      text-align: center;
      color: var(--gray-500);
      margin-bottom: 12px;
    }

    &-canva-btn {
      width: 100%;
      min-height: 56px;
      border-radius: 20px;
      background: linear-gradient(90deg, var(--primary) 0%, var(--accent) 100%);
      justify-content: center;
      margin-bottom: 10px;
    }

    &-btn {
      width: 100%;
      min-height: 56px;
    }
  }

  &--placeholder {
    font-size: var(--fs-18);

    line-height: 32px;
    text-align: center;
    color: var(--gray-500);
    margin-top: 13px;

    @include fw('semibold');
  }

  &--description {
    font-size: var(--fs-16);
    line-height: 20px;
    text-align: center;
    color: var(--gray-400);
    margin-top: 13px;

    b {
      display: block;
      text-align: center;
      color: var(--gray-400);

      @include fw('medium');
    }
  }

  &--image {
    max-width: 100%;
    height: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(5, 1fr);
    padding: 10px;
    grid-auto-rows: 1fr;

    &--hover {
      display: none;

      &_remove-btn {
        color: var(--white);
        background-color: transparent;

        &:hover:not(.hover-disabled) {
          background-color: var(--primary);
        }

        &.btn.has-icon {
          padding: 0;
          justify-content: center;
        }

        .btn--icon {
          margin: 0;
        }

        svg {
          width: 21.5px;
          height: 22.5px;
        }
      }
    }

    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--gray-300);
      border-radius: 20px;
      padding: 10px;
      position: relative;
      background-color: var(--white);
      color: var(--gray-500);

      &.invalid {
        border-color: var(--orange);
        background-color: var(--orange-5);
        color: var(--orange);
      }

      &:hover {
        .ayn-file-upload--image--hover {
          width: calc(100% - 20px);
          height: calc(100% - 20px);
          position: absolute;
          top: auto;
          left: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(var(--black-rgb), 0.7);
          border-radius: 20px;
        }
      }

      &--placeholder {
        ayn-icon {
          svg {
            width: 26px;
          }
        }
      }
    }

    .image {
      border: 1px solid var(--gray-300);
      width: 100%;
      height: 100%;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  $linesWidth: 22.3px;

  &--group {
    padding: 30px 0 0 0;

    &-header {
      position: relative;
      padding-left: $linesWidth;
      padding-right: $linesWidth;
      display: flex;
      align-items: center;
      color: var(--gray-500);

      &__label-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
        width: 100%;
        color: var(--gray-500);

        span {
          margin: 0 6px;
        }
      }

      .divider {
        width: 100%;
        height: 2px;
        background: var(--gray-100);
        z-index: 0;
        flex: 1;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: $linesWidth;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        background: var(--gray-100);
        z-index: 0;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    .ayn-file-upload--image {
      padding: 20px 24px;
    }

    .image-container {
      img {
        object-fit: cover;
        max-height: none;
        min-height: 100%;
      }
    }
  }

  &--single {
    #{$fileClass}--inner {
      &--description {
        border: none;
      }
    }

    &-image {
      max-width: 100%;
      height: 100%;

      img {
        width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 25px;
      }
    }
  }
}

.ayn-file-upload--tooltip {
  max-width: 205.74px;
  font-size: var(--fs-14);
  padding: 20px;
  line-height: 20px;
}

@media only screen and (max-width: 600px) {
  .ayn-file-upload {
    padding: 10px;
  }
}
