.aayn-error-modal {
  will-change: filter;
  width: 581px;
  display: flex;
  flex-direction: column;
  margin-left: 47px;
  margin-right: 46px;

  .modal-close {
    right: -10px;
  }

  &--header {
    margin-top: 62px;
    display: flex;
    flex-direction: column;

    &__title {
      font-weight: 600;
      font-size: var(--fs-40);

      line-height: var(--fs-32);
      text-align: center;
      color: var(--orange);
    }

    &__description {
      margin-top: 10px;
      font-weight: normal;
      font-size: var(--fs-20);

      line-height: var(--fs-20);
      text-align: center;
      color: var(--gray-500);
    }

    ayn-modal-close {
      ayn-icon {
        color: var(--gray-400);
      }
    }
  }

  &--header--has-description-wide {
    .aayn-error-modal--header__description {
      line-height: var(--fs-25);
      letter-spacing: 0;
      margin-bottom: 10px;
    }
  }

  &--body {
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 23px;
    border-radius: 15px;
    background: var(--white);
    border: 1px solid var(--gray-200);

    &__item {
      font-weight: 600;
      font-size: var(--fs-16);

      line-height: 32px;
      color: var(--primary);
    }

    &__sub-item {
      font-weight: normal;
      font-size: var(--fs-14);

      line-height: 32px;
      text-align: right;
      color: var(--gray-400);
    }
  }

  &--subcontent {
    font-size: var(--fs-16);

    color: var(--gray-400);
    margin: 12px 0;
    text-align: center;
    line-height: 20px;
  }

  &--footer {
    display: flex;
    margin-top: 18px;
    margin-bottom: 62px;

    .btn {
      flex: 1;
      width: auto;
      font-weight: 600;
      font-size: var(--fs-16);

      line-height: 32px;
      text-align: left;
      color: var(--white);

      ayn-icon {
        margin-left: -15px;
      }

      .has-icon {
        padding: 0px;
      }

      &:first-child {
        margin-right: 1rem;
      }
    }
  }
}
