@import '../../helpers';

.aayn-university-certificate {
  width: 260px;

  &--certificate-name {
    font-weight: 600;
    font-size: var(--fs-16);
    color: var(--gray-500);
  }
  &--certificate-download {
    font-size: var(--fs-16);
    color: var(--gray-500);
    opacity: 0.8;
  }
  &--icon {
    margin-left: 16px;
  }

  .ayn-option {
    font-weight: 500;
    font-size: var(--fs-14);

    &--inner {
      width: 100%;

      ayn-icon {
        max-width: 18px;
      }
    }
  }
}
