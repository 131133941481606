.aayn-level--body {
  width: 933px;
  height: 482.11px;
  border-radius: 30px;
  background: var(--white);
  box-shadow: 0 5px 99px rgba(var(--gray-rgb), 0.08);

  &::before {
    content: '';
    width: 17px;
    height: 21px;
    background: url(/assets/images/svgs/up--blue.svg) no-repeat center center;
    position: absolute;
    top: -9px;
    z-index: -1;
    right: 380px;
  }

  &__inner {
    ayn-modal-close {
      position: absolute;
      top: 17px;
      right: 19px;
      color: var(--white);
      z-index: 1;
      cursor: pointer;
    }

    &-header {
      height: 176px;
      border-radius: 30px 30px 0 0;
      background: var(--white);
      background: url(/assets/images/pngs/modal-xl-bg.png) no-repeat center center;
      background-size: cover;
      padding: 38px 42px 32px 70px;
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        display: inline-block;
        height: 69px;
        width: 12px;
        border-radius: 0 12px 12px 0;
        background: var(--white);
        box-shadow: 0 5px 99px rgba(var(--gray-rgb), 0.08);
        position: absolute;
        top: 57px;
        left: 39px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 38px;
        left: 39px;
        bottom: 0;
        right: 42px;
        width: calc(100% - (39px + 42px));
        height: calc(100% - (38px + 32px));
        border: 1px solid var(--white);
        border-radius: 15px;
        z-index: 0;
      }

      &__left {
        max-width: 334px;
        margin-right: 52px;

        strong {
          font-size: var(--fs-20);

          line-height: 1;
          text-align: center;
          color: var(--white);

          @include fw('semibold');
        }

        p {
          font-size: var(--fs-16);

          line-height: 20px;
          text-align: left;
          color: var(--white);

          b {
            @include fw('semibold');
          }
        }
      }

      &__levels {
        display: flex;
        align-items: center;

        .aayn-level-item {
          min-width: 36px;
          min-height: 36px;
          background: rgba(var(--white-rgb), 0.2);
          border-radius: 50px;
          font-size: var(--fs-16);

          text-align: center;
          color: var(--white);
          position: relative;

          @include flex-center;
          @include fw('semibold');

          &:not(:last-child) {
            margin-right: 5px;
          }

          &--text {
            position: relative;
            z-index: 2;
          }

          img {
            display: none;
          }

          &__active {
            background: transparent;

            img {
              display: block;
              position: absolute;
              z-index: 1;
              bottom: 0;
            }

            &::after {
              --xp-text: '+10xp';

              content: var(--xp-text);

              font-size: var(--fs-14);

              line-height: 20px;
              text-align: left;
              color: var(--white);
              position: absolute;
              bottom: -24px;

              @include fw('normal');
            }
          }
        }
      }

      &__total {
        width: 113px;
        height: 69px;
        border-radius: 15px 0 0 15px;
        background: var(--white);
        box-shadow: 0 5px 99px rgba(var(--gray-rgb), 0.08);
        position: relative;
        right: -42px;
        font-size: var(--fs-16);

        line-height: 1;
        text-align: center;
        color: var(--gray-500);

        @include flex-center;
        @include flex-direction('column');

        b {
          font-size: var(--fs-20);

          text-align: center;
          color: var(--accent);
          display: block;

          @include fw('semibold');
        }
      }
    }

    &-body {
      padding: 40px 40px 40px 42px;

      &--content {
        border-radius: 15px;
        background: var(--white);
        border: 1px solid var(--gray-200);
        padding: 16px 0 7px;
        height: 226px;

        &-title {
          font-weight: 600;
          font-size: var(--fs-18);

          line-height: 1;
          color: var(--gray-500);
          margin: 0 20px;
        }

        &-description {
          font-size: var(--fs-14);
          line-height: 20px;
          text-align: left;
          color: var(--gray-400);
          margin: 1px 20px 0;
        }

        &-levels {
          padding: 0 14px;

          &--inner {
            display: flex;
            overflow-x: auto;
            padding-bottom: 7px;
            margin-top: 16px;

            @include scrollbar;
          }

          aayn-level-item {
            &:not(:first-child) {
              margin-left: 14px;
            }
          }

          .aayn-level-item {
            width: 160px;
            height: 130px;
            border-radius: 15px;
            background: var(--gray-100);
            padding: 11px 12px 10px 13px;

            @include flex-justify-between;
            @include flex-direction('column');

            &--header {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
            }

            &--name {
              border-radius: 8px;
              background: var(--white);
              height: 20px;
              padding: 0 8px;
              display: inline-flex;
              align-items: center;
              font-size: var(--fs-12);
              color: var(--gray-400);
              margin-top: 4px;

              ayn-icon[name='check'] {
                margin-left: 8px;
                display: none;
              }
            }

            &--icon {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              background: rgba($color: #6884a9, $alpha: 0.3);
              color: var(--white);
              position: relative;

              @include flex-align-end;
              @include flex-direction('column');

              &__point {
                display: inline-block;
                font-size: 9px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                @include fw('semibold');
              }

              &__xp {
                font-size: var(--fs-14);

                line-height: 20px;
                text-align: right;
                color: var(--gray-400);
              }

              ayn-icon {
                margin-right: 4px;
                display: block;

                svg {
                  max-width: 20px;
                  max-height: 28px;

                  text {
                    tspan {
                      display: none;
                    }
                  }
                }
              }
            }

            &--task {
              font-weight: 600;
              font-size: var(--fs-16);

              line-height: 20px;
              text-align: left;
              color: var(--gray-400);
            }

            &--go-task {
              color: var(--white);
              text-decoration: none;
              display: flex;
              font-size: var(--fs-12);
              line-height: 20px;
              text-align: left;
              align-items: center;

              &:hover {
                ayn-icon {
                  svg {
                    margin-left: 6px;
                  }
                }
              }

              ayn-icon {
                svg {
                  height: 7px;
                  margin-left: 3px;
                  transition: 0.2s;
                }
              }
            }

            &__active {
              background: var(--primary);

              .aayn-level-item {
                &--name {
                  color: var(--primary);
                }

                &--icon {
                  background: rgba(var(--white-rgb), 0.3);

                  &__xp {
                    color: var(--white);
                  }
                }

                &--task {
                  color: var(--white);
                }
              }
            }

            &__passed {
              background: var(--gray-400);

              .aayn-level-item {
                &--name {
                  color: var(--gray-400);
                }

                &--icon {
                  background: rgba(var(--white-rgb), 0.3);

                  &__xp {
                    color: var(--white);
                  }
                }

                &--task {
                  color: var(--white);
                }
              }
            }

            &__gift {
              background: linear-gradient(180deg, var(--primary) 0%, var(--success) 100%);
              position: relative;

              .aayn-level-item {
                &--name {
                  display: none;
                }

                &--icon {
                  display: none;
                }

                &--task {
                  color: var(--white);
                  display: inline-block;
                  width: 10px;
                  white-space: normal;
                }

                &--gift-bg {
                  position: absolute;
                  top: 10px;
                  right: 12px;
                }

                &--xp__for-gift {
                  display: flex;
                  align-items: flex-end;
                  font-size: var(--fs-16);

                  line-height: 20px;
                  text-align: left;
                  color: var(--white);
                  margin-top: 5px;

                  @include fw('semibold');

                  ayn-icon {
                    margin-right: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
