.aayn-university {
  &-title {
    display: flex;
    &--completed {
      color: var(--success);
      ayn-icon {
        font-size: var(--fs-24);
        display: flex;
        margin-left: 5px;
      }
    }
  }
  &--duration-information {
    color: var(--gray-500);
    font-size: var(--fs-14);
  }

  .aayn-collapse-card--header {
    position: relative;
  }
}
