@mixin progress-value-box {
  background: linear-gradient(
        -45deg,
        rgba(var(--white-rgb), 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(var(--white-rgb), 0.15) 50%,
        rgba(var(--white-rgb), 0.15) 75%,
        transparent 75%
      )
      left/1.875rem 1.875rem repeat-x,
    linear-gradient(90deg, var(--primary) 0%, var(--accent) 100%) left/var(--p, 100%), var(--gray-200);
  box-shadow: 0rem 0.625rem 3.125rem rgba(var(--gray-rgb), 0.06);
  animation: change 1s linear infinite;
  transition: 0.5s all;
}

@mixin progress-value-box-millennium {
  background: var(--primary);
  box-shadow: 0rem 0.625rem 3.125rem rgba(var(--gray-rgb), 0.06);
  animation: change 1s linear infinite;
  border-radius: 20px;
  transition: 0.5s all;
}

@mixin progress-value-box-white {
  background: linear-gradient(
        -45deg,
        rgba(var(--primary-rgb), 0.06) 25%,
        transparent 25%,
        transparent 50%,
        rgba(var(--primary-rgb), 0.06) 50%,
        rgba(var(--primary-rgb), 0.06) 75%,
        transparent 75%
      )
      left/1.875rem 1.875rem repeat-x,
    linear-gradient(90deg, var(--white) 0%, var(--gray-400) 100%) left/var(--p, 100%), var(--gray-400);
  box-shadow: 0rem 0.625rem 3.125rem rgba(var(--gray-rgb), 0.06);
  animation: change 1s linear infinite;
  transition: 0.5s all;
}

@keyframes change {
  from {
    background-position: 0 0, left;
  }
  to {
    background-position: 1.875rem 0, left;
  }
}

.ayn-progressbar {
  position: relative;
  overflow: hidden;
  border: 0 none;
  background: var(--gray-200);
  border-radius: 0.25rem;
  border-radius: 0.4375rem;

  &.x-small {
    width: 7.5rem;
    min-width: 7.5rem;
    height: 0.875rem;

    .ayn-progressbar-value {
      height: 0.875rem;
      @include progress-value-box-white();
    }

    .ayn-progressbar-label {
      color: var(--white);
      line-height: 0.875rem;
    }
  }

  &.small {
    width: 14.5231rem;
    min-width: 13.5587rem;
    height: 0.625rem;

    .ayn-progressbar-value {
      height: 0.625rem;
      @include progress-value-box();
    }

    .ayn-progressbar-label {
      color: var(--white);
      line-height: 0.4375rem;
    }
  }

  &.medium {
    width: 14.5231rem;
    min-width: 13.5587rem;
    height: 0.875rem;

    .ayn-progressbar-value {
      height: 0.875rem;
      border-radius: 50px;

      @include progress-value-box();
    }

    .ayn-progressbar-label {
      color: var(--white);
      line-height: 0.4375rem;
    }
  }

  &.large {
    width: 14.5231rem;
    min-width: 13.5587rem;
    height: 1.25rem;

    .ayn-progressbar-value {
      height: 1.25rem;
      @include progress-value-box();
    }

    .ayn-progressbar-label {
      color: var(--white);
      line-height: 0.4375rem;
    }
  }

  &.millennium {
    width: 100%;
    min-width: 7.5rem;
    height: 13px;
    background: rgba(var(--primary-rgb), 0.2);

    .ayn-progressbar-value {
      height: 13px;

      @include progress-value-box-millennium();
    }

    .ayn-progressbar-label {
      color: var(--white);
      line-height: 0.3125rem;
    }
  }
}
