@mixin content-center {
  justify-content: center;
  align-items: center;
  justify-items: center;
}

@mixin modal-close($color) {
  position: absolute;
  top: 2.35rem;
  right: 2.05rem;
  width: 1.1475rem;
  height: 1.155rem;
  color: $color;
  cursor: pointer;
}

@mixin modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  border-radius: 1.875rem;
}

@mixin header-backgroun-polygon($background) {
  margin-right: -0.0625rem;
  background: $background;
  background-position: inherit;
  background-size: 100% 24.6875rem;
  background-repeat: no-repeat;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 96%, 0 78%);
  clip-path: polygon(0 0, 100% 0, 100% 96%, 0 78%);
}

@mixin h4($color, $fontSize, $lineHeigh) {
  font-weight: 600;
  font-size: $fontSize;

  line-height: $lineHeigh;
  text-align: center;
  color: $color;
}

@mixin p($color, $fontSize, $lineHeight) {
  font-weight: normal;
  font-size: $fontSize;

  line-height: $lineHeight;
  text-align: center;
  color: $color;
}

@mixin button {
  font-weight: 600;
  font-size: var(--fs-16);

  line-height: 2rem;
  text-align: left;
}

ngb-modal-window:focus-visible {
  outline: none !important;
}

.advance-modal {
  .modal-content {
    @include modal-content();

    .modal-close {
      @include modal-close(var(--white));
    }

    &--header {
      display: block;
      @include content-center();
      @include header-backgroun-polygon($background: url(/assets/img/modal-bg.png));
      margin-top: 0;
      height: 17.25rem;

      &--h4 {
        display: flex;
        @include content-center();
        @include h4($color: var(--white), $fontSize: 2.5rem, $lineHeigh: 2.625rem);
        margin-top: 2.6875rem;
      }

      &--p {
        display: flex;
        @include content-center();
        @include p($color: var(--white), $fontSize: 1rem, $lineHeight: 1.5625rem);
        margin: 0.75rem 2.9375rem;
      }

      .btn {
        display: flex;
        @include content-center();
        @include button();
        margin: 1.375rem auto;
      }
    }

    &--main {
      display: block;
      @include content-center();
      align-items: center;
      margin-top: -1.25rem;

      &--h4 {
        display: flex;
        @include content-center();
        @include h4($color: var(--gray-500), $fontSize: 1.125rem, $lineHeigh: 2rem);
      }

      &--p {
        display: flex;
        @include content-center();
        @include p($color: var(--gray-400), $fontSize: 0.875rem, $lineHeight: 1.375rem);
      }
    }

    &--footer {
      display: flex;

      ayn-input-with-button {
        display: block;
        width: 100%;
      }
    }
  }
}

.roadmap-modal {
  .modal-content {
    @include modal-content();
    height: 47.8125rem;

    .modal-close {
      @include modal-close(var(--white));
    }

    header {
      display: block;
      @include content-center();
      margin-top: 0;
      height: 21.1875rem;
      margin-bottom: 4.75rem;
      @include header-backgroun-polygon($background: url(/assets/images/pngs/modal-xl-bg.png));

      h4 {
        display: flex;
        @include content-center();
        @include h4($color: var(--white), $fontSize: 2.5rem, $lineHeigh: 2.625rem);
        margin-top: 2.9375rem;
      }

      p {
        display: flex;
        @include content-center();
        @include p($color: var(--white), $fontSize: 1rem, $lineHeight: 1.5625rem);
        margin: 0.75rem 15.625rem;
      }

      .btn {
        display: flex;
        @include content-center();
        @include button();
        margin: 1.375rem auto;
      }
    }

    main {
      display: block;
      @include content-center();

      ayn-lottie {
        height: 7.125rem;
        display: block;
      }

      h4 {
        display: flex;
        @include content-center();
        @include h4($color: var(--accent), $fontSize: 1.25rem, $lineHeigh: 2rem);
      }

      p {
        display: flex;
        @include content-center();
        @include p($color: var(--gray-400), $fontSize: 0.875rem, $lineHeight: 1.25rem);
        margin-top: 0.625rem;
      }

      .btn {
        display: flex;
        @include content-center();
        margin: 1.125rem auto;
        font-weight: 600;
        font-size: var(--fs-14);

        line-height: 2rem;
        text-align: center;
      }
    }
  }
}

.text-generator-modal {
  .modal-content {
    will-change: filter;
    background: var(--white);
    filter: drop-shadow(0px 5px 99px rgba(var(--gray-rgb), 0.08));
    margin-top: 32px;
    margin-right: 44px;

    .modal-close {
      @include modal-close(var(--gray-400));
    }

    header {
      display: flex;
      justify-content: flex-start;
      height: 90px;

      ayn-icon {
        color: var(--primary);
        margin-left: 35px;
        margin-right: 15px;
        margin-bottom: 10px;
      }

      h4 {
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
        font-size: var(--fs-25);

        line-height: 32px;
        text-align: left;
        color: var(--primary);
        margin-top: 21px;
      }

      p {
        display: flex;
        justify-content: flex-start;
        font-weight: normal;
        font-size: var(--fs-14);
        line-height: 20px;
        text-align: left;
        color: var(--gray-400);
        margin-top: 0px;
        margin-left: 0px;
      }

      ayn-modal-close {
        ayn-icon {
          color: var(--gray-400) !important;
        }
      }
    }

    main {
      width: 1308.14px;
      height: 576px;
      background: var(--white);
      border: 1px solid var(--gray-200);
      border-radius: 20px;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  transition: all 0.5;

  &:before {
    display: inline-block;
    vertical-align: middle;
    content: ' ';
    height: 100%;
  }

  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
    display: inline-block;
    text-align: left;
    vertical-align: middle;

    &.fade {
      transition: transform 0.3s ease-out;
      transform: translateY(-3.125rem);
    }

    &.show {
      transform: none;
    }

    &.modal-static {
      transform: scale(1.02);
    }
  }
}

@include media-breakpoint-down(lg) {
  .modal {
    .modal-dialog {
      position: fixed;
      top: 50%;
      left: 10px;
      right: 10px;
      margin: 0;
      transform: translateY(-50%);
    }
  }
}

.modal-content {
  @include modal-content();
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 1px solid var(--white);

  .modal-close {
    @include modal-close(var(--white));
  }

  &--header {
    display: block;
    @include content-center();
    margin-top: 3.875rem;

    h4 {
      display: flex;
      @include content-center();
      @include h4($color: var(--orange), $fontSize: 2.5rem, $lineHeigh: 2rem);
    }

    p {
      display: block;
      @include content-center();
      @include p($color: var(--gray-500), $fontSize: 1.25rem, $lineHeight: 1.25rem);
      margin: 0.75rem 2.9375rem;
    }
  }

  &--main {
    display: flex;
    position: relative;
    @include content-center();
    margin: 0.75rem 2.9375rem;
  }

  &--footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    margin: 0.75rem 2.9375rem 3.875rem;

    .btn {
      width: 12.5rem;
    }
  }
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);

  &.modal-content {
    max-height: 100%;
    overflow: hidden;
  }

  &--main {
    overflow-y: auto;
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 0.5;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;

  &.modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  h4 {
    border-radius: 0;
  }

  &--main {
    overflow-y: auto;
  }

  &--footer {
    border-radius: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

ngb-modal-window[aria-hidden='true'] {
  display: none !important;
}
