.modal-v3 {
  width: 635px;
  max-width: 635px;

  .modal-content {
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    border: 1px solid var(--white);
    box-shadow: 0px 10px 30px rgba(63, 78, 136, 0.08);
  }

  &--header {
    min-height: 120px;
    border-radius: 25px 25px 0px 0px;
    background: var(--orange);
    border: 1px solid var(--gray-300);
    padding: 27px 25px;
    color: var(--white);
    position: relative;
    display: flex;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -71px;
      width: 277.51px;
      height: 135.92px;
      background: linear-gradient(to top, rgba(var(--white-rgb), 0) 0%, rgba(var(--white-rgb), 0.1) 100%);
      transform: skew(-37deg, 0deg);
    }

    ayn-icon {
      display: grid;
      place-items: center;
      min-width: 50px;
      min-height: 50px;
      max-width: 50px;
      max-height: 50px;
      border-radius: 50%;
      margin-right: 15px;
      background-color: rgba(var(--white-rgb), 0.1);

      svg-icon {
        display: initial;
      }
    }

    &-content {
      h5 {
        font-weight: 600;
        font-size: var(--fs-20);
        line-height: 32px;
        color: var(--white);
        margin-bottom: 3px;
      }

      p {
        font-size: var(--fs-18);
        line-height: 20px;
        color: var(--white);
      }
    }
  }

  &--content {
    padding: 30px 30px 20px;
  }

  &--footer {
    padding: 0 30px 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.\:space-between {
      justify-content: space-between;
    }

    &\:go-back {
      font-weight: 500;
      font-size: var(--fs-16);
      color: var(--gray-500);
      cursor: pointer;
    }
  }
}
