.ayn-table {
  background: var(--white);
  border: 1px solid var(--gray-25);
  border-radius: 20px;
  padding: 20px 0 0;
  @include media-breakpoint-down(lg) {
    overflow: auto;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    thead {
      tr {
        th {
          background: var(--gray-50);
          font-weight: 500;
          font-size: var(--default-font-size);

          line-height: 2rem;
          color: var(--gray-600);
          padding: 0 0 0 24px;
          border: 1px solid var(--gray-50);
          border-left-color: var(--white);
          border-right-color: var(--white);

          &:first-child {
            border-left-color: var(--gray-50);
          }

          &:last-child {
            border-right-color: var(--gray-50);
          }
        }

        th:not(.text-center, .text-right) {
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 14px 24px;
          background: var(--white);
          border: 1px solid var(--gray-25);
          font-size: var(--fs-16);

          text-align: left;
          color: var(--gray-600);
          min-height: 50px;

          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: none;
          }
        }

        &:last-child {
          td {
            border-bottom: none;

            &:first-child {
              border-bottom-left-radius: 20px;
            }

            &:last-child {
              border-bottom-right-radius: 20px;
            }
          }
        }
      }
    }
  }

  &--pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 25px;
    margin-bottom: 10px;

    ayn-form-field {
      width: auto;
    }

    span {
      margin-right: 10px;
    }
  }

  &.primary {
    border: none;
    padding: 0;
    border-radius: 0;
    background: transparent;
    position: relative;

    --border-radius: 15px;
    --th-height: 44px;

    &::before {
      content: '';
      position: absolute;
      left: -11px;
      top: calc(var(--th-height) + 2px);
      height: calc(100% - var(--th-height) + 2px);
      width: calc(100% + 22px);
      border-radius: 20px;
      background: var(--white);
      border: 1px solid var(--white);
      box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.04);
      z-index: 0;
    }

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 6px;
      position: relative;
      z-index: 2;

      thead {
        tr {
          th {
            font-weight: 500;
            font-size: var(--default-font-size);
            border: none;
            line-height: var(--th-height);
            color: var(--gray-500);
            background: transparent;
          }

          th:not(.text-center, .text-right) {
            text-align: left;
          }
        }
      }

      tbody {
        tr {
          height: 56px;

          &:nth-child(odd) {
            td {
              background: var(--white);

              @include darkModeColorDefault;
            }

            &:hover {
              td {
                background: rgba(0, 0, 0, 0.02);
              }
            }
          }

          &:nth-child(even) {
            td {
              background: rgba(234, 238, 255, 0.4);

              [data-theme='dark'] & {
                background: rgba(16, 18, 31, 0.4);
              }
            }

            &:hover {
              td {
                background: #e1e6fb;
              }
            }
          }

          &:first-child {
            td {
              &:first-child {
                border-top-left-radius: var(--border-radius);
              }

              &:last-child {
                border-top-right-radius: var(--border-radius);
              }
            }
          }

          &:last-child {
            td {
              &:first-child {
                border-bottom-left-radius: var(--border-radius);
              }

              &:last-child {
                border-bottom-right-radius: var(--border-radius);
                @include darkModeColorDefault;
              }
            }
          }

          td {
            padding: 20px 24px;
            border: none;
            font-size: var(--fs-16);
            background: transparent;
            color: var(--gray-600);
            @include darkModeColorDefault;
            min-height: 50px;
            transition: 0.2s all;

            &:first-child {
              border-radius: 15px 0 0 15px;
            }

            &:last-child {
              border-radius: 0 15px 15px 0;
            }
          }
        }
      }
    }
  }
}
