.aayn-need-help {
  min-width: 131px;
  min-height: 56px;

  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-14);

  line-height: 32px;
  text-align: center;
  color: var(--gray);
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  padding-right: 19px;
  padding-left: 15px;

  &:hover {
    background: var(--white);
    box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.04);
    color: var(--gray-500);
  }

  @include fw('medium');

  ayn-icon {
    margin-left: 6px;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}
