aayn-select-review-and-publish {
  display: block;
  width: 100%;

  .aayn-review-and-publish {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;

    &--header {
      border-radius: 20px;
      border: solid 1px var(--whites);
      background: rgba(var(--white-rgb), 0.3);
      filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      text-align: center;
      padding: 30px;

      &__title {
        font-size: var(--fs-40);
        font-weight: 600;

        line-height: 1;
        color: var(--accent);
      }

      &__description {
        margin-top: 10px;
        font-size: var(--fs-20);
        line-height: 1;
        color: var(--gray-500);
      }
    }

    &--row {
      display: flex;
      flex: 1;
      gap: 20px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      aayn-review-and-publish-input-item,
      ayn-datepicker {
        display: block;
        width: 100%;
      }
    }

    &--main {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      overflow: auto; //TODO: Ahmet mobile için yeniden düzenlenince silinecek...

      &-col {
        flex: 1;
        min-height: 346px;
        border-radius: 20px;
        border: solid 1px var(--white);
        background-color: rgba(var(--white-rgb), 0.3);
        padding: 30px;
        max-width: 100%;
        filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));

        &__header {
          display: flex;

          &-icon {
            margin-right: 15px;
            color: var(--primary);
          }
        }

        &__title {
          font-size: var(--fs-20);
          font-weight: 600;
          line-height: 1.6;
          color: var(--gray-500);

          &-reccommended {
            font-size: var(--fs-14);
            line-height: 1.43;
            color: var(--primary);
          }
        }

        &__description {
          font-size: var(--fs-14);
          line-height: 1.43;
          color: var(--gray-500);
          margin-bottom: 19px;
        }

        &__sub-title {
          margin-top: 13px;
          margin-bottom: 24px;
          font-size: var(--fs-16);
          font-weight: 600;
          line-height: 2;
          color: var(--primary);
        }

        &__budget {
          display: flex;
          width: 100%;
          justify-content: space-between;

          ayn-radio {
            width: 100%;
            margin-right: 20px;

            &:not(:first-child) {
              margin-right: 0px;
            }
          }
        }
      }

      &__input {
        display: block;
        width: 100%;

        &-currency {
          margin-left: 3px;
          font-weight: 500;
          font-size: var(--fs-12);
          line-height: 24px;
          color: var(--dark-blue--100);
        }

        input {
          text-align: right;
          padding-right: 26px;
        }
      }

      .aayn-smart-currency {
        &:has(.ayn-select.show) {
          border-radius: var(--input-border-radius) var(--input-border-radius) 0 var(--input-border-radius);
          background-color: var(--white);
        }
      }
    }

    &--footer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      margin-bottom: 60px;
      margin-top: 30px;
      gap: 30px;
      position: relative;

      &__section-select {
        position: absolute;
        left: 0;
        top: 0;
        width: 250px;
      }
    }
  }

  .aayn-smart-campaign--budget-switch {
    .ayn-checkbox {
      width: 67px;
      --height: 32px;

      .ayn-checkbox-box {
        border-color: var(--gray-400);
        background: var(--gray-400);

        &::before {
          height: calc(var(--height) - 8px);
          width: calc(var(--height) - 8px);
          left: 3px;
          bottom: 3px;
          background-color: var(--white);
        }

        &.ayn-highlight {
          background: var(--primary);

          &::before {
            transform: translateX(37px);
            background-color: var(--primary);
          }

          &::before {
            left: 0;
            background: url(/assets/images/icons/brain.svg) no-repeat center center;
            background-size: cover;
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}

aayn-review-and-publish-input-item {
  display: block;
  width: 100%;

  .aayn-review-and-publish-input-item {
    width: 100%;
    height: 56px;
    margin: 0 0 20px 0;
    padding: 18px 18.2px 17px 21.5px;
    border-radius: 20px;
    border: solid 1px var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(var(--white-rgb), 0.6);
    filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));

    &--text {
      font-size: var(--fs-16);
      font-weight: 500;
      line-height: 1.5;
      text-align: left;
      color: var(--gray-500);

      &-sub-text {
        font-size: var(--fs-12);
        color: var(--gray-500);
      }

      &-active {
        font-size: var(--fs-18);
        font-weight: 600;
        line-height: 1.67;
        color: var(--primary);
        @include darkModeColorDefault;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 20px;
        text-align: end;
        max-width: calc(100% - 100px);
      }
    }

    &--platform {
      display: flex;
      align-items: center;
      color: var(--primary);
      @include darkModeColorDefault;
      font-size: var(--fs-18);
      font-weight: 600;
      line-height: 1.67;

      ayn-icon {
        margin-left: 7px;
        max-width: 24px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  aayn-select-review-and-publish {
    .aayn-review-and-publish {
      margin-left: 10px;
      margin-right: 10px;

      &--row {
        flex-wrap: wrap;
      }

      &--main-col {
        padding: 20px;
      }
    }
  }
}
