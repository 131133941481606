@mixin scrollbar(
  $pr: 0px,
  $size: 5px,
  $scrollbar-track: transparent,
  $scrollbar-thumb: var(--gray-200),
  $scrollbar-thumb-hover: var(--gray-400)
) {
  padding-right: $pr;

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-track;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-thumb-hover;
  }
}
