.aayn-audience--detailed-targeting {
  &__active &--input [ayn-input] {
    border-radius: 20px 20px 0 0;
    border-bottom: none;

    &:hover,
    &:focus {
      border-color: rgba(var(--gray-rgb), 0.3);
    }
  }

  &--added-items {
    --color: var(--primary);

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 14px;
    }

    &-description {
      font-size: var(--fs-16);
      text-align: left;
      color: var(--color);

      @include darkModeColorDefault;

      strong {
        font-weight: bold;
      }
    }

    &__inclusions {
      --color: var(--primary);
    }

    &__exclusions {
      --color: var(--orange);
    }

    &__narrows {
      --color: var(--accent);
    }

    &-body {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 25px;

      .ayn-badge {
        font-weight: 500;

        span {
          font-weight: 600;
          font-size: var(--fs-16);
          margin-right: 30px;
        }
      }
    }
  }

  &--overlay {
    position: relative;
    display: block;
    width: 100%;

    &-inner {
      background: var(--white);
      top: 0 !important;
      left: 0 !important;
      width: 100%;
      margin-top: 0 !important;
      border: 1px solid var(--gray-200);
      border-top: none;
      border-radius: 0 0 20px 20px;
      overflow: hidden;

      .ayn-tab {
        border: none;

        &--default {
          border-radius: 0;
          background: var(--white);
          padding-bottom: 0;

          li {
            margin-top: 0;
            margin-bottom: 0;
            height: 56px;
            border-radius: 20px 20px 0 0;
            border: 1px solid var(--body-bg);
            border-bottom: none;
            min-width: 158px;
            padding: 0 34px;
            font-weight: 600;
            font-size: var(--fs-16);

            &.active {
              background: var(--primary);
              color: var(--white);
            }
          }
        }
      }

      .aayn-audience--detailed-targeting--options {
        max-height: 335px;
        padding: 16px 17px;
        overflow: auto;

        @include scrollbar(10px);

        aayn-detailed-targeting-overlay-item:not(:last-child) {
          display: block;
          margin-bottom: 5px;
        }

        li {
          height: 56px;
          border-radius: 20px;
          background: rgba(var(--white-rgb), 0.7);
          border: 1px solid var(--white);
          box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.04);
          display: flex;
          align-items: center;
          padding: 0 30px;

          &:not(.active) {
            cursor: pointer;
          }

          &.inactive {
            opacity: 0.6;

            button {
              color: var(--gray-400) !important;
            }
          }

          .aayn-audience--detailed-targeting--active-text {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-weight: 600;
            font-size: var(--fs-14);
            text-align: right;
            color: var(--white);
            margin-right: 23px;
            width: 200px;

            ayn-icon {
              margin-left: 7px;
            }
          }

          .aayn-audience--detailed-targeting-text {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: max-content;
            flex: 1;
            line-height: 20px;
            color: var(--gray-500);
          }

          .aayn-audience--detailed-targeting--info {
            display: inline-flex;
            align-items: center;
            margin-left: auto;
            margin-right: 21px;
            justify-content: center;
          }

          .aayn-audience--detailed-targeting--type {
            display: inline-block;
            font-size: var(--fs-16);
            line-height: 28px;
            text-align: right;
            color: var(--gray-500);
          }

          .aayn-audience--detailed-targeting--value {
            display: inline-block;
            font-weight: 600;
            font-size: var(--fs-16);
            line-height: 28px;
            text-align: right;
            color: var(--gray-500);
            min-width: 100px;
            margin-left: 18px;
            margin-right: 90px;
          }

          .aayn-audience--detailed-targeting--li-actions {
            display: flex;
            align-items: center;
            min-width: 235px;

            [ayn-button] {
              background: transparent;
              padding: 4px;
              color: var(--primary);
              height: auto;
              width: 100%;
              box-shadow: none;

              &:not(:last-child) {
                margin-right: 50px;
              }

              &:hover {
                color: var(--gray-500);
              }

              &[disabled] {
                color: var(--gray-50);
              }
            }

            .\:inclusion-btn {
              color: var(--primary);
            }

            .\:exclusion-btn {
              color: var(--orange);
            }
          }

          &.active__includes {
            .\:inclusion-btn {
              color: var(--primary) !important;
            }
          }

          &.active__excludes {
            .\:exclusion-btn {
              color: var(--orange) !important;
            }
          }
        }
      }
    }
  }
}
