$grid-gutter-width: 20px;
$spacer: 1rem;

$colors: ();
$grays: ();
$theme-colors: ();

$grid-breakpoints: (
  xs: 0,
  sm: 1024px,
  md: 1200px,
  lg: 1280px,
  xl: 1366px,
  xxl: 1536px,
  'desktop-xl': 1750
);

$container-max-widths: () !default;

@import '../../../../../node_modules/bootstrap/scss/bootstrap-grid.scss';

.row {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
