.aayn-select-objective--has-disabled-info {
  .ayn-option__disabled {
    .ayn-option--inner {
      width: 100%;
      margin-right: 0;
      ayn-icon {
        margin-right: 0;
      }
    }
  }
}
