.aayn-mobile-nav {
  position: fixed;
  bottom: 0px;
  right: 10px;
  left: 10px;
  height: 70px;
  background: var(--white);
  border: 1px solid var(--white);
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24.5px 24.5px 0px 0px;

  z-index: 1;

  ul {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;

    li {
      &.create-ad {
        a {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          background: var(--primary);
          box-shadow: 0px 10px 40px rgba(var(--gray-rgb), 0.4);
          margin-top: -55px;
        }
      }

      &.active {
        a {
          background: var(--accent);
          color: var(--white);
        }
      }

      &.disabled {
        a {
          background: var(--gray-50);
          color: var(--white);
        }
      }

      a {
        width: 44px;
        height: 44px;
        background: rgba(var(--white-rgb), 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: var(--gray-500);

        ayn-icon {
          svg-icon {
            display: block;
            font-size: 0;

            svg {
              align-self: unset;
            }
          }
        }
      }
    }
  }
}

//mobile
@media only screen and (max-width: 600px) {
  .aayn-mobile-nav {
    ul {
      gap: 5px;

      li {
        &.create-ad {
          a {
            width: 50px;
            height: 50px;
          }
        }

        a {
          width: 40px;
          height: 40px;
          background: rgba(var(--white-rgb), 0.7);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: var(--gray-500);

          ayn-icon {
            svg-icon {
              display: block;
              font-size: 0;

              svg {
                align-self: unset;
              }
            }
          }
        }
      }
    }
  }
}
