.aayn-dashboard-drafts-tab {
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  margin-top: 21px;
  margin-right: 12px;

  &--form {
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 14px;

    &__left {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;

      &__slider {
        margin-right: 10px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        width: 109px;
        height: 56px;
        border-radius: 20px;
        background: var(--white);
        box-shadow: 0px 20px 80px rgba(var(--gray-rgb), 0.06);
        color: var(--gray-500);

        &-left {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 46px;
          height: 46px;
          border-radius: 16px 10px 10px 17px;
          background: transparent;
          border: 1px solid var(--gray-100);
          box-shadow: 0px 20px 80px rgba(var(--gray-rgb), 0.06);
          margin-left: 6px;

          &.active {
            background: var(--accent);
            color: var(--white);

            [data-theme='dark'] & {
              color: var(--gray-500);
            }
          }
        }

        &-right {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 46px;
          height: 46px;
          border-radius: 10px 16px 17px 10px;
          background: transparent;
          border: 1px solid var(--gray-100);
          box-shadow: 0px 20px 80px rgba(var(--gray-rgb), 0.06);
          margin-right: 6px;

          &.active {
            background: var(--accent);
            color: var(--white);

            [data-theme='dark'] & {
              color: var(--gray-500);
            }
          }
        }
      }

      span {
        margin-right: 10px;
        font-weight: 500;
        font-size: var(--fs-16);

        line-height: 32px;
        text-align: left;
        color: var(--gray-400);
      }

      &__select {
        min-width: 178px;
        border-radius: 20px;
        background: var(--gray-25);
        border: 1px solid var(--gray-25);

        svg {
          --icon-color: var(--gray-500);
        }
      }
    }

    &__right {
      display: inline-flex;
      align-items: center;

      a {
        cursor: pointer;
        margin-right: 10px;
        color: var(--gray-500);
      }

      ayn-form-field {
        width: 274px;

        ayn-icon {
          svg,
          path,
          g {
            fill: var(--primary);
          }
        }
      }

      &__slider {
        margin-left: 10px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        width: 88px;
        height: 56px;
        border-radius: 20px;
        background: var(--white);
        box-shadow: 0px 20px 80px rgba(var(--gray-rgb), 0.06);

        &-left {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          width: 36px;
          height: 46px;
          border-radius: 16px 10px 10px 17px;
          background: transparent;
          border: 1px solid var(--gray-300);
          box-shadow: 0px 20px 80px rgba(var(--gray-rgb), 0.06);
          margin-left: 6px;
          color: var(--gray-500);
        }

        &-right {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          width: 36px;
          height: 46px;
          border-radius: 10px 16px 17px 10px;
          background: transparent;
          border: 1px solid var(--gray-300);
          box-shadow: 0px 20px 80px rgba(var(--gray-rgb), 0.06);
          color: var(--gray-500);
        }
      }
    }
  }

  &--list {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 15px;

    @media (max-width: 2200px) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @media (max-width: 1960px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media (max-width: 1645px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: 1645px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: 1160px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: 880px) {
      grid-template-columns: 1fr;
    }

    &__item {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      border-radius: 15px;
      background: var(--white);
      border: 1px solid var(--gray-200);
      position: relative;

      &__image {
        width: calc(100% - 12px);
        margin: 6px;
        position: relative;

        img {
          width: 100%;
          height: 246px;
          border-radius: 15px;
          object-fit: cover;
        }

        &-btn {
          position: absolute;
          right: 10px;
          top: 10px;
          display: flex;
          align-items: center;
          width: 30px;
          height: 40px;
          background: var(--gray-75);
          color: var(--gray-500);
          border-radius: 10px;
          padding: 0;
          justify-content: center;

          &.active {
            background: var(--primary);
            color: var(--white);
          }
        }
      }

      &__info {
        margin: 0 20px;
        height: 100%;
        position: relative;
        width: calc(100% - 40px);

        span {
          font-weight: normal;
          font-size: var(--fs-12);
          line-height: 20px;
          text-align: left;
          color: var(--gray-500);
        }

        h4 {
          font-weight: 600;
          font-size: var(--fs-18);
          line-height: 32px;
          text-align: left;
          color: var(--gray-500);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        p {
          font-weight: 500;
          font-size: var(--fs-14);
          line-height: 20px;
          text-align: left;
          color: var(--success);
        }

        &__budget {
          margin-bottom: 10px;
          margin-top: 7px;
          width: 104px;
          height: 25px;
          border-radius: 12.5px;
          background: var(--accent);
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-weight: normal;
            font-size: var(--fs-12);

            line-height: 32px;
            text-align: center;
            opacity: 1;
            color: var(--white);

            @include darkModeColorDefault;

            strong {
              font-weight: 500;
              font-size: var(--fs-16);
              line-height: 50px;
              text-align: center;
              opacity: 1;
            }
          }
        }

        .btn {
          width: 40px;
          height: 40px;
          border-radius: 10px;
          background: var(--accent);
          position: absolute;
          right: 0;
          bottom: 10px;
          padding: 0 !important;
        }

        &__platform {
          display: inline-flex;
          align-items: center;
          margin-top: 8px;
          color: var(--gray-500);

          ayn-icon {
            margin-right: 5px;
            height: 25px;

            svg {
              height: 25px;
              width: 25px;
            }
          }

          ayn-lottie {
            margin-left: -9px;
            height: 43px;
            margin-top: -9px;
            margin-bottom: -9px;
          }

          span {
            color: var(--gray-500);
          }

          & &__smart-text {
            @include text-gradient(var(--primary), var(--accent));

            [data-theme='dark'] & {
              @include text-gradient(var(--gray-500), var(--gray-500));
            }

            font-weight: 600;
            line-height: 1.3;
          }
        }
      }

      ayn-progressbar {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: calc(100% - 40px);
      }

      .ayn-progressbar {
        border-radius: 6px 6px 0 0;
        height: 6px;
        width: 100%;
      }

      &--smart-campaign {
        .aayn-dashboard-drafts-tab--list__item__info__budget {
          margin-top: -2px;
        }
      }
    }

    &__table {
      &__img {
        text-align: center;
        width: 120px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 10px;

          vertical-align: middle;
        }
      }

      &__edit {
        width: 68px;
        text-align: center;

        button {
          width: 40px;
          height: 40px;
          min-height: 40px;
          padding: 0 !important;
          border-radius: 10px;
          vertical-align: middle;
        }
      }

      &__230 {
        width: clamp(100px, 230px, 230px);
        text-align: center;
      }

      &__more {
        width: 60px;
        text-align: center;

        > button {
          vertical-align: middle;
          color: var(--gray-500) !important;
        }
      }
    }
  }
}

.aayn-dashboard-drafts-tab--item--popup {
  margin-left: -60px;

  &.__list {
    margin-left: -50px;
  }

  button.btn.has-icon {
    padding-right: 13px;

    ayn-icon {
      margin-left: auto;
    }
  }
}
