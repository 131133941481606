ayn-slider {
  --color: rgba(100, 113, 147, 0.4);
  --pointer-color: var(--white);
  --pointer-border-color: var(--primary);

  [data-theme='dark'] & {
    --color: rgba(255, 255, 255, 0.4);
    --pointer-color: var(--white);
    --pointer-border-color: var(--gray-500);
  }

  .custom-slider {
    &.default {
      .ngx-slider {
        .ngx-slider-selection {
          background: var(--color) !important;
        }

        .ngx-slider-pointer {
          background-color: var(--pointer-color);
          border: 2px solid var(--pointer-border-color);
          width: 0.875rem;
          height: 0.875rem;
          top: -0.3438rem;

          &.ngx-slider-active {
            &:after {
              display: none;
            }
          }
        }
      }
    }

    &.range {
      .ngx-slider {
        margin: 0;

        .ngx-slider-bar {
          height: 2px;
          background-color: #d7e1ea;
        }

        .ngx-slider-selection {
          background: var(--pointer-border-color) !important;
          height: 2px;
        }

        .ngx-slider-pointer {
          background-color: var(--pointer-color);
          border: 2px solid var(--pointer-border-color);
          width: 1.5rem;
          height: 1.5rem;
          position: absolute;
          top: 50%;
          transform: rotate(0) translateY(-50%) !important;

          &::after {
            content: '';
            display: none;
          }

          &.ngx-slider-active {
            &:after {
              content: '';
              display: none;
            }
          }
        }
      }
    }
  }
}

.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value,
.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-high,
.ngx-slider-span.ngx-slider-bubble.ngx-slider-combined {
  height: 28px;
  border-radius: 14px;
  background: var(--gray-500);
  box-shadow: 0px 5px 14px rgba(var(--gray-rgb), 0.04);
  padding: 5px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-size: var(--fs-14);
  line-height: 32px;
  text-align: center;
  color: var(--white);

  &::before {
    content: '';
    background: url(/assets/images/icons/tooltip-arrow.svg) no-repeat center center;
    background-size: contain;
    rotate: 180deg;
    left: calc(50% - 10px);
    bottom: -6px;
    position: absolute;
    width: 20px;
    height: 6px;
  }
}

.ngx-slider .ngx-slider-bubble {
  top: -57px;
}

.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit.ngx-slider-floor,
.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit.ngx-slider-ceil {
  display: none;
}
