.aayn-select-ad-item {
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  cursor: pointer;
  position: relative;
  align-items: flex-start;
  height: 100%;
  justify-content: flex-start;
  min-width: 250px;
  background: rgba(var(--white-rgb), 0.6);
  border: 1px solid var(--white);
  box-shadow: 0 10px 30px rgba(var(--primary-rgb), 0.04);

  ayn-icon {
    color: var(--gray-500);
  }

  padding: 30px;
  transition: 0.15s;

  &:not(&__disabled, &__active, &__empty):hover {
    background: var(--white);
  }

  &__disabled {
    opacity: 0.7;
    cursor: auto;
  }

  &--title {
    font-weight: 600;
    font-size: var(--fs-18);
    margin-top: 6px;

    line-height: 32px;
    text-align: left;
    color: var(--gray-500);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
  }

  &--description {
    font-weight: normal;
    font-size: var(--fs-14);
    line-height: 20px;
    text-align: left;
    color: var(--gray-400);
    max-width: calc(100% - 20px);
  }

  &--coming-soon-text {
    position: absolute;
    top: -6px;
    right: 16px;
    background: var(--primary);
    color: var(--white);
    padding: 6px 8px;
    border-radius: 12px;
    font-size: var(--fs-14);
  }

  &__active {
    background: var(--accent);
    cursor: auto;

    h4 {
      color: var(--white);

      @include darkModeColorDefault;
    }

    p {
      color: var(--white);

      @include darkModeColorDefault;
    }

    ayn-icon {
      color: var(--white);

      @include darkModeColorDefault;
    }
  }

  &__empty {
    border: 0;
    background: rgba(var(--white-rgb), 0.25);
    cursor: default;
    padding: 0;
  }
}
