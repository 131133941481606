.ayn-badge {
  --color: var(--primary);
  height: 46px;
  border-radius: 23px;
  padding: 0 18px 0 20px;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  align-items: center;
  color: var(--white);
  @include darkModeColorDefault;

  &.rounded {
    border-radius: 3.125rem;
  }

  &__has-slider {
    padding-right: 4px;
  }

  > span {
    font-weight: 500;
    font-size: var(--fs-16);
    line-height: 2rem;
    text-align: left;
    color: var(--white);
    @include darkModeColorDefault;
  }

  ayn-icon {
    width: 0.945rem;
    vertical-align: middle;
    margin-left: 12px;
    color: var(--white);
    @include darkModeColorDefault;
  }

  .slider {
    width: 8.8rem;
    height: 2rem;
    margin-left: 0.7125rem;
    border-radius: 0.625rem;
    background: var(--white);
    display: flex;
    align-items: center;

    ayn-slider {
      display: inline-block;

      .custom-slider {
        min-width: 5rem;
        bottom: 0.625rem;
        left: 0.625rem;
        position: relative;

        .ngx-slider {
          &-pointer {
            &:after {
              display: none;
            }
          }

          &-active {
            border: none;

            &:after {
              content: ' ';
              background-color: var(--color) !important;
            }
          }
        }
      }
    }

    .slider-text {
      margin: 0 0.625rem 0 1.25rem;
      font-weight: 600;
      font-size: var(--fs-12);

      line-height: 2rem;
      color: var(--gray-500);
      white-space: nowrap;
      min-width: 70px;
    }
  }
}

@media only screen and (max-width: 600px) {
  ayn-badge {
    max-width: 100%;
  }

  .ayn-badge {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
  }
}
