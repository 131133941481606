.aayn-upgrade-header {
  width: 100%;
  border-radius: 25px;
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 1px solid var(--white);
  box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.08);
  padding: 20px 20px 20px 40px;
  display: flex;
  align-items: center;
  margin-bottom: 22px;

  &-left {
    display: flex;
    align-items: center;
    gap: 20px;

    &--icon {
      width: 60px;
      height: 60px;
      background: rgba(101, 55, 252, 0.1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary);

      svg {
        width: 33px;
        height: 33px;
      }
    }

    &--texts {
      h3 {
        font-weight: 600;
        font-size: var(--fs-28);
        color: var(--primary);
        margin-bottom: 3px;
      }

      p {
        font-size: var(--fs-16);
        color: var(--gray-500);
      }
    }
  }

  &--checkbox-container {
    margin-left: auto;
    min-width: 372px;
    height: 90px;
    border-radius: 20px;
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    border: 1px solid var(--white);
    box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.08);
    padding: 17px 20px 17px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      h5 {
        font-weight: 600;
        font-size: var(--fs-20);
        color: var(--primary);
      }

      p {
        font-size: var(--fs-16);
        color: var(--gray-500);
      }
    }

    ayn-lottie {
      margin: 0 8px;
      max-width: 48px;
    }

    .aayn-upgrade-checkbox {
      --color: var(--gray-500);

      width: 144px;
      height: 56px;
      border-radius: 28px;
      background: var(--color);
      box-shadow: 0px 10px 40px rgba(var(--gray-rgb), 0.4);
      position: relative;
      cursor: pointer;
      transition: 0.2s;
      overflow: hidden;

      ayn-icon {
        width: 40px;
        height: 40px;
        background: var(--white);
        color: var(--color);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 8px;
        top: 8px;
        transition: 0.2s;
      }

      &--inner {
        font-weight: 300;
        font-size: var(--fs-14);
        color: var(--white);
        position: relative;
        left: 58px;
        top: 11px;
        transition: 0.2s;
        user-select: none;

        b {
          font-weight: 600;
          font-size: var(--fs-18);
          text-align: left;
          color: var(--white);
          display: block;
          width: 100%;
        }
      }

      &__active {
        --color: var(--primary);

        color: var(--color);
        box-shadow: 0px 10px 40px rgba(var(--gray-rgb), 0.4);

        ayn-icon {
          left: calc(100% - 40px - 8px);
        }

        .aayn-upgrade-checkbox--inner {
          left: 20px;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 10px;
    position: sticky;
    top: 10px;
    z-index: 1;
    transition: background-color 0.3s ease;
    $parent: &;
    &.scrolled {
      background: rgba(var(--white-rgb), 1);

      #{$parent}-left {
        display: none;
      }

      #{$parent}--checkbox-container {
        background: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
      }
    }
    &-left {
      width: 100%;
    }
    &--checkbox-container {
      margin-left: 0;
    }
  }
}

.aayn-upgrade-packages {
  border-radius: 25px;
  background: rgba(var(--white-rgb), 0.4);
  border: 1px solid var(--white);
  box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.08);
  padding: 40px 40px 60px 40px;

  &-pricing_table {
    display: grid;
    grid-template-areas: 'coupon package1 package2 package3' 'row row row row';
    grid-template-columns: 26% repeat(3, 1fr);
    grid-gap: 10px;
    margin-bottom: 20px;

    &_coupon-item {
      height: 190px;
      border-radius: 25px;
      background: rgba(var(--white-rgb), 0.4);
      @include darkModeBgDefault;
      border: 1px solid var(--white);
      box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.04);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 29px 10px 43px;
      grid-area: coupon;

      &-title {
        font-weight: bold;
        font-size: var(--fs-40);
        color: var(--gray-500);
        text-align: center;

        span {
          font-weight: bold;
          font-size: var(--fs-20);
          color: var(--gray-500);
        }
      }

      &-description {
        font-size: var(--fs-16);
        line-height: 20px;
        color: var(--gray-500);

        b {
          font-weight: 500;
        }
      }

      ayn-lottie {
        max-width: 48px;
        max-height: 48px;
        margin: 0;
      }
    }

    &_package-item {
      --bg-color: var(--white);
      --color: var(--gray-500);
      --gray-color: var(--gray-500);

      height: 190px;
      border-radius: 25px;
      background: var(--bg-color);
      box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.04);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__best-seller {
        --bg-color: var(--primary);
        --color: var(--white);
        --gray-color: var(--white);
        position: relative;

        &::before {
          content: attr(best-seller-text);
          width: 130px;
          height: 26px;
          border-radius: 10px 10px 0px 0px;
          background: var(--gray-500);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: var(--fs-14);
          color: var(--white);
          position: absolute;
          top: -26px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &-title {
        font-weight: 600;
        font-size: var(--fs-20);
        line-height: 24px;
        color: var(--color);
        margin-bottom: 8px;
      }

      &-old-price {
        font-size: var(--fs-20);
        line-height: 32px;
        color: var(--gray-color);
        position: relative;
        margin-bottom: 3px;

        &::before {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% + 10px);
          height: 1px;
          background: var(--gray-color);
          rotate: 335deg;
          bottom: 15px;
          left: -5px;
        }
      }

      &-price {
        font-weight: bold;
        font-size: 50px;
        color: var(--color);
        position: relative;
        margin-left: 7px;
        margin-bottom: 6px;

        sub {
          vertical-align: sub;
          font-size: var(--fs-20);
          position: relative;
          bottom: 10px;
        }

        sup {
          vertical-align: super;
          font-size: var(--fs-20);
          position: relative;
          top: -5px;
        }
      }

      &-info {
        font-size: var(--fs-16);
        line-height: 20px;
        margin-bottom: 8px;
      }

      &-per-month {
        font-size: var(--fs-16);
        color: var(--gray-color);
      }

      &--features {
        display: none;
      }

      @include media-breakpoint-down(xl) {
        background: rgba(var(--white-rgb), 0.4);
        @include darkModeBgDefault;
        height: max-content;
        padding: 10px 10px 15px 10px;
        &--features {
          width: 100%;
          padding: 0 20px;
          display: block;
          font-weight: 600;
          font-size: var(--fs-16);
          line-height: 32px;
          color: var(--gray-500);

          &-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            grid-gap: 10px;
            padding: 10px 15px;

            &:nth-child(even) {
              border-radius: 15px;
              background: rgba(var(--white-rgb));
            }

            &:last-child {
              margin-top: 10px;
            }

            a {
              padding-bottom: 2px;
              cursor: pointer;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: start;

              ayn-icon {
                max-width: 20px;

                svg {
                  max-width: 15px;
                  max-height: 15px;
                }
              }
            }

            span {
              text-align: center;
            }
          }
        }
      }
    }

    &-row_container {
      width: 100%;
      grid-area: row;
      border-radius: 20px;
      background: rgba(var(--white-rgb), 0.4);
      @include darkModeBgDefault;
      border: 1px solid var(--white);
      box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.04);
      padding: 13px;
      font-weight: 600;
      font-size: var(--fs-16);
      line-height: 32px;
      color: var(--gray-500);

      &-row {
        display: grid;
        grid-template-columns: 26% repeat(3, 1fr);
        grid-gap: 10px;
        min-height: 47px;

        &:nth-child(even) {
          border-radius: 15px;
          background: rgba(var(--white-rgb));
        }

        &:last-child {
          margin-top: 10px;
        }
      }
    }

    &-column {
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        justify-content: start;
        padding: 0 0 0 20px;

        a {
          padding-bottom: 2px;
          cursor: pointer;

          ayn-icon {
            &:first-child {
              margin-left: 10px;
            }

            max-width: 20px;

            svg {
              max-width: 15px;
              max-height: 15px;
            }
          }
        }
      }

      ayn-icon {
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 29px;
        height: 29px;
      }

      ayn-icon[ayn-icon-name='check'] {
        background: rgba(101, 55, 252, 0.1);
        color: var(--primary);
      }

      ayn-icon[ayn-icon-name='cross'] {
        background: rgba(244, 105, 6, 0.1);
        color: var(--orange);
      }

      [ayn-button],
      [ng-reflect-type],
      .btn {
        min-width: 156px;
        justify-content: center;
        height: 49px;
      }
    }

    @include media-breakpoint-down(xl) {
      & {
        grid-template-areas:
          'coupon coupon'
          'package1 package2'
          'package3 package4'
          'row row';
        grid-template-columns: 1fr 1fr;
      }
      &-row_container {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      & {
        grid-template-areas:
          'coupon'
          'package1'
          'package2'
          'package3'
          'package4'
          'row';
        grid-template-columns: 1fr;
      }
      &-row_container {
        display: none;
      }
    }
  }

  ayn-icon {
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 29px;
    height: 29px;
  }

  ayn-icon[ayn-icon-name='check'] {
    background: rgba(101, 55, 252, 0.1);
    color: var(--primary);
  }

  ayn-icon[ayn-icon-name='cross'] {
    background: rgba(244, 105, 6, 0.1);
    color: var(--orange);
  }
}
