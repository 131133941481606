.aayn-campaigns-tab {
  .ayn-tabs {
    padding: 0 30px;

    li {
      margin-right: 20px;
      background-color: var(--white);
      padding: 20px 40px;
      border-radius: 20px 20px 0 0;
      cursor: pointer;
      color: var(--gray-500);

      &.active {
        background-color: var(--primary);
        color: var(--white);

        @include darkModeColorDefault;

        box-shadow: 0 10px 40px rgba(var(--primary-rgb), 0.4);
      }
    }
  }

  padding: 0 25px;

  &--form {
    width: 100%;
    padding: 30px;
    display: inline-flex;
    justify-content: space-between;

    &__left {
      button {
        margin-right: 10px;
      }

      &-select {
        display: inline-flex;
        width: 190px;
      }
    }

    &__right {
      display: inline-flex;
      align-items: center;

      a {
        cursor: pointer;
        margin-right: 10px;
        color: var(--gray-400);

        &:hover {
          color: var(--gray-400);
        }
      }

      ayn-form-field {
        width: 274px;

        ayn-icon {
          svg,
          path,
          g {
            fill: var(--primary);

            [data-theme='dark'] & {
              fill: var(--gray-500);
            }
          }
        }
      }

      &__slider {
        margin-left: 10px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        width: 88px;
        height: 56px;
        border-radius: 20px;
        background: transparent;
        border: 1px solid var(--gray-100);
        box-shadow: 0 20px 80px rgba(var(--accent-rgb), 0.06);

        &-left {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          width: 36px;
          height: 46px;
          border-radius: 16px 10px 10px 17px;
          background: transparent;
          border: 1px solid var(--gray-100);
          box-shadow: 0 20px 80px rgba(var(--accent-rgb), 0.06);
          margin-left: 6px;
        }

        &-right {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          width: 36px;
          height: 46px;
          border-radius: 10px 16px 17px 10px;
          background: transparent;
          border: 1px solid var(--gray-100);
          box-shadow: 0 20px 80px rgba(var(--accent-rgb), 0.06);
          margin-right: 6px;
        }
      }
    }
  }

  .all-campaigns {
    &--loader {
      ng-lottie > div {
        max-height: 100px;
      }
    }

    &--table {
      &-row-type {
        text-align: left;
        padding-left: 11px;
        width: 145px;

        span {
          height: 22px;
          border-radius: 0 11px 11px 0;
          background: var(--color);
          display: inline-flex;
          align-items: center;
          padding: 0 14px 0 10px;
          color: var(--white);

          ayn-icon {
            margin-right: 5px;

            svg {
              height: 10px;
            }
          }
        }
      }

      &-checkbox {
        width: 40px;
        max-width: 40px;
        vertical-align: middle;
      }

      &-no-record {
        text-align: center;
        padding-top: 50px;
        color: var(--gray-400);
      }

      &-status {
        width: 65px;
        max-width: 65px;
      }

      &-adgroup-name {
        max-width: 200px;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
        padding-top: 10px;
      }

      &-budget {
        width: 125px;
        text-align: center;

        span {
          display: inline-block;
          max-height: 45px;
          line-height: 1;
          text-align: center;
          font-weight: 500;
          font-size: var(--fs-16);

          position: relative;
          top: 5px;

          div {
            font-weight: normal;
            font-size: var(--fs-12);
          }
        }
      }

      &-search-ad {
        font-weight: 500;

        line-height: 18px;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        &__title {
          font-size: var(--fs-14);
          color: var(--gray-500);
          text-overflow: ellipsis;
          overflow: hidden;
          width: 185px;
          height: 1.2em;
          white-space: nowrap;
          display: inline-block;
        }

        &__link {
          font-size: var(--fs-12);
          color: var(--primary);
          text-overflow: ellipsis;
          overflow: hidden;
          width: 185px;
          height: 1.2em;
          white-space: nowrap;
          display: inline-block;
        }

        &__description {
          font-size: var(--fs-12);
          color: var(--gray-400);
          text-overflow: ellipsis;
          overflow: hidden;
          width: 185px;
          height: 1.2em;
          white-space: nowrap;
          display: inline-block;
        }
      }

      &-display-ad {
        display: flex;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        width: 100%;

        &__image {
          width: 60px;
          height: 40px;
          border-radius: 8px;
          margin-right: 5px;
          margin-top: 7px;
        }

        &__texts {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &__title {
          font-size: var(--fs-12);
          color: var(--primary);
        }

        &__subtitle {
          font-size: var(--fs-14);
          color: var(--gray-500);
        }

        &__description {
          font-size: var(--fs-12);
          color: var(--gray-400);
        }
      }

      &-video-ad {
        display: flex;
        font-weight: 500;

        line-height: 18px;
        text-align: left;

        &__image {
          width: 60px;
          height: 40px;
          border-radius: 8px;
          margin-right: 5px;
          margin-top: 7px;
        }

        &__title {
          font-size: var(--fs-12);
          color: var(--primary);
        }

        &__subtitle {
          font-size: var(--fs-14);
          color: var(--gray-500);
        }

        &__description {
          font-size: var(--fs-12);
          color: var(--gray-400);
        }
      }

      &-date {
        width: 130px;
        text-align: center;

        span {
          display: inline-block;
          max-height: 45px;
          line-height: 1;
          font-size: var(--fs-14);

          text-align: center;
          position: relative;
          top: 7px;
          font-weight: 500;

          div {
            margin-top: 3px;
          }
        }
      }

      &-value {
        width: 125px;
        text-align: center;
      }

      &-actions {
        width: 80px;

        ayn-icon {
          svg-icon {
            justify-content: center;
          }
        }

        a {
          color: var(--gray-400);

          &:hover {
            color: var(--primary);
          }
        }
      }

      &_ad-cell {
        position: relative;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 62px;

        img {
          width: 46px;
          height: 46px;
          border-radius: 8px;
          border: 1px solid var(--gray-200);
          position: absolute;
          top: 5px;
          left: 10px;
          margin-right: 6px;
        }
      }

      &-edit {
        vertical-align: middle;

        &__text {
          display: flex;
          align-items: center;

          ayn-icon {
            margin-right: 5px;
          }
        }

        &-btn {
          width: 40px;
          height: 40px;
          border-radius: 10px;
          background: var(--gray-75);
          color: var(--gray-500);
          display: flex;
          justify-content: center;
          align-items: center;

          &.active {
            background: var(--gray-500);
            color: var(--white);
          }
        }
      }
    }
  }

  .ayn-expandable-table--cell img,
  .aayn-all-campaigns--img {
    width: 46px;
    height: 46px;
    border-radius: 8px;
    border: 1px solid var(--gray-200);
    margin-right: 6px;
  }

  &--popup {
    &::before {
      content: '';
      height: 8px;
      width: calc(100% - 58px);
      background: var(--primary);
      border-radius: 0 0 8px 8px;
      position: absolute;
      top: 0;
      margin-left: 23px;
    }

    &.accent::before {
      background: var(--accent);
    }

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 11px 23px;

      &__title {
        font-weight: 600;
        font-size: var(--fs-18);

        line-height: 32px;
        text-align: left;
        color: var(--gray-500);
      }

      ayn-icon {
        width: 17.46px;
        height: 20.82px;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
      margin-left: 20px;
      margin-right: 20px;

      ayn-form-field {
        margin-bottom: 15px;
        display: block;
        --icon-color: var(--gray-500);

        .form-field {
          height: auto;
        }

        [ayn-input] {
          border: 1px solid var(--gray-200);
        }

        label {
          &::after {
            content: '';
            display: block;
            width: 307px;
            height: 1px;
            background: var(--gray-200);
            position: absolute;
            top: 0;
            left: -28px;
          }

          padding-top: 15px;
          margin-left: 5px;
          font-weight: 500;
          font-size: var(--fs-16);
          line-height: 32px;
          text-align: left;
          color: var(--gray-500);

          span {
            font-weight: normal;
            color: var(--gray-400);
          }
        }

        ayn-icon {
          &:hover {
            --icon-color: var(--primary);
          }
        }
      }
    }
  }

  &__smart-campaign {
    display: flex;
    align-items: center;
    justify-content: start;

    &_icon {
      svg {
        height: 34px !important;
      }
    }

    span.active {
      @include text-gradient(var(--primary), var(--accent));
    }
  }
}

.aayn-remove-overlay {
  transform: translateX(-68px) !important;

  .aayn-campaigns-tab--popup-body {
    margin: 0 10px 10px;
  }
}
