.aayn-platform-item {
  --border-color: var(--white);

  border-radius: 25px;
  border: 1px solid var(--border-color);
  box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));

  &--inner {
    @include flex-align-center;
    padding: 10px 10px 10px 30px;
    position: relative;

    &-coming-soon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 32px;
      top: 50%;
      bottom: 50%;

      ayn-icon {
        color: var(--gray-500);
        width: 10px;
      }

      &__text {
        margin-left: 5px;
        font-size: var(--fs-12);
        line-height: 20px;
        color: var(--gray-500);
      }
    }
  }

  &--platform {
    @include flex-align-center;
    font-weight: 600;
    font-size: var(--fs-18);
    line-height: 32px;
    color: var(--gray-500);

    ayn-icon {
      width: 24px;
      height: 26px;
      margin-right: 12px;
    }

    &:nth-child(2) {
      border-left: 1px solid var(--border-color);
      margin-left: 13.5px;
      padding-left: 11px;
      height: 56px;
    }
  }

  &--more {
    position: relative;
  }

  &--more-btn.btn {
    width: 34px;
    height: 40px;
    margin-left: 19px;
    border-radius: 15px;
    background: transparent;
    color: var(--gray-500);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn--icon {
      margin-left: 0;
    }

    &:hover {
      background: var(--white);
    }
  }

  &--overlay {
    .ayn-overlay {
      position: absolute;
      top: 50px !important;
      left: 50% !important;
      transform: translateX(calc(-50% + 9px)) !important;

      padding: 12px;
      width: 164px;
      min-height: 80px;
      border-radius: 25px;
      background: var(--white);
      border: 1px solid var(--white);
      box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);

      [ayn-button] {
        max-width: 100%;
        border-radius: 20px;
        background: rgba(234, 238, 255, 0.3);
        border: 1px solid var(--body-bg);
        box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
        padding: 17px;

        &:hover {
          background: rgba(var(--gray-rgb), 0.6);
        }
      }
    }
  }

  &--btn {
    margin-left: auto;
    width: max-content;
    font-size: var(--fs-16);
    padding: 0 0.9375rem 0 1.25rem;

    @include fw('semibold');
  }

  &--footer-text {
    --color: var(--accent);
    border-top: 1px solid var(--border-color);
    font-size: var(--fs-14);
    color: var(--color);
    padding: 15px 10px 15px 30px;
    @include flex-align-center;

    &::before {
      content: '';
      display: inline-block;
      min-width: 10px;
      min-height: 10px;
      border-radius: 50px;
      background: var(--color);
      margin-right: 9px;
    }
  }

  &__empty {
    border: 0;
    background: #f8f9fc;
  }

  &__active {
    .aayn-platform-item--footer-text {
      color: var(--gray-500);
    }
  }

  &__warning {
    .aayn-platform-item--footer-text {
      &::before {
        background-color: var(--orange);
      }
    }
  }

  &__disabled {
    opacity: 0.5;
    padding: 15px 0;
  }
}

@media only screen and (max-width: 600px) {
  .aayn-platform-item--inner {
    flex-wrap: wrap;
  }

  .aayn-platform-item--platform {
    margin-bottom: 10px;
  }

  .aayn-platform-item--btn {
    width: 100%;
    margin-left: initial;
  }
}
