.aayn-form {
  &-row {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &-col {
    flex: 1;
    display: block;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  textarea {
    height: auto;
  }
}
