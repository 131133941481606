$buttons: (
  'primary': (
    'bg': var(--primary),
    'color': var(--white),
    'hover': (
      'bg': var(--primary-100),
      'box-shadow': 0 0.9375rem 2.5rem rgba(var(--primary-rgb), 0.4)
    ),
    'sexy': (
      'gradient': linear-gradient(to top, rgba(var(--white-rgb), 0) 0%, rgba(var(--white-rgb), 0.1) 100%),
      'box-shadow': 0px 10px 40px rgba(var(--primary-rgb), 0.4),
      'border-color': var(--gray-500),
      'hover-border-color': var(--gray-500),
      'hover-bg-color': var(--primary-100),
      'hover-color': var(--white)
    )
  ),
  'orange-gradient': (
    'bg': var(--orange),
    'color': var(--white),
    'hover': (
      'bg': #ff1f02,
      'box-shadow': 0rem 0.3125rem 2.5rem rgba(255, 0, 0, 0.4)
    )
  ),
  'light-success': (
    'bg': hsl(138, 100%, 42%, 0.1),
    'color': var(--success),
    'hover': (
      'box-shadow': 0rem 0.3125rem 2.5rem rgba(0, 216, 66, 0.4)
    ),
    'opacity': 0.5
  ),
  'light-primary': (
    bg: hsl(243, 100%, 50%, 0.1),
    color: var(--primary),
    hover: (
      box-shadow: 0rem 0.3125rem 2.5rem rgba(83, 45, 208, 0.4)
    ),
    opacity: 0.5
  ),
  'light-accent': (
    bg: rgba(0, 104, 222, 0.1),
    color: var(--accent),
    hover: (
      box-shadow: 0rem 0.3125rem 2.5rem rgba(0, 103, 222, 0.4)
    ),
    opacity: 0.5
  ),
  'accent': (
    'bg': var(--accent),
    'color': var(--white),
    'hover': (
      'bg': #0067de,
      box-shadow: 0rem 0.3125rem 2.5rem rgba(0, 103, 222, 0.4)
    )
  ),
  'dark': (
    'bg': var(--gray-400),
    'color': var(--white),
    'hover': (
      'bg': var(--gray-400),
      box-shadow: 0rem 0.3125rem 2.5rem rgba(33, 41, 54, 0.4)
    )
  ),
  'light-dark': (
    'bg': var(--gray-400),
    'color': var(--white),
    'hover': (
      'bg': var(--gray-400)
    )
  ),
  'orange': (
    'bg': var(--orange),
    'color': var(--white),
    'hover': (
      'bg': #ff1f02,
      'bg-color': #ff1f02,
      'box-shadow': 0rem 0.3125rem 2.5rem rgba(255, 89, 2, 0.4)
    )
  ),
  'light-orange': (
    'bg': hsl(21, 100%, 50%, 0.1),
    'color': var(--orange),
    'hover': (
      'box-shadow': 0rem 0.3125rem 2.5rem hsl(21, 100%, 50%, 0.4)
    )
  ),
  'white': (
    'bg': var(--white),
    'color': var(--gray-500),
    'border': var(--gray-300),
    'hover': (
      'bg-color': var(--gray-75)
    ),
    'sexy': (
      'gradient': linear-gradient(to top, rgba(var(--gray-rgb), 0) 0%, rgba(var(--gray-rgb), 0.1) 100%),
      'hover-border-color': var(--white),
      'hover-bg-color': var(--gray-500),
      'hover-color': var(--white)
    )
  ),
  'white-shadow': (
    'bg': var(--white),
    'color': var(--gray-500),
    'box-shadow': 0px 5px 30px rgba(var(--gray-rgb), 0.08)
  ),
  'white-primary': (
    'bg': var(--white),
    'color': var(--primary)
  ),
  'disabled': (
    'bg': var(--white),
    'color': var(--gray-50),
    'hover': (
      'box-shadow': 0px 10px 30px rgba(var(--primary-rgb), 0.04)
    )
  ),
  'gray': (
    'bg': #647193,
    'color': var(--white),
    'hover': (
      'box-shadow': 'none'
    )
  ),
  'light-gray': (
    'bg': var(--white),
    'color': var(--gray-500),
    'hover': (
      'box-shadow': 'none'
    )
  )
);

.btn {
  border: 0;
  border-radius: 28px;
  height: 3.5rem;
  color: var(--white);

  @include darkModeColorDefault;

  background: var(--primary);
  padding: 0 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-16);
  text-decoration: none;
  white-space: nowrap;

  &.btn--only-icon {
    padding: 0;
    height: auto;
    background: transparent;
    color: var(--gray-200);
    justify-content: center;
    transition: 0.2s;

    &[disabled] {
      color: var(--gray-100);
      background: none;
    }

    &:not([disabled]):hover {
      color: var(--gray-400);
      cursor: pointer;
    }
  }

  &.btn__icon\:hidden {
    ayn-icon {
      display: none;
    }
  }

  &.btn__icon\:loading {
    padding-right: 8px;
    color: var(--gray-500);
  }

  &--loading-img {
    max-width: 40px;
  }

  &.has-icon {
    justify-content: space-between;
    padding: 0 1.5rem;

    svg,
    g,
    path {
      fill: currentColor;
      max-height: 1.25rem;
    }
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &[ayn-mini-button] {
    justify-content: center;
    height: 2.25rem;
    border-radius: 0.625rem;
  }

  &[ayn-button-block] {
    width: 100%;
  }

  &--icon {
    margin-left: 0.625rem;
  }

  b {
    font-weight: 600;
  }

  @each $key, $button in $buttons {
    &--#{$key}-outline {
      background: var(--white);

      $color: map-get(
        $map: $button,
        $key: 'bg'
      );

      border: 0.0625rem solid $color;

      color: $color;
      transition: 0.2s all;

      &:hover,
      &:focus {
        background: $color !important;
        border-color: $color !important;
        color: var(--white) !important;

        [data-theme='dark'] & {
          color: var(--gray-500) !important;
        }

        ayn-icon {
          color: var(--white);

          [data-theme='dark'] & {
            color: var(--gray-500) !important;
          }

          svg,
          path,
          g {
            fill: currentColor;
          }
        }
      }

      &:focus {
        $focus: map-get(
          $map: $button,
          $key: 'focus'
        );

        @if $focus != null {
          $box-shadow: map-get(
            $map: $focus,
            $key: 'box-shadow'
          );

          box-shadow: none;

          @if $box-shadow != null {
            box-shadow: $box-shadow;
          }
        }
      }
    }

    &--#{$key} {
      background: map-get($map: $button, $key: 'bg');
      color: map-get($map: $button, $key: 'color');

      @include darkModeColorDefault;

      transition: 0.2s all;

      $border: map-get($button, 'border');

      @if $border {
        border: 1px solid #{$border};
      }

      $button-box-shadow: map-get(
        $map: $button,
        $key: 'box-shadow'
      );

      @if $button-box-shadow {
        box-shadow: $button-box-shadow;
      }

      &:hover {
        $hover: map-get(
          $map: $button,
          $key: 'hover'
        );

        @if $hover != null {
          $bg: map-get(
            $map: $hover,
            $key: 'bg'
          );
          $bg-color: map-get(
            $map: $hover,
            $key: 'bg-color'
          );
          $box-shadow: map-get(
            $map: $hover,
            $key: 'box-shadow'
          );
          $color: map-get(
            $map: $hover,
            $key: 'color'
          );

          @if $bg != null {
            background: $bg;
          }

          @if $color != null {
            color: $color;
          }

          @if $bg-color != null {
            background-color: $bg-color;
          }

          @if $box-shadow != null {
            box-shadow: $box-shadow;
          }
        }
      }

      &:focus {
        $focus: map-get(
          $map: $button,
          $key: 'focus'
        );

        @if $focus != null {
          $box-shadow: map-get(
            $map: $focus,
            $key: 'box-shadow'
          );
          $border: map-get(
            $map: $focus,
            $key: 'border'
          );

          box-shadow: none;

          @if $box-shadow != null {
            box-shadow: $box-shadow;
          }

          @if $border {
            border: $border;
          }
        }
      }

      $sexy: map-get($button, 'sexy');

      @if $sexy {
        &-sexy {
          @include shimmer-button();

          --button-hover-border-color: #{map-get($sexy, 'hover-border-color')};
          --button-hover-bg-color: #{map-get($sexy, 'hover-bg-color')};
          --button-hover-color: #{map-get($sexy, 'hover-color')};

          $bg: map-get(
            $map: $button,
            $key: 'bg'
          );

          --button-bg-color: #{$bg};

          overflow: hidden;
          position: relative;
          justify-content: center;
          font-weight: 500;
          background-color: map-get($map: $button, $key: 'bg');

          $box-shadow: map-get($button, sexy, box-shadow);

          box-shadow: $box-shadow;

          &:hover {
            &::before {
              right: calc((100% - 66px) * -1);
            }
          }

          &::before {
            content: '';
            position: absolute;
            width: calc(100%);
            height: 100%;
            right: -53px;
            top: 0;
            transform: skew(-34deg);
            transition: 0.2s right;
            z-index: 2;

            $gradient: map-get($button, sexy, gradient);

            background: $gradient;
          }
        }
      }
    }
  }

  &[disabled],
  &.btn--disabled {
    background: map-get($buttons, 'disabled', 'bg');
    color: map-get($buttons, 'disabled', 'color');

    @include darkModeColorDefault;

    cursor: initial !important;
    pointer-events: none;
    border-color: map-get($buttons, 'disabled', 'bg');
  }

  &[disabled].btn--primary-sexy {
    --primary: var(--primary-rgb);
    background: #e9e5fe;
    border-color: #e9e5fe;
    --button-bg-color: #e9e5fe;
    color: rgba(var(--primary), 0.5);
    --border-color: rgba(var(--primary), 0.2);
    box-shadow: 0 10px 40px rgba(var(--primary-rgb), 0.4);
  }
}
