.ayn-tooltip {
  position: fixed;

  font-size: var(--fs-14);
  line-height: 20px;
  text-align: center;
  color: var(--white);
  border-radius: 15px;
  background: var(--gray-500);
  padding: 20px 36px;

  opacity: 0;

  &::before {
    content: '';
    width: 24px;
    height: 4px;
    position: absolute;
  }

  &--visible {
    opacity: 1;
    transition: opacity 300ms;
  }

  &--bottom {
    transform: translateX(-50%);
    margin-top: 8px;

    &::before {
      background: url(/assets/images/icons/tooltip-arrow.svg) no-repeat center center;
      background-size: contain;
      left: calc(50%);
      top: -5px;

      transform: translateX(-50%);
    }
  }

  &--top {
    transform: translate(-50%, -115%);
    margin-bottom: 7px;

    &::before {
      background: url(/assets/images/icons/tooltip-arrow.svg) no-repeat center center;
      background-size: contain;
      rotate: 180deg;
      left: calc(50% - 12px);
      bottom: -5px;
    }
  }

  &--left {
    transform: translate(calc(-100% - 7px), -50%);

    &::before {
      background: url(/assets/images/icons/tooltip-arrow.svg) no-repeat center center;
      background-size: contain;
      rotate: 90deg;
      right: -15px;
      top: calc(50% - 2px);
    }
  }

  &--right {
    transform: translateY(-50%);
    margin-left: 8px;

    &::before {
      background: url(/assets/images/icons/tooltip-arrow.svg) no-repeat center center;
      background-size: contain;
      rotate: -90deg;
      left: -15px;
      top: calc(50% - 2px);
    }
  }

  &--dynamic {
    margin-left: 20px;

    &::before {
      display: none;
    }
  }
}
