.aayn-dashboard {
  padding: 0 !important;

  .aayn-collapse-card {
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    border: 1px solid var(--white);
    filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));

    &--header__collapse {
      background: rgba(var(--white-rgb), 0.6);
      border: 1px solid var(--white);
    }

    &--header__title {
      display: flex;
      align-items: flex-end;

      &-bold {
        color: var(--gray-500);
        font-weight: 600;
        font-size: 25px;
        line-height: 32px;
      }

      p {
        color: var(--gray-500);

        font-weight: normal;
        font-size: var(--fs-16);
        line-height: 28px;
      }

      @include media-breakpoint-down(md) {
        align-items: flex-start;
        flex-direction: column;

        p {
          margin-left: 0;
          margin-top: 0;
        }

        &-bold {
          line-height: 24px;
        }
      }
    }
  }

  &-empty-content {
    display: flex;
    justify-content: center;
    height: 475px;

    &--bg-image {
      position: absolute;
      width: 90%;
      height: 475px;
      background-image: url('/assets/images/pngs/bg-dashboard-breakdown.webp');
      filter: blur(8px);
      -webkit-filter: blur(8px);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    aayn-empty-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--inner {
    padding: 30px 40px 0;

    @include media-breakpoint-down(md) {
      padding: 30px 10px 0;
    }
  }

  &-header {
    height: 90px;
    border-radius: 30px 30px 0px 0px;
    box-shadow: 0px 10px 50px rgba(var(--gray-rgb), 0.06);
    padding: 0 20px 0 18px;

    @include flex-align-center;

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      height: 120px;
      box-shadow: 0px 10px 90px rgba(var(--gray-rgb), 0.06);
    }

    &--platform {
      max-width: 298px;

      @include media-breakpoint-down(md) {
        max-width: 100%;
        margin-top: 10px;
      }

      border-radius: var(--select-border-radius);
      border-color: var(--white) !important;
      background-color: var(--white);
      box-shadow: 0px 5px 30px rgba(var(--gray-rgb), 0.08);

      &.show {
        border-radius: 20px 20px 0 0;
      }

      .ayn-select--btn {
        display: flex;

        .ayn-option--inner {
          img {
            margin-right: 8px;

            &:nth-child(2) {
              padding-left: 14px;
              margin-left: 14px;
              position: relative;

              &::before {
                content: '';
                height: 56px;
                width: 1px;
                background: var(--gray-200);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0px;
              }
            }
          }
        }
      }

      ayn-overlay {
        .ayn-overlay {
          box-shadow: 0 5px 30px rgba($color: #3b2a82, $alpha: 0.08);
          border-top-color: var(--gray-200);
          border-color: var(--white);
          top: 1px !important;
        }
      }

      .ayn-option {
        &.selected,
        &:hover {
          img[name='platforms:facebook'] {
            svg {
              path {
                &:first-child {
                  fill: var(--white);
                }

                &:last-child {
                  fill: transparent;
                }
              }
            }
          }

          img {
            svg {
              path,
              g,
              rect,
              circle {
                fill: var(--white);
              }
            }
          }
        }

        img {
          margin-right: 8px;

          &:nth-child(2) {
            margin-left: 14px;
          }

          &[name='check'] {
            display: none;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      ayn-datepicker-extend {
        margin-left: 0 !important;
        margin-top: 10px;

        .ayn-datepicker-extend--button {
          width: 100%;
        }
      }
    }

    &--tabs {
      display: flex;
      align-items: center;
      margin-left: 19px;

      li {
        height: 56px;
        border-radius: 20px;
        background: var(--gray-75);
        padding: 0 20px;
        min-width: 130px;
        font-size: var(--fs-16);

        line-height: 32px;
        text-align: center;
        color: var(--gray-400);
        transition: 0.2s all;

        &:not(.aayn-dashboard-header--tab-item__active) {
          cursor: pointer;
        }

        &:not(:first-child) {
          margin-left: 15px;
        }

        @include flex-center;
        @include fw('medium');

        &:hover,
        &.aayn-dashboard-header--tab-item__active {
          background: var(--white);
          color: var(--primary);
          box-shadow: 0px 10px 50px rgba(var(--gray-rgb), 0.06);
        }
      }
    }

    aayn-need-help {
      margin-left: auto;

      @include media-breakpoint-down(md) {
        display: none;
      }

      .aayn-need-help {
        position: relative;
        top: initial;
        left: initial;
        border-radius: 20px;
      }
    }
  }

  &-account-overview {
    display: flex;
    align-items: center;
    margin-top: 15px;

    &--chart {
      flex: 1;
      height: 390px;

      .apex-chart {
        margin: 0;
        max-width: 100%;
      }
    }

    &--list {
      min-width: 350px;
      height: 392px;
      padding: 0 10px;
      margin-left: 10px;
      border-radius: 20px;

      &-inner {
        max-height: 100%;
        overflow: auto;

        @include scrollbar(3px);

        li {
          height: 56px;
          border-radius: 20px;
          background: var(--white);
          font-size: var(--fs-16);
          font-weight: 500;
          line-height: 1;
          color: var(--gray-500);
          transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
          padding: 0 20px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &:not(.active) {
            cursor: pointer;
          }

          &.active,
          &:hover {
            background: var(--color);
            color: var(--white);
            @include darkModeColorDefault;
            border: 0;
          }

          @include flex-center-between;
          @include fw('medium');
        }
      }
    }
  }
}
