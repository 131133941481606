.aayn-cancel-subscription-modal--backdrop {
  background: #e8ebf7;
}

.aayn-cancel-subscription-modal {
  width: 539px !important;
  border-radius: 25px;
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 1px solid var(--white);
  box-shadow: 0px 10px 30px #3f4e8814;
  position: relative;
  padding-top: 26px;

  b {
    font-weight: 600;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 26px;
    background: var(--orange);
    border-radius: 25px 25px 0 0;
  }

  .modal-content {
    border-radius: 0;
    background: unset;
    box-shadow: unset;
    height: 100%;
    border: 0;
  }

  &--header {
    display: flex;
    flex-direction: column;
    align-items: center;

    > ayn-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      background: rgba(244, 105, 6, 0.1);
      color: var(--orange);
      margin-top: 28px;
      margin-bottom: 17px;
    }

    .modal-close {
      width: 40px;
      height: 40px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 11px;
      right: 11px;
      color: var(--gray-500);
      transition: 0.15s all;

      &:hover {
        background: var(--white);
      }
    }

    &--title {
      font-weight: 600;
      font-size: 25px;
      line-height: 32px;
      text-align: center;
      color: var(--orange);
      margin-bottom: 5px;
    }

    &--description {
      font-size: var(--fs-18);
      line-height: 24px;
      text-align: center;
      color: var(--orange);
      margin: 0 29px 22px;
    }
  }

  &--body {
    &--card {
      border-radius: 30px;
      background: rgba(var(--white-rgb), 0.4);
      @include darkModeBgDefault;
      border: 1px solid var(--white);
      box-shadow: 0px 10px 30px rgba(63, 78, 136, 0.08);
      padding: 35px 23px;
      margin: 0 24px 20px;

      &-title {
        font-weight: 600;
        font-size: 25px;
        text-align: center;
        color: var(--primary);
        margin-bottom: 9px;
      }

      &-description {
        font-size: var(--fs-18);
        line-height: 24px;
        text-align: center;
        color: var(--gray-500);

        b {
          font-weight: 600;
        }
      }
    }

    &--form {
      margin: 0 24px 44px;

      .form-field {
        height: auto;
        margin-bottom: 20px;
      }

      textarea {
        height: 90px;
        border-radius: 20px;
        background: var(--white);
        border-color: var(--white);
        font-size: var(--fs-16);
        line-height: 28px;
        text-align: left;

        &::placeholder {
          color: var(--gray-500);
        }
      }

      &-footer {
        display: flex;

        button {
          flex: 1;
          border-radius: 28px;

          &:last-child {
            margin-left: 31px;
          }
        }
      }
    }
  }

  &--footer {
    height: 51px;
    border-top: 1px solid var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    a {
      font-weight: 500;
      font-size: var(--fs-18);
      line-height: 22px;
      text-align: center;
      color: var(--gray-500);
      cursor: pointer;
    }
  }

  &--button {
    display: flex;
    margin: 0 auto;
    border-radius: 28px;
  }

  &.aayn-welcome-back-modal {
    &::before {
      background-color: var(--primary);
    }

    .aayn-cancel-subscription-modal--header {
      color: var(--primary);

      > ayn-icon {
        background-color: rgba(101, 55, 252, 0.1);
        color: var(--primary);
      }
    }

    .aayn-cancel-subscription-modal--header--title {
      color: var(--primary);
    }

    .aayn-cancel-subscription-modal--header--description {
      color: var(--gray-500);
    }

    .aayn-cancel-subscription-modal--button {
      margin-bottom: 41px;
    }
  }
}
