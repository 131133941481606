.ayn-input--payment {
  background: var(--white);
  border-radius: 0.9375rem;
  height: 3.5rem;
  outline: none;
  padding: 1.25rem 1.875rem 1.25rem 1.875rem;
  color: var(--gray-500);
  font-size: var(--fs-16);
  width: 100%;
  min-width: 330px;
  font-weight: 500;
  letter-spacing: -0.04em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  #card-number {
    flex: 1;
  }

  #card-expiry {
    padding-left: 16px;
    width: 100px;
  }

  #card-cvv {
    width: 50px;
  }
}

.aayn-payment--country {
  display: flex;
  align-items: center;
  color: var(--gray-500);
  margin: 20px 0;

  label {
    margin-left: 20px;
    margin-right: 38px;
    white-space: nowrap;
  }

  ayn-form-field {
    flex: 1;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ayn-select {
    .ayn-select {
      background-color: var(--white);

      .ayn-select--btn {
        justify-content: unset;

        ayn-icon[name='location'] {
          margin-left: auto;
          margin-right: 14px;
        }
      }

      .ayn-option--inner {
        display: block;
        max-width: 199px;
        overflow: hidden;
      }

      .flag-icon {
        margin-right: 10px;
        border: 1px solid var(--white);
        border-radius: 6px;
        background-size: cover;
      }
    }
  }
}

.aayn-payment--or-text {
  --text-color: var(--gray-400);
  --text-bg: var(--body-bg);
  --border-color: var(--white);
  --border-width: calc(100% + 144px);
  --border-left: -72px;

  position: relative;
  height: 21px;
  margin: 20px 0;
  display: block;

  &::before {
    content: '';
    width: var(--border-width);
    height: 1px;
    background: var(--border-color);
    position: absolute;
    left: var(--border-left);
    top: 10px;
  }

  &::after {
    content: 'or';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: var(--text-color);
    padding: 0 28px;
    background: var(--text-bg);
    font-size: var(--fs-18);
  }
}

.aayn-payment--applied-text {
  font-size: var(--fs-16);
  color: var(--white);
  margin-bottom: 20px;
  text-align: center;

  b {
    font-weight: 600;
  }
}

.aayn-payment--square-btn {
  width: 48px;
  height: 48px;
  border-radius: 14px;
  background: var(--primary);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px !important;
  cursor: pointer;

  svg,
  path {
    color: var(--white) !important;
    width: 20px;
    height: 14px;
  }

  &.loading {
    svg,
    path {
      width: 50px;
      height: 50px;
    }
  }
}

.aayn-auth-payment {
  .aayn-payment--country {
    color: var(--white);
  }

  .aayn-payment--or-text {
    --text-bg: var(--primary);
    --border-color: rgba(var(--white-rgb), 0.2);
    --text-color: var(--white);
    --border-width: 100%;
    --border-left: 0;
  }

  &--stripe {
    text-align: center;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    font-size: var(--fs-16);
    line-height: 28px;

    > ayn-icon {
      margin-right: 8px;
    }

    span {
      margin-left: 2px;

      img {
        vertical-align: middle;
      }
    }
  }

  &--appsumo-tooltip {
    text-align: left;

    h3 {
      font-weight: bold;
      font-size: var(--fs-16);
      line-height: 32px;
      text-align: left;
      color: var(--white);
    }

    p {
      font-size: var(--fs-14);
      line-height: 22px;
      text-align: left;
      color: var(--white);

      a {
        color: var(--accent);
        text-decoration: underline;
      }
    }
  }
}

.aayn-payment-modal {
  display: flex;
  flex-direction: column;
  width: 644px;
  border-radius: 30px;
  padding: 70px 72px 32px;

  &--header {
    display: flex;
    flex-direction: column;

    &-title {
      font-weight: 600;
      font-size: var(--fs-40);
      letter-spacing: -0.04em;
      line-height: 42px;
      text-align: center;
      color: var(--success);
    }

    &-description {
      margin-top: 10px;
      font-size: var(--fs-16);
      letter-spacing: -0.02em;
      line-height: 24px;
      text-align: center;
      color: var(--gray-600);

      span {
        display: block;
        font-weight: bold;
      }
    }
  }

  &--body {
    margin-top: 12px;

    aayn-payment-form {
      .aayn-auth--form-card {
        width: 100%;
        background: transparent;
        padding: 0;
        border: 0;
      }

      .aayn-auth--submit-button {
        background: var(--primary);
        color: var(--white);
      }
    }

    ayn-form-field {
      display: block;
      margin-top: 15px;
    }

    &--select-with-label {
      display: flex;
      margin-top: 15px;
      width: 100%;
      height: 56px;
      border-radius: 15px;
      background: var(--white);
      border: 1px solid var(--white);
      justify-content: space-between;
      align-items: center;

      label {
        display: inline-flex;
        margin: auto 20px;

        font-weight: normal;
        font-size: var(--fs-16);
        line-height: 32px;
        text-align: left;
        color: var(--gray-500);
      }

      ayn-select {
        margin-right: 7px;
        display: inline-block;
        width: 75%;

        .ayn-select {
          &--inner {
            height: 46px !important;

            .ayn-select--btn {
              padding-right: 14px;
            }
          }

          span {
            min-width: 36px;
            height: 24px;
            margin-right: 11px;
          }

          ::ng-deep .ayn-option--inner {
            justify-content: flex-start;

            span {
              margin-right: 11px;
            }
          }
        }
      }
    }

    .aayn-payment--country {
      border: 1px solid var(--white);
      background: var(--white);
      border-radius: 15px;
      padding: 4px;
    }

    &__button {
      display: flex;

      margin: 22px auto;
      max-width: max-content;
    }
  }

  &--logout-footer {
    border-top: 1px solid var(--white);
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      color: var(--gray-500);
      border: 0;
      outline: none;
      background: transparent;
      font-size: var(--fs-16);
      font-weight: 500;
      cursor: pointer;

      ayn-icon {
        margin-right: 7px;
      }
    }
  }

  .aayn-payment-modal--header-description {
    b {
      font-weight: 500;
      color: var(--primary);
    }
  }

  .aayn-payment--applied-text {
    color: var(--gray-500);

    b {
      color: var(--primary);
    }
  }
}

.aayn-auth--form-card-appsumo-icon {
  height: 29px;
}
