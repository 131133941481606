.pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: 0.5s;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loading-horizontal {
  0% {
    left: -45%;
  }
  100% {
    left: 100%;
  }
}

@keyframes loading-vertical {
  0% {
    top: -45%;
  }
  100% {
    top: 100%;
  }
}

@keyframes loading-cross-right {
  0% {
    top: -100%;
    left: 10%;
  }
  100% {
    top: 75%;
    left: 55%;
  }
}

@keyframes loading-cross-left {
  0% {
    top: -45%;
    left: 100%;
  }
  100% {
    top: 100%;
    left: -45%;
  }
}

@mixin skeleton($loading: loading-horizontal) {
  position: absolute;
  top: 0;
  left: -45%;
  --white: 0, 0%, 100%;
  height: 100%;
  z-index: 1;
  width: 45%;
  background-image: linear-gradient(
    to left,
    hsla(var(--white), 0.05),
    hsla(var(--white), 0.3),
    hsla(var(--white), 0.6),
    hsla(var(--white), 0.3),
    hsla(var(--white), 0.05)
  );

  [data-theme='dark'] & {
    --white: 10, 10%, 10%;
    background-image: linear-gradient(
      to left,
      hsla(var(--white), 0.1),
      hsla(var(--white), 0.3),
      hsla(var(--white), 0.6),
      hsla(var(--white), 0.3),
      hsla(var(--white), 0.05)
    );
  }
  animation: #{$loading} 1s infinite;
}

.ayn-skeleton {
  --skeleton-border-radius: 20px;
  position: relative;
  display: inline-flex;
  background-color: var(--gray-25) !important;
  opacity: 75%;

  [ayn-input],
  .ayn-color-picker {
    background: var(--gray-25) !important;
    opacity: 75%;
  }

  [data-theme='dark'] & {
    background-color: var(--gray-100);

    [ayn-input],
    .ayn-color-picker {
      background: var(--gray-100);
    }
  }

  overflow: hidden;
  width: 100%;
  border-radius: var(--skeleton-border-radius);

  &:before {
    content: '';
    @include skeleton;
  }

  &.loader-horizontal {
    &:before {
      animation: loading-horizontal 1s infinite;
    }
  }

  &.loader-vertical {
    &:before {
      transform: rotate(90deg);
      width: 100%;
      height: 50%;
      animation: loading-vertical 1s infinite;
    }
  }

  &.loader-cross-right {
    &:before {
      transform: rotate(45deg);
      left: 0;
      top: -45%;
      height: calc(100% + 45%);

      animation: loading-cross-right 1s infinite;
    }
  }

  &.loader-cross-left {
    &:before {
      transform: rotate(135deg);
      left: unset;
      right: 0;
      top: -45%;
      height: calc(100% + 45%);

      animation: loading-cross-left 1s infinite;
    }
  }
}

.ayn-img-skeleton {
  position: absolute;
  background-color: var(--gray-200) !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block !important;
}

.ayn-skeleton-text {
  --skeleton-border-radius: 20px;
  position: relative;
  background-color: var(--gray-50);
  display: inline-flex;
  overflow: hidden;
  width: 100%;
  border-radius: var(--skeleton-border-radius);
  height: 10px;

  &--bar {
    @include skeleton;
  }
}
