.modal {
  z-index: 10050;

  &-backdrop {
    z-index: 10000 !important;
    opacity: 1 !important;

    will-change: filter;

    --webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);

    background: rgba(234, 238, 255, 0.7);

    [data-theme='dark'] & {
      background: rgba(16, 18, 31, 0.7);
    }
  }

  &-content {
    box-shadow: 0px 5px 99px rgba(var(--gray-rgb), 0.08);
    filter: none;
  }
}
