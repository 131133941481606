.aayn-ad-preview-bing-single {
  .aayn-ad-preview-content {
    padding-top: 0;
  }

  &_content {
    padding: 15px 20px 26px 20px;
  }

  &_header {
    font-size: var(--fs-18);
    line-height: 20px;
    font-weight: bold;
    color: var(--gray-500);
    overflow-wrap: break-word;
  }

  &_description {
    margin-top: 15px;
    font-size: var(--fs-14);
    font-weight: normal;
    line-height: 18px;
    color: var(--gray-500);
  }

  &__business_name {
    margin-top: 25px;
    font-weight: 500;
    font-size: var(--fs-12);
    text-align: left;
    color: var(--gray-400);
    line-height: 23px;
    overflow: hidden !important;
    max-width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &-ad_info {
      border: 1px solid var(--gray-600);
      border-radius: 4px;
      padding: 3px;
      color: var(--gray-600);
      font-weight: 600;
    }
  }

  &_image {
    border-radius: 25px 25px 0 0;
  }
}
