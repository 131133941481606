.aayn-ad-type--carousel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--form {
    width: 100%;

    &--button {
      margin-right: 10px;
      margin-top: 20px;
      color: var(--gray-500);

      .check-icon svg {
        width: 17.29px;
        height: 13.7px;
      }

      svg {
        margin-left: 6px;
      }

      path {
        fill: var(--gray-500);
      }

      &:hover {
        background-color: var(--gray-500);
        color: var(--white);

        path {
          fill: var(--white);
        }
      }
    }
  }

  .aayn-carousel-add-more {
    background: none;
    padding: 13px 23.4px 12.4px 24px;
    margin-top: 30px;
    border: none;
    font-size: var(--fs-16);
    cursor: pointer;

    &:hover {
      background-color: var(--gray-500);
      border-radius: 35px;
      color: var(--white);
    }
  }

  .ayn-file-upload {
    width: 100%;
    border: 1px solid var(--gray-200);
  }
}
