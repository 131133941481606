.aayn-tiktok-identity-select {
  .form-field &--element {
    .ayn-option {
      padding: 0 10px;
    }

    .ayn-select--btn {
      padding: 0 10px;
    }

    .aayn-tiktok-identity-select--element-create-option {
      color: var(--primary);
      border-color: var(--primary);
      justify-content: center;
    }

    .aayn-tiktok-identity-select--create {
      margin-bottom: 10px;

      [ayn-input] {
        border-color: rgba(var(--gray-rgb), 0.6);
      }

      .aayn-tiktok-identity-select--create-button {
        &:not(.btn[disabled], .btn.has-icon:hover) {
          svg {
            --icon-color: var(--gray-500);
            color: inherit;
          }
        }

        ayn-icon {
          position: static;
          transform: none;
        }
      }

      aayn-file-upload-item {
        margin-right: 12px;
      }
    }

    .aayn-tiktok-identity-select--element--image {
      width: 40px;
      height: 40px;
      border-radius: 15px;
    }
  }

  &--create {
    border: 1px solid var(--primary);
    border-radius: 20px;
    padding: 12px 10px 10px 10px;
    display: flex;

    aayn-file-upload-item {
      margin-right: 20px;
    }

    &-button.btn.has-icon {
      margin-left: 14px;
      padding: 18px;
      border-radius: 15px;
      background-color: transparent;
      border: none;
      position: static;
      height: 100%;

      &:hover {
        background-color: var(--primary) !important;
        color: var(--white) !important;

        svg {
          --icon-color: var(--white);
        }
      }

      .btn--icon {
        margin: 0;
      }

      &.btn__icon\:loading {
        padding: 8px;
        background-color: var(--primary-10);
      }
    }
  }

  .aayn-file-upload-item.aspect-ratio-1\:1 img.aayn-file-upload-item--selected_image {
    width: 56px;
    height: 56px;
  }

  .aayn-tiktok-file-upload-item {
    background-color: var(--white);

    --error-color: var(--gray-500);

    --file-border-width: 138px;
    --file-border-height: 56px;
    --radius: 15;
    --dashArray: 7;

    width: 138px;
    height: 56px;
    border-radius: 15px;

    &.aayn-file-upload-item__selected {
      width: 56px;
      height: 56px;
      background-image: none;
    }

    .-or-text {
      display: none;
    }

    .-upload-icon {
      display: none;
    }

    .-upload-text {
      display: none;
    }

    .-canva-btn {
      display: none;
    }

    ayn-lottie {
      display: none;
    }

    label {
      &:not(.ayn-datepicker--label) {
        line-height: 18px;
        display: flex;
        justify-content: center;
        gap: 5px;
        padding: 0 10px;
      }

      ayn-progressbar {
        width: 100%;

        .ayn-progressbar.millennium {
          width: 100%;
          min-width: unset;
        }
      }

      .aayn-file-upload-item--upload-progress {
        margin: 0;
        line-height: 20px;
      }
    }

    .aayn-file-upload-item--custom-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      --icon-color: var(--gray-500);

      ayn-icon {
        position: static;
        transform: none;
      }

      svg {
        width: 18px;
        height: 20.05px;
      }
    }

    .aayn-file-upload-item\:hover [ayn-button] {
      width: 30px;
      height: 30px;
      position: static;

      ayn-icon {
        position: static;
        transform: none;

        svg {
          width: 18px;
          height: 16px;
          --icon-color: var(--white);
        }
      }
    }
  }

  .aayn-file-upload-item__has-error {
    --file-border-color: var(--orange);

    span {
      color: var(--error-color);
    }

    svg {
      --icon-color: var(--error-color);
    }
  }

  &-area.aayn-ad-type-sections {
    margin-bottom: 30px;
    margin-top: 0;
  }
}
