.aayn-unpaid-invoices-alert {
  text-align: center;

  header {
    h5,
    p {
      display: none;
    }
  }

  .ayn-video-player {
    width: 462.64px;
    height: 259.99px;
    border: 8px solid var(--white);
    border-radius: 20px;
    background: var(--white);
    box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
    margin-top: 25px;
    overflow: hidden;

    iframe {
      height: 250px;

      .player.right-content-area-supported {
        border-radius: 15px;
      }
    }
  }

  main {
    padding: 0 40px;
  }

  &--title {
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    color: var(--orange);
    margin-top: 15px;
    margin-bottom: 7px;
  }

  &--subtitle {
    font-weight: 600;
    font-size: var(--fs-18);
    line-height: 24px;
    color: var(--orange);
    margin-bottom: 20px;
  }

  &--title-2 {
    font-weight: 600;
    font-size: var(--fs-20);
    line-height: 24px;
    color: var(--gray-500);
    margin-bottom: 7px;
  }

  &--description {
    font-size: var(--fs-16);
    line-height: 24px;
    color: var(--gray-500);
    margin-bottom: 27px;
    padding: 0 30px;
  }

  &--buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    [ayn-button] {
      margin: 0 !important;

      &:last-child {
        margin-left: 20px !important;
      }
    }
  }

  &--footer-text {
    font-size: var(--fs-16);
    line-height: 24px;
    color: var(--gray-500);

    a {
      color: var(--gray-500);
      font-weight: 600;
    }
  }

  footer {
    height: 44px;
    border-top: 1px solid var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    a {
      font-weight: 600;
      font-size: var(--fs-18);
      line-height: 24px;
      color: var(--gray-500);
      text-decoration: none;
    }
  }
}
