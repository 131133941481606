.aayn-watchtower {
  height: 222px;
  border-radius: 20px;
  border: 1px solid var(--gray-100);
  display: flex;
  flex-direction: column;

  &--form {
    display: inline-flex;
    justify-content: space-between;

    margin: 18px 17px;

    &__left {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      height: 98px;
      border: 1px solid var(--gray-100);
      border-radius: 20px;
      margin-right: 10px;

      .ayn-input-with-button {
        width: 425px;
        margin-left: 25px;
        margin-right: 10px;

        label {
          position: absolute;
          width: 232px;
          font-weight: 600;
          font-size: var(--fs-18);

          line-height: 32px;
          text-align: left;
          color: var(--gray-500);
          padding: 16px 18px;
          border-right: 1px solid var(--gray-100);
        }

        input {
          display: block;
          padding-left: 252px;
        }

        [ayn-button] {
          border-radius: 15px;
          margin: 4px;
          height: 48px;
        }

        &.stretch {
          [ayn-button] {
            margin: 0;
            width: 66px;
            border-radius: 0 14px 14px 0;
            height: 100%;
            box-shadow: none !important;
          }
        }
      }

      ayn-select {
        width: 181px;
        margin-left: 10px;
        margin-right: 25px;
      }
    }

    &__right {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      height: 98px;
      margin-left: 10px;
      border: 1px solid var(--gray-100);
      border-radius: 20px;
    }
  }

  .btn {
    display: inline-flex;
    width: 219px;
    margin: 20px auto;
  }
}
