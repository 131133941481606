.aayn-ad-preview--meta-carousel {
  .aayn-ad-preview-placeholder {
    width: 312px;
    height: 312px;
  }

  &_header {
    font-size: var(--fs-14);
    line-height: 20px;
    color: var(--gray-500);
    padding: 13px 19px 13px 27px;
    overflow-wrap: break-word;
    min-height: 60px;
  }

  &_slide {
    padding-left: 27px;

    &-item {
      display: grid;
      grid-template-rows: 1fr;
      background-color: var(--body-bg);
      border-radius: 20px;
      width: 312px;
      margin-right: 15px;
      overflow: hidden;

      &__image {
        max-height: 312px;
        max-width: 312px;
        align-self: center;
        justify-self: center;
        border-radius: 0;
      }

      &__content {
        padding: 15px 15px 15px 24px;

        &__labels {
          display: flex;
          justify-content: space-between;
          gap: 25px;
        }

        &__body {
          margin-top: 10px;
          font-size: var(--fs-16);
          font-weight: bold;
          line-height: 18px;
          color: var(--gray-500);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: start;
        }

        &__cta {
          background-color: var(--white);
          border-radius: 13px;
          padding: 16px 20px;
          font-size: var(--fs-16);
          font-weight: 500;
          max-height: 48px;
          white-space: nowrap;
        }
      }
    }
  }
}
