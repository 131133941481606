@mixin flex {
  display: flex;
}

@mixin flex-center {
  @include flex;

  align-items: center;
  justify-content: center;
}

@mixin flex-align-center {
  @include flex;

  align-items: center;
}

@mixin flex-align-end {
  @include flex;

  align-items: flex-end;
}

@mixin flex-justify-center {
  @include flex;

  justify-content: center;
}

@mixin flex-justify-between {
  @include flex;

  justify-content: space-between;
}

@mixin flex-center-between {
  @include flex;

  align-items: center;
  justify-content: space-between;
}

@mixin flex-direction($name: 'row') {
  flex-direction: #{$name};
}
