.ayn-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.0625rem;
  margin: -0.0625rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.0625rem;
}

.ayn-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.gender {
    width: 9.1875rem;
    height: 3.5rem;
    border-radius: 20px;
    background: var(--white);
    border: 0.0625rem solid var(--gray-200);
    display: flex;
    justify-content: space-between;

    &.ayn-radio--checked {
      background: var(--accent);

      label {
        font-weight: 600;
        font-size: var(--fs-16);
        line-height: 2rem;

        text-align: left;
        color: var(--white);
        margin: auto 0.9375rem;
        cursor: pointer;
      }

      ayn-icon {
        display: inline-flex;

        svg-icon {
          width: 1.435rem;
          height: 1.4344rem;

          svg,
          path,
          g {
            fill: var(--white);
          }

          margin: auto 1.0313rem;
        }
      }
    }

    .ayn-radio-box {
      width: 100%;
      height: 100%;
    }

    label {
      font-weight: normal;
      font-size: var(--fs-16);
      line-height: 2rem;
      text-align: left;
      color: var(--gray-400);
      margin: auto 0.9375rem;
      cursor: pointer;
    }

    ayn-icon {
      display: inline-flex;

      svg-icon {
        width: 1.435rem;
        height: 1.4344rem;
        margin: auto 1.0313rem;

        svg,
        path,
        g {
          fill: var(--gray-400);
        }
      }
    }
  }

  &.default {
    min-width: 4.375rem;
    height: 3.5rem;
    background: var(--white);
    border: 0.0625rem solid var(--white);
    border-radius: 20px;

    &:not(.ayn-radio--checked):hover {
      background: rgba(0, 0, 0, 0.02);
    }

    label {
      font-weight: normal;
      font-size: var(--fs-16);
      line-height: 32px;
      text-align: left;
      color: var(--gray-500);

      margin: auto 0.9375rem;
      cursor: pointer;
    }

    &.ayn-radio--checked {
      background: var(--accent);

      label {
        font-weight: 600;
        font-size: var(--fs-16);
        line-height: 2rem;

        text-align: left;
        color: var(--white);
        @include darkModeColorDefault;
        margin: auto 0.9375rem;
        cursor: pointer;
      }

      ayn-icon {
        display: inline-flex;

        svg-icon {
          width: 1.435rem;
          height: 1.4344rem;

          svg,
          path,
          g {
            fill: var(--white);
          }

          margin: auto 1.0313rem;
        }
      }
    }
  }

  &.select {
    min-width: 12.8125rem;
    height: 56px;
    border-radius: 20px;
    background: var(--white);
    border: 0.0625rem solid var(--gray-200);
    display: flex;
    justify-content: flex-start;
    padding-right: 0.625rem;

    label {
      font-weight: 500;
      font-size: var(--fs-16);
      line-height: 2rem;
      text-align: left;
      color: var(--gray-500);
      margin: auto 0rem;
      cursor: pointer;
    }

    .ayn-radio-box {
      margin: auto 0.8125rem;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      background: var(--white);
      border: 0.0625rem solid var(--gray-200);
      border-radius: 50%;

      &.ayn-highlight {
        background: var(--success);
        border: 0.0625rem solid var(--success);
      }
    }
  }

  &.radio {
    height: 3rem;
    border-radius: 20px;
    background: var(--white);
    display: flex;
    justify-content: flex-start;

    label {
      font-weight: 500;
      font-size: var(--fs-16);
      line-height: 2rem;
      text-align: left;
      color: var(--gray-500);
      margin: auto 0rem;
      cursor: pointer;
    }

    .ayn-radio-box {
      margin: auto 0.8125rem;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      background: var(--white);
      border: 0.0625rem solid var(--gray-200);
      border-radius: 50%;

      &.ayn-highlight {
        background: var(--success);
        border: 0.0625rem solid var(--success);
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &.default label {
      cursor: not-allowed;
    }
  }
}
