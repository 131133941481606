.aayn-user-menu--body {
  top: 56px !important;
  left: 0 !important;
  margin-top: 0;
  background-color: var(--white);
  border-radius: 0 0 20px 20px;
  width: 201px;
  box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.08);

  ul {
    padding: 8px;

    &.aayn-user-menu--footer-list {
      border-top: 1px solid var(--gray-200);

      .aayn-user-menu--list-item_community {
        background: var(--primary);
        color: var(--white);
        &:hover {
          color: var(--gray-500);
        }
      }

      .aayn-user-menu--list-item_secure {
        background: var(--success);
        color: var(--white);
        &:hover {
          color: var(--gray-500);
        }
      }
    }

    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }

      &.aayn-user-menu--upgrade-btn {
        .aayn-user-menu--list-item {
          background: var(--success);
          color: var(--white);
        }
      }

      .aayn-user-menu--list-item {
        display: flex;
        outline: 0;
        align-items: center;
        justify-content: space-between;
        min-height: 46px;
        padding: 0 12px 0 17px;
        border-radius: 15px;
        background: var(--white);
        border: 1px solid var(--gray-300);
        color: var(--gray-500);
        text-decoration: none;
        width: 100%;
        font-size: var(--fs-16);
        cursor: pointer;

        &:hover {
          border-color: rgba(var(--gray-rgb), 0.6);
          background-color: rgba(var(--gray-rgb), 0.05);
        }
      }
    }
  }
}
