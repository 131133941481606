.aayn-google-import-modal {
  width: 1067px;
  max-height: 757px;

  .modal-close {
    @include modal-close(var(--white));
    right: 30px;
    top: 40px;
  }

  &--header {
    min-height: 120px;
    border-radius: 29px 29px 0px 0px;
    background: var(--primary);
    border: 1px solid var(--gray-300);
    padding: 27px 25px;
    color: var(--white);
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -71px;
      width: 400px;
      height: 135.92px;
      background: linear-gradient(to top, rgba(var(--white-rgb), 0) 0%, rgba(var(--white-rgb), 0.1) 100%);
      transform: skew(-37deg, 0deg);
    }

    &__content {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      margin-right: 100px;
    }

    &__title {
      display: flex;
      justify-content: flex-start;
      font-weight: 600;
      font-size: var(--fs-25);

      line-height: 32px;
      text-align: left;
      color: var(--white);
    }

    &__description {
      display: flex;
      justify-content: flex-start;
      font-weight: normal;
      font-size: var(--fs-16);
      line-height: 20px;
      text-align: left;
      color: var(--white);
    }

    ayn-form-field {
      width: 279px;
    }

    &-search {
      [ayn-input] {
        border-radius: 20px;
      }
    }
  }

  &--main {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  &--table {
    margin: 24px;
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    border: 1px solid var(--white);
    border-radius: 30px;
    padding: 10px;
    filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));

    @include scrollbar(10px);

    table {
      td,
      th {
        vertical-align: middle;
        padding: 10px !important;
      }
    }

    .ayn-table {
      background: rgba(var(--white-rgb), 0.4);
      @include darkModeBgDefault;
    }

    tr td {
      padding: 10px 15px !important;
    }

    .name {
      width: 300px;
      display: flex;
      align-items: center;

      ayn-checkbox {
        margin-right: 10px;
      }
    }

    .type {
      width: 294px !important;
    }

    .status {
      width: 180px !important;
      min-width: 180px !important;
      line-height: 1.2;

      &:before {
        content: '';
        width: 14px;
        height: 14px;
        display: inline-block;
        border-radius: 50%;
      }

      &.active {
        &:before {
          background: var(--primary);
        }

        color: var(--primary) !important;
      }

      &.passive {
        &:before {
          background: var(--orange);
        }

        color: var(--orange) !important;
      }
    }
  }

  &--footer {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 39px;

    a {
      ayn-icon {
        margin-right: 5px;
      }

      display: flex;
      align-items: center;

      cursor: pointer;
      position: absolute;
    }

    .aayn-google-import-modal--btn {
      width: max-content;
      margin: 5px auto;
    }
  }

  ayn-loader-lottie {
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
