.aayn-businesses {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(md) {
    padding: 10px;
  }

  &--container {
    max-width: 1411px;
    width: 100%;

    border-radius: 20px;
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    border: 1px solid var(--white);
    box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.08);

    &-header {
      border-bottom: 0.0625rem solid var(--white);
      padding: 33px 33px 22px 31px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__right {
        display: flex;
        align-items: center;

        ayn-form-field {
          --icon-color: var(--gray-500);

          margin-left: 0.9375rem;
          min-width: 17.9375rem;
        }
      }
    }

    aayn-empty-content {
      display: flex;
      justify-content: center;
      margin: 144px auto;
    }

    &-body {
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      gap: 20px 20px;

      &_empty {
        display: flex;
      }

      .aayn-business-item {
        width: 100%;
        min-width: 320px;
        height: 184px;
        background: rgba(var(--white-rgb), 0.6);
        border: 1px solid var(--white);
        border-radius: 25px;
        position: relative;
        filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));

        &--header {
          display: flex;
          padding: 30px;

          &__left {
            flex: 1;
            overflow: hidden;
            padding-right: 13px;
          }
        }

        &--name {
          font-weight: 600;
          font-size: var(--fs-20);
          line-height: 25px;
          color: var(--gray-500);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 13px;
        }

        &--category {
          font-weight: normal;
          font-size: var(--fs-14);
          color: var(--gray-500);
          line-height: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &--utm {
          font-weight: 500;
          font-size: var(--fs-14);
          color: var(--gray-400);
          margin-top: 1px;
          display: flex;
          line-height: 20px;
          align-items: center;

          ayn-icon {
            margin-left: 3px;
          }
        }

        &__menu-btn {
          width: 33px;
          height: 40px;
          background: transparent;
          color: var(--gray-500);
          border-radius: 10px;
          padding: 0;
          justify-content: center;

          &.active {
            background: var(--primary);
            color: var(--white);
          }

          &:hover {
            background: var(--white);
            color: var(--gray-500);
          }
        }

        &--footer {
          position: absolute;
          margin-top: auto;
          height: 80px;
          bottom: 0;
          width: 100%;
          display: flex;
          align-items: center;
          padding-left: 30px;

          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: var(--white);
            position: absolute;
            top: 0;
            left: 0;
          }

          &-platforms {
            display: flex;

            ayn-icon {
              margin-right: 12px;
              max-width: 20px;
              max-height: 20px;
              color: var(--gray-500);

              svg {
                max-height: 20px;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }

          &__options {
            bottom: 20px;
            right: 20px;
            position: absolute;
          }
        }
      }
    }
  }
}

// BUSINESSES:BUSINESS-ITEM--POPUP

.aayn-business-item--popup {
  margin-left: -40px;
}

@media only screen and (max-width: 600px) {
  .aayn-businesses--container-header {
    flex-wrap: wrap;

    button {
      width: 100% !important;
    }

    &__right {
      width: 100%;

      ayn-form-field {
        margin-left: 0;
        min-width: initial;
      }
    }
  }

  .aayn-businesses--container-body {
    padding: 10px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 10px 10px;

    .aayn-business-item {
      min-width: 250px;
    }
  }
}
