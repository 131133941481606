.ayn-paginator {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5 all;

  li {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0 0.1563rem;
    border-radius: 1.25rem;
    font-weight: 600;
    font-size: var(--fs-18);

    line-height: 2rem;
    text-align: center;
    border: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-500);
    background-color: var(--gray-100);
    position: relative;

    &.active {
      color: var(--white);
      background-color: var(--primary);

      &:hover:not(.last):not(.new) {
        background-color: var(--orange);
        color: transparent;

        &::before {
          transition: 1s;
          background-image: url(/assets/images/icons/trash.svg);
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      filter: brightness(0) invert(1);
      background-position: center center;
      background-repeat: no-repeat;
    }

    &.new {
      color: var(--white);
      background-color: var(--accent);

      &::before {
        background-image: url(/assets/images/icons/plus.svg);
      }

      .title {
        display: none;
      }
    }

    &.last {
      color: var(--white);
      background-color: var(--gray-400);
      cursor: not-allowed;

      &::before {
        background-image: url(/assets/images/icons/plus.svg);
      }
    }

    &.disabled {
      background: #f1f4f7;
      color: var(--white);
      cursor: auto;

      &::before {
        content: none;
      }

      &:hover {
        background: #f1f4f7 !important;
        color: var(--white) !important;
      }
    }
  }

  &__small {
    li {
      min-width: 35px;
      width: 35px;
      min-height: 35px;
      height: 35px;
      border-radius: 10px;
      font-size: var(--fs-18);
      line-height: 32px;
    }
  }
}
