.aayn-account-breakdown-view {
  &--tabs {
    .ayn-tab {
      border: none;
      margin-top: 34px;
      border-radius: 0;

      .ayn-tab--unstyled {
        padding-left: 32px;
        padding-right: 32px;

        ul {
          li {
            padding: 18px 30.6px 18px 29px;
            border-radius: 20px 20px 0 0;
            height: 56px;
            border: solid 1px var(--white);
            border-bottom: none;
            margin: 0 20px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            float: left;
            cursor: pointer;
            color: var(--primary);
            background-color: var(--white);
            @include darkModeColorDefault;

            &.active {
              background-color: var(--primary);
              color: var(--white);
              @include darkModeColorDefault;

              path:not(#Path_11014),
              ellipse {
                fill: var(--white);
              }

              path#Path_11014 {
                fill: var(--primary);
              }
            }
          }
        }
      }

      .ayn-tab-item {
        padding: 15px 32px;
        border: solid 1px var(--white);
        border-radius: 20px;
        background: rgba(var(--white-rgb), 0.4);
        @include darkModeBgDefault;
        box-shadow: 0px 10px 30px rgba(63, 78, 136, 0.08);
      }
    }
  }

  .ayn-table {
    padding: 0;
    border-top: none;

    tr {
      th {
        width: calc(100% / 8);
      }
    }
  }
}
