.apex-chart {
  max-width: 1052px;
  margin: 35px auto;
  height: 390px;
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 1px solid var(--white);
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));

  border-radius: 20px;
  display: flex;
  flex-direction: column;

  .chart-button {
    display: flex;
    flex-direction: row;
    margin-left: 16px;

    .chart-badge {
      min-width: 120px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      margin-left: 10px;
      margin-top: -15px;
      padding-right: 15px;
      cursor: pointer;

      &.primary {
        background: var(--accent);
      }

      &.success {
        background: linear-gradient(90deg, #00d842 0%, #00c7a9 100%);
      }

      &.info {
        background: linear-gradient(90deg, #6884a9 0%, #42516b 100%);
      }

      ayn-checkbox {
        justify-content: center;
        padding: 6px 3px 6px 6px;
        display: none;
      }

      .name {
        padding-left: 16px;
        font-weight: 500;
        font-size: var(--fs-14);
        line-height: 32px;
        text-align: left;
        color: var(--white);
        @include darkModeColorDefault;
        margin-left: 3px;
      }

      .value {
        font-weight: 500;
        font-size: var(--fs-14);
        line-height: 32px;
        text-align: right;
        color: var(--white);
        @include darkModeColorDefault;
        margin-left: 14px;
      }
    }
  }

  .chart {
    width: 100%;
    display: block;
  }
}
