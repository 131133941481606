.aayn-text-generator {
  background: #fafbfd !important;
  border-radius: 30px;

  &--header {
    background: var(--white) !important;
    border-radius: 20px 20px 0 0;
    margin-top: -10px !important;
    align-items: center;

    h4 {
      margin-top: 0px !important;
      line-height: 1;
    }

    p {
      margin-bottom: 0px !important;
    }

    .modal-close {
      top: 10px !important;
    }
  }

  &--body {
    margin-top: 19px;
    margin-bottom: 42px;
    min-width: 1279px;
    min-height: 504px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-content {
      border-radius: 20px;
      width: 1279px;
      min-height: 576px;
      border: 1px solid var(--gray-50);
      background: var(--white);
      display: flex;
      position: relative;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      ayn-loader-lottie {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:before {
        content: '';
        background: var(--gray-200);
        width: 1248px;
        display: inline-flex;
        position: absolute;
        padding: 15px;
        border-radius: 20px 20px 0 0;
      }

      &--form {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 65px 35px 35px 35px;
        padding: 0 37px;

        &__text-area {
          .form-field {
            ayn-icon {
              top: 80%;
            }
          }
          [ayn-input].text-area {
            height: 110px;
          }
        }

        ayn-select {
          ayn-icon {
            margin-right: 8px;
          }

          img {
            margin-right: 8px;
            filter: unset !important;
          }
        }
        &__button {
          display: flex;
          justify-content: center;

          .btn {
            display: flex;
            width: 172px;
          }

          a {
            display: flex;
            align-items: center;
            font-weight: normal;
            font-size: var(--fs-16);
            line-height: 32px;
            text-align: left;
            color: var(--gray-500);
            cursor: pointer;
            margin-left: 25px;

            ayn-icon {
              width: 27.08px;
              height: 24.33px;
              margin-right: 5px;
            }
          }
        }
      }

      &--texts {
        margin: 65px 35px 35px 35px;
        display: flex;

        &__left {
          margin-right: 15px;
          min-width: 594px;
          max-height: 504px;
          overflow-y: scroll;
          @include scrollbar(12px);

          &-item {
            margin-bottom: 15px;
            padding: 10px;
            max-width: 594px;
            border-radius: 20px;
            background: var(--white);
            border: 1px solid var(--gray-200);
            display: flex;
            flex-direction: column;

            p {
              padding: 20px;
              max-width: 594px;
              border-radius: 20px;
              background: var(--white);
              border: 1px solid var(--gray-200);
              font-weight: 500;
              font-size: var(--fs-16);
              letter-spacing: -0.02em;
              line-height: var(--fs-22);
              color: var(--gray-500);
              display: flex;
            }

            &-footer {
              display: flex;
              position: relative;

              a {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                margin-top: 10px;
                width: 100px;
                height: 40px;
                border-radius: 15px;
                background: var(--white);
                border: 1px solid var(--gray-200);
                font-weight: 600;
                font-size: var(--fs-16);
                line-height: 32px;
                text-align: left;
                color: var(--gray-400);
                cursor: pointer;

                ayn-icon {
                  margin-right: 8px;
                }

                &:hover {
                  color: var(--primary);
                }
              }

              span {
                display: flex;
                align-items: center;
                position: absolute;
                right: 10px;
                bottom: 10px;
                font-size: var(--fs-14);
                line-height: 20px;
                text-align: left;
                color: var(--gray-400);
              }
            }
          }
        }

        &__right {
          margin-left: 15px;
          min-width: 594px;
          max-height: max-content;
          border-radius: 20px;
          background: var(--white);
          border: 1px solid var(--gray-200);

          &-editor {
            display: flex;
            flex-direction: column;
            margin: 10px;

            &-button {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;

              &__tooltip {
                display: flex;
                margin-left: 20px;

                a {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  ayn-icon {
                    margin-right: 30px;
                  }
                  &:hover {
                    color: var(--primary);
                  }
                }
              }

              &__copy {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 40px;
                border-radius: 15px;
                background: var(--white);
                border: 1px solid var(--gray-200);
                font-weight: 600;
                font-size: var(--fs-16);
                line-height: 32px;
                text-align: left;
                color: var(--gray-400);
                cursor: pointer;

                ayn-icon {
                  margin-right: 8px;
                }

                &:hover {
                  color: var(--primary);
                }
              }
            }

            [ayn-input] {
              height: 100% !important;
            }
            text-area {
              border-radius: 15px;
              background: var(--white);
              width: 100%;
              height: 100% !important;
            }

            &-footer {
              display: flex;
              justify-content: space-between;
              margin-left: 25px;
              margin-right: 25px;
              margin-top: 10px;

              span {
                font-weight: normal;
                font-size: var(--fs-14);
                line-height: 20px;
                text-align: left;
                color: var(--gray-400);
              }
              .error {
                color: var(--orange);
              }
            }
          }
        }
      }

      &--footer {
        display: flex;
      }
    }
  }
}
