.aayn-ad-type-sections {
  border-radius: 25px;
  margin-top: 30px;
  background: rgba(var(--white-rgb), 0.3);
  border: 1px solid var(--white);
  box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);

  .aayn-ad-type-section {
    padding: 34px 32px 25px 32px;
    border-bottom: 1px solid var(--body-bg);

    &--header {
      display: flex;
      flex-direction: row;
      align-items: start;
    }

    &--icon {
      padding: 17px 14.4px 16.7px 14px;
      background-color: rgba(var(--primary-rgb), 0.1);
      border-radius: 15px;
      margin-right: 15px;
    }

    &--info {
      margin-bottom: 21px;

      &--header {
        font-size: var(--fs-20);
        color: var(--gray-500);
      }

      &--description {
        font-size: var(--fs-14);
        color: var(--gray-500);
        margin-top: 7px;
        display: block;
      }
    }
  }

  aayn-ad-type-section:last-child {
    .aayn-ad-type-section {
      border-bottom: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .aayn-ad-type-section {
    padding: 20px !important;
  }
}
