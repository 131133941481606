.aayn-instant-ad--describe-project {
  width: 100%;

  &-scan--form {
    background-color: rgba(var(--white-rgb), 0.6);
    border-radius: 25px;
    filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));
    padding: 34px 25px 25px 25px;
    width: 100%;
    border: 1px solid var(--white);

    &_title {
      font-size: var(--fs-20);
      font-weight: 500;
      margin-bottom: 7px;
      color: var(--gray-500);
    }

    &_description {
      font-size: var(--fs-14);
      line-height: 20px;
      text-align: left;
      color: var(--gray-500);
    }

    &-row {
      display: flex;
      margin-top: 28px;
      gap: 20px;
    }
  }

  .aayn-collapse-card.active {
    overflow: visible;
  }

  &--collapse {
    display: flex;
    margin-top: 30px;
    width: 100%;

    .aayn-collapse-card {
      border-radius: 25px;
      border: 1px solid var(--white);
      background: rgba(var(--white-rgb), 0.4);
      @include darkModeBgDefault;
      width: 100%;
      max-height: 100px;

      &--header {
        margin: 15px 0 0 0;

        &__collapse {
          margin-left: 10px;

          &-icon {
            left: 0.7815rem;
          }
        }

        &__title {
          &-icon {
            background: var(--white);

            [data-theme='dark'] & {
              background: var(--gray-500);
            }

            border-radius: 28px;
            padding: 16px;
          }

          h4 {
            font-weight: 600;
            font-size: var(--fs-18);

            line-height: 32px;
            text-align: left;
            color: var(--gray-500) !important;
          }

          & + p {
            font-size: var(--fs-16);
            letter-spacing: -0.02em;
            line-height: 24px;
            color: var(--gray-400);
          }
        }

        .ayn-collapse--header__left-collapse {
          .icon {
            left: 0.7815rem;
          }
        }
      }

      &--main {
        margin-top: 32px;
        margin-left: 25px;
        margin-right: 25px;
      }
    }

    &-content-form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &_row {
        display: flex;
        gap: 20px;

        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
          ayn-form-field {
          }
        }
      }

      [ayn-input].text-area {
        min-height: 130px;
      }
    }

    &-content-creatives {
      margin-top: 40px;
      display: flex;
      flex-direction: column;

      .divider {
        background-color: var(--white);
      }

      &--upload {
        margin-top: 27px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 25px;
        row-gap: 20px;
        width: 100%;

        &--item {
          background: rgba(var(--white-rgb), 0.6);
          border-radius: 25px;
          padding: 25px;
          border: 1px solid var(--white);

          &.selected {
            border-color: var(--primary);
            position: relative;
          }

          &_selected-content {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            width: 100%;
            justify-content: space-between;
            color: var(--primary);

            .selected-text {
              font-weight: 500;
              font-size: var(--fs-14);
              position: relative;
              background-color: rgba(var(--white-rgb), 0.25);
              backdrop-filter: blur(4px) brightness(100%);
              padding: 3px 6px;
              top: -10px;
              left: 20px;
            }

            svg {
              background-color: var(--white);
              border-radius: 20px;
              width: 15px;
              height: 15px;
            }
          }

          aayn-file-upload-item {
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            background-color: var(--white);
            filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));

            &.filled {
              background: none;
              padding: 0;
            }

            &.ayn-skeleton {
              background-color: var(--gray-25);
              opacity: 75%;

              label,
              .aayn-file-upload-item--custom-content p {
                color: var(--gray-400);
              }
            }
          }

          .aayn-file-upload-item.aspect-ratio-1\:1 img.aayn-file-upload-item--selected_image {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          .aayn-file-upload-item {
            width: 100%;
            height: auto;
            aspect-ratio: 1;

            .-upload-icon,
            .-upload-text,
            .-or-text {
              display: none;
            }

            --file-border-color: var(--gray-500);
            --dashArray: 7;
            --file-border-width: 100%;
            --file-border-height: 100%;

            &__selected {
              background: none;
            }

            &__has-error {
              --file-border-color: var(--orange);

              label,
              .aayn-file-upload-item--custom-content p {
                color: var(--orange);
              }
            }
          }

          .aayn-file-upload-item--custom-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            svg {
              width: 32.73px;
              height: 36.47px;
            }

            h3 {
              font-size: var(--fs-20);
              font-weight: 500;
              margin-top: 12px;
            }

            p {
              font-size: var(--fs-14);
              margin-top: 8px;
              color: var(--gray-400);
            }

            &__tooltip {
              position: absolute;
              right: 10px;
              top: 10px;

              svg {
                width: 24px;
                height: 24px;
              }
            }
          }

          .aayn-file-upload-item--selected-custom-content {
            &__delete {
              position: absolute;
              top: 10px;
              right: 10px;
              background-color: rgba(var(--primary-rgb), 0.1);
              border-radius: 10px;
              width: 36px;
              height: 36px;
              transition: background-color 0.3s;
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--white);
              cursor: pointer;

              &:hover {
                background-color: rgba(var(--primary-rgb), 0.4);
              }

              &:active {
                background-color: rgba(var(--primary-rgb), 1);
              }
            }

            .aayn-file-upload-item--custom-content__tooltip {
              left: 10px;
              right: unset;
              color: var(--orange);

              svg {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        @media (max-width: 1590px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(sm) {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      &__see-more {
        margin-top: 20px;
        align-self: end;
      }
    }
  }
}
