.existing-post {
  &--container {
    &--tab_sections {
      .aayn-ad-type-sections {
        margin-top: 0;
      }
    }
    .aayn-ad-type-sections .aayn-ad-type-section {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      &--header {
        padding-left: 32px;
        padding-right: 32px;
      }
      &--icon {
        color: var(--primary);
      }
    }
    .ayn-tab {
      border: none;
      margin-top: 34px;
      border-radius: 0;

      .ayn-tab--unstyled {
        border-bottom: 1px solid var(--gray-300);
        padding-left: 32px;
        padding-right: 32px;
        margin-bottom: 22px;

        ul {
          li {
            padding: 18px 30.6px 18px 29px;
            border-radius: 20px 20px 0 0;
            height: 56px;
            border: solid 1px var(--gray-300);
            border-bottom: none;
            margin: 0 20px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            float: left;
            cursor: pointer;
            color: var(--primary);

            path:not(#Path_11014),
            ellipse {
              fill: var(--primary);
            }

            svg {
              margin-right: 8.5px;
            }

            &.active {
              background-color: var(--primary);
              color: var(--white);
              path:not(#Path_11014),
              ellipse {
                fill: var(--white);
              }
              path#Path_11014 {
                fill: var(--primary);
              }
            }
          }
        }
      }
      .ayn-tab-item {
        padding-left: 32px;
        padding-right: 32px;

        .existing-post--tab-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 24px;
        }

        .existing-post--list {
          padding-top: 30px;
          max-height: 690px;
          overflow: auto;
          @include scrollbar();

          .existing-post--li {
            margin: 0 0 20px;
            padding: 14px 20px 13px 14px;
            border-radius: 15px;
            border: solid 1px var(--gray-300);
            display: flex;
            align-items: center;
            transition: background-color 0.2s ease-in-out;

            &__active {
              background-color: var(--primary-10);
              border: solid 1px var(--primary);
            }

            &-img {
              padding: 7px;
              border-radius: 12px;
              border: solid 1px var(--gray-300);
              width: 89px;
              height: 89px;
              min-width: 89px;
            }

            &-content {
              width: 100%;
              padding-left: 19px;
              &--header {
                display: grid;
                grid-template-columns: auto 1fr;
              }
              &--title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: var(--fs-20);
              }
              &--type {
                justify-self: end;
                padding: 5px 9px 4px 10px;
                background-color: var(--primary-10);
                color: var(--primary);
                border-radius: 12.5px;
              }
            }

            &-footer {
              display: flex;
              width: 100%;
              justify-content: space-between;
              margin-top: 9px;

              &--icons {
                display: flex;
                align-items: center;
                gap: 10px;
              }
              &--icon {
                display: inline-flex;
                gap: 5.2px;
                color: var(--gray-400);
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
  &--error-text {
    font-size: var(--fs-14);
    color: var(--gray-400);
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .existing-post--container {
    .aayn-ad-type-sections .aayn-ad-type-section--header {
      padding-left: 0;
      padding-right: 0;
    }

    .ayn-tab {
      .ayn-tab-item {
        padding-left: 0;
        padding-right: 0;

        .existing-post--tab-row {
          flex-wrap: wrap;
          gap: 5px;
          justify-content: center;
        }

        .existing-post--list {
          .existing-post--li {
            flex-wrap: wrap;

            &-content {
              padding-left: 0;
              padding-top: 10px;
            }
          }
        }
      }

      .ayn-tab--unstyled {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;

        ul li {
          padding: 10px;

          ayn-icon {
            margin-left: 7px;
          }

          span {
            display: none;
          }
        }
      }
    }
  }
}
