aayn-ad-preview-card {
  display: block;
}

.aayn-ad-preview--card {
  min-height: min-content !important;
  position: relative;

  width: 100%;
  height: min-content !important;
  border-radius: 25px;
  background: rgba(var(--white-rgb), 0.3);
  border: 1px solid var(--white);
  box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.04);

  &-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-right: 20px;
    padding-left: 43px;
    padding-top: 34px;

    &__container {
      flex: 1;
    }

    &__title {
      font-weight: 600;
      font-size: var(--fs-20);
      line-height: 32px;
      color: var(--gray-500);
    }

    &__description {
      font-size: var(--fs-14);
      line-height: 20px;
      color: var(--gray-500);
    }

    ayn-select {
      --icon-color: var(--gray-500);

      .ayn-option.selected {
        background: rgb(var(--primary-rgb));
        color: var(--white);
        --icon-color: var(--white);
      }

      .ayn-select--btn .ayn-option--inner {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 8.3px;

        ayn-icon {
          transform: none;
          margin: 0;
          position: static;
        }
      }
    }
  }

  &_loading {
    .aayn-ad-preview--card-main {
      height: 690px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-footer {
    position: relative;

    &-button {
      position: absolute;
      border-radius: 15px;
      background: var(--white);
      color: var(--gray-500);
      border: 1px solid rgba(var(--gray-rgb), 0.3);

      right: 20px;
      bottom: 20px;
      width: 56px;
      height: 56px;

      .btn--icon {
        margin: 0;
      }

      &.btn.has-icon {
        padding: 0;
        justify-content: center;
      }

      &:hover {
        color: var(--accent);
      }

      &:active {
        background-color: var(--accent);
        color: var(--white);
        transform: translateY(4px);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .aayn-ad-preview--card {
    margin-left: 0px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .aayn-ad-preview--card {
    min-height: max-content;
    height: max-content;
  }

  .aayn-ad-preview--card-main .aayn-ad-preview-youtube--texts__title {
    margin-top: calc(40% + 48px);
    margin-left: 50px;
    width: 220px;
  }
}

/**
    &__text {
      font-size: var(--fs-14);
      line-height: 20px;
      color: var(--gray-500);
      padding: 13px 19px 13px 27px;
      overflow-wrap: break-word;
      &--carousel {
        min-height: 60px;
      }
    }

    &__image.full-width {
      max-height: 408px;
      max-width: 408px;
      object-fit: contain;
      border-radius: 0;
    }

    &-content {
      padding: 15px 16px 32px 27px;
      &__labels {
        display: flex;
        justify-content: space-between;
      }

      &__link {
        font-size: var(--fs-14);
        line-height: 20px;
        color: var(--gray-500);
      }

      &__body {
        margin-top: 10px;
        font-size: var(--fs-16);
        font-weight: bold;
        line-height: 18px;
        color: var(--gray-500);
        &_carousel {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: start;
        }
      }
      &__description {
        margin-top: 7px;
        font-size: var(--fs-14);
        font-weight: normal;
        line-height: 18px;
        color: var(--gray-500);
      }
      &__cta {
        background-color: var(--body-bg);
        border-radius: 13px;
        padding: 16px 20px;
        font-size: var(--fs-16);
        font-weight: 500;
        max-height: 48px;
        &--linkedin {
          border: 1px solid var(--primary);
          border-radius: 10px;
          color: var(--primary);
          max-height: 35px;
          padding: 8px 16px;
        }
      }
    }

    .ayn-tab {
      border: none;
      margin-top: 28px;
      border-radius: 0;
    }

    .aayn-ad-preview-youtube {
      background: url('/assets/images/svgs/create-ads/youtube-placeholder.svg') no-repeat top center !important;
      background-size: contain !important;
      margin-top: 15px;
      height: 710px;
      position: relative;

      &--button {
        position: absolute;
        right: 38px;
        top: 220px;
        width: 104.96px;
        height: 42.19px;
      }

      &--texts {
        &__title {
          margin-top: calc(40% + 30px);
          margin-left: 60px;
          font-weight: 500;
          font-size: var(--fs-16);
          line-height: 29px;
          text-align: left;
          color: var(--gray-500);

          display: inline-block;
          width: 280px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }

        &__url {
          margin-left: 90px;
          font-weight: 500;
          font-size: var(--fs-12);
          line-height: 15px;
          text-align: left;
          color: var(--gray-400);
        }
      }
    }

    .aayn-ad-preview-display {
      background: url('/assets/images/svgs/create-ads/display-placeholder.svg') no-repeat top center !important;
      background-size: contain !important;
      margin-top: 15px;
      height: 710px;

      .aayn-ad-preview-main {
        margin-top: 80px;

        &-display-ads {
          width: 95%;
          margin: auto;
          margin-top: 45px;
        }
      }

      &--image {
        width: 342.09px;
        height: 341.17px;
        margin-left: 20px;
        margin-top: -30px;
      }

      &--header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        margin-left: 20px;

        &__logo {
          width: 89px;
          height: 85.13px;
          border-radius: 15px;
          background: var(--white);
          border: 1px solid var(--gray-200);
          object-fit: contain;
          margin-right: 10px;
        }

        &__title {
          font-weight: 600;
          font-size: var(--fs-13);
          line-height: 29px;
          text-align: left;
          color: var(--gray-500);
        }

        &__sub-title {
          font-weight: 600;
          font-size: 11px;
          text-align: left;
          color: var(--gray-500);
        }

        &__description {
          font-weight: normal;
          font-size: 11px;
          line-height: 14px;
          text-align: left;
          color: var(--gray-400);
        }
      }
    }

    .aayn-ad-preview-google {
      background: url('/assets/images/svgs/create-ads/google-placeholder.svg') no-repeat top center !important;
      background-size: contain !important;
      margin-top: 15px;
      height: 710px;

      &--texts {
        margin-top: calc(40% + 30px);
        margin-left: 10px;
        padding: 10px;
        width: 361.29px;
        height: 102.81px;
        border-radius: 15px;

        &__url {
          strong {
            font-weight: 600;
            font-size: var(--fs-12);
            line-height: 30px;
            text-align: left;
            color: var(--gray-500);
          }

          font-weight: 500;
          font-size: var(--fs-12);
          line-height: 30px;
          text-align: left;
          color: var(--gray-500);
        }

        &__headline {
          max-width: 320px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 500;
          font-size: var(--fs-16);
          line-height: 29px;
          text-align: left;
          color: var(--primary);
        }
        &__description {
          font-weight: normal;
          font-size: var(--fs-12);
          line-height: 14px;
          text-align: left;
          color: var(--gray-400);
          margin-right: 5px;
          max-width: 320px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
 */
