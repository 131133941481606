$width: clamp(15px, 2vw, 20px);

.loader {
  min-width: $width;
  max-width: $width;
  height: $width;
  max-height: $width;
  border: 3px dotted currentColor;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  animation: rotation 0.8s linear infinite;
  aspect-ratio: 1;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
