.aayn-ad-preview-tiktok-single {
  position: relative;

  .aayn-ad-preview-placeholder {
    height: 690px;
  }

  &_image {
    width: 100%;
    height: 100%;

    img {
      min-height: 690px;
      object-fit: cover;
      border-radius: 32px 32px 0 0;
    }
  }

  &_content {
    color: var(--white);
    position: absolute;
    bottom: 0;
    width: calc(100% - 40px);
    padding: 0 23px 0 23px;
    height: 287px;
    background-image: linear-gradient(0, #000000 0%, rgba(0, 0, 0, 0) 100%);

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: start;
    }

    &__right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 26.2px;
    }

    &__business_name {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    &__headline {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 16px;
    }

    &__cta {
      border: 1px solid var(--white);
      border-radius: 13px;
      padding: 16px 8px;
      font-weight: 600;
      font-size: 16px;
    }

    &__logo {
      img {
        background-color: var(--white);
        width: 56px;
        height: 56px;
        border-radius: 28px;
        border: 1px solid var(--gray-50);
      }
    }
  }
}
