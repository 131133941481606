.connect-google-ads-account-modal {
  width: 853px;
  max-width: 853px;
  height: 352px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--white);
  box-shadow: 0px 10px 30px rgba(63, 78, 136, 0.08);
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  align-items: center;

  .modal-close {
    color: var(--gray-500);
  }

  &--content {
    padding: 67px 53px 66px 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--title {
    font-weight: 600;
    font-size: var(--fs-30);
    line-height: var(--fs-30);
    color: var(--primary);
    margin-bottom: 3px;
    line-height: 40px;
    text-align: center;
  }

  &--description {
    font-weight: normal;
    font-size: var(--fs-18);
    line-height: 30px;
    color: var(--gray-400);
    text-align: center;
  }

  &--buttons {
    margin-top: 22px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  }
}
