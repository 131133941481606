.aayn-ad-preview-linkedin-carousel {
  &_header {
    font-size: var(--fs-14);
    line-height: 20px;
    color: var(--gray-500);
    padding: 13px 19px 13px 27px;
    overflow-wrap: break-word;
    min-height: 60px;
  }

  .ayn-carousel-slide img {
    flex: 1;
  }

  &_slide {
    padding-left: 27px;

    &-item {
      display: flex;
      background-color: var(--body-bg);
      border-radius: 20px;
      flex-direction: column;
      width: 312px;
      margin-right: 15px;
      justify-content: space-between;

      &__image {
        max-height: 312px;
        max-width: 312px;
        object-fit: contain;
        border-radius: 20px 20px 0 0;
      }

      &__content {
        padding: 15px 15px 15px 24px;

        &__labels {
          display: flex;
          justify-content: space-between;
          gap: 25px;
        }

        &__body {
          margin-top: 10px;
          font-size: var(--fs-16);
          font-weight: bold;
          line-height: 18px;
          color: var(--gray-500);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: start;
        }
      }
    }
  }
}
