.aayn-library {
  max-width: 1497px;
  width: auto;
  max-height: 817px;

  .upload-ads-images--container {
    padding-top: 0;
    padding-bottom: 25px;
  }

  &-body {
    [right] {
      display: flex;
      align-items: center;
      flex: 1;

      .aayn-library--selected-files {
        font-weight: bold;
        font-size: var(--fs-16);
        line-height: 32px;
        color: var(--gray-500);
        margin: 0 23px;
      }

      ayn-form-field {
        margin-left: auto;
        width: auto;

        .form-field--inner {
          width: 274px;
        }
      }
    }

    ayn-tab {
      .ayn-tab {
        border: none;
      }

      .ayn-tab--default {
        border-radius: 20px 20px 0 0;
        background: rgba(var(--white-rgb), 0.4);
        @include darkModeBgDefault;

        li.active {
          background-color: var(--primary);
          color: var(--white);
          box-shadow: 0 10px 40px rgba(var(--primary-rgb), 0.4);
        }
      }
    }

    ayn-tab-item {
      .aayn-library--images {
        padding: 49px 29px 21px;
        display: flex;
        align-content: flex-start;
        gap: 15px;
        flex-wrap: wrap;
        max-height: 550px;
        overflow: auto;

        @include scrollbar;
      }
    }
  }

  &--pagination {
    width: 88px;
    height: 56px;
    border-radius: 20px;
    background: var(--white);
    border: 1px solid var(--gray-25);
    box-shadow: 0px 20px 80px rgba(var(--gray-rgb), 0.06);
    padding: 4px 5px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-btn {
      width: 36px;
      height: 46px;
      border-radius: 16px 10px 10px 17px;
      background: transparent;
      border: 1px solid var(--gray-25);
      box-shadow: 0px 20px 80px rgba(var(--gray-rgb), 0.06);
      color: var(--gray-400);
      display: flex;
      align-items: center;
      justify-content: center;

      &:last-child {
        border-radius: 10px 16px 17px 10px;
        margin-left: 5px;
      }

      &:hover {
        color: var(--primary);
        cursor: pointer;
      }

      &[disabled],
      &.disabled {
        color: var(--gray-400);
      }
    }
  }
}
