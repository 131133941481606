.aayn-smart-campaign__tiktok-identity {
  .aayn-ad-type-section--header {
    margin-bottom: 14px;
  }
  .aayn-ad-type-section--icon {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
  }
  .aayn-ad-type-section--info {
    margin-bottom: 0;
  }
}
