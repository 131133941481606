.aayn-header {
  width: calc(100% + 80px);
  height: 90px;
  background: var(--gray-25);

  filter: drop-shadow(0px 10px 50px rgba(var(--primary-rgb), 0.06));
  border-radius: 30px 30px 0 0;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  margin-top: -40px;
  margin-left: -40px;
  padding: 0 34px 0 37px;

  &--left {
    display: flex;
    justify-items: center;
    align-items: center;

    ayn-icon {
      margin-right: 7.5px;
      color: var(--primary);

      svg {
        min-width: 32px;
        height: 32px;
      }
    }

    h4 {
      font-weight: 600;
      font-size: var(--fs-30);

      line-height: 32px;
      text-align: left;
      color: var(--primary);
    }

    span {
      margin-left: 20px;
      font-weight: normal;
      font-size: var(--fs-20);

      line-height: 20px;
      text-align: left;
      color: var(--gray-500);
    }
  }

  &--right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
