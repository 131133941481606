.ayn-month-picker {
  &--btn {
    width: 162px;
    height: 56px;
    border-radius: 15px;
    background: var(--white);
    border: 1px solid #dbdff2;
    font-family: SF Pro Display;
    font-weight: 500;
    font-size: var(--fs-16);

    line-height: 32px;
    text-align: left;
    color: var(--gray-500);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    cursor: pointer;
  }

  &--body {
    &-inner {
      width: 359px;
      height: 275px;
      border-radius: 30px;
      background: var(--white);
      box-shadow: 0px 5px 99px rgba(var(--gray-rgb), 0.08);
    }
  }

  &--header {
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--gray-100);

    &__selected-year {
      font-family: SF Pro Display;
      font-weight: 600;
      font-size: var(--fs-24);

      line-height: 1;
      color: var(--primary);
      display: inline-block;
      margin: 0 15px;
    }

    &__decrement-btn,
    &__increment-btn {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  &--main {
    padding: 24px 10px 27px;

    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 9px;

      &-item {
        font-family: SF Pro Display;
        font-weight: 500;
        font-size: var(--fs-16);

        line-height: 40px;
        height: 40px;
        width: 77px;
        text-align: center;
        color: var(--gray-500);
        transition: 0.2s all;
        border-radius: 20px;

        &__active {
          background: var(--primary);
          color: var(--white);
        }

        &:not(&__active):hover {
          cursor: pointer;
          background: var(--gray-50);
        }
      }
    }
  }
}
