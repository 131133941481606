.ayn-tab--ad-preview-google {
  border: none;
  margin-top: 28px;
  border-radius: 0;
}

.aayn-ad-preview-google-search {
  position: relative;
  height: 710px;

  &_header-placeholder {
    background: url('/assets/images/svgs/create-ads/google-search-header.svg') no-repeat top center white;
    background-size: contain !important;
    height: 146.766px;
  }

  &_placeholder-box {
    padding: 15px 20px;
    border: 1px solid var(--gray-50);
    border-radius: 15px;
  }

  &-content {
    display: flex;
    gap: 16px;
    flex-direction: column;
    padding: 0 23px 30px 23px;
  }

  &--texts {
    border-radius: 15px;
    padding: 15px 20px;
    border: 1px solid var(--gray-50);
    margin-top: 16px;

    &__url {
      strong {
        font-weight: 600;
        font-size: var(--fs-12);
        text-align: left;
        color: var(--gray-500);
      }

      font-weight: 500;
      font-size: var(--fs-12);
      text-align: left;
      color: var(--gray-500);
      margin-bottom: 8px;
    }

    &__headline {
      max-width: 320px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      font-size: var(--fs-16);
      text-align: left;
      color: var(--primary);
      margin-bottom: 10px;
    }

    &__description {
      font-weight: normal;
      font-size: var(--fs-12);
      line-height: 14px;
      text-align: left;
      color: var(--gray-400);
      margin-right: 5px;
      max-width: 320px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
