.aayn-ad-preview-linkedin-single {
  .aayn-ad-preview-content {
    min-height: 690px;
  }

  &_header {
    font-size: var(--fs-14);
    line-height: 20px;
    color: var(--gray-500);
    padding: 13px 19px 13px 27px;
    overflow-wrap: break-word;
  }

  &_image.full-width {
    max-height: 408px;
    max-width: 408px;
    object-fit: contain;
    border-radius: 0;
  }

  &_content {
    padding: 15px 16px 32px 27px;
    background-color: var(--body-bg);

    &__link {
      font-size: var(--fs-14);
      line-height: 20px;
      color: var(--gray-500);
    }

    &__labels {
      display: flex;
      justify-content: space-between;
      align-items: start;
    }

    &__body {
      font-size: var(--fs-16);
      font-weight: bold;
      line-height: 18px;
      color: var(--gray-500);
      margin-bottom: 10px;
    }

    &__cta {
      border-radius: 13px;
      border: 1px solid var(--primary);
      color: var(--primary);
      padding: 8px 16px;
      font-size: var(--fs-16);
      font-weight: 500;
      max-height: 35px;
      white-space: nowrap;
    }
  }
}
