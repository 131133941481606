.upload-ads-images {
  min-width: 1084px;
  max-width: 1084px;

  &--header {
    display: flex;
    align-items: center;
    padding: 0 35px 0 40px;
    min-height: 105px;
    margin-bottom: 26px;
    box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.06);

    @extend .bg-primary--sexy;
    border: 1px solid var(--gray-300);
    border-radius: 29px 29px 0 0;

    position: relative;
    overflow: hidden;

    &-button {
      margin-left: auto;
      margin-right: 40px;
      color: var(--primary);

      &:hover {
        color: var(--white);
      }
    }

    &__left {
      color: var(--white);
      display: flex;
      align-items: center;

      ayn-icon {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(var(--white-rgb), 0.1);
        border-radius: 100%;
        margin-right: 14px;
        color: var(--white);

        @include darkModeColorDefault;
      }

      h4 {
        font-weight: 600;
        font-size: var(--fs-25);
        color: var(--gray-500);
        line-height: 32px;
        text-align: left;
        display: flex;
        align-items: center;
      }

      p {
        font-size: var(--fs-14);
        color: var(--gray-500);
        line-height: 20px;
        text-align: left;
      }
    }

    ayn-modal-close {
      ayn-icon {
        color: var(--white);

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  &--group {
    &-header {
      &.not-fluid {
        max-width: 90%;
        margin: 0 auto;

        h5 {
          padding-left: 0;

          span {
            &:last-child {
              right: 0;
            }
          }
        }
      }

      h5 {
        position: relative;
        padding-left: 28px;
        padding-right: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > span:last-child {
          font-weight: 500;
          cursor: pointer;
        }

        p {
          padding-left: 11px;
          font-size: var(--fs-14);
          line-height: 20px;
          text-align: left;
          color: var(--gray-400);
          font-weight: 500;
          position: relative;
          top: 4px;

          & + span {
            margin-top: 7px;
          }
        }

        span {
          display: inline-flex;
          align-items: center;
          z-index: 2;
          position: relative;
          padding: 0 11px 0 11px;
          font-size: var(--fs-16);
          line-height: 32px;
          color: var(--gray-500);

          ayn-icon {
            margin-left: 21px;
            max-width: 25px;
          }
        }

        .divider {
          width: 100%;
          height: 2px;
          background: var(--gray-100);
          z-index: 0;
          flex: 1;
        }

        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 28px;
          top: 50%;
          transform: translateY(-50%);
          height: 2px;
          background: var(--gray-100);
          z-index: 0;
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }
      }

      p {
        font-size: var(--fs-14);
        line-height: 20px;
        color: var(--gray-400);
        padding-left: 84px;
      }
    }
  }

  &--container {
    margin-left: 24px;
    margin-right: 24px;
    background-color: rgba(var(--white-rgb), 0.4);

    border-radius: 20px;
    border: 1px solid var(--white);
    margin-bottom: 27.1px;
  }

  &--body {
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    gap: 20px;

    .aayn-file-upload-item {
      background-color: var(--white);
    }
  }

  &-main-body:not(.without-scroll) {
    max-height: 684px;
    overflow: auto;

    aayn-upload-image-modal--header:not(:first-child) {
      .upload-ads-images--group-header {
        padding-top: 35px;
      }
    }

    aayn-upload-image-modal--header {
      &:first-child {
        .upload-ads-images--group-header {
          padding-top: 15px;
        }
      }
    }

    @include scrollbar();
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 62px 16px 58px;
  }
}

@include media-breakpoint-down(md) {
  .upload-ads-images {
    min-width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .upload-ads-images {
    &--header {
      flex-wrap: wrap;
      padding: 20px;

      &__left--title p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 20px);
      }

      &-button {
        margin-top: 10px;
        margin-left: 60px;
      }
    }
  }

  .upload-ads-images--group-header h5 {
    display: inline-block;
  }
}
