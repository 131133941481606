.aayn-empty-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px 0;

  ayn-lottie {
    display: flex;
    margin: 10px auto;
    width: 102px;
    height: 114px;
  }

  h4 {
    font-weight: 600;
    font-size: var(--fs-20);

    line-height: 32px;
    text-align: center;
    color: var(--accent);
  }

  p {
    font-weight: normal;
    font-size: var(--fs-14);

    line-height: 20px;
    text-align: center;
    color: var(--gray-400);
  }

  .btn {
    height: 45px;
    margin: 10px auto;
  }

  &.dashboard {
    width: 100%;
    max-width: 500px;
    background: var(--white);
    border-radius: 30px;
    filter: drop-shadow(0px 10px 50px rgba(var(--primary-rgb), 0.06));
    padding: 40px;

    ayn-lottie {
      display: inline-flex;
      justify-content: center;
      height: 50%;
    }
  }

  &.clickfraud {
    h4 {
      color: var(--primary);
    }

    width: 508px;
    height: 252px;
    border-radius: 30px;
    background: var(--white);
    box-shadow: 0px 10px 50px rgba(var(--gray-rgb), 0.06);
    filter: drop-shadow(0px 10px 50px rgba(var(--primary-rgb), 0.06));
    padding: 42px 42px 42px 42px;

    ayn-lottie {
      display: inline-flex;
      justify-content: center;
      margin: 0 auto;
      width: 68px;
      height: 68px;
    }
  }
}
