aayn-select-platform-campaign-item {
  display: flex;
  flex: 1;

  .aayn-select-platform-campaign-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 170px;
    width: 100%;
    min-width: 250px;
    border-radius: 15px;
    background: rgba(var(--white-rgb), 0.6);
    border: 1px solid var(--white);
    padding: 0 40px;
    position: relative;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      min-width: 668px !important;
    }

    &:not(&__selected):hover {
      background: var(--white);
    }

    &--status {
      position: absolute;
      left: 40px;
      top: 20px;
      display: inline-flex;
      align-items: center;

      &__icon {
        width: 10px;
        height: 10px;
        color: var(--gray-500);
        margin-right: 5px;

        svg {
          max-width: 10px;
          max-height: 10px;
        }
      }

      &__text {
        font-weight: 500;
        font-size: var(--fs-12);
        line-height: 18px;
        color: var(--gray-500);
      }
    }

    &-content {
      &--title {
        font-weight: 600;
        font-size: var(--fs-20);
        line-height: 32px;
        text-align: left;
        color: var(--gray-500);
      }

      &--description {
        font-size: var(--fs-14);
        line-height: 20px;
        text-align: left;
        color: var(--gray-500);
        max-width: 472px;
      }

      &--beta {
        border-radius: 16px;
        background: var(--primary-10);
        padding: 4px 11px;
        place-items: center;
        font-weight: 500;
        font-size: var(--fs-12);
        color: var(--primary);

        @include darkModeColorDefault;

        margin-left: 9px;
        top: -4px;
        white-space: nowrap;
        line-height: 1;
        position: relative;
      }
    }

    &--buttons {
      position: relative;
      min-width: 40%;
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        display: none;
      }

      &__bg-image {
        position: absolute;
        top: 0;
        right: -40px;
        width: calc(100% + 40px);
        height: 100%;
        object-fit: cover;
        z-index: 0;
      }

      &__icon {
        z-index: 1;
        position: relative;

        svg-icon {
          display: block;

          svg {
            height: 100%;
          }
        }
      }
    }

    &__disabled {
      opacity: 0.4;
      cursor: default;
    }

    &__selected {
      background: var(--primary);
      border-color: var(--primary);
      color: var(--white);

      .aayn-select-platform-campaign-item {
        &-content {
          &--title {
            color: var(--white);

            @include darkModeColorDefault;
          }

          &--description {
            color: var(--white);

            @include darkModeColorDefault;
          }

          &--beta {
            background-color: var(--white);
            color: var(--primary);

            @include darkModeColorDefault;
          }
        }
      }
    }
  }
}

.aayn-select-platform-item {
  min-width: 300px;
  height: 182px;
  border-radius: 20px;
  border: 1px solid var(--white);
  padding: 30px;
  flex: 1;
  background: rgba(var(--white), 0.4);
  box-shadow: 0 0 2rem #3f4d8814;

  position: relative;

  display: flex;
  flex-direction: column;

  &--status {
    position: absolute;
    right: 24px;
    top: 23px;
    display: inline-flex;
    align-items: center;
    color: var(--gray-500);

    &__icon {
      width: 10px;
      height: 10px;
      color: var(--primary);

      @include darkModeColorDefault;

      margin-right: 5px;

      svg {
        max-width: 10px;
        max-height: 10px;
      }
    }

    &__text {
      font-weight: 500;
      font-size: var(--fs-12);
      line-height: 18px;
      text-align: right;
      color: var(--primary);

      @include darkModeColorDefault;
    }
  }

  ayn-icon {
    color: var(--gray-500);
  }

  &--title {
    font-weight: 600;
    font-size: var(--fs-18);
    line-height: 30px;
    text-align: left;
    color: var(--gray-500);
    margin: 6px 0;
  }

  &--description {
    font-weight: normal;
    font-size: var(--fs-14);
    line-height: 20px;
    text-align: left;
    color: var(--gray-500);
  }

  &.selected {
    background: var(--accent);
    color: var(--white);

    @include darkModeColorDefault;

    .aayn-select-platform-item {
      &--status {
        &__icon {
          display: none;
        }

        &__text {
          color: var(--white);
        }
      }

      &--title {
        color: var(--white);

        @include darkModeColorDefault;
      }

      &--description {
        color: var(--white);

        @include darkModeColorDefault;
      }
    }
  }

  &.coming-soon {
    opacity: 0.4;
    cursor: default;

    .aayn-select-platform-item {
      &--status {
        &__icon {
          color: var(--gray-500);
        }

        &__text {
          color: var(--gray-500);
        }
      }
    }
  }

  &.connect-first {
    .aayn-select-platform-item--status {
      &__text {
        color: var(--primary);

        @include darkModeColorDefault;
      }
    }
  }

  &:not(.coming-soon, .selected):hover {
    background: var(--white);
    border: 1px solid var(--white);
    cursor: pointer;
    transition: 0.2s all;
  }
}

@include media-breakpoint-down(md) {
  .aayn-create-ad--item--platform-header {
    flex-direction: column;
    align-items: flex-start;

    &__description {
      margin-left: 0px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .aayn-create-ad--item--container {
    margin: 10px;
  }

  aayn-select-platform-campaign-item .aayn-select-platform-campaign-item {
    padding: 30px;
    min-width: 220px;
    height: auto;
  }
}
