.stepper--block {
  width: 100%;
}

.stepper {
  display: flex;

  li {
    display: flex;
    align-items: flex-start;
    padding: 0 0.9375rem 0 3.125rem;
    transition: 0.2s;
    cursor: pointer;
    position: relative;

    .stepper--inner {
      display: flex;
      align-items: center;
      width: 100%;
      color: var(--gray-400);
      margin-top: 0.25rem;

      .stepper--status-icon {
        margin-left: auto;
        color: var(--gray-200);
      }
    }

    &:first-child {
      padding-left: 0.75rem;
      margin-left: 0 !important;
    }

    &:not(:first-child) {
      margin-left: 100px !important;
    }

    .stepper--index {
      border-radius: 50%;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
    }

    .stepper--icon {
      max-width: 1.375rem;
      max-height: 1.4375rem;
      background: transparent;
      margin-right: 0.4375rem;

      svg {
        max-width: 1.375rem;
        max-height: 1.4375rem;
      }
    }

    .stepper--title {
      font-weight: 500;
      font-size: var(--fs-18);

      line-height: 2rem;
      text-align: left;
      margin-left: 13px;
    }

    .stepper--description {
      font-weight: 500;
      font-size: var(--fs-14);

      line-height: 2rem;
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: 0.6875rem;
    }

    &:not(:first-child) {
      &:before {
        content: '';
        background-color: var(--gray-50);
        position: absolute;
        left: -25%;
        display: block;
        width: 60px;
        height: 2px;
        top: 20px;
        opacity: 0.6;
      }

      .stepper--description {
        margin-left: -30px;
      }
    }

    &:not(.not-set) {
      .stepper--icon {
        svg {
          * {
            fill: var(--white);
          }
        }
      }

      .stepper--inner {
        .stepper--status-icon {
          color: var(--white);
        }
      }
    }

    &.not-set {
      .stepper--index {
        background-color: var(--gray-400);
      }

      .stepper--title {
        color: var(--gray-400);
      }

      .stepper--description {
        color: var(--gray-400);
      }
    }

    &.passed {
      .stepper--index {
        background-color: var(--gray-500);
      }

      .stepper--title {
        color: var(--gray-500);
      }

      .stepper--description {
        color: var(--gray-500);
      }
    }

    &.active {
      .stepper--index {
        background-color: var(--primary);
      }

      .stepper--title {
        color: var(--primary);
      }

      .stepper--description {
        color: var(--primary);
      }
    }
  }
}
