.aayn-ad-preview--meta-single {
  &_header {
    font-size: var(--fs-14);
    line-height: 20px;
    color: var(--gray-500);
    padding: 13px 19px 13px 27px;
    overflow-wrap: break-word;
  }

  &_image.full-width {
    max-height: 408px;
    max-width: 408px;
    object-fit: contain;
    border-radius: 0;
  }

  &_content {
    padding: 15px 16px 32px 27px;

    &__link {
      font-size: var(--fs-14);
      line-height: 20px;
      color: var(--gray-500);
    }

    &__labels {
      display: flex;
      justify-content: space-between;
    }

    &__body {
      margin-top: 10px;
      font-size: var(--fs-16);
      font-weight: bold;
      line-height: 18px;
      color: var(--gray-500);
    }

    &__description {
      margin-top: 7px;
      font-size: var(--fs-14);
      font-weight: normal;
      line-height: 18px;
      color: var(--gray-500);
    }

    &__cta {
      background-color: var(--body-bg);
      border-radius: 13px;
      padding: 16px 20px;
      font-size: var(--fs-16);
      font-weight: 500;
      max-height: 48px;
      white-space: nowrap;
      color: var(--gray-500);
    }
  }
}
