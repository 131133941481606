.aayn-payment-price-table {
  border-radius: 30px;
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 1px solid var(--white);
  box-shadow: 0px 10px 30px rgba(63, 78, 136, 0.08);
  padding: 11px 9px;
  margin: 0 24px 30px;

  &--row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    height: 40px;
  }

  &--col {
    flex: 1;
    font-weight: 500;
    font-size: var(--fs-14);
    color: var(--gray-500);
    padding: 0 29px 0 35px;
    white-space: nowrap;

    &:last-child {
      text-align: right;
    }

    .\:from,
    .\:to {
      font-weight: 500;
      font-size: var(--fs-16);
      line-height: 32px;
      color: var(--gray);
      display: inline-flex;
      align-items: center;

      ayn-icon {
        margin: 0 8px;

        svg {
          height: 10px;
        }
      }
    }

    .\:to {
      color: var(--gray-500);
      justify-content: flex-end;
      font-weight: 500;
    }
  }

  $parent: &;

  &--inner {
    border-radius: 20px;
    background: var(--white);
    border: 1px solid var(--white);
    box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
    padding: 0 12px;

    #{$parent}--row {
      height: 56px;
      color: var(--gray-500);

      &:nth-child(even) {
        background: rgba(234, 238, 255, 0.4);
      }

      &:last-child {
        margin-bottom: 12px;
      }
    }

    #{$parent}--col {
      padding: 0 17px 0 23px;
      font-weight: 500;
      font-size: var(--fs-16);
      color: inherit;
    }
  }
}
