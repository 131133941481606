.aayn-ad-preview-youtube {
  position: relative;
  height: 710px;

  .aayn-ad-preview-content {
    padding: 10px 10px 0 10px;
  }

  &--image {
    margin-bottom: 10px;
    position: relative;
    height: 220px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url('/assets/images/svgs/create-ads/youtube-play.svg');
      height: 45.729px;
      width: 64.622px;
      box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
    }

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 120px;
      height: 3.83px;
      background: #ffe200;
    }

    img,
    .aayn-ad-preview-placeholder {
      border-radius: 25px 25px 0 0;
      width: 100%;
      height: 220px;
      object-fit: cover;
    }

    &_skip {
      position: absolute;
      right: 0;
      bottom: 25px;
      width: 105px;
      height: 42px;
      background-color: var(--gray-500);
      border: 1px solid var(--gray-200);
      border-radius: 6px 0 0 6px;
      color: var(--white);
      --icon-color: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 14.5px;
      font-size: var(--fs-14);
      font-weight: 500;

      span {
        color: var(--white);
      }

      .ayn-icon {
        color: var(--white);

        svg {
          fill: var(--white);
        }
      }
    }
  }

  &--button {
    position: absolute;
    right: 38px;
    top: 220px;
    width: 104.96px;
    height: 42.19px;
  }

  &--content {
    padding: 0 10px;

    &_header {
      display: flex;
      justify-content: space-between;

      &__left {
        display: flex;
        gap: 8px;
      }
    }
  }

  &--logo {
    height: 42px;
    width: 42px;

    img {
      border-radius: 6px;
      width: 100%;
      height: 100%;
    }
  }

  &--texts {
    height: 42px;
    display: flex;
    flex-direction: column;
    align-items: start;
    max-width: 160px;
    gap: 5px;

    &__title {
      font-weight: 500;
      font-size: var(--fs-16);
      text-align: left;
      color: var(--gray-500);
      display: inline-block;
      white-space: nowrap;
      overflow: hidden !important;
      max-width: 160px;
      text-overflow: ellipsis;
    }

    &__url {
      font-weight: 500;
      font-size: var(--fs-12);
      text-align: left;
      color: var(--gray-400);
      line-height: 20px;
      overflow: hidden !important;
      max-width: 160px;
      text-overflow: ellipsis;
      white-space: nowrap;

      &-ad_info {
        background-color: #ffe200;
        padding: 3px;
        color: var(--gray-600);
        font-weight: 600;
      }
    }
  }

  &--cta {
    background-color: var(--accent);
    padding: 13px 25px;
    border-radius: 6px;
    color: var(--white);
  }

  &--description-placeholder {
    background: url('/assets/images/svgs/create-ads/youtube-description-placeholder.svg') no-repeat top center !important;
    width: 364px;
    height: 57px;
    margin-top: 25px;
  }

  &--footer {
    margin: 21px -10px 0 -10px;
    background: url('/assets/images/svgs/create-ads/youtube-placeholder.svg') no-repeat top center white;
    background-size: contain !important;
    height: 200px;
  }
}
