.aayn-ad-form-input {
  display: flex;
  justify-content: space-between;
  min-height: 80px;
  border-radius: 25px;
  background: rgba(var(--white-rgb), 0.3);
  border: 1px solid var(--white);
  box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
  align-items: center;
  padding: 10px 10px 10px 30px;
  margin-top: 20px;

  &__label {
    display: inline-flex;
    align-items: center;

    ayn-icon {
      margin-right: 10px;
      color: var(--gray-500);
    }

    label {
      display: inline-flex;
      flex-direction: column;
      min-width: 100%;
      font-size: var(--fs-20);
      font-weight: 600;
      line-height: 1.6;
      color: var(--gray-500);
      margin-right: 227px;

      span {
        font-weight: normal;
        font-size: var(--fs-14);
        line-height: 20px;
        color: var(--gray-500);

        strong {
          color: var(--primary);
        }
      }
    }
  }

  &__content {
    display: block;
    width: 65%;
    margin-left: auto;

    ayn-radio {
      width: 100%;

      &:not(:first-child) {
        margin-left: 20px;
      }
    }

    ayn-form-field {
      width: 100%;

      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .aayn-ad-form-input {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    align-items: normal;

    &__label label {
      margin-right: 90px;
    }

    &__content {
      margin-top: 10px;
      width: 100%;

      ayn-radio {
        .select label {
          display: inline-block;
          width: max-content;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }

        &:not(:first-child) {
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }
  }

  .aayn-select-objective--row {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .aayn-ad-form-input {
    flex-direction: column;
    padding: 20px;
    align-items: normal;

    &__content {
      margin-top: 10px;
      width: 100%;
    }
  }
}
