aayn-audience-gender-selection {
  height: 100%;
}

.aayn-audience--gender-selection {
  display: flex;
  height: 100%;

  [ayn-button] {
    height: 100%;
    min-height: 150px;
    border-radius: 20px;
    flex: 1;
    box-shadow: none;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    background: var(--white);
    border: 1px solid var(--white);
    color: var(--gray-500);

    ayn-icon {
      color: var(--gray-500);
    }

    ayn-icon {
      margin: 0 0 14px;

      svg-icon {
        margin: 0;
      }

      svg {
        width: 34px;
        max-height: max-content;
      }
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:not(.active):hover {
      background: rgba(0, 0, 0, 0.02);
    }

    &.active {
      background: var(--primary);
      border: 1px solid var(--primary);
      color: var(--white);

      @include darkModeColorDefault;

      ayn-icon {
        color: var(--white);

        @include darkModeColorDefault;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .aayn-audience--gender-selection {
    display: flex;
    height: 100%;

    [ayn-button] {
      width: 60px;
      min-height: 100px;
    }
  }
}
