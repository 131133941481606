.aayn-review-and-publish--instant-ad {
  .aayn-review-and-publish--main-col {
    min-height: unset;
  }
  aayn-audience-item {
    flex: 1;
  }

  &__destination {
    background: rgba(var(--white-rgb), 0.6);
    border: 1px solid var(--white);

    &:has(input:focus) {
      border-color: var(--white);
    }

    label {
      font-weight: 500;
      font-size: var(--fs-16);
    }

    input {
      text-align: end;
      background: transparent;
      color: var(--primary);
      font-size: var(--fs-18);
      font-weight: 600;
      padding-right: 0;
      &.focused {
        color: var(--gray-500);
      }
    }
    .btn {
      margin: 8px 10px 8px 18px;
      min-width: 40px;
      width: 40px;
      height: 40px;
      border-radius: 15px;
      &:hover {
        box-shadow: none;
      }
    }

    .btn--light-gray {
      padding: 10px 8px;

      &:hover {
        background: var(--primary);
        color: var(--white);
      }
      svg {
        width: 17.5px;
      }
    }

    .btn--accent {
      padding: 9px 10px 9px 11px;
      margin-left: 14.8px;
      &:hover {
        background-color: var(--primary);
      }
    }
  }
}
