@import './config';

@import './utilities/';

@import './mixins/';

@import './base';

@import './overrides';

html,
body,
* {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
}
