@mixin file-upload-aspect-ratio($width, $height) {
  width: $width * 1px;
  height: $height * 1px;

  --file-border-width: #{$width * 1px};
  --file-border-height: #{$height * 1px};

  img.aayn-file-upload-item--selected_image {
    width: $width * 1px;
    height: $height * 1px;
  }
}

aayn-file-upload-item {
  position: relative;
}

aayn-file-upload-item--border {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

.aayn-file-upload-item {
  --error-color: var(--primary);
  --file-border-width: 177px;
  --file-border-height: 177px;
  --file-border-color: var(--primary);
  --dashArray: 5;
  --radius: 19.5;

  [data-theme='dark'] & {
    --file-border-color: var(--gray-500) !important;
    --error-color: var(--gray-500) !important;
  }

  width: 177px;
  height: 177px;
  position: relative;
  overflow: hidden;
  background-size: auto;
  border-radius: 20px;

  &--border {
    width: var(--file-border-width);
    height: var(--file-border-height);
    object-fit: cover;
    object-position: center;
    position: absolute;
    z-index: 0;

    &_group {
      stroke-dasharray: var(--dashArray);
    }

    rect {
      rx: var(--radius);
    }
  }

  &__single:not(&__selected) {
    padding: 59px 130px 58.7px;
  }

  &__has-error {
    --file-border-color: var(--orange);
  }

  &.aspect-ratio-1\:1 {
    @include file-upload-aspect-ratio(177, 177);
  }

  &.aspect-ratio-1\.5\:1 {
    @include file-upload-aspect-ratio(177, 118);

    img:not(.aayn-file-upload-item--selected_image),
    ayn-lottie,
    .-or-text {
      display: none;
    }
  }

  &.aspect-ratio-1\.78\:1 {
    @include file-upload-aspect-ratio(178, 100);

    img:not(.aayn-file-upload-item--selected_image),
    ayn-lottie,
    .-or-text {
      display: none;
    }
  }

  &.aspect-ratio-1\.33\:1 {
    @include file-upload-aspect-ratio(178, 133);

    .-or-text {
      display: none;
    }
  }

  &.aspect-ratio-1\:1\.91 {
    @include file-upload-aspect-ratio(178, 339);
  }

  &.aspect-ratio-4\:5 {
    @include file-upload-aspect-ratio(177, 221);
  }

  &.aspect-ratio-9\:16 {
    @include file-upload-aspect-ratio(177, 314.6);
  }

  &.aspect-ratio-2\:3 {
    @include file-upload-aspect-ratio(177, 265.5);
  }

  &.aspect-ratio-1\.91\:1 {
    @include file-upload-aspect-ratio(176, 92);

    img:not(.aayn-file-upload-item--selected_image),
    ayn-lottie,
    .-or-text {
      display: none;
    }
  }

  &.aspect-ratio-4\:1 {
    @include file-upload-aspect-ratio(400, 100);

    label {
      flex-direction: row;
      justify-content: space-around;

      > * {
        margin: 0 !important;
      }
    }
  }

  &__large-1\:1 {
    @include file-upload-aspect-ratio(430, 430);

    label img {
      min-height: 80px !important;
      height: auto !important;
      min-width: 97.85px !important;
    }

    &:has(.aayn-file-upload-item--custom-content) {
      .-or-text,
      .-upload-text {
        display: none !important;
      }

      .-canva-btn {
        width: 100%;
        height: 56px;
        font-size: var(--fs-16);
        border-radius: 15px;
        background: linear-gradient(90deg, var(--primary) 0%, var(--accent) 100%);
        display: flex;
        justify-content: space-between;
      }

      .aayn-file-upload-item\:hover,
      .aayn-file-upload-item--selected_image {
        width: 100%;
        height: 100%;
      }
    }

    &:has(.aayn-file-upload-item--custom-content-footer) {
      .ayn-file-upload--footer-btn {
        height: 56px;
      }
    }

    .aayn-file-upload-item--custom-content-footer {
      width: 100%;
    }

    .aayn-file-upload-item--custom-content {
      .title {
        font-size: var(--fs-18);

        line-height: 32px;
        text-align: center;
        color: var(--gray-500);
        margin-top: 13px;
        font-weight: 600;
      }

      .recommend-text {
        display: block;
        text-align: center;
        color: var(--gray-400);
        font-weight: 500;
      }

      .description {
        font-size: var(--fs-16);
        line-height: 20px;
        text-align: center;
        color: var(--gray-400);
        margin-top: 13px;
      }

      .footer-text {
        font-weight: 600;
        font-size: var(--fs-18);

        line-height: 32px;
        text-align: center;
        color: var(--gray-500);
        margin-bottom: 12px;
      }
    }
  }

  &--add-more {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-image: none !important;
    color: var(--gray-500);

    &:hover {
      background-color: var(--primary-10);
      color: var(--primary);
      border: 1px solid var(--primary);
    }

    &-icon {
      svg {
        width: 29px;
        height: 29px;
      }
    }
  }

  input {
    display: none;
  }

  &__selected-image {
    display: none;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  label {
    font-size: var(--fs-16);

    line-height: 18px;
    text-align: center;
    color: var(--gray-500);
    padding: 20px 12px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    z-index: 1;
    position: relative;

    .-or-text {
      font-size: var(--fs-14);

      line-height: 18px;
      text-align: center;
      color: var(--gray-400);
      margin-top: 6px;
    }

    .-canva-btn {
      font-size: var(--fs-12);

      line-height: 18px;
      text-align: center;
      color: var(--white);

      @include darkModeColorDefault;

      white-space: nowrap;
      width: 150px;
      min-height: 30px;
      border-radius: 10px;
      margin-top: 14px;

      ayn-icon {
        margin-right: 4px;
      }

      .btn--icon {
        margin: 0;
      }
    }

    img,
    ayn-lottie {
      min-height: 44px;
      max-width: 44px;
      margin-bottom: 8px;
    }

    ayn-lottie {
      margin: unset;
      margin-bottom: 8px;
    }

    .aayn-file-upload-item--upload-progress {
      display: block;
      margin: 8px auto 0;
      font-size: var(--fs-16);

      line-height: 32px;

      color: var(--primary);
    }

    ayn-progressbar {
      display: block;
      margin: 0 4px;

      .ayn-progressbar.millennium {
        min-width: 144px;
        width: 144px;
        height: 6px;

        .ayn-progressbar-value {
          height: 100%;
        }
      }
    }
  }

  &__selected {
    background: none;

    img {
      display: block;
      max-width: 100%;
      object-fit: cover;
    }

    label {
      display: none;
    }

    &:hover {
      .aayn-file-upload-item\:hover {
        opacity: 1;
        visibility: visible;
        transition: 0.1s all;
      }
    }
  }

  $parent: &;

  &__aspect-dynamic {
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &\:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    transition: 0.1s all;
    background-color: rgba(var(--black-rgb), 0.7);
    opacity: 0;
    visibility: hidden;
    cursor: pointer;

    [ayn-button] {
      margin: 0;
      width: 56px;
      height: 56px;
      border: 1px solid var(--white);
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .btn--icon {
        margin-left: 0;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  & + &--primary-icon {
    color: var(--error-color);
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(20%, -20%);
    z-index: 10;
  }

  & + &--trash-icon {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--orange);
    transform: translate(20%, -20%);
    border-radius: 50%;
    padding: 7.5px;
    z-index: 10;
    color: var(--white);
    @include darkModeColorDefault;
    cursor: pointer;
    transition: background-color 0.3s linear;

    &:hover {
      background-color: lighten($orange, 10);
    }

    svg {
      width: 12.65px;
      height: 13.24px;
    }
  }

  .disabled {
    color: var(--gray-400) !important;
  }
}
