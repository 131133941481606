.aayn-carousel-card-container {
  position: relative;

  ayn-file-upload-area {
    display: block;
    margin-bottom: 20px;
  }

  &.collapsed {
    display: flex;
    flex-direction: row;
    align-items: center;

    .aayn-ad-type--carousel-container--form--button {
      display: none;
    }
  }

  .aayn-ad-type--carousel-container--form--button[disabled] {
    background: none;
    border: none;
    color: var(--gray-400);

    path {
      fill: var(--gray-400);
    }
  }

  &--preview {
    &-image {
      width: 100px;
      border-radius: 20px;
      position: relative;
      overflow: hidden;
      border: 1px solid var(--gray-200);
      margin-right: 15px;
    }

    &-inputs {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }

  &--move-button {
    border: none;
    border-radius: 15px;
    height: 62px;
    width: 25px;
    background: var(--gray-150);
    cursor: pointer;
    margin-right: 20px;
    transition: background-color 0.3s linear;

    svg-icon {
      justify-content: center;
    }

    &:hover {
      background: var(--primary);

      circle {
        fill: var(--white);
      }
    }
  }

  &--button-container {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 15px;

    &__collapse-button {
      border: none;
      border-radius: 15px 0 0 15px;
      height: 62px;
      width: 25px;
      background: var(--gray-150);
      cursor: pointer;
    }

    &__preview-button {
      border-radius: 15px;
      background: rgba(var(--white-rgb), 0.3);

      &.active {
        color: var(--accent);
        background: var(--white);
      }

      &.btn.has-icon {
        padding: 0 1rem;
      }

      svg-icon {
        margin: 0;
      }
    }
  }
}

.carousel-drag--preview {
  border: 1px solid var(--gray-200);
  border-radius: 25px;
  background-color: var(--white);
  padding-left: 25px;
  position: relative;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.aayn-ad-type-section.aayn-carousel-card-collapsed {
  padding-bottom: 13px;
  padding-top: 13px;
  margin-bottom: 0;
  padding-right: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
