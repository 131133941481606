.aayn-request-custom-offer {
  header {
    h5 {
      margin-top: 40px;
    }

    p {
      padding: 0 80px;
    }
  }

  .aayn-form {
    margin: 0 26px;

    [ayn-input] {
      border: none;
      box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
    }

    [ayn-button] {
      margin: 8px auto 0;
      display: flex;
    }
  }
}
