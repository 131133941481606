@mixin title {
  font-weight: 600;
  font-size: var(--fs-18);
  line-height: 32px;
  text-align: left;
  color: var(--gray-500);
}

@mixin description {
  font-size: var(--fs-14);
  line-height: 20px;
  text-align: left;
  color: var(--gray-500);
}

@mixin content-center {
  align-items: center;
  justify-content: center;
  justify-items: center;
}

@mixin setting-card {
  border-radius: 25px;
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 1px solid var(--white);
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));
  padding: 35px;
}

.aayn-settings {
  display: flex;
  border-radius: 30px;
  padding: 40px 42px 40px;

  @include media-breakpoint-down(md) {
    padding: 10px;
  }

  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 1px solid var(--white);
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));
  flex-direction: column;
  height: 100%;

  .ayn-tab {
    border: 1px solid var(--white);
  }

  &-account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 30px;
    overflow: auto;

    @include media-breakpoint-down(md) {
      gap: 10px;
      padding: 10px;
    }

    &-row {
      display: flex;
      width: 100%;
      gap: 30px;
      @include media-breakpoint-down(md) {
        gap: 10px;
      }
      flex-wrap: wrap;

      > * {
        flex: 1;
      }
    }

    &--adspend-detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 100%;
      min-width: 410px;

      @include setting-card();

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 19px;
      }

      &-title {
        font-weight: 600;
        font-size: var(--fs-18);
        color: var(--gray-500);
        display: flex;
        align-items: center;

        strong {
          color: var(--primary);
          margin-left: 6px;
        }
      }

      &-description {
        font-size: var(--fs-14);
        color: var(--gray-500);
        margin-top: 10px;
      }

      &-active-spend {
        font-weight: 500;
        font-size: var(--fs-20);
        color: var(--gray-500);
        display: flex;
        align-items: center;

        b {
          color: var(--primary);
          font-size: var(--fs-30);
          margin-right: 6px;
        }
      }

      ayn-progressbar {
        width: calc(100% + 10px);
        position: relative;
        left: -5px;
      }
    }

    &--dark-mode {
      width: 100%;
      height: 100%;
      min-width: 410px;

      min-height: 135px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
      }
      @include setting-card();

      &__title {
        @include title();
      }

      &__description {
        @include description();

        display: inline-block;
        max-width: 270px;
      }

      ayn-lottie {
        height: 44.39px;
        margin-right: 0px;
        cursor: pointer;
        animation-duration: 4s;
      }

      ayn-badge {
        @include media-breakpoint-down(md) {
          margin-top: 15px;
        }

        .ayn-badge {
          background: rgba(var(--primary-rgb), 0.1) !important;

          span {
            color: var(--primary);
          }
        }
      }
    }

    &--personal-information {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      width: 100%;
      height: 100%;
      min-width: 410px;

      @include setting-card();

      &__title {
        @include title();
      }

      &__description {
        @include description();
      }

      &__file {
        display: inline-flex;
        @include content-center();
        width: 140px;
        height: 140px;
        border-radius: 20px;
        border: 1px solid var(--white);
        background: var(--white);
      }

      &__fileupload {
        display: inline-flex;
        @include content-center();
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23B3C1D4FF' stroke-width='2' stroke-dasharray='3%2c5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        flex-direction: column;
        width: 125px;
        height: 125px;
        border-radius: 15px;
        transition: 0.2s all;

        &:hover {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23333' stroke-width='2' stroke-dasharray='3%2c5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        }

        &__file_select {
          display: inline-flex;
          @include content-center();
          flex-direction: column;
          color: var(--gray-500);
          cursor: pointer;
        }

        input {
          display: none;
        }

        &__img {
          width: 110px;
          height: 110px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            border-radius: 15px;
            border: 1px solid var(--gray-200);
            object-fit: contain;
          }

          .btn {
            position: absolute;
            top: 27px;
            left: 27px;
            width: 56px;
            height: 56px;
            display: none;

            ayn-icon {
              margin-left: -12px;
            }
          }

          &:hover {
            img {
              opacity: 0.1;
              cursor: pointer;
            }

            .btn {
              display: flex;
              justify-content: center;
            }
          }
        }

        span {
          &:not(:last-child) {
            font-weight: 600;
            font-size: var(--fs-16);
            line-height: 20px;
            margin-top: 4px;
            color: var(--gray-500);
          }

          font-size: var(--fs-14);
          line-height: 20px;
          text-align: left;
          color: var(--gray-400);
        }
      }

      &__form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        &-inputs {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1;
          margin-left: 20px;

          &--item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            margin-top: 10px;

            button {
              border-radius: 28px;
              justify-content: center;
            }
          }
        }
      }
    }

    &--change-password {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      min-height: 285px;
      min-width: 410px;

      box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
      @include setting-card();

      &__password {
        margin-top: 27px;
        margin-bottom: 20px;
      }

      &__password-confirm {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        button {
          margin-left: 12px;
          border-radius: 28px;
          justify-content: center;
        }
      }

      &__title {
        @include title();
      }

      &__description {
        @include description();
      }
    }

    &--not-happy,
    &--cancelled-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      box-shadow: 0px 10px 30px rgba(var(--primary-rgb), 0.04);
      @include setting-card();

      &__title {
        @include title();
      }

      &__description {
        @include description();
      }

      &__button {
        border-radius: 28px;
        justify-content: center;
      }
    }

    &--cancelled-section {
      &__title {
        color: var(--orange);
      }

      &__description {
        margin-right: 20px;
        display: block;
      }
    }
  }
}
