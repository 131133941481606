@each $key, $color in $colors {
  .color\:#{$key} {
    color: #{$color} !important;
  }
}

@each $key, $color in $colors {
  .bg\:#{$key} {
    background-color: #{$color} !important;
  }
}
