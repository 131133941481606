.ayn-tab {
  width: 100%;
  border-radius: 30px;
  border: 1px solid var(--white);
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));

  &--unstyled {
    display: flex;
    justify-content: space-between;
    width: 100%;

    li {
      display: inline-flex;
      justify-content: center;
      margin: 0.9375rem;
    }

    .active {
      background-color: var(--gray-100);
    }
  }

  &--default {
    display: flex;
    justify-content: space-between;
    background: var(--gray-50);
    width: 100%;
    padding: 15px;
    border-radius: 30px 30px 0 0;
    outline: 1px solid var(--gray-50);

    li {
      height: 3.5rem;
      min-width: 8.125rem;
      border-radius: 1.25rem;
      padding: 0 0.8125rem;
      display: inline-flex;
      align-items: center;
      transition: 0.2s;
      margin: 0.3125rem;
      box-shadow: 0rem 0.3125rem 1.875rem rgba(var(--gray-rgb), 0.06);
      justify-content: space-evenly;

      span {
        font-weight: 600;
        font-size: var(--fs-16);
        color: var(--gray-500);
        line-height: 2rem;
        text-align: center;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
      }

      &:not(.active) {
        cursor: pointer;
        background-color: var(--white);
        color: var(--gray-400);
      }

      &:hover {
        color: var(--gray-500);
      }

      &.active {
        background-color: var(--white);
        color: var(--primary);
      }
    }
  }

  &--millennium {
    display: flex;
    justify-content: space-between;
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    width: 100%;
    padding: 15px;
    border-radius: 30px 30px 0 0;
    border-bottom: 1px solid var(--white);

    @include media-breakpoint-down(lg) {
      align-items: center;
      justify-content: center;
    }

    li {
      height: 3.5rem;
      min-width: 8.125rem;
      border-radius: 28px;
      padding: 0 0.8125rem;
      display: inline-flex;
      align-items: center;
      transition: 0.2s;
      margin: 0.3125rem;
      box-shadow: 0rem 0.3125rem 1.875rem rgba(var(--gray-rgb), 0.06);
      justify-content: center;
      background: var(--white);
      border: 1px solid var(--white);

      span {
        font-size: var(--fs-16);
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        justify-content: center;
        margin-left: 5px;
        margin-right: 5px;
        color: var(--gray-500);
      }

      &:not(.active) {
        cursor: pointer;
        background-color: var(--white);
        color: var(--primary);
      }

      &.active {
        background-color: var(--primary);
        color: var(--white);

        span {
          color: var(--white);

          @include darkModeColorDefault;
        }
      }
    }
  }

  &--primary {
    border-bottom: 1px solid rgba(var(--gray-rgb), 0.3);
    padding-left: 32px;
    padding-right: 32px;
    margin-bottom: 22px;

    ul {
      display: flex;
      justify-content: center;

      li {
        padding: 18px 30.6px 18px 29px;
        border-radius: 20px 20px 0 0;
        height: 56px;
        border: solid 1px rgba(var(--gray-rgb), 0.3);
        border-bottom: none;
        margin: 0 20px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        cursor: pointer;
        color: var(--gray-500);

        path:not(#Path_11014),
        circle {
          fill: var(--gray-500);
        }

        svg {
          margin-right: 8.5px;
        }

        &.active {
          background-color: var(--accent);
          color: var(--white);
          @include darkModeColorDefault;

          path:not(#Path_11014),
          circle {
            fill: var(--white);

            [data-theme='dark'] & {
              fill: var(--gray-500);
            }
          }
        }

        &.disabled {
          background-color: var(--gray-300);
          color: var(--white);

          path:not(#Path_11014),
          circle {
            fill: var(--white);
          }
        }
      }
    }
  }
}

.ayn-tab__validate {
  .ayn-tab {
    ul {
      li {
        svg {
          margin-right: 0;
        }

        &.ayn-tab--li__invalid:not(.active) {
          color: var(--gray-500);

          ayn-icon,
          svg,
          path,
          circle,
          g {
            fill: var(--gray-500) !important;
          }
        }

        &.ayn-tab--li__invalid {
          position: relative;

          /* Circle */
          .ayn-tab--li__invalid-circle {
            height: 9px;
            width: 9px;
            border-radius: 50%;
            background-color: var(--orange);
            position: absolute;
            top: 1px;
            right: 2px;

            &::before,
            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border: 1px solid var(--orange);
              border-radius: 50%;
            }

            &::before {
              animation: ripple 2s linear infinite;
            }

            &::after {
              animation: ripple 2s linear 1s infinite;
            }
          }
        }
      }
    }
  }
}

.ayn-tabs {
  li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.6);
    opacity: 1;
  }
  100% {
    transform: scale(1.9);
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .ayn-tab--primary ul li {
    padding: 10px;

    ayn-icon {
      margin-left: 8px;
    }

    span {
      display: none;
    }
  }
}
