.aayn-audience--age-range {
  &-main {
    display: flex;
    align-items: flex-end;
  }

  &-label {
    font-weight: 600;
    font-size: var(--fs-16);

    color: var(--gray-500);
  }

  ayn-slider {
    flex: 1;
    display: block;
    margin: 48px 10px 0;
  }

  &-footer {
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px 0 29px;
    font-size: var(--fs-16);
    color: var(--primary);

    @include darkModeColorDefault;

    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background: var(--white);
    border-radius: 0 0 27px 27px;

    b {
      font-weight: 600;
      font-size: var(--fs-20);
    }
  }
}

@media only screen and (max-width: 600px) {
  .aayn-audience--age-range {
    min-height: 150px;
  }
}
