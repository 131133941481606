// Position
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 0.75rem;
  left: 0.75rem;
}

.toast-top-right {
  top: 149px;
  right: 0;
}

.toast-bottom-right {
  right: 0.75rem;
  bottom: 149px;
}

.toast-bottom-left {
  bottom: 0.75rem;
  left: 0.75rem;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 18.75rem;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

// Toast
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;

  .ngx-toastr {
    position: relative;
    overflow: hidden;
    padding: 1.375rem 2.65rem 1.375rem 1.75rem;
    border-radius: 0.9375rem 0rem 0rem 0.9375rem;
    pointer-events: auto;
    outline: 0;
    margin-top: 0.625rem;

    .toast-title {
      font-family: SF Pro Display;
      font-weight: 600;
      font-size: var(--fs-25);
      display: none;
      line-height: 2rem;
      text-align: left;
      color: var(--white);
    }

    .toast-message {
      font-family: SF Pro Display;
      font-weight: normal;
      font-size: var(--fs-14);
      line-height: 1.25rem;
      text-align: left;
      color: var(--white);
      max-width: 21.875rem;
      margin-left: 2.5rem;

      ayn-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    .toast-message a:hover {
      text-decoration: underline;
    }

    .toast-close-button {
      position: absolute;
      top: 1.75rem;
      left: 1.75rem;
      font-size: var(--fs-30);
      font-weight: normal;
      background: transparent;
      color: var(--white);
      border: 0;
      padding: 0;
      width: 0.875rem;
      height: 0.875rem;
      background: url('/assets/images/icons/cross.svg') no-repeat center center;
      background-size: cover;
      filter: brightness(0) invert(1);

      [data-theme='dark'] & {
        filter: brightness(4) invert(1);
      }

      span {
        display: none;
      }
    }

    .toast-button {
      margin-top: 0.3125rem;

      button {
        width: 5.3125rem;
        height: 3rem;
        border: none;
        border-radius: 0.875rem;
        background: var(--white);
        justify-content: center;
        text-align: center;
        align-items: center;

        font-weight: 600;
        font-size: var(--fs-16);

        line-height: 2rem;
        color: var(--gray-500);
      }
    }

    .toast-progress {
      position: absolute;
      left: 5.875rem;
      bottom: 0.6937rem;
      height: 0.3681rem;
      width: 6.25rem;
      max-width: 19.3325rem;
      border-radius: 0.1837rem;
      background: var(--white);
      box-shadow: 0rem 0.625rem 3.125rem rgba(var(--gray-rgb), 0.06);
    }

    .toast-close-button:hover,
    .toast-close-button:focus {
      color: #000000;
      text-decoration: none;
      cursor: pointer;
      opacity: 0.75;
    }
  }

  .ngx-toastr:hover {
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.15);
    opacity: 1;
    cursor: pointer;
  }
}

.toast-success {
  background-color: var(--gray-500);
}

.toast-error {
  background-color: var(--gray-500);
}

.toast-info {
  background-color: var(--gray-500);
}

.toast-warning {
  background-color: var(--gray-500);
}

.toast-container .ngx-toastr .toast-close-button:hover,
.toast-container .ngx-toastr .toast-close-button:focus {
  color: #fafbfd;
}

// Responsive Design
@media all and (max-width: 15rem) {
  .toast-container .ngx-toastr.div {
    padding: 0.5rem 0.5rem 0.5rem 3.125rem;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 15.0625rem) and (max-width: 30rem) {
  .toast-container .ngx-toastr.div {
    padding: 0.5rem 0.5rem 0.5rem 3.125rem;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 30.0625rem) and (max-width: 48rem) {
  .toast-container .ngx-toastr.div {
    padding: 0.9375rem 0.9375rem 0.9375rem 3.125rem;
    width: 25em;
  }
}
