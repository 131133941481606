.aayn-image-crop {
  max-width: 608px;
  min-width: auto !important;

  .upload-ads-images--header__left {
    ayn-icon svg {
      height: 25px;
      width: 25px;
    }
  }

  &--header {
    height: 100px;
    background: var(--gray-25);
    filter: drop-shadow(0px 10px 50px rgba(var(--gray-rgb), 0.12));
    padding: 0 34px;
    border-radius: 30px 30px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-weight: 600;
      font-size: var(--fs-25);

      line-height: 32px;
      text-align: left;
      color: var(--primary);
      display: flex;
      align-items: center;

      ayn-icon {
        margin-right: 9px;
      }
    }

    p {
      font-size: var(--fs-14);
      line-height: 20px;
      text-align: left;
      color: var(--gray-400);
      padding-left: 42px;
    }

    .modal-close {
      position: relative;
      top: unset;
      right: unset;
      color: var(--gray-400);
    }
  }

  &--body {
    padding: 39px 35px 0;

    .aayn-image-crop--container {
      border-radius: 20px;
      background: var(--gray-25);
      border: 1px solid var(--gray-200);
      padding: 5px;
      //max-height: 400px;

      image-cropper {
        border-radius: 20px;
        height: 100%;
        max-height: 400px;
      }
    }
  }

  &--ratio-slider.ngx-slider {
    max-width: 255px;
    margin: 0;

    .ngx-slider-selection {
      background: var(--primary);
    }

    .ngx-slider-pointer {
      background: var(--white);
      border: 2px solid var(--primary);

      &:after {
        display: none;
      }
    }
  }

  &--multiple-ratios {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    gap: 20px;

    button {
      padding: 5px;
      flex: 1;
      justify-content: space-evenly;
      border-radius: 10px;

      .aspect-ratio {
        &-1\:1 {
          width: 34px;
          height: 34px;
          border: 2px var(--gray-500) solid;
          border-radius: 10px;
        }

        &-1\.91\:1 {
          width: 40px;
          height: 24px;
          border: 2px var(--gray-500) solid;
          border-radius: 10px;
        }

        &-9\:16 {
          width: 30px;
          height: 38px;
          border: 2px var(--gray-500) solid;
          border-radius: 10px;
        }

        &-300\:157 {
          width: 30px;
          height: 18px;
          border: 2px var(--gray-500) solid;
          border-radius: 5px;
        }

        &-375\:604 {
          width: 25px;
          height: 35px;
          border: 2px var(--gray-500) solid;
          border-radius: 5px;
        }
      }
    }
  }

  &--footer {
    padding: 0 35px 40px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    strong {
      font-weight: 600;
      font-size: var(--fs-16);
      line-height: 32px;
      text-align: left;
      color: var(--gray-500);
    }
  }

  &--end {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
  }
}
