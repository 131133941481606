.aayn-ad-preview-instagram-carousel {
  &_slide {
    margin-top: 12px;
    min-height: 690px;
    position: relative;
    .ayn-carousel {
      position: static;
    }
    &-item {
      display: flex;
      border-radius: 0;
      flex-direction: column;
      min-width: 100%;
      justify-content: space-between;

      &__image {
        min-width: 100%;
        object-fit: contain;
      }

      &__content {
        padding: 15px 15px 15px 24px;

        &__cta {
          padding: 16px 26px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: var(--primary);
          color: var(--white);
          --icon-color: var(--white);
          span {
            font-size: var(--fs-16);
            font-weight: 500;
            white-space: nowrap;
          }
        }

        &__body {
          font-size: var(--fs-16);
          text-align: start;
          color: var(--gray-500);
          &-business_name {
            font-weight: bold;
            margin-right: 6px;
          }
        }
      }
    }

    .ayn-carousel.ordered .ayn-carousel--btn_container {
      position: absolute;
      bottom: 20px;
      left: 25px;
      padding: 0;
    }
  }
}
