.aayn-user-menu--body--secure-popup {
  display: flex !important;
  flex-direction: column !important;
  min-height: 142px !important;
  width: 497.35px !important;
  border-radius: 30px;
  background: linear-gradient(var(--gray-500) 0%, var(--gray-500) 100%) !important;
  box-shadow: 0 5px 99px rgba(var(--gray-rgb), 0.08);
  padding: 25px 30px;
  border: none;
  margin-top: 0;
  top: -56px !important;
  left: -520px !important;

  .popup--inner {
    &:before {
      content: '';
      border-color: transparent transparent transparent var(--gray-500);
      border-style: solid;
      border-width: 10px 0 10px 15px;
      width: 0;
      height: 0;
      top: 46px;
      right: -52px;
      left: auto;
      background-image: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__icon {
    color: var(--white);
    margin-right: 5px;
  }

  &__title {
    font-weight: 500;
    font-size: var(--fs-16);

    line-height: 32px;
    text-align: left;
    color: var(--white);
  }

  &__description {
    font-size: var(--fs-14);

    line-height: 22px;
    text-align: left;
    color: var(--white);
  }
}
