.aayn-auth-layout {
  padding: 20px;
  background-color: var(--body-bg);
  height: 100%;

  &--inner {
    border-radius: 30px;
    border: solid 1px rgba(var(--white-rgb), 0.4);
    background: var(--primary);
    min-height: calc(100vh - 40px);

    display: flex;
    align-items: center;
    justify-content: space-between;

    .\:lines-wrapper {
      flex: 1;
      width: 33%;
      user-select: none;
      overflow: hidden;
      position: relative;

      img {
        user-select: none;
      }

      &::before {
        content: '';
        height: calc(100% - 10px);
        min-width: 195px;
        background: linear-gradient(0.25turn, transparent, #ffffff, transparent);
        display: block;
        position: absolute;
        top: 0;
        z-index: 2;
        animation: 1s linear slideToRight infinite, 2s linear infinite delay-animation;
      }

      &::after {
        content: '';
        display: block;
        height: 100%;
        min-width: 100px;
        background: linear-gradient(90deg, transparent 0%, var(--primary) 80%);
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
      }

      &.\:left {
        &::before {
          left: 0;
        }
      }

      &.\:right {
        &::before {
          right: 100%;
          animation: 1s linear slideToLeft infinite, 2s linear delay-animation infinite;
        }

        &::after {
          left: 0;
          right: auto;
          background: linear-gradient(90deg, var(--primary) 25%, transparent 100%);
        }
      }

      .\:lines {
        width: 100%;
        z-index: 2;
        position: relative;
      }
    }

    @keyframes slideToLeft {
      from {
        right: 0%;
      }
      to {
        right: 100%;
      }
    }

    @keyframes slideToRight {
      from {
        left: 0;
      }
      to {
        left: 100%;
      }
    }

    @keyframes delay-animation {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 1;
      }
      50.01% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @media (max-width: 1335px) {
      .\:lines {
        display: none;
      }
    }
  }

  &--content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    min-height: calc(100vh - 40px);
    padding-top: 109px;

    .\:logo {
      width: 220px;
    }
  }

  &--footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 33px auto;

    &_terms-of-use {
      font-size: var(--fs-14);
      line-height: 20px;
      text-align: center;
      color: var(--white);
      @include darkModeColorDefault;
      margin-top: 27px;
      display: block;
      width: 100%;

      a {
        text-decoration: underline;
        color: var(--white);
        @include darkModeColorDefault;
      }
    }
  }
}

// INNER PAGE COMPONENTS

.aayn-auth {
  position: relative;

  &--page-title {
    font-weight: 600;
    font-size: var(--fs-40);
    line-height: 1;
    text-align: center;
    color: var(--white);
    @include darkModeColorDefault;
    margin-top: 22px;
  }

  &--page-subtitle {
    font-weight: 600;
    font-size: var(--fs-24);
    line-height: 1;
    text-align: center;
    color: var(--white);
    @include darkModeColorDefault;
    margin-top: 12px;

    &.-thiny {
      font-weight: normal;
      font-size: var(--fs-18) !important;
      line-height: 28px;
      max-width: 460px;
    }

    b {
      font-weight: 600;
    }
  }

  &--form-card {
    width: 460px;
    border-radius: 25px;
    background-image: radial-gradient(circle at 50% 0, rgba(var(--white-rgb), 0.2), rgba(128, 128, 128, 0.1) 60%);
    border: 1px solid rgba(var(--white-rgb), 0.2);
    filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));
    padding: 20px 20px 0;
    margin-top: 22px;
    position: relative;
    z-index: 10;

    &.aayn-auth--email-verification {
      ayn-lottie {
        max-width: 180px;
        margin: -110px auto 0;
      }

      .aayn-auth--form-card-title {
        text-align: center;
        font-size: var(--fs-40);
      }

      p,
      button,
      a {
        font-size: var(--fs-18);
        line-height: 28px;
        text-align: center;
        color: var(--white);
        font-weight: 300;
      }

      button {
        margin: 0 auto;
        text-decoration: underline;
        display: block;
        text-align: center;
        background: transparent;
        border: none;
        outline: none;
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 27px auto 46px;
        gap: 20px;

        li {
          width: 56px;
          height: 56px;
          border-radius: 15px;
          background: #8565f2;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 1;

          &:hover {
            &::before {
              top: 64px;
            }
          }

          &::before {
            content: attr(data-text);
            position: absolute;
            top: 25px;
            left: 50%;
            transform: translateX(-50%);
            transition: 0.2s all;
            color: var(--white);
            font-size: 13px;
            z-index: 0;
            white-space: nowrap;
          }

          a {
            width: 44px;
            height: 44px;
            border-radius: 12px;
            background: #9175f3;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 2;
            cursor: pointer;
          }
        }
      }
    }

    &-title {
      font-weight: 500;
      font-size: var(--fs-18);
      text-align: left;
      color: var(--white);
      @include darkModeColorDefault;
      margin: 10px 18px 23px;
      line-height: 1;
    }

    &-footer {
      height: 49px;
      width: calc(100% + 40px);
      border-radius: 0px 0px 25px 25px;
      background: rgba(var(--white-rgb), 0.1);
      box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.04);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 14px 0 22px;
      position: relative;
      left: -20px;

      &-label {
        display: flex;
        align-items: center;
        font-size: var(--fs-14);
        line-height: 1;
        color: var(--white);
        @include darkModeColorDefault;

        ayn-checkbox {
          margin-right: 6px;

          .ayn-checkbox.default .ayn-checkbox-box {
            border-color: var(--white);
            background: #9275f4;

            &.ayn-highlight {
              background: var(--white);
            }
          }
        }
      }

      a {
        color: var(--white);
        @include darkModeColorDefault;
        text-decoration: none;
      }
    }

    ayn-form-field {
      display: block;
      margin-bottom: 20px;
    }
  }

  &--alt-footer-text {
    font-size: var(--fs-18);
    line-height: 28px;
    text-align: center;
    color: var(--white);
    @include darkModeColorDefault;
    margin-top: 53px;

    a {
      font-size: var(--fs-18);
      line-height: 28px;
      color: var(--white);
      @include darkModeColorDefault;
      text-decoration: underline;
      font-weight: 500;
    }
  }

  &--submit-button {
    margin: 30px auto 0;
    height: 56px;
    border-radius: 28px;
    background: var(--white);
    font-size: var(--fs-16);
    line-height: 32px;
    text-align: center;
    color: var(--primary);
    width: 230px;
    justify-content: center;
    display: flex;

    &.btn__icon\:loading {
      .button-bg .button-inside {
        padding-right: 0px;
      }
    }

    &[disabled] {
      color: var(--primary);
    }
  }

  .\:two-row {
    display: flex;
    justify-content: space-between;

    ayn-form-field {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  &--select {
    &.ayn-select__has-value {
      .ayn-select--btn {
        color: var(--gray-400);
      }
    }

    .ayn-select--btn {
      background: var(--white);
      border: 0.0625rem solid var(--gray-200);
      border-radius: 15px;
    }

    &.show .ayn-select--btn {
      border-radius: 15px 15px 0 0;
      border-bottom: none;
    }

    &.show:has(:not(button:focus)) {
      border-radius: 15px 15px 0 0;
    }

    ayn-overlay .ayn-overlay {
      width: 100%;
      left: 0px !important;
    }
  }

  &--logout-btn {
    position: fixed;
    bottom: 50px;
    left: 50px;
    background: transparent;
    border: none;
    outline: none;
    color: var(--white);
    display: flex;
    align-items: center;
    cursor: pointer;

    ayn-icon {
      margin-right: 10px;
    }
  }

  @media (max-width: 550px) {
    .\:two-row {
      display: block;
    }

    .aayn-auth--form-card {
      width: calc(100dvw - 80px);
      margin-left: 0;
    }
  }
}
