.aayn-language-selection--body {
  left: 0 !important;
  top: 56px !important;
  margin-top: -8px;
  background-color: var(--white);

  border-radius: 0 0 20px 20px;
  box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.08);
  width: 56px;
  max-height: 40vh;
  overflow: auto;
  @include scrollbar(5px);

  &__list {
    padding: 0 8px 9px;

    li {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      background: var(--gray-25);

      img {
        border: 1px solid transparent;
        width: 26px;
      }

      &:not(.selected):hover {
        background: darken($color: #eff3f6, $amount: 2);
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      &.selected {
        background: var(--primary);

        img {
          border-radius: 6px;
          border-color: var(--white);
        }
      }
    }
  }
}
