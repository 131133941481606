ayn-carousel {
  position: relative;
  display: block;
}

.ayn-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;

  .ayn-slides-container {
    display: flex;
    transition: transform 0.5s ease;
  }

  &-slide {
    &--dynamic-height {
      height: max-content;
    }

    flex: 0 0 100%;
    display: flex;
    text-align: center;
    transition: opacity 0.5s ease;

    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;

      &.full-width {
        width: 100%;
        object-fit: contain;
        flex: 1;
        min-width: 100%;
      }
    }
  }

  &--btn_container {
    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 1;
    display: flex;
    gap: 5px;

    .btn--carousel {
      width: 40px;
      height: 40px;
      background-color: rgba(179, 193, 212, 0.2);
      color: black;
      border: 0;
      border-radius: 15px;
      transition: all 0.2s ease-in-out;
      padding: 0;

      &:hover {
        background-color: var(--primary);
        color: var(--white);
      }
    }
  }

  $parent: &;

  &.ordered {
    #{$parent}--btn_container {
      width: 100%;
      margin: 30px 0 0 0;
      position: relative;
      justify-content: start;
      gap: 10px;
      padding: 0 12px;
    }
  }
}

.ayn-carousel--btn_container_full-image--buttons {
  width: calc(100% + 72px);
  position: absolute;
  bottom: 28px;
  right: -36px;
  z-index: 1;
  display: flex;
  justify-content: space-between;

  .btn--carousel {
    width: 36px;
    height: 48px;
    background-color: rgba(var(--white-rgb));
    border: 1px solid rgba(var(--white-rgb), 0.4);

    &:hover {
      background-color: var(--primary);
      color: var(--white);
    }

    &.ayn-carousel-prev-button {
      border-radius: 10px 0 0 10px;
    }

    &.ayn-carousel-next-button {
      border-radius: 0 10px 10px 0;
    }
  }
}
