.ayn-expandable-table {
  font-size: var(--fs-14);
  grid-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: 40px auto;
  margin: 10px auto;

  --grid-columns: 1fr;

  --first-grid-column: 0;
  --last-grid-column: 0;

  &--row,
  &--columns {
    display: grid;
    grid-template-columns: var(--first-grid-column) var(--grid-columns) var(--last-grid-column);
    justify-content: center;
    align-items: center;
  }

  &--columns {
    .ayn-expandable-table--cell {
      height: 100%;
      position: relative;

      &:not(:last-child)::after {
        background-color: var(--gray-50);
        height: 18px;
        width: 1px;
        content: '';
        position: absolute;
        right: 0;
        top: 19px;
        border-radius: 10px;
      }
    }
  }

  .ayn-expandable-table--row_children {
    .ayn-expandable-table--row {
      grid-template-columns:
        calc(var(--first-grid-column) - (10px * var(--child-level)))
        var(--grid-columns)
        calc(var(--last-grid-column) - (10px * var(--child-level)));
    }
  }

  &--changeable-form-field {
    ayn-select {
      .ayn-select--btn {
        ayn-icon[name='tick'] {
          right: 0;
        }
      }
    }
  }

  &--rows {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  &--columns {
    width: 100%;
    height: 56px;
  }

  &--row {
    background-color: var(--white);
    border-radius: 20px;
    height: 56px;
    box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.04);

    &.ayn-expandable-table--row__large-height {
      height: 70px;
    }

    .ayn-expandable-table--cell__type {
      svg {
        height: 10px;
      }
    }

    &.active-primary {
      .ayn-expandable-table--cell__type {
        color: var(--primary);
        @include darkModeColorDefault;

        svg {
          fill: var(--primary);

          [data-theme='dark'] & {
            fill: var(--gray-500);
          }
        }
      }
    }

    &.active-accent {
      .ayn-expandable-table--cell__type {
        color: var(--accent);
        font-size: var(--fs-16);

        svg {
          fill: var(--accent);
          max-width: 6px;
        }
      }
    }

    &.active {
      border-radius: 20px 20px 0 0;
      color: var(--white);
      border: 1px solid var(--primary);

      button {
        color: var(--white);
      }

      &.active-primary {
        border-color: var(--primary);
        background-color: var(--primary);
      }

      &.active-accent {
        border-color: var(--accent);
        background-color: var(--accent);
      }
    }
  }

  &--row-wrapper {
    --primary-color: 101, 55, 252;
    --accent-color: var(--accent-rgb);

    &.active {
      border: 1px solid;
      border-top: 0;
      border-radius: 20px;

      &.active-primary {
        border-color: var(--primary);
      }

      &.active-accent {
        border-color: var(--accent);
      }
    }

    &.border-opacity {
      &.active-primary {
        border-color: rgba(var(--primary-color), 0.5);
      }

      &.active-accent {
        border-color: rgba(var(--accent-color), 0.5);
      }
    }

    .opacity-50 {
      filter: blur(1px);
    }
  }

  &--row_children {
    padding: 10px;
  }

  &--cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-items: center;
    display: flex;
    padding: 8px;
    position: relative;
    color: var(--gray-500);

    &_align__center {
      justify-content: center;

      span {
        text-align: center;
      }
    }

    &_align__right {
      justify-content: flex-end;

      span {
        text-align: right;
      }
    }

    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    strong {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    span {
      min-height: 15px;
      color: var(--gray-500);
    }

    ayn-form-field .form-field {
      border-radius: 0;
      box-shadow: none;
      height: auto;
    }

    .ayn-select {
      border: none;
      border-radius: 0;

      .ayn-select--inner {
        height: auto;
      }

      .ayn-select--btn {
        background: none;
        padding: 0;
        border-radius: 0;
      }
    }
  }

  &--pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 10px;

    ayn-form-field {
      width: unset;
    }

    &__label {
      margin-right: 10px;
      color: var(--gray-500);
    }

    &__slider {
      margin-left: 10px;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      gap: 10px;
      background: var(--white);
      border: 1px solid var(--gray-100);
      box-shadow: 0px 20px 80px rgba(var(--gray-rgb), 0.06);
      border-radius: 20px;

      &-left,
      &-right {
        background: rgba(var(--body-bg), 0.4);
        width: 35px;
        height: 46px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 20px 80px rgba(var(--gray-rgb), 0.06);
        color: rgb(33, 41, 54);
        border: 1px solid var(--gray-100);
        transition: background-color, color 0.3s linear;
        padding: 5px;

        svg {
          width: 5.801px;
          height: 9.42px;
        }

        &[disabled] {
          background: rgba(var(--body-bg), 0.4);
          color: rgb(178, 194, 214);
          cursor: not-allowed;
        }

        &:hover {
          background: rgb(33, 41, 54);
          color: var(--white);
        }
      }

      &-left {
        border-radius: 16px 10px 10px 17px;
      }

      &-right {
        border-radius: 10px 16px 17px 10px;
      }
    }

    .ayn-select__overlay--position-top.show:has(:not(button:focus)) {
      border-radius: 0 0 var(--select-border-radius) var(--select-border-radius);

      .ayn-select--btn {
        border-radius: 0 0 calc(var(--select-border-radius) - 1px) calc(var(--select-border-radius) - 1px);
      }
    }
  }
}

.ayn-expandable-table--columns__overlay {
  &.ayn-overlay--center_top {
    margin-left: -8px;
    margin-top: 5px;
  }

  &.ayn-overlay--center_bottom {
    margin-left: -8px;
    margin-top: -5px;
  }

  .ayn-select__append-body {
    border-radius: 20px;
  }

  .ayn-select--search {
    padding: 21px 15px 15px 10px;

    input {
      padding: 0;
      border-radius: 0;
    }
  }
}

.ayn-expandable-table--pagination__overlay {
  &.ayn-overlay--center_bottom {
    .ayn-select__append-body {
      border-radius: 15px 15px 0 0;
      border-top: 0.0625rem solid var(--gray-200);
    }
  }

  &.ayn-overlay--center_top {
    .ayn-select__append-body {
      border-radius: 0 0 15px 15px;
    }
  }
}

@media (max-width: 900px) {
  .ayn-expandable-table {
    &--group-content {
      overflow-x: auto;
    }

    &--row,
    &--columns {
      justify-content: flex-start;
      min-width: 750px;
    }
  }
}
