.bg-primary--sexy {
  background: var(--primary);

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -47px;
    width: 398.51px;
    height: 135.92px;
    background: linear-gradient(to top, rgba(var(--white-rgb), 0) 0%, rgba(var(--white-rgb), 0.1) 100%);
    transform: skew(-37deg, 0deg);
    z-index: 0;
  }

  & * {
    z-index: 1;
  }
}
