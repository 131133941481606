aayn-select-objective {
  display: block;
  width: 100%;

  .aayn-select-objective {
    margin: 30px 50px;

    &--header {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      &__title {
        font-weight: 600;
        font-size: var(--fs-20);
        line-height: 32px;
        color: var(--primary);

        @include darkModeColorDefault;
      }

      &__description {
        margin-left: 5px;
        font-weight: normal;
        font-size: var(--fs-14);
        line-height: 20px;
        color: var(--gray-500);
      }
    }

    &--row {
      display: flex;
    }

    aayn-ad-form-input {
      margin-bottom: 20px;
    }
  }
}

@include media-breakpoint-down(md) {
  .aayn-select-objective--header {
    flex-direction: column;
    align-items: flex-start !important;

    &__description {
      margin-left: 0 !important;
    }
  }
  .aayn-select-objective--row {
    margin-top: 10px;
    display: inline !important;
  }
}

@media only screen and (max-width: 600px) {
  .aayn-select-objective {
    margin: 10px !important;
  }

  aayn-select-objective .aayn-select-objective--row {
    margin-top: 10px;
    display: inline !important;
  }
}
