@mixin title {
  font-weight: 600;
  font-size: var(--fs-18);
  line-height: 32px;
  text-align: left;
  color: var(--gray-500);
}

@mixin description {
  font-size: var(--fs-14);
  line-height: 20px;
  text-align: left;
  color: var(--gray-500);
}

@mixin content-center {
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

@mixin setting-card {
  border-radius: 25px;
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 1px solid var(--white);
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));
  padding: 35px;
}

.aayn-settings {
  display: flex;
  border-radius: 30px;
  padding: 40px 42px 40px;

  @include media-breakpoint-down(md) {
    padding: 10px;
  }

  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 1px solid var(--white);
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));
  flex-direction: column;
  height: 100%;

  .ayn-tab {
    border: 1px solid var(--white);
  }

  &-billing {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 30px;
    gap: 30px;
    overflow: auto;

    @include media-breakpoint-down(md) {
      padding: 10px;
      gap: 10px;
    }

    &-row {
      display: flex;
      width: 100%;
      gap: 20px;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        gap: 10px;
      }
    }

    &-form-row {
      display: flex;
      width: 100%;
      gap: 20px;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;
        gap: 10px;
      }
    }

    aayn-promo-code {
      display: block;
      width: 100%;
    }

    &--promo-code {
      width: 100%;
      padding: 30px;
      border-radius: 25px;
      background: rgba(var(--white-rgb), 0.4);
      @include darkModeBgDefault;
      border: 1px solid var(--white);
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
      }

      &__icon {
        width: 56px;
        height: 56px;
        margin-right: 17px;
        border-radius: 15px;
        background: rgba(var(--primary-rgb), 0.1);
        min-width: 56px;
        position: absolute;
        top: 28px;
        left: 28px;
        display: flex;
        align-items: center;
        justify-content: center;

        ayn-icon {
          color: var(--primary);

          svg,
          path,
          g {
            fill: var(--primary) !important;
          }
        }
      }

      &__content {
        display: inline-flex;
        flex-direction: column;
        margin-left: 78px;

        @include media-breakpoint-down(md) {
          margin-left: 70px;
        }

        &-title {
          @include title();
        }

        &-description {
          @include description();

          strong {
            color: var(--primary);
          }
        }

        &-form {
          display: inline-flex;
          width: 100%;
          max-width: 664px;
          margin-right: 49px;

          @include media-breakpoint-down(md) {
            max-width: 100%;
          }

          input {
            border-radius: 20px;
          }

          button {
            margin-left: 24px;
            border-radius: 28px;
          }

          @include media-breakpoint-down(md) {
            justify-content: center;
            padding-top: 15px;
          }
        }
      }
    }

    &--billing-information-card {
      display: inline-flex;
      flex-direction: column;

      padding-left: 40px;
      padding-right: 40px;
      padding-top: 33px;
      min-width: 450px;
      width: 100%;
      border-radius: 25px;
      background: rgba(var(--white-rgb), 0.4);
      @include darkModeBgDefault;
      border: 1px solid var(--white);

      &-title {
        @include title();
      }

      &-description {
        @include description();
      }

      &__form {
        margin-top: 30px;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          margin-bottom: 0;
        }

        ayn-form-field:first-of-type {
          margin-bottom: 20px;

          @include media-breakpoint-down(md) {
            margin-bottom: 0;
          }
        }

        &-button {
          border-radius: 28px;
          justify-content: center;

          @include media-breakpoint-down(md) {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }

      aayn-credit-card {
        display: block;
        width: 100%;

        .ayn-input--payment {
          margin-top: 0;
        }
      }
    }

    &--billing-information-summary {
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      min-width: 450px;
      overflow: auto;
      @include scrollbar(5px);
      @include setting-card();

      &-title {
        @include title();
      }

      &-description {
        @include description();
      }

      &--price {
        margin-left: auto;

        .aayn-settings-billing--billing-information-summary-description {
          margin-bottom: 13px;
          display: block;
          text-align: right;
        }

        &-title {
          display: flex;
          align-items: flex-end;
        }
      }

      &-pay-as-you-grow {
        display: flex;
        align-items: center;
        padding: 30px;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 20px;
        background: var(--white);
        border: solid 1px var(--white);

        &__old-price {
          object-fit: contain;
          font-size: var(--fs-20);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: right;
          color: var(--gray-500);
          margin-right: 12px;
          position: relative;

          &::before {
            content: '';
            width: 45px;
            height: 1px;
            background: var(--gray-500);
            position: absolute;
            rotate: -20deg;
            left: -7px;
            top: 15px;
          }
        }

        &__new-price {
          min-width: 195px;
          object-fit: contain;
          font-size: 60px;
          font-weight: 500;
          line-height: 0.8;
          letter-spacing: normal;
          text-align: right;
          color: var(--gray-500);
          display: flex;
          align-items: flex-start;

          strong {
            font-size: var(--fs-20);
          }
        }
      }

      &-invoice {
        margin-top: 30px;
        position: relative;

        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          height: 56px;
          margin: 10px 0;
          padding: 8px 9px 8px 26.5px;
          border-radius: 20px;
          border: 1px solid var(--white);
          background-color: var(--white);

          &-date {
            font-weight: 500;
            font-size: var(--fs-16);
            line-height: 24px;
            text-align: left;
            color: var(--gray-500);
          }

          &-price {
            display: flex;
            color: var(--gray-500);

            @include content-center();

            ayn-icon {
              margin-left: 30px;
            }

            button {
              width: 40px;
              height: 40px;
              padding: 0 !important;
              margin-left: 20px;
              border-radius: 12px;

              ayn-icon {
                margin: 0;

                svg-icon {
                  margin: 0;

                  svg {
                    height: 30px;
                    width: 40px;
                    max-height: none !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
