.aayn-sitelink-extensions-modal {
  min-width: 823px;

  &--header {
    display: flex;
    align-items: center;
    padding: 0 35px 0 33px;
    min-height: 90px;
    margin-bottom: 26px;
    box-shadow: 0px 10px 30px rgba(var(--gray-rgb), 0.06);

    border-radius: 25px 25px 0px 0px;
    background: var(--primary);
    border: 1px solid var(--gray-300);
    color: var(--white);
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -71px;
      width: 400px;
      height: 135.92px;
      background: linear-gradient(to top, rgba(var(--white-rgb), 0) 0%, rgba(var(--white-rgb), 0.1) 100%);
      transform: skew(-37deg, 0deg);
    }

    &__content {
      display: inline-flex;
      flex-direction: column;
      margin-left: 15px;

      h4 {
        font-weight: 600;
        font-size: var(--fs-25);

        line-height: 32px;
        text-align: left;
        color: var(--white);
        @include darkModeColorDefault;
        display: flex;
        align-items: center;
      }

      p {
        font-size: var(--fs-14);
        line-height: 20px;
        text-align: left;
        color: var(--white);
        @include darkModeColorDefault;
      }
    }

    ayn-modal-close {
      ayn-icon {
        color: var(--white);
        @include darkModeColorDefault;
      }
    }
  }

  &--row {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;

    &__item {
      display: flex;
      flex-direction: column;
      flex: 1;

      .ayn-input-with-button {
        background: var(--white);
        border: 1px solid var(--white);
      }

      &:first-child {
        margin-right: 15px;
      }
    }
  }

  &--form {
    margin: 25px;
    padding: 30px;
    border-radius: 30px;
    background: rgba(var(--white-rgb), 0.4);
    @include darkModeBgDefault;
    border: 1px solid var(--white);
    filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));

    h5 {
      font-size: var(--fs-20);
      line-height: 32px;
      color: var(--gray-500);
    }
  }

  &--button {
    display: flex;
    margin: 30px auto;
  }
}
