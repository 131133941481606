.aayn-ad-preview-google-display {
  margin-top: 15px;
  height: 710px;

  &-header {
    padding: 0 23.5px 0 22.5px;
  }
  &__image {
    margin-top: 14.5px;
    width: 100%;
  }

  &--header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 23px;
    margin-left: 23px;
    padding-bottom: 60px;

    &__logo {
      width: 86px;
      height: 84px;
      border-radius: 15px;
      background: var(--white);
      border: 1px solid var(--gray-200);
      object-fit: contain;
      margin-right: 10px;
    }

    &__title {
      font-weight: 600;
      font-size: var(--fs-13);
      text-align: left;
      margin-top: 4.5px;
      margin-bottom: 4.5px;
      color: var(--gray-500);
    }

    &__sub-title {
      font-weight: 600;
      font-size: 11px;
      text-align: left;
      color: var(--gray-500);
    }

    &__description {
      font-weight: normal;
      font-size: 11px;
      line-height: 14px;
      max-width: 220px;
      text-align: left;
      color: var(--gray-400);
    }
  }
}
