aayn-create-business {
  width: 100%;
  display: block;
}

.aayn-create-business {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-down(md) {
    padding: 10px;
  }

  &--container {
    margin: auto;

    &-description {
      margin-left: 20px;
      color: var(--gray-500);
      font-size: 22px;
      line-height: 32px;
      font-weight: 600;
    }

    &-platforms {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 20px 20px;

      aayn-platform-item {
        width: 100%;
        min-width: 320px;
      }
    }

    &-advanced-settings {
      @include flex-align-center;
      padding-left: 30px;
      height: 76px;
      border-radius: 25px;
      background: rgba(var(--white-rgb), 0.6);
      border: 1px solid var(--white);
      box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.04);

      ayn-checkbox + label {
        font-size: var(--fs-16);

        color: var(--gray-500);
        margin-left: 10px;

        @include fw('semibold');
      }

      &--text {
        margin-left: 14px;
        font-weight: normal;
        font-size: var(--fs-14);
        line-height: 20px;
        text-align: left;
        color: var(--gray-500);
      }
    }

    &-footer {
      margin-top: 25px;

      @include flex-justify-between;

      &__link {
        @include flex-align-center;

        color: var(--gray-500);
        text-decoration: none;

        ayn-icon {
          margin-right: 5px;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 120px;
      }
    }
  }
}

.aayn-base-business-creation {
  &-site {
    display: flex;
    flex-direction: column;

    &-header {
      display: flex;
      margin-left: 30px;
      flex-direction: column;

      &-recommended {
        display: flex;
        align-items: center;

        .ayn-recommended {
          display: flex;
          align-items: center;
          justify-content: center;
          width: max-content;
          height: 22px;
          border-radius: 11px;
          background: var(--gray-25);
          margin-left: 15px;

          &-text {
            font-weight: 500;
            font-size: var(--fs-12);
            line-height: 32px;
            color: var(--primary);

            @include darkModeColorDefault;

            padding: 6px 10px;
          }
        }
      }

      &--title {
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        text-align: left;
        color: var(--gray-500);
      }

      &--description {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: var(--gray-500);
      }
    }

    &--input-container {
      width: 100%;
      margin-top: 20px;
      display: inline-flex;
      position: relative;

      &-button {
        border: 1px solid var(--gray-300);
        margin-left: 12px;
      }
    }

    .ayn-skeleton {
      background: none;
      border-radius: 20px;

      &::before {
        z-index: 2;
      }
    }
  }

  &-brand {
    display: flex;
    position: relative;
    margin-top: 30px;
    gap: 30px;
    flex-wrap: wrap;

    &-form {
      background: rgba(var(--white-rgb), 0.6);
      border: 1px solid var(--white);
      filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));
      padding: 26px;
      border-radius: 25px;
      width: 100%;
      flex: 1;

      &-detail {
        display: flex;
        margin-bottom: 20px;

        &__file {
          display: inline-flex;
          @include content-center();
          border-radius: 20px;
          border: 1px solid var(--white);
          background: var(--white);
          width: 250px;
          height: 236px;
          box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.04);

          &.error {
            box-shadow: 0 10px 30px 0 rgba(var(--gray-rgb), 0.08);
            border: solid 1px rgba(var(--orange-rgb), 0.6);
            background-color: rgba(var(--orange-rgb), 0.05);
          }

          &.disabled {
            border: solid 1px var(--gray-200);
            opacity: 75%;
          }
        }

        &__fileupload {
          display: inline-flex;
          @include content-center();
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23B3C1D4FF' stroke-width='2' stroke-dasharray='3%2c5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
          flex-direction: column;
          width: 218px;
          height: 205px;
          border-radius: 16px;
          transition: 0.2s all;
          box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.04);

          &:hover {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23333' stroke-width='2' stroke-dasharray='3%2c5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
          }

          &__file_select {
            display: inline-flex;
            @include content-center();
            flex-direction: column;
            cursor: pointer;
            color: var(--gray-500);
          }

          input {
            display: none;
          }

          &__img {
            width: 218px;
            height: 205px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              border-radius: 15px;
              border: 1px solid var(--gray-200);
              object-fit: contain;
            }

            .btn {
              position: absolute;
              top: 70px;
              left: 80px;
              width: 56px;
              height: 56px;
              display: none;

              ayn-icon {
                margin-left: -16px;
              }
            }

            &:hover {
              img {
                opacity: 0.1;
                cursor: pointer;
              }

              .btn {
                display: flex;
              }
            }
          }

          span {
            &:not(:last-child) {
              font-weight: 600;
              font-size: var(--fs-16);
              line-height: 20px;
              margin-top: 4px;
              color: var(--gray-500);
            }

            font-size: var(--fs-14);
            line-height: 20px;
            text-align: left;
            color: var(--gray-400);
          }
        }

        &-row {
          margin-left: 20px;
          display: flex;
          flex: 1;
          gap: 20px;
          flex-direction: column;
        }
      }

      &-color {
        display: flex;
        width: 100%;
        background: rgba(var(--white-rgb), 0.4);
        @include darkModeBgDefault;
        border: 1px solid var(--white);
        filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));
        border-radius: 25px;
        align-items: center;
        justify-content: space-between;
        padding: 12px 12px 12px 30px;
        gap: 30px;
        flex-wrap: wrap;

        &-title {
          display: flex;
          align-items: center;
          color: var(--gray-500);

          span {
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
            text-align: left;
            color: var(--gray-500);
            margin-left: 12px;
          }
        }

        &-colors {
          display: flex;
          gap: 30px;
          flex-wrap: wrap;
          flex: 1;
        }

        &.error {
          box-shadow: 0 10px 30px 0 rgba(var(--gray-rgb), 0.08);
          border: solid 1px rgba(var(--orange-rgb), 0.6);
          background-color: rgba(var(--orange-rgb), 0.05);
        }
      }
    }

    &-preview {
      background: rgba(var(--white-rgb), 0.6);
      border: 1px solid var(--white);
      filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));
      padding: 26px;
      border-radius: 25px;
      width: 498px;
      height: 393px;
      backdrop-filter: blur(30px);
      --webkit-backdrop-filter: blur(30px);
    }
  }

  &-form {
    &::before {
      content: '';
      height: 5px;
      width: calc(100% - 60px);
      background: var(--primary);
      border-radius: 0 0 5px 5px;
      position: absolute;
      top: 0;
    }

    background: rgba(var(--white-rgb), 0.6);
    border: 1px solid var(--white);
    filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));
    padding: 26px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    position: relative;

    &__text-area {
      .form-field--inner__character-number {
        top: 90% !important;
      }

      .form-field {
        ayn-icon {
          top: auto;
          bottom: 20px;
          right: 20px;
          transform: none;
        }
      }

      [ayn-input].text-area {
        height: 160px;
      }
    }
  }

  &-platforms {
    &::before {
      content: '';
      height: 5px;
      width: calc(100% - 60px);
      background: var(--accent);
      border-radius: 0 0 5px 5px;
      position: absolute;
      top: 0;
    }

    margin-top: 30px;
    background: rgba(var(--white-rgb), 0.6);
    border: 1px solid var(--white);
    filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));
    padding: 26px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    position: relative;

    &--header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .ayn-required {
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      height: 22px;
      border-radius: 11px;
      background-color: rgba(var(--orange-rgb), 0.05);
      margin-left: 15px;

      &-text {
        font-weight: 500;
        font-size: var(--fs-12);
        line-height: 32px;
        color: var(--orange);
        padding: 6px 10px;
      }
    }

    &.error {
      box-shadow: 0 10px 30px 0 rgba(var(--gray-rgb), 0.08);
      border: solid 1px rgba(var(--orange-rgb), 0.6);
      background-color: rgba(var(--orange-rgb), 0.05);

      &::before {
        background: var(--orange);
      }

      .aayn-create-business--container-description {
        color: var(--orange);
        font-weight: 600;
      }
    }

    &--error-message {
      width: 100%;
      height: 39px;
      margin-top: 20px;
      padding: 10px;
      border-radius: 20px;
      box-shadow: 0 10px 30px 0 rgba(var(--gray-rgb), 0.08);
      background-color: var(--orange);
      color: var(--white);
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 600px) {
  .aayn-create-business {
    padding: 10px;
  }

  .aayn-base-business-creation-form {
    padding: 10px;

    &--recommended {
      flex-wrap: wrap;
      gap: 10px;

      &-button {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .aayn-base-business-creation-platforms {
    padding: 10px;
  }

  .aayn-create-business--container {
    &-platforms {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    &-footer {
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}
