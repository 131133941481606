.aayn-language-selection {
  .ayn-select--btn {
    padding: 0 20px 0 25px;
  }

  ayn-option {
    .ayn-option {
      &--inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &:not(.ayn-option__disabled):hover {
        background: rgba(0, 0, 0, 0.02);
        color: var(--gray-500);
      }
    }

    [ayn-button] {
      padding: 4px !important;
      background: transparent;
      color: var(--primary);

      @include darkModeColorDefault;
    }
  }
}

.aayn-language-selection--footer {
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs-16);
  color: var(--gray-500);
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0px 35px;
  height: 69px;
  border-radius: 0px 0px 25px 25px;

  b {
    font-weight: 600;
    font-size: var(--fs-20);
    display: flex;
    align-items: center;

    ayn-icon {
      margin-left: 10px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .aayn-language-selection,
  aayn-language-selection--google,
  aayn-language-selection--bing,
  aayn-language-selection--linkedin,
  aayn-language-selection--facebook,
  aayn-language-selection--tiktok,
  aayn-language-selection--smart-campaign {
    min-height: 130px;
  }
}
