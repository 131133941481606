.gauge-chart {
  &.small {
    width: 120.52px;
    height: 72.53px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -26px;

      font-weight: normal;
      font-size: var(--fs-14);

      line-height: 25px;
      text-align: center;
      color: var(--gray-400);
    }
  }

  &.medium {
    position: relative;
    width: 389px;
    height: 200px;
    background: var(--white);
    border: 1px solid var(--gray-200);
    border-radius: 15px;

    .chart-text {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -26px;

      font-weight: normal;
      font-size: var(--fs-18);

      line-height: 48px;
      text-align: center;
      color: var(--gray-400);
    }

    .chart-desc {
      width: 389px;
      height: 28px;
      background: var(--gray-400);

      font-family: SF Pro Display;
      font-weight: normal;
      font-size: var(--fs-16);
      line-height: 32px;
      text-align: center;
      color: var(--white);
      border-radius: 0 0 15px 15px;
      right: 0;
      bottom: 0;
      position: absolute;
    }
  }
}
