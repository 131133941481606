.aayn-lecture-item {
  &--completed-title {
    color: var(--success);
  }
  &--playlist {
    height: 691px;
    overflow: hidden;
    border: 1px solid var(--gray-100);
    padding: 20px;
    border-radius: 20px;
    display: flex;
  }

  &--now-playing {
    width: calc(65% - 10px);
    margin-right: 20px;
  }

  &--video-info {
    padding-left: 22px;
    padding-top: 29px;
    position: relative;
  }
  &--video-name {
    font-size: var(--fs-25);
    line-height: 32px;
    font-style: normal;
    color: var(--gray-500);
  }
  &--video-level {
    margin-left: 11px;

    font-size: var(--fs-16);
    color: var(--primary);
  }
  &--icon {
    //!
    position: absolute;
    right: 12px;
    ayn-icon {
      color: var(--gray-400);
    }
  }
  &--video-description {
    font-size: var(--fs-14);
    line-height: 20px;
    color: var(--gray-400);
  }

  &--video-list {
    width: calc(35% - 10px);
    height: 100%;
    overflow-y: auto;
    border: 1px solid var(--gray-100);
    border-radius: 20px;
    @include scrollbar();
  }
  &--lecture-actions {
    margin-top: 28px;
    margin-bottom: 27px;
    text-align: center;
    button {
      height: 56px;
      font-size: var(--fs-16);
    }
    &--platform-name {
      font-weight: 200;
    }
    &--mark-completed {
      font-weight: 600;
    }
  }
  &--watching-video-empty {
    border: 1px solid var(--gray-100);
    background: var(--gray-500);
    width: 100%;
    height: 525px;
    border-radius: 20px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
