aayn-select-audience--loader {
  .aayn-select-audience--loader--header {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    margin: 30px 40px;
  }

  .aayn-select-audience--loader--content {
    width: 1210px;
    max-width: calc(100% - 80px);
    margin: auto;
  }
}
