html,
body {
  height: 100%;
  background: var(--gradient);
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background: var(--body-bg-img);
  background-color: var(--body-bg);
  background-attachment: fixed;
}

$topbar-index: 999;
$aside-index: 1000;

@mixin element-glass-class {
  border-radius: 20px;
  background: rgba(var(--white-rgb), 0.7);
  border: 1px solid var(--white);
  box-shadow: 0 0 2rem rgba(var(--gray-rgb), 0.08);
}

@mixin cover-glass-class {
  border-radius: 25px;
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  border: 1px solid var(--white);
  box-shadow: 0 0 2rem rgba(var(--gray-rgb), 0.08);
}

.ayn-layout {
  --aside-width: 17.25rem;

  display: flex;
  padding: 1.25rem;
  height: 100vh;

  --select-border-radius: 20px;
  --input-border-radius: 20px;

  &:has(.ayn-layout--aside__expanded) {
    --aside-width: 17.25rem;
  }

  &--aside {
    --padding: 23px;

    position: fixed;
    top: 1.25rem;
    bottom: 1.25rem;
    height: calc(100vh - 2.5rem);
    border-radius: 1.875rem;
    width: var(--aside-width);
    padding: var(--padding);
    display: flex;
    flex-direction: column;
    z-index: $aside-index;
    transition: 0.2s all;
    background-color: rgba(var(--white-rgb), 0.1);
    border: 1px solid rgba(var(--white-rgb), 0.5);
    box-shadow: 0 0 3rem rgba(var(--gray-rgb), 0.04);

    &__expanded {
      box-shadow: 0 0 50px rgba(var(--gray-rgb), 0.15);
      --aside-width: 17.25rem;

      .ayn-layout--aside-collapse {
        margin-top: 12px !important;
        width: 50px;

        .btn--icon {
          margin-left: 12px;
        }
      }

      aayn-package {
        display: block !important;
      }

      .ayn-layout--aside {
        &-logo {
          display: block;
          margin: calc(2.5625rem - var(--padding)) auto 2.8125rem;

          &-large {
            display: inline;
          }
        }

        &__lottie-logo {
          max-width: fit-content !important;
          margin-bottom: 10px !important;
        }

        &-logo-version {
          top: 72px !important;
        }

        &__lottie-logo,
        &-logo-version {
          display: block !important;
        }

        &__logo-mini {
          display: none !important;
        }

        &__create-ad {
          padding: 0 0.9375rem 0 1.25rem !important;
          min-height: 50px;
          height: 50px;

          &-text {
            display: block !important;
          }
        }

        &__menu {
          li {
            padding: 0 10px 0 6px;

            a {
              img {
                margin-left: auto;
                margin-top: 0;
                width: auto;
              }
            }
          }
        }
      }

      .aside {
        &__menu-text {
          display: block !important;
        }
      }
    }

    &-logo {
      padding: calc(10px - var(--padding)) calc(45px - var(--padding)) 45px;
      color: var(--gray-500);
      height: 130px;

      img {
        max-width: 100%;
      }
    }

    &__logo {
      display: block;
      margin: calc(2.5625rem - var(--padding)) auto 2.8125rem;
      width: 13.5625rem;
    }

    &__lottie-logo {
      max-width: fit-content !important;
      margin-bottom: 10px;
    }

    &__logo-mini {
      display: none;
    }

    &-logo-version {
      font-size: 10px;
      color: var(--primary);
      position: absolute;
      top: 64px;
      line-height: 10px;
    }

    &__create-ad {
      min-height: 3.5rem;
    }

    &__menu {
      margin-top: 49px;

      li {
        height: 50px;
        border-radius: 25px;
        background: rgba(var(--white-rgb), 0.5);
        border: 1px solid var(--white);
        padding: 0 4px 0 4px;
        margin-bottom: 15px;
        transition: 0.15s all;

        &:hover {
          background: var(--white);
        }

        &.active {
          background: var(--accent);

          a {
            color: var(--white);

            @include darkModeColorDefault;

            ayn-icon {
              background: rgba(var(--white-rgb), 0.1);
            }
          }
        }

        &.disabled {
          opacity: 0.5;
        }

        .aside__menu-text + img {
          margin-left: auto;
        }

        a {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          font-size: var(--fs-16);
          color: var(--gray-500);
          text-decoration: none;
          cursor: pointer;

          ayn-icon {
            min-width: 40px;
            height: 40px;
            background: rgba(var(--white-rgb), 0.7);
            border-radius: 50%;
            margin-right: 17px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg-icon {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            svg {
              max-width: 20px;
              max-height: 20px;
            }
          }
        }
      }
    }

    aayn-package {
      display: block;
      margin-top: auto;

      .ayn-layout--package {
        min-width: 230px;
        width: 100%;

        border-radius: 20px;
        padding: 22px;
        background-color: var(--primary);
        position: relative;
        overflow: hidden;

        &::before {
          content: '';
          display: block;
          width: 167.38px;
          height: 140px;
          background: linear-gradient(rgba(var(--white-rgb), 0) 0%, rgba(var(--white-rgb), 0.1) 100%);
          position: absolute;
          top: 9px;
          right: -90px;
          rotate: 215deg;
        }

        &-title {
          font-weight: 600;
          font-size: var(--fs-16);
          line-height: 1;
          margin-bottom: 15px;
          color: var(--white);

          @include darkModeColorDefault;
        }

        ul {
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: var(--fs-12);
            line-height: 30px;
            color: var(--white);

            @include darkModeColorDefault;

            &:last-child {
              border-top: 1px solid rgba(var(--body-bg), 0.2);
            }

            span {
              white-space: nowrap;
            }

            b {
              font-weight: 600;
              font-size: var(--fs-14);
              line-height: 28px;
            }

            .ayn-layout--package-limit-text {
              color: rgba($color: var(--white), $alpha: 0.5);
            }
          }
        }

        .ayn-progressbar {
          min-width: 100%;
          width: 100%;
          background: rgba(var(--body-bg), 0.2);
          box-shadow: 0 10px 50px rgba(var(--gray-rgb), 0.06);
          height: 5px;
          margin-top: 5px;
          border-radius: 2.5px;

          &-value {
            background: var(--white);
            height: 100%;
            border-radius: 2.5px;
            animation: unset;
          }
        }
      }

      .ayn-layout--invite-friend {
        .ayn-badge {
          margin-top: 10px;
          color: var(--white);
          height: 30px;
          display: inline-flex;
          align-items: center;
          padding: 0 11px;
          font-size: var(--fs-14);

          white-space: nowrap;
          cursor: pointer;
          transition: 0.2s;
          user-select: none;

          ayn-icon[name='add-user'] {
            padding: 0;
            margin-right: 5px;
          }
        }
      }
    }

    &-collapse {
      display: none;
      padding: 6px !important;
      border-radius: 12px;
      min-height: 50px;
      height: 50px;
    }
  }

  aayn-topbar {
    position: relative;
    display: block;

    &::before {
      content: '';
      height: 102px;
      width: 100%;
      position: fixed;
      z-index: 8;
      top: 0;
      background: var(--topbar-before-bg);
    }
  }

  &--topbar {
    position: fixed;
    top: 1.25rem;
    border: 0.125rem solid var(--white);
    width: calc(100% - var(--aside-width) - 60px);
    background: var(--bg-gray);
    border-radius: 1.875rem;
    box-shadow: 0 0.3125rem 1.5625rem rgba(var(--gray-rgb), 0.08);
    margin-bottom: 1.25rem;
    padding: 10px;
    display: flex;
    z-index: $topbar-index;

    backdrop-filter: blur(4.529359817504883px);
    --webkit-backdrop-filter: blur(4.529359817504883px);

    .aayn-business-selection {
      background: var(--white);
      width: 400px;
      height: 56px;
      border-radius: 20px;
      box-shadow: 0 5px 30px rgba($color: #3b2a82, $alpha: 0.08);
      position: relative;

      &.active {
        border-radius: 20px 20px 0 0;
      }

      &--inner {
        background: transparent;
        width: 100%;
        height: 56px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        justify-content: space-between;
        padding: 0 14px 0 27px;
        cursor: pointer;
      }

      &--name {
        font-weight: 600;
        font-size: 1rem;

        color: var(--gray-500);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 210px;
      }

      &_right {
        display: flex;
        align-items: center;
        color: var(--gray-500);

        ayn-icon {
          margin-left: 7px;
          max-width: 22px;
        }
      }

      &.disabled {
        color: var(--black);

        button {
          cursor: default;
        }

        .aayn-business-selection--name {
          color: rgb(179, 193, 212);
        }

        .aayn-business-selection_right {
          color: rgb(179, 193, 212);
        }
      }
    }

    .aayn-create-business--btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 18px 0 27px;
      min-width: 300px;
      width: 400px;
      height: 56px;
      border-radius: 20px;
      background: var(--white);
      border: 0;
      font-weight: 600;
      font-size: var(--fs-16);

      line-height: 32px;
      color: var(--primary);
      box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.08);
      cursor: pointer;
      transition: 0.15s all;

      &:hover {
        box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.2);
      }

      &:focus {
        box-shadow: 0 0 0 0.1875rem rgba(var(--accent-rgb), 0.3);
      }
    }

    .ayn-layout--right {
      margin-left: auto;
      display: flex;

      @mixin base-button {
        border: 1px solid var(--white);
        background: var(--white);
        color: var(--gray-400);
        box-shadow: 0 5px 30px rgba($color: #3b2a82, $alpha: 0.08);

        &:not(.active):hover {
          border-color: rgba($color: var(--accent), $alpha: 0.3);
        }
      }

      .aayn-layout--topbar-upgrade {
        padding: 0 1.3rem 0 1.25rem;

        svg-icon {
          margin-left: 0;
        }
      }

      .aayn-publish {
        &-btn {
          width: 140px;

          ayn-icon {
            margin-left: auto;
          }

          &.active {
            border-radius: 15px 15px 0 0;
          }
        }
      }

      .aayn-use-animations {
        display: flex;
        align-items: center;
        margin-right: 14px;
        height: 100%;

        ayn-checkbox {
          margin-left: 12px;
        }
      }

      .aayn-level {
        &-btn {
          padding: 0 13px 0 18px;
          transition: 0.2s;

          @include base-button();

          b {
            font-weight: 600;
            margin-left: 4px;
          }

          ayn-icon {
            color: var(--primary);
            margin-left: 1rem;
          }

          &.active {
            background: var(--accent);
            box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.08);
            color: var(--white);
            transition: 0.2s;

            ayn-icon {
              color: var(--white);
            }
          }
        }
      }

      .aayn-notifications {
        margin-left: 20px;
        position: relative;

        &-btn {
          width: 56px;
          height: 56px;
          padding: 0;
          justify-content: center;
          position: relative;
          transition: 0.2s;

          @include base-button();

          &.unread {
            color: var(--primary);

            &::before {
              content: '';
              display: block;
              width: 14px;
              height: 14px;
              background: var(--primary);
              border-radius: 50%;
              position: absolute;
              top: 0;
              right: 0;
            }
          }

          &.active {
            &.unread {
              &::before {
                opacity: 0;
              }
            }

            background: var(--accent);
            color: var(--white);
          }
        }
      }

      .aayn-language-selection {
        position: relative;
        margin-left: 20px;

        &--selected-btn {
          width: 56px;
          height: 56px;
          border-radius: 20px;
          outline: 0;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          @include base-button();
        }

        &.active {
          .aayn-language-selection--selected-btn {
            border-radius: 20px 20px 0 0;
          }
        }
      }

      .aayn-user-menu {
        margin-left: 20px;
        position: relative;
        max-width: 201px;

        &--btn {
          display: flex;
          align-items: center;
          border-radius: 20px;
          min-height: 56px;
          padding: 0 15px 0 9px;
          min-width: 201px;
          max-width: 201px;
          cursor: pointer;

          @include base-button();

          &_img-placeholder {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: var(--gray-100);
            min-width: 40px;
            min-height: 40px;
            border-radius: 20px;
            font-size: var(--fs-16);
            font-weight: 600;
            color: var(--gray-500);
            margin-right: 9px;
          }

          &_info {
            text-align: left;
            max-width: 115px;

            &__name {
              font-size: var(--fs-16);
              font-weight: 600;
              color: var(--gray-500);
              line-height: 1;
              margin-bottom: 5px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            &__role {
              font-size: var(--fs-12);
              color: var(--gray-500);
              line-height: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          &.active {
            border-radius: 20px 20px 0 0;
          }
        }

        ayn-icon[name='tick'] {
          display: inline-block;
          margin-left: auto;
          color: var(--gray-500);
        }
      }
    }
  }

  &--main {
    flex: 1;
    padding: 0 0 0 calc(var(--aside-width) + 1.25rem);
    max-width: 100%;
    margin-bottom: 1.25rem;
    margin-top: calc(5rem + 1.25rem);

    &-inner {
      padding: 40px 42px 40px;

      @include media-breakpoint-down(sm) {
        padding: 0;
        margin: 10px 10px 0 10px;
      }

      border-radius: 30px;
      background: var(--bg-gray);
      border: 1px solid var(--white);
      min-height: 100%;
      margin: 17px 20px 0 22px;
      display: flex;
      align-items: stretch;

      &:has(.stretched) {
        padding: 0;
        border: 0;

        .ayn-layout--main-inner__content {
          background: var(--bg-gray);
        }
      }

      > router-outlet + .ng-star-inserted {
        flex: 1;
        max-width: 100%;

        .ayn-layout--main-inner__content {
          height: 100%;

          @include media-breakpoint-down(md) {
            height: auto;
          }
        }
      }

      &__blurred {
        filter: blur(4px);
      }

      &__content {
        border-radius: 32px;
        border: 1px solid var(--white);
        filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));
        padding: 20px;
        position: relative;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1.25rem;
    }
  }

  @media (max-width: 1366px) {
    --aside-width: 74px;
    &--aside {
      border-radius: 25px;
      padding: 10px;

      &-logo {
        padding: 6px 3px 0 9px;
        margin-top: calc(42px - 13px);
        margin-bottom: 38px;
        color: var(--gray-500);

        &-large {
          display: none;
        }
      }

      &__lottie-logo,
      &-logo-version {
        display: none;
      }

      &__logo-mini {
        display: block;
      }

      &__create-ad {
        background-image: url(/assets/images/icons/create-ad.svg);
        background-repeat: no-repeat;
        background-position: center;
        padding: 1px !important;

        min-height: 50px;
        height: 50px;

        .button-bg,
        .border-gradient,
        .animation-bg {
          display: none;
        }

        &-text {
          display: none;
        }
      }

      &-collapse {
        display: block;
        margin-top: auto;
      }

      aayn-package {
        display: none;
      }

      &__menu {
        li {
          a {
            img {
              margin-left: -20px;
              width: 15px;
              margin-top: 15px;
            }
          }
        }
      }
    }

    .aside {
      &__menu-text {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .ayn-layout--main {
    flex: 1;
    padding: 0;
    margin: 0;
    max-width: 100%;

    &-inner {
      margin-bottom: 100px;
    }
  }

  .ayn-layout {
    --aside-width: 0px;
    padding: 0;
  }
}
