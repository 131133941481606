.ayn-datepicker-extend {
  position: relative;

  &--button {
    width: 186px;
    height: 56px;
    border-radius: 20px;
    background: var(--white);
    box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.08);
    border: 0;
    padding: 0 16px 0 20px;
    cursor: pointer;
    margin-left: auto;
    position: relative;
    z-index: 11;
    transition: 0.2s all;
    color: var(--gray-500);
    font-size: var(--fs-16);

    @include flex-center-between;
  }

  &--body {
    width: 641px;
    height: 382px;
    background: var(--white);
    border-radius: 20px;
    box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.08);
    display: flex;
    padding: 16px 0 15px 15px;
    margin-top: 0;
    z-index: 10 !important;
    overflow: hidden;
    left: auto !important;
    top: 0 !important;
    right: 0;

    .ayn-overlay-content {
      width: 100%;
    }

    &-inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__active {
    .ayn-datepicker-extend--button {
      border-radius: 0 20px 0 0;
      box-shadow: none;
      width: 186px;
      transition: 0.2s all;
    }
  }

  &--left {
    ngb-datepicker {
      --width: 422px;

      width: var(--width);
      height: 300px;
      border-radius: 15px;
      background: var(--white);
      border: 1px solid var(--gray-200);

      .ngb-dp-header {
        border-radius: 15px 15px 0 0;
        background: var(--primary);

        @include darkModeColorDefault;

        height: 40px;

        .ngb-dp {
          &-arrow {
            .btn {
              height: 34px;
              background-repeat: no-repeat;
              background-position: left;
              background-image: url(/assets/images/icons/chevron-left.svg);
              filter: brightness(0) invert(1);
              justify-content: flex-end;
              margin-left: 25px;
              outline: none;
            }

            .ngb-dp-navigation-chevron {
              display: none;
            }

            &.right {
              .btn {
                background-repeat: no-repeat;
                background-position: right;
                margin-right: 25px;
                background-image: url(/assets/images/icons/arrow-right.svg);
                filter: brightness(0) invert(1);
              }

              justify-content: flex-end;

              .ngb-dp-navigation-chevron {
                display: none;
              }
            }
          }

          &-arrow-btn {
            background-color: transparent;
            z-index: 1;
          }

          &-navigation-select {
            display: flex;
            flex: 1;
            pointer-events: none;

            select {
              box-shadow: none;
              border: none;
              appearance: none;

              color: var(--white);

              @include darkModeColorDefault;

              background-color: var(--primary);
              font-size: var(--fs-16);
              line-height: 34px;

              &:nth-last-child(1) {
                font-weight: bold;
              }
            }
          }
        }
      }

      .ngb-dp-weekdays {
        width: var(--width);
        background: var(--gray-100);
        border-bottom: 0;
        margin-bottom: 13px;

        .ngb-dp-weekday {
          font-size: var(--fs-14);
          color: var(--gray-500);
          width: auto;
          flex: 1;
          text-align: center;
          margin: 5px 0;
        }
      }

      .ngb-dp-day {
        width: auto;
        flex: 1;
        text-align: center;
      }

      .ngb-dp {
        &-weekday,
        &-week-number {
          font-weight: normal;
          font-size: var(--fs-14);
          line-height: 28px;
          color: var(--gray-500);
          text-align: center;
          border-radius: 0;
        }

        &-months {
          overflow: hidden;
        }

        &-week {
          display: flex;
        }

        &-day,
        &-weekday,
        &-week-number {
          flex: 1;
          height: 28px;
          font-style: normal;
          font-weight: normal;
          font-size: var(--fs-14);
          line-height: 28px;
          margin: 5px 5px 0;
        }
      }

      .ayn-datepicker-extend--day {
        cursor: pointer;

        display: inline-block;
        min-width: 48px;
        height: 28px;
        font-weight: normal;
        font-size: var(--fs-14);
        line-height: 28px;
        border-radius: 8px;

        color: var(--gray-500);
        transition: 0.2s;

        &.bg-primary {
          background-color: var(--primary) !important;
          color: var(--white) !important;
        }

        &:hover {
          background: var(--gray-400);
          color: var(--white);
        }

        &.disabled,
        &.hidden {
          cursor: default;
          pointer-events: none;
        }

        .outside {
          cursor: default;
          opacity: 1;
          background: rgba(179, 193, 212, 0.2);
          color: rgba(179, 193, 212, 0.5) !important;
        }

        &.focused {
          background-color: #e6e6e6; //TODO:Color
        }

        &.in-other-month {
          background: rgba(179, 193, 212, 0.2);
          color: rgba(179, 193, 212, 0.5) !important;
        }

        &.range,
        &:not(.disabled):hover {
          background-color: var(--primary);
          color: var(--white) !important;

          @include darkModeColorDefault;

          font-weight: 500;
        }

        &.faded {
          background-color: var(--white);
          color: var(--primary) !important;

          @include darkModeColorDefault;

          font-weight: 500;
        }
      }
    }

    .ayn-datepicker-extend--footer {
      margin-top: 12px;

      @include flex-center-between;

      &-left {
        @include flex-justify-center;
        @include flex-direction('column');

        strong {
          font-size: var(--fs-16);

          color: var(--gray-500);
          line-height: 1;

          @include fw('semibold');
        }

        p {
          font-weight: 300;
          font-size: var(--fs-14);

          margin-top: 4px;
          text-align: left;
          color: var(--gray-400);
        }
      }

      &-actions {
        display: flex;

        [ayn-button] {
          width: 107px;
          height: 46px;
          border-radius: 14px;
          justify-content: center;

          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }
  }

  &--time-sets-container {
    border-top: 1px solid var(--gray-200);
    margin-top: 40px;
    padding: 10px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 190px;
      height: calc(100% + 57px);
      top: -57px;
      left: 0;
      background: var(--white);
      box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.08);
      z-index: -1;
    }
  }

  &--time-sets {
    width: 167px;
    overflow: auto;
    max-height: 305px;

    @include scrollbar(7px);

    li {
      height: 46px;
      border-radius: 15px;
      background: var(--white);
      border: 1px solid var(--gray-300);
      padding: 0 20px;
      font-size: var(--fs-16);
      text-align: left;
      color: var(--gray-500);
      transition: 0.2s all;

      @include flex-center-between;
      @include text-ellipsis;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      &:not(.active) {
        cursor: pointer;
      }

      &:hover {
        background: rgba(var(--gray-rgb), 0.05);
      }

      &.active {
        border-color: var(--gray-500);
      }
    }
  }
}
