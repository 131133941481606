.aayn-audience--location-selection {
  &--input {
    input {
      height: 56px;
      border-radius: var(--input-border-radius);
      background: var(--white);
      border: 1px solid var(--white);

      &:hover,
      &:focus {
        border-color: var(--gray-300);
      }

      &::placeholder {
        color: var(--gray-400);
        font-weight: 500;
      }
    }

    .form-field ayn-icon svg,
    .form-field ayn-icon path,
    .form-field ayn-icon g {
      color: var(--gray-500);
    }

    &:has(&-label) input {
      padding-right: 180px;
    }

    &-label {
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      font-size: var(--fs-16);
      line-height: 24px;
      text-align: left;
      color: var(--gray-400);
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);

      ayn-icon {
        margin-left: 15px;
        position: relative !important;
        right: unset !important;
        top: unset !important;
        transform: unset !important;
      }
    }
  }

  @mixin applySelections($color) {
    &--label {
      font-weight: 600;
      font-size: var(--fs-16);
      color: $color;
      margin-bottom: 14px;
      display: block;
    }

    &--content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        margin-right: 20px;
        margin-bottom: 20px;

        ayn-badge {
          .ayn-badge {
            height: 46px;
            border-radius: 23px;
            background: $color;
            padding: 0 18px 0 20px;
            cursor: pointer;
            user-select: none;

            ayn-icon {
              svg {
                max-width: 16px;
              }
            }
          }
        }
      }
    }
  }

  &--inclusions {
    @include applySelections(var(--primary));

    [data-theme='dark'] & {
      @include applySelections(var(--gray-500));
    }
  }

  &--exclusions {
    margin-top: 10px;

    @include applySelections(var(--orange));
  }

  &--overlay {
    position: relative;
    display: block;
    width: 100%;

    &-inner {
      background: var(--white);
      top: 0 !important;
      left: 0 !important;
      margin-top: 0 !important;
      width: 100%;
      border: 1px solid var(--gray-200);
      border-radius: 0 0 var(--input-border-radius) var(--input-border-radius);
      padding: 15px 16px 19px;

      ul {
        max-height: 302px;
        overflow: auto;

        @include scrollbar(10px);

        aayn-location-selection-overlay-item {
          display: block;

          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }

        li {
          height: 56px;
          border-radius: 15px;
          background: rgba(var(--white-rgb), 0.7);
          border: 1px solid var(--gray-300);
          display: flex;
          align-items: center;
          padding: 0 32px 0 29px;

          .aayn-audience--location-selection--content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            align-items: center;
            color: var(--gray-500);
          }

          .aayn-audience--location-selection--type {
            margin-left: auto;
            margin-right: 85px;
            font-size: var(--fs-14);
            line-height: 26px;
            text-align: right;
            color: var(--gray-500);
          }

          .aayn-audience--location-selection--li-actions {
            width: 317px;
            display: flex;
            justify-content: flex-end;

            [ayn-button] {
              background: transparent;
              padding: 4px;
              color: var(--primary);
              height: auto;
              max-width: 90px;
              width: 100%;

              &:hover {
                color: var(--gray-500);
              }

              &[disabled] {
                color: var(--gray-50);
              }
            }

            .\:inclusion-btn {
              color: var(--primary);
              margin-right: 40px;
            }

            .\:exclusion-btn {
              color: var(--orange);
            }
          }

          &.active.__inclusions {
            .\:inclusion-btn {
              color: var(--primary);
            }
          }

          &.active.__exclusions {
            .\:exclusion-btn {
              color: var(--orange);
            }
          }
        }
      }
    }

    &.__for-smart-campaign {
      aayn-location-selection-overlay-item {
        cursor: pointer;
      }

      .aayn-audience--location-selection--li-actions {
        width: auto;

        .\:inclusion-btn {
          margin-right: 0;
        }
      }
    }
  }

  &__active {
    .aayn-audience--location-selection--input input {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .aayn-audience--item-loader {
    $width: 20px;

    min-width: $width;
    max-width: $width;
    height: $width;
    max-height: $width;
    border: 3px dotted currentColor;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    animation: rotation 2.4s linear infinite;
    aspect-ratio: 1;
    color: var(--gray-500);
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &--tabs {
    margin-left: -30px;
    width: calc(100% + 60px);
    z-index: 9;
    margin-top: 30px;

    ayn-tab {
      margin-bottom: 25px;

      .ayn-tab {
        border: 0;
      }

      .ayn-tab--primary {
        border-bottom-color: var(--white);
        margin: 0;

        ul {
          justify-content: flex-start;

          li {
            border: none;
            width: 158px;
            font-weight: 600;
            font-size: var(--fs-16);
            justify-content: space-between;
            padding-right: 25px;
            background: var(--white);
            color: var(--primary);

            @include darkModeColorDefault;

            ayn-icon,
            svg {
              max-height: 21px;
            }

            ayn-icon,
            svg,
            path,
            circle,
            g {
              fill: var(--primary) !important;

              [data-theme='dark'] & {
                fill: var(--gray-500) !important;
              }
            }

            &.active {
              color: var(--white);

              @include darkModeColorDefault;

              background: var(--primary);
              box-shadow: 0 10px 40px rgba(var(--primary-rgb), 0.4);

              ayn-icon,
              svg,
              path,
              circle,
              g {
                fill: var(--white) !important;

                [data-theme='dark'] & {
                  fill: var(--gray-500) !important;
                }
              }
            }
          }
        }
      }

      .ayn-tab-item {
        padding: 25px 30px 0;
      }
    }
  }

  &--add-more {
    display: inline-block;
    position: relative;

    ayn-badge ayn-icon {
      rotate: 0;
      transition: 0.2s all ease-in-out;
    }

    ayn-badge[ng-reflect-color='gray-500'] {
      ayn-icon {
        rotate: 45deg;
      }
    }

    .ayn-overlay {
      position: absolute;
      top: 70px !important;
      left: 0 !important;
      width: 540px;
      border-radius: 25px;
      background: rgba(var(--white-rgb), 0.6);
      border: 1px solid var(--white);
      box-shadow: 0 10px 30px rgba(var(--gray-rgb), 0.04);
      padding: 20px;
    }

    &--overlay-inner {
      background: var(--white);
      border-radius: 25px;

      .form-field {
        box-shadow: none;
      }

      ul {
        padding: 0 13px;
        overflow: auto;
        max-height: 222px;
        margin-top: 10px;

        li {
          height: 56px;
          border-radius: 20px;
          background: rgba(var(--white-rgb), 0.7);
          border: 1px solid var(--body-bg);
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          font-size: var(--fs-14);
          line-height: 26px;
          color: var(--gray-500);
          padding: 0 25px 0 27px;
          transform: 0.2s all;

          button {
            font-size: var(--fs-16);
            line-height: 28px;
            text-align: right;
            background: transparent;
            color: var(--gray-500);
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            padding: 0;

            ayn-icon {
              margin-left: 8px;
              color: rgba(128, 141, 172, 0.3);
            }
          }

          &:not(:last-child) {
            margin-bottom: 5px;
          }

          &.__selected {
            cursor: auto;

            button {
              color: var(--primary);

              ayn-icon {
                color: var(--primary);
              }
            }
          }

          &:hover {
            border-color: var(--gray-300);
            cursor: pointer;
          }
        }
      }
    }
  }
}
