.aayn-instant-ad--select-ai-generated {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 18px;
  row-gap: 20px;
  position: relative;

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: minmax(0, 1fr);
  }

  &_card {
    background-color: rgba(var(--white-rgb), 0.4);
    border: 1px solid var(--white);
    border-radius: 25px;
    padding: 20px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: all 0.3s ease-in-out;
    z-index: 2;

    $parent: &;

    &.selected {
      border-color: var(--primary);
      background-color: var(--white);
      position: relative;

      #{$parent}-content {
        border-color: #eaeeff;
      }
    }

    &_selected-content {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      justify-content: space-between;
      color: var(--primary);

      .selected-text {
        font-weight: 500;
        font-size: var(--fs-14);
        position: relative;
        background-color: rgba(var(--white-rgb), 0.25);
        backdrop-filter: blur(4px) brightness(100%);
        padding: 3px 6px;
        top: -10px;
        left: 20px;
      }

      svg {
        background-color: var(--white);
        border-radius: 20px;
        width: 15px;
        height: 15px;
      }
    }

    &-conversion-score {
      display: flex;
      max-width: max-content;
      gap: 9px;
      align-items: center;
      background-color: rgba(var(--gray-500-rgb), 0.1);
      border-radius: 15px;
      padding: 5px 6px;
      margin-bottom: 20px;
      color: var(--gray-500);

      &--loader {
        background-color: rgba(var(--gray-500-rgb), 0.1);
        width: 25%;
        padding: 5px 6px;
        margin-bottom: 20px;
        border-radius: 15px;
      }
    }

    &-content {
      padding: 20px;
      border-radius: 20px;
      background-color: var(--white);
      border: 1px solid var(--white);
      filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.04));
      position: relative;
      transition: all 0.3s ease-in-out;
      height: 100%;

      &_business {
        display: flex;
        align-items: center;

        &--img {
          width: 65px;
          height: 65px;
          margin-right: 13px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &--placeholder {
          width: 50px;
          height: 50px;
          background: var(--gray-200);
          border-radius: 50%;
          margin-right: 13px;
          font-weight: 500;
          font-size: var(--fs-12);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &--title {
          font-weight: 600;
          font-size: var(--fs-16);

          line-height: 22px;
          color: var(--gray-500);
        }

        &--description {
          font-weight: normal;
          font-size: var(--fs-12);
          line-height: 1;
          color: var(--gray-400);
          display: flex;
          align-items: center;

          ayn-icon {
            margin-left: 6px;

            svg {
              width: 12px;
            }
          }
        }
      }

      &_texts {
        position: relative;
        height: max-content;
        color: var(--gray-500);

        &-content {
          position: absolute;
          left: -20px;
          top: 0;
          padding: 27px 20px 0 20px;
          background: var(--white);
          width: calc(100% + 40px);
          z-index: 2;

          &:has(.ayn-skeleton) {
            padding-top: 0;
            padding-bottom: 10px;
          }

          &--see-more {
            text-decoration: none;
            font-weight: bold;
            font-size: var(--fs-16);
            color: var(--gray-200);
            margin: 10px 0;
            display: block;
            line-height: 32px;
          }

          &--more {
            display: none;
            transition: height linear 0.3s;

            &.active {
              margin-top: 10px;
              display: flex;
              flex-direction: column;
              gap: 10px;
            }
          }
        }
      }

      &_image {
        margin: 0px -20px;

        img {
          width: 100%;
        }

        .ayn-skeleton-img {
          object-fit: scale-down;
        }

        &.ayn-skeleton {
          --skeleton-border-radius: 0;
          aspect-ratio: 1;
          width: calc(100% + 40px);
        }
      }

      &_url {
        margin-top: 18px;
        color: var(--gray-400);
        font-size: var(--fs-14);
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
      }

      &_title {
        font-weight: bold;
        font-size: var(--fs-18);
        margin-top: 7px;
        line-height: 1.3;
        color: var(--gray-500);
      }

      .ayn-skeleton-text-wrapper {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .ayn-skeleton-text {
          width: 100%;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &:not(.see-more-visible):nth-last-child(-n + 4) {
        height: 310px;
        overflow: hidden;
        z-index: 0;
      }
    }

    @include media-breakpoint-between(sm, lg) {
      &:not(.see-more-visible):nth-last-child(-n + 3) {
        height: 310px;
        overflow: hidden;
        z-index: 0;
      }
    }
    @include media-breakpoint-down(sm) {
      &:not(.see-more-visible):nth-last-child(-n + 2) {
        height: 310px;
        overflow: hidden;
        z-index: 0;
      }
    }
  }

  &_see-more {
    position: absolute;
    bottom: -20px;
    left: -50px;
    background: linear-gradient(180deg, rgba(243, 246, 252, 0) 0%, rgba(243, 246, 252, 1) 100%);

    [data-theme='dark'] & {
      background: linear-gradient(180deg, rgba(24, 26, 43, 0) 0%, rgb(24, 26, 43) 100%);
    }

    height: 500px;
    width: calc(100% + 100px);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: end;

    button {
      margin-bottom: 50px;
    }
  }
}
