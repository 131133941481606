$spacer: 4px;

$spacings: (
  auto: auto,
  0: 0,
  1: 1 * $spacer,
  2: 2 * $spacer,
  3: 3 * $spacer,
  4: 4 * $spacer,
  5: 5 * $spacer
);

$rotations: (auto auto, t top, r right, b bottom, l left, x left, x right, y top, y bottom);

@each $key, $value in $spacings {
  .m\:#{$key} {
    margin: $value;
  }

  @each $rotate-key, $rotate in $rotations {
    .m#{$rotate-key}\:#{$key} {
      margin-#{$rotate}: $value;
    }
  }

  .p\:#{$key} {
    padding: $value;
  }

  @each $rotate-key, $rotate in $rotations {
    .p#{$rotate-key}\:#{$key} {
      padding-#{$rotate}: $value;
    }
  }
}

@for $i from 1 through 10 {
  .w\:#{$i * 10} {
    width: $i * 10%;
  }

  .h\:#{$i * 10} {
    height: $i * 10%;
  }
}
