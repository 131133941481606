.aayn-header-with-progressbar {
  width: calc(100% + 40px);
  height: 90px;
  border-radius: 30px 30px 0 0;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
  margin-left: -20px;
  padding: 0 34px 0 37px;
  position: relative;
  background: rgba(var(--white-rgb), 0.4);
  @include darkModeBgDefault;
  filter: drop-shadow(0px 10px 30px rgba(var(--gray-rgb), 0.08));

  --progress-percent: 0%;

  &::after {
    content: '';
    height: 1px;
    width: var(--progress-percent);
    background: var(--primary);
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.2s all;
  }

  &--left {
    display: flex;
    justify-items: center;
    align-items: center;

    ayn-icon {
      margin-right: 13px;
      color: var(--gray-500);

      svg {
        min-width: 30px;
        height: 30px;
      }
    }

    &-content {
      display: flex;

      &__title {
        font-weight: 600;
        font-size: var(--fs-30);
        color: var(--gray-500);
      }

      &__description {
        margin-left: 20px;
        font-weight: 600;
        font-size: var(--fs-20);
        line-height: 1;
        margin-top: 6px;
        color: var(--gray-500);
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;

        &__description {
          margin-left: 0;
        }
      }
    }
  }
}
