.create-ad-complete {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--white);
  filter: drop-shadow(0px 10px 50px rgba(var(--primary-rgb), 0.06));
  border-radius: 30px;

  h4 {
    font-weight: 600;
    font-size: var(--fs-40s);
    line-height: 32px;
    color: var(--gray-500);
  }

  h3 {
    font-weight: 600;
    font-size: 50px;
    line-height: 32px;
    color: var(--primary);
    margin-top: 16px;
  }

  p {
    font-weight: 600;
    font-size: var(--fs-20);
    line-height: 32px;
    text-align: center;
    color: var(--gray-500);
    margin-top: 22px;
  }

  &--footer {
    width: 542px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 33px;

    [ayn-button] {
      flex: 1;
      width: max-content;
      max-width: max-content;

      &[ayn-button='accent'] {
        margin-right: 20px;
      }
    }
  }
}
