@mixin shimmer-button() {
  --border-color: var(--gray-500);
  --button-bg-color: var(--primary);

  --button-hover-border-color: var(--white);
  --button-hover-bg-color: var(--white);
  --button-hover-color: var(--white);

  padding: 1px;
  background: transparent;

  &:hover {
    background-color: var(--button-hover-bg-color);

    .border-gradient {
      animation-play-state: paused;
    }

    .button-bg {
      background-color: var(--button-hover-bg-color) !important;
      color: var(--button-hover-color) !important;

      [data-theme='dark'] & {
        color: var(--gray-500) !important;
      }
    }
  }

  &.btn__icon\:loading {
    padding-right: 1px;
  }

  .button-bg {
    z-index: 1;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    background-color: var(--button-bg-color);
    border-radius: 10em;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s;
    display: flex;
    position: relative;

    .button-inside {
      z-index: 2;
      grid-column-gap: 0.5em;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
      padding: 1.3375em 2.525em;
      font-size: 1.125em;
      font-weight: 500;
      line-height: 1.2;
      display: flex;
    }
  }

  .border-gradient {
    width: 100%;
    min-height: 308px;
    transition: opacity 0.2s;
    position: absolute;
    transform: rotate(0);
  }

  .animation-bg {
    z-index: 0;
    width: 6em;
    height: 4em;
    position: absolute;
  }
}

@keyframes spin {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
