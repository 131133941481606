$leftOffset2image: 22px;
$leftOffset3image: 40px;
.aayn-instant-ad-platform-header {
  position: relative;
  display: flex;
  align-items: center;
  &-one {
    left: 0;
  }
  &-two {
    left: $leftOffset2image;
  }
  &-three {
    left: $leftOffset3image;
  }
  &-image {
    border-radius: 10px;
    border: 1px solid var(--white);
    &.image-1 {
      width: 58px;
      height: 58px;
      z-index: 2;
    }
    &.image-2 {
      width: 52px;
      height: 52px;
      transform: translateX(-#{$leftOffset2image});
      z-index: 1;
    }
    &.image-3 {
      width: 48px;
      height: 48px;
      transform: translateX(-#{$leftOffset3image});
    }
  }
}
