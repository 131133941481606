@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

.aayn-collapse-card {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 84px;
  border-radius: 1.875rem;
  background: var(--white);
  box-shadow: 0rem 0.625rem 3.125rem rgba(var(--gray-rgb), 0.06);
  margin-bottom: 20px;
  overflow: hidden;
  border: 1px solid var(--white);

  &.active {
    max-height: max-content;
    transition: 0.5s all;
    overflow: auto;

    .aayn-collapse-card--header__collapse {
      &-icon {
        width: 0.5rem;
        height: 0.5rem;
        background-image: url(/assets/images/icons/collapse-arrow.svg);
        background-repeat: no-repeat;
        transform: rotate(90deg);
      }
    }

    .aayn-collapse-card--main {
      display: block;
      animation: 0.5s;
      opacity: 1;
      height: auto;
      visibility: visible;
    }
  }

  &.disabled {
    .aayn-collapse-card--header {
      &__title {
        &-bold {
          color: var(--gray-400);

          span {
            color: var(--gray-400);
          }
        }

        p {
          color: var(--gray-400);
        }
      }
    }
  }

  &--header {
    width: 100%;
    display: inline-flex;
    align-items: center;
    transition: 0.2s;
    margin: 12px auto;
    cursor: pointer;
    user-select: none;

    &__collapse {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5625rem;
      height: 3.125rem;
      background: var(--white);
      border-radius: 0 0.6rem 0.6rem 0;
      margin-right: 1.625rem;
      position: relative;

      &-icon {
        width: 0.5rem;
        height: 0.5rem;
        background-image: url(/assets/images/icons/collapse-arrow.svg);
        background-repeat: no-repeat;
        transition: 0.2s;
        animation: rotation 0.5s linear;
      }
    }
    $parent: &;
    &-right {
      justify-content: space-between;
      padding-left: 25px;
      #{$parent}__collapse {
        border-radius: 0.6rem 0 0 0.6rem;
        order: 2;
        margin-left: 1.625rem;
        margin-right: 0;

        &-icon {
          right: 0.5625rem;
          left: unset;
        }
      }
    }

    &__title {
      padding: 5px;
      display: flex;
      align-items: center;
      gap: 20px;
      &-icon {
        height: 100%;
      }

      &-bold {
        font-weight: 600;
        font-size: 1.5625rem;

        text-align: left;
        color: var(--primary);
        display: flex;
        align-items: center;

        &-sub-title {
          font-weight: 500;
          font-size: var(--fs-14);

          line-height: 32px;
          text-align: center;
          margin-left: 5px;
        }
      }

      p {
        font-weight: normal;
        font-size: var(--fs-16);

        margin-top: 4px;
        text-align: left;
        color: var(--gray-500);
      }
    }

    &__right {
      span {
        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 500;
        font-size: var(--fs-16);

        line-height: 32px;
        text-align: left;
        color: var(--gray-500);

        ayn-progressbar {
          margin-left: 10px;
        }

        strong {
          margin-left: 10px;
          color: var(--success);
        }
      }

      &--error {
        display: flex;
        align-items: center;

        ayn-icon {
          color: var(--orange);
          margin-right: 7px;
        }

        span {
          font-weight: 500;
          font-size: var(--fs-16);

          line-height: 32px;
          text-align: left;
          color: var(--orange);
        }

        button {
          margin-right: 40px;
          margin-left: 15px;
        }
      }

      &--coming-soon {
        display: flex;
        justify-content: center;
        align-items: center;

        ayn-icon {
          color: var(--gray-400);
          width: 10px;
        }

        &__text {
          margin-left: 5px;
          font-size: var(--fs-12);
          line-height: 20px;
          color: var(--gray-400);
        }
      }
    }
  }

  &--main {
    margin: 0 1.5625rem 1.5625rem;
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: 0.2s all;
  }
}
