// TOPBAR:BUSINESS-SELECTION--BODY

.aayn-business-selection--body {
  background: var(--white);
  width: 400px;
  margin-top: 0;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 5px 30px rgba(var(--gray-rgb), 0.08);
  border-top: 1px solid var(--gray-200);
  padding: 11px 12px 12px;
  top: 56px !important;
  left: 0 !important;

  &__list {
    max-height: 261px;
    overflow: auto;

    @include scrollbar(5px);

    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 46px;
      border-radius: 15px;
      background: var(--white);
      border: 1px solid var(--gray-300);
      padding: 0 22px 0 20px;
      transition: 0.2s;

      &:not(:last-child) {
        margin-bottom: 7px;
      }

      &:not(.active) {
        cursor: pointer;
      }

      &:hover {
        background: rgba(var(--gray-rgb), 0.05);
      }

      &.active {
        border-color: var(--gray-500);
      }

      &--name {
        font-size: var(--fs-16);
        color: var(--gray-500);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
      }

      &--type {
        font-size: var(--fs-14);
        color: var(--gray-400);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
      }
    }
  }
}
