:root {
  --select-border-radius: 15px;
}

.ayn-select {
  border: 1px solid transparent;
  border-radius: var(--select-border-radius);
  transition: 0.2s all;

  &--placeholder {
    color: var(--gray-400);
    font-weight: 500;
  }

  &:has(button:hover) {
    border-color: rgba(var(--gray-rgb), 0.6);
  }

  &.show {
    &:has(:not(button:focus)) {
      border-radius: var(--select-border-radius) var(--select-border-radius) 0 0;
      border-color: rgba(var(--gray-rgb), 0.6);
    }

    .ayn-select--inner {
      .ayn-select--btn {
        border-radius: calc(var(--select-border-radius) - 1px) calc(var(--select-border-radius) - 1px) 0 0;
      }
    }
  }

  &__disabled {
    .ayn-select--btn {
      border: 1px solid var(--gray-200) !important;
      background-color: var(--gray-25) !important;
      color: var(--gray-400);
      cursor: default;
      opacity: 75%;
      height: calc(100% - 2px);
    }
  }

  &--inner {
    height: 3.5rem;
  }

  &--btn {
    height: 100%;
    width: 100%;
    background: var(--white);
    border: 0;
    border-radius: calc(var(--select-border-radius) - 1px);
    outline: 0;
    cursor: pointer;
    color: var(--gray-500);
    padding: 0 30px;
    text-align: left;
    font-size: var(--fs-16);
    font-weight: 500;
    display: flex;
    align-items: center;

    > div {
      margin-right: auto;
    }

    ayn-icon {
      color: var(--gray-500);

      &[name='tick'] {
        svg,
        g,
        path,
        circle {
          color: var(--gray-500);
        }
      }
    }

    .ayn-option--inner {
      overflow: initial;

      ayn-icon {
        width: 20px;
        margin-right: 12px;
      }
    }
  }

  &--search {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    ayn-icon {
      color: var(--gray-500);
    }

    input {
      width: 100%;
      height: 100%;
      border: 0;
      outline: 0;
      border-radius: 20px 20px 0 0;
      color: var(--gray-500);
      background: var(--white);
 
      font-size: var(--fs-16);
      font-weight: 500;
      padding-left: 30px;

      &::placeholder {
        color: var(--gray-400);
      }
    }
  }

  &__append-body {
    --width: auto;

    @include scrollbar;

    margin-top: 0;
    border: 0.0625rem solid rgba(var(--gray-rgb), 0.6);
    border-top: none;
    width: calc(var(--width) + 2px);
    position: relative;
    top: 0 !important;
    left: -1px !important;
    border-radius: 0 0 15px 15px;
    padding: 7px 11px 12px 11px;
    background-color: var(--white);
  }

  ayn-overlay {
    display: block;
    width: 100%;
    position: relative;

    .ayn-overlay {
      margin-top: 0;
      border: 0.0625rem solid rgba(var(--gray-rgb), 0.6);
      border-top: none;
      width: calc(100% + 0.125rem);
      top: 0 !important;
      left: -0.0625rem !important;
      border-radius: 0 0 0.9375rem 0.9375rem;
      padding: 0.4375rem 0.6875rem 0.75rem 0.6875rem;
      background-color: var(--white);
    }
  }

  &__open-search ayn-overlay .ayn-overlay {
    padding-top: 0;
  }

  &--content {
    overflow: auto;

    &-label {
      font-size: var(--fs-16);
      line-height: 32px;
      text-align: left;
      color: var(--gray-500);

      @include fw('semibold');
    }

    @include scrollbar(5px);
  }

  &:not(.show).invalid {
    border-color: var(--orange);

    .ayn-select--inner {
      ayn-icon {
        svg,
        path,
        g,
        circle {
          fill: var(--orange) !important;
        }
      }
    }

    .ayn-select--btn {
      color: var(--orange);
    }

    &::placeholder {
      color: var(--orange);
    }
  }
}

ayn-form-field .form-field ayn-select ayn-option ayn-icon {
  position: static;
  top: unset;
  right: unset;
  transform: unset;
}

.ayn-option {
  width: 100%;
  margin-top: 0.3125rem;
  cursor: pointer;
  background: var(--white);
  border: 1px solid var(--gray-300);
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs-16);
  color: var(--gray-500);
  border-radius: 0.9375rem;
  transition: 0.1s;
  padding: 0 1.25rem;
  font-weight: 500;
  position: relative;

  &--inner {
    overflow: initial;

    ayn-icon {
      width: 20px;
      margin-right: 12px;
    }

    img {
      margin-right: 12px;
    }
  }

  &:not(.ayn-option__disabled):not(.selected):hover {
    background: rgba(var(--gray-rgb), 0.05);
  }

  &.selected {
    background: rgba(var(--primary), 0.1);
    border-color: rgba(var(--primary, 0.6));
    color: rgb(var(--primary));
    font-weight: 600;

    [data-theme='dark'] & {
      color: var(--gray-500) !important;
    }

    ayn-icon,
    svg,
    path,
    g,
    circle {
      color: currentColor;
    }
  }

  &__disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &--inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.3125rem;
  }
}

ayn-select {
  ayn-icon {
    margin-right: 0;
  }

  &.ng-invalid.ng-touched {
    & + ayn-icon {
      display: none;
    }

    .ayn-select--btn {
      .ayn-select--tick {
        display: none;
      }
    }
  }
}

form.ng-submitted {
  ayn-select {
    &.ng-invalid.ng-untouched {
      ayn-icon[name='tick'] {
        display: none;
      }
    }
  }
}

ayn-form-field {
  ayn-select:has(.ayn-select--tick) + ayn-icon {
    right: 42px;
  }

  ayn-select:has(.ayn-select__open-search.show) + ayn-icon {
    display: none;
  }
}
