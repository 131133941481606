.aayn-publish--body {
  top: 56px !important;
  margin-left: -318px;

  .ayn-overlay-content {
    display: flex;
    flex-direction: column;
    background: var(--accent);
    padding: 10px;
    width: 140px;
    max-width: 140px;
    border-radius: 0 0 15px 15px;

    [ayn-button] {
      ayn-icon {
        min-width: 25px;
        margin-right: 6px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
