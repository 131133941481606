.ayn-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.0625rem;
  margin: -0.0625rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.0625rem;
}

.ayn-checkbox {
  display: flex;
  cursor: pointer;

  &.default {
    width: 1.375rem;
    height: 1.375rem;

    .ayn-checkbox-box {
      border: 0.0625rem solid var(--gray-200);
      background: var(--white);
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;

      &.ayn-highlight {
        border-color: var(--success);
        background: var(--success);
      }
    }
  }

  &.toggle {
    --height: 19px;

    position: relative;
    display: inline-block;
    width: 34px;
    height: var(--height);

    .ayn-checkbox-box {
      position: absolute;
      border-radius: 34px;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid var(--gray-500);
      background-color: var(--white);

      &::before {
        position: absolute;
        content: '';
        height: calc(var(--height) - 4px);
        width: calc(var(--height) - 4px);
        left: 1px;
        bottom: 1px;
        border-radius: 50%;
        background-color: var(--gray-500);
        transition: 0.15s;
      }

      &.ayn-highlight {
        border-color: var(--primary);

        &::before {
          transform: translateX(15px);
          background-color: var(--primary);
        }
      }
    }

    &.ayn-checkbox--disabled {
      .ayn-checkbox-box {
        border-color: var(--gray-100);

        &::before {
          background-color: var(--gray-100);
        }
      }
    }
  }

  &.payment {
    width: 1.375rem;
    height: 1.375rem;

    .ayn-checkbox-box {
      border: 0.0625rem solid var(--gray-400);
      background: var(--white);
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: all 0.15s;

      &.ayn-highlight {
        border-color: var(--success);
        background: var(--success);
      }
    }
  }

  &.chart {
    width: 1.125rem;
    height: 1.125rem;

    .ayn-checkbox-box {
      border: 0.0625rem solid var(--gray-400);
      background: var(--white);
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: all 0.15s;

      &.ayn-highlight {
        border-color: var(--white);
        background: inherit;
      }
    }
  }

  &.audience {
    width: 9.625rem;
    height: 3.5rem;
    border-radius: 0.9375rem;
    background: var(--gray-400);
    display: flex;
    justify-content: space-between;

    label {
      font-weight: 500;
      font-size: var(--fs-16);

      line-height: 1.0625rem;
      text-align: left;
      color: var(--white);
      margin: auto 0.9375rem;
      max-width: 4.75rem;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
    }

    .ayn-checkbox-box {
      margin: auto 0.8125rem;
      justify-content: center;
      align-items: center;
      width: 1.375rem;
      height: 1.375rem;
      background: var(--white);
      border: 0.0625rem solid var(--gray-200);
      border-radius: 0.5rem;

      &.ayn-highlight {
        background: var(--primary);
        border: 0.0625rem solid var(--white);
      }
    }
  }
}
