.ayn-badge-list {
  margin-top: 30px;
  display: flex;
  align-items: center;

  &-inner {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    overflow: hidden;
    height: 46px;
    width: auto;

    ayn-badge {
      display: inline-flex;
    }
  }

  [ayn-button] {
    min-width: 46px;
    min-height: 46px;
    max-width: 46px;
    max-height: 46px;
    border-radius: 23px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    background: var(--gray-500);
    color: var(--white);
    border: none;
    outline: none;
  }
}

.ayn-badge-list--overlay {
  position: relative;
}

.ayn-badge-list--more-items {
  border-radius: 15px;
  background: var(--gray-500);
  padding: 9px 12px;
  color: var(--white);
  font-size: var(--fs-14);
  text-align: center;
  top: 40px !important;
  left: 50% !important;
  transform: translateX(calc(-50% - 20px)) !important;

  &::before {
    content: '';
    background: url(/assets/images/icons/tooltip-arrow.svg) no-repeat center center;
    background-size: contain;
    rotate: 0;
    left: calc(50% - 10px);
    top: -6px;
    position: absolute;
    width: 20px;
    height: 6px;
  }

  ul {
    margin-top: 8px;

    li {
      margin-bottom: 10px;
    }
  }
}
